import { Dispatch, FC, SetStateAction } from 'react';

import { useNavigate } from 'react-router-dom';
import { get1CInstanceWithIdRoute } from 'routes/settings/list';

import { CInstancesResponseType } from 'services/1c-instances/cInstancesService.types';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CInstanceType } from 'types/cInstances.types';

import { FormResponseType } from '../../settings1cInstances.utils';
import useColumns from './useColumns.hook';

interface TableProps {
  dataSource?: CInstancesResponseType;
  loading: boolean;
  isInitialLoading: boolean;
  setCInstanceToDelete: Dispatch<SetStateAction<string[]>>;
  isShiftTab?: boolean;
}

const Table: FC<TableProps> = (props) => {
  const { dataSource, loading, setCInstanceToDelete, isInitialLoading, isShiftTab } = props;

  const navigate = useNavigate();
  const form = Form.useFormInstance();

  const columns = useColumns({ setCInstanceToDelete, isShiftTab });

  const onRow = (row: CInstanceType) => ({
    onClick: () => {
      const requestBody = form.getFieldsValue();

      navigate(get1CInstanceWithIdRoute(row.id), {
        state: { requestBody },
      });
    },
  });

  return (
    <FiltersV2.Table<CInstanceType, FormResponseType>
      isInitialLoading={isInitialLoading}
      rowKey="id"
      columns={columns}
      dataSource={dataSource?.items}
      loading={loading}
      total={dataSource?.totalCount}
      onRow={onRow}
    />
  );
};

export default Table;
