import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { useEnhanceMutation } from 'services/crypto/cryptoApiService';

import { Button, Form, Icon, Radio, Steps, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import WithLoader from 'containers/wrappers/with-loader';

import s from './SignCheck.module.scss';
import ExpiredModal from './containers/expired-modal';
import {
  checkCryptoProvider,
  checkExtension,
  checkPlugin,
  getCertificates,
  signString, // signString,
} from './signCheck.utils';

type CertificateType = {
  validTo: Date;
  validFrom: Date;
  subjectName: string;
  issuerName: string;
  algoName: string;
  valid: boolean;
};

const SIGN_STR = 'Hello, world!';

const SignCheck = () => {
  const [isExpiredModalVisible, setIsExpiredModalVisible] = useState(false);
  const [enhance, { isLoading }] = useEnhanceMutation();
  const [enhanceErrorMessage, setEnhanceErrorMessage] = useState('');

  const [form] = Form.useForm();
  const [certificates, setCertificates] = useState<CertificateType[]>([]);
  const [activeStep, setActiveStep] = useState<{
    number: number;
    state: 'pending' | 'done' | 'disabled' | 'error';
  }>({
    number: 1,
    state: 'pending',
  });

  const handleEnhance = async () => {
    const res = await signString(form.getFieldValue('certificate'), btoa(SIGN_STR));
    if (res)
      enhance({
        signature: res,
        data: btoa(SIGN_STR),
      }).then((r) => {
        if ('error' in r) {
          const error = r.error as { data: { message: string } };
          setEnhanceErrorMessage(error.data.message);
          setActiveStep({
            number: 4,
            state: 'error',
          });
        } else {
          setActiveStep({
            number: 4,
            state: 'done',
          });
        }
      });
  };

  const selectedCertificate = Form.useWatch('certificate', form);

  const selectedCertificateInfo = useMemo(() => {
    return certificates.find((certificate) => {
      return (
        `${certificate.validFrom.toString()}.${certificate.validTo.toString()}.${
          certificate.subjectName
        }` === selectedCertificate
      );
    });
  }, [certificates, selectedCertificate]);

  const steps = useMemo(() => {
    return [
      {
        title: t('sign_step0_pending_title'),
        state: activeStep.number === 0 ? activeStep.state : 'done',
      },
      {
        title: t('sign_step1_pending_title'),
        state:
          activeStep.number === 1 ? activeStep.state : activeStep.number > 1 ? 'done' : 'disabled',
      },
      {
        title: t('sign_step2_pending_title'),
        state:
          activeStep.number === 2 ? activeStep.state : activeStep.number > 2 ? 'done' : 'disabled',
      },
      {
        title: t('sign_check_choose_certificate'),
        state:
          activeStep.number === 3 ? activeStep.state : activeStep.number > 3 ? 'done' : 'disabled',
      },
      {
        title: t('sign_step4_pending_title'),
        state:
          activeStep.number === 4 ? activeStep.state : activeStep.number > 4 ? 'done' : 'disabled',
      },
    ];
  }, [activeStep]);

  const extensionStep = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const isExtensionInstalled = checkExtension();

        if (isExtensionInstalled) {
          setActiveStep({
            number: 1,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 0,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  const pluginStep = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const isPluginInstalled = await checkPlugin();

        if (isPluginInstalled) {
          setActiveStep({
            number: 2,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 1,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  const cryptoProviderStep = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const isCryptoProviderInstalled = await checkCryptoProvider();

        if (isCryptoProviderInstalled) {
          setActiveStep({
            number: 3,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 2,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    const run = async () => {
      const isExtensionStepSuccess = await extensionStep();
      if (isExtensionStepSuccess) {
        const isPluginStepSuccess = await pluginStep();

        if (isPluginStepSuccess) {
          const isCryptoProviderSuccess = await cryptoProviderStep();

          if (isCryptoProviderSuccess) {
            try {
              const certs = (await getCertificates()) as CertificateType[];
              setCertificates(certs);
            } catch (e) {
              setActiveStep({
                number: 3,
                state: 'error',
              });
            }
          }
        }
      }
    };

    run();
  }, []);

  const renderCertificate = (certificate: CertificateType, notSelectable?: boolean) => {
    const splitSubject = certificate.subjectName.split(', ');
    const title = splitSubject.find((part) => part.includes('CN='));
    return (
      <div className={s.radio}>
        {notSelectable ? (
          <span className={s.radioTitle}>{title}</span>
        ) : (
          <Radio.Button
            disabled={!certificate.valid}
            value={`${certificate.validFrom.toString()}.${certificate.validTo.toString()}.${
              certificate.subjectName
            }`}>
            <span className={s.radioTitle}>{title}</span>
          </Radio.Button>
        )}
        <div className={s.certificate}>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_issuer')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.issuerName}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_owner')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.subjectName}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_date_from')}</Typography.Text>
            <Typography.Text type="secondary">
              {dayjs(certificate.validFrom).format('YYYY-MM-DD HH:mm:ss')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_date_to')}</Typography.Text>
            <Typography.Text type="secondary">
              {dayjs(certificate.validTo).format('YYYY-MM-DD HH:mm:ss')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_algo')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.algoName.toString()}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_valid')}</Typography.Text>
            <Typography.Text type="secondary">
              {certificate.valid ? t('common_yes') : t('common_no')}
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ExpiredModal
        isOpen={isExpiredModalVisible}
        onClose={() => setIsExpiredModalVisible(false)}
      />
      <WithLoader isLoading={false}>
        <div className={s.header}>
          <Typography.Title level={3}>{t('sign_check_enhance')}</Typography.Title>
        </div>
        <div className={s.content}>
          <div className={s.left}>
            <Typography.Title level={4}>
              {activeStep.number === 3
                ? t('sign_check_choose_certificate')
                : t(`sign_step${activeStep.number}_${activeStep.state}_title`)}
            </Typography.Title>
            <Typography.Text size="14" type="secondary">
              {(activeStep.state === 'error' && enhanceErrorMessage) ||
                t(`sign_step${activeStep.number}_${activeStep.state}_description`)}
            </Typography.Text>

            {activeStep.number === 3 && (
              <div className={s.signData}>
                <Icon name="info" color="var(--color-primary-6)" />
                <Typography.Text size="14" weight="500">
                  {t('sign_data', { data: SIGN_STR })}
                </Typography.Text>
              </div>
            )}

            {activeStep.state !== 'error' && activeStep.number !== 3 && activeStep.number !== 4 && (
              <div className={s.waiting}>
                <UniversalLoader />
                <Typography.Text size="14" type="secondary">
                  {t('sign_waiting_title')}
                </Typography.Text>
              </div>
            )}

            {activeStep.number >= 3 && certificates.length > 0 && (
              <div style={{ display: activeStep.number === 4 ? 'none' : 'block' }}>
                <Form form={form}>
                  <div />
                  <Form.Item name="certificate">
                    <Radio.Group className={s.radioGroup}>
                      {certificates.map((certificate) => renderCertificate(certificate))}
                    </Radio.Group>
                  </Form.Item>
                  {selectedCertificate && (
                    <Button
                      className={s.button}
                      rightIcon="arrowRight"
                      onClick={handleEnhance}
                      loading={isLoading}>
                      {t('sign_check_enhance_button')}
                    </Button>
                  )}
                </Form>
              </div>
            )}

            {activeStep.number === 4 && selectedCertificateInfo && (
              <div className={s.enhance}>
                {activeStep.state === 'done' && renderCertificate(selectedCertificateInfo, true)}
                <div className={s.buttons}>
                  <Button
                    leftIcon="arrowLeft"
                    onClick={() => {
                      setActiveStep({
                        number: 3,
                        state: 'pending',
                      });
                    }}>
                    {t('sign_check_choose_another_certificate')}
                  </Button>
                  {activeStep.state === 'error' && (
                    <Button
                      leftIcon="refresh"
                      type="secondary"
                      onClick={handleEnhance}
                      loading={isLoading}>
                      {t('sign_check_again')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={s.right}>
            <Typography.Title level={5} className={s.title}>
              {t('sign_steps_title')}
            </Typography.Title>
            <Steps className="s" items={steps} hideLeft />
          </div>
        </div>
      </WithLoader>
    </>
  );
};

export default SignCheck;
