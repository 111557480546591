import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';

import { SuggestionDecisionType } from 'types/suggestion.types';

import FilesField from '../files-field';

interface Props {
  suggestionId: string;
  releaseReportFile?: SuggestionDecisionType['suggestion']['releaseReportFile'];
  performer: SuggestionDecisionType['suggestion']['performer'];
}

const Performer = (props: Props) => {
  const { releaseReportFile, performer, suggestionId } = props;

  return (
    <Flex vertical gap="24">
      <DocumentView.Employee
        showAvatar
        showEmployer={false}
        employee={performer}
        title={t('common_responsible_for_implementation')}
      />
      {releaseReportFile && (
        <FilesField
          title={t('suggestion_release_report')}
          files={releaseReportFile}
          suggestionId={suggestionId}
        />
      )}
    </Flex>
  );
};

export default Performer;
