import { t } from 'i18next';

import { Flex, TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import IsPublish from 'pages/surveys/containers/is-publish';
import TitleWithTags from 'pages/surveys/containers/title-with-tags';

import { CommunityAnketaType } from 'types/community.types';
import { SORT_ENUM } from 'types/sort.types';

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  order: SORT_ENUM.DESC,
  property: 'updatedAt',
};

export type FormResponseType = {
  page: number;
  size: number;
  search: string;
  order: SORT_ENUM;
  property?: string;
};

const infoRenderer = (props: CommunityAnketaType) => {
  return (
    <TitleWithTags
      text={props.name}
      primaryTag={t('surveys_table_anketa_id', { id: props.anketologId })}
      secondaryTag={t('surveys_table_revision_id', { id: props.revisionId })}
    />
  );
};

const createdAtRenderer = (date: string) => <TableDate date={date} />;

const activeRenderer = (value: CommunityAnketaType['isPublish']) => {
  return (
    <Flex vertical align="center">
      <IsPublish isActive={value} />
    </Flex>
  );
};

export const columns: TableProps<CommunityAnketaType>['columns'] = [
  {
    title: t('surveys_column_info'),
    render: infoRenderer,
  },
  {
    title: t('surveys_column_created'),
    dataIndex: 'createdAt',
    align: 'center',
    width: 100,
    render: createdAtRenderer,
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    title: t('common_published'),
    dataIndex: 'isPublish',
    align: 'center',
    render: activeRenderer,
    width: 140,
  },
];
