import { useCallback, useEffect, useRef } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import initialApiService from 'services/initialApiService';
import { useGetSurveyStatusByIdQuery } from 'services/surveys/surveysApiService';
import { GetSurveyStatusByIdResponseType } from 'services/surveys/surveysApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { SURVEY_STATUSES_ENUM } from 'types/surveys.types';

import { DEFAULT_POLLING_INTERVAL } from './surveysDetails.utils';

const useSurveysDetailsValue = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const {
    data: surveyStatus,
    isLoading: surveyStatusIsLoading,
    refetch,
  } = useGetSurveyStatusByIdQuery(id ? { id } : skipToken);

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const refetchHandler = useCallback(() => {
    if (id) {
      const onSuccess = (response?: GetSurveyStatusByIdResponseType) => {
        const status = response?.status;
        const isNotInProgress = status !== SURVEY_STATUSES_ENUM.IN_PROGRESS;

        if (isNotInProgress && intervalRef.current) {
          resetInterval();
          dispatch(
            initialApiService.util.invalidateTags([
              { type: SERVICE_TAGS.GET_SURVEYS_SETTINGS_BY_ID },
            ]),
          );
        }
      };

      intervalRef.current = setInterval(() => {
        refetch().then(handleRequest({ onSuccess }));
      }, DEFAULT_POLLING_INTERVAL);
    }
  }, [dispatch, id, refetch]);

  useEffect(() => {
    refetchHandler();

    return () => {
      if (intervalRef.current) {
        resetInterval();
      }
    };
  }, [refetchHandler]);

  return {
    status: surveyStatus,
    surveyStatusIsLoading,
    refetchHandler,
  };
};

export default useSurveysDetailsValue;
