import { useNavigate } from 'react-router-dom';
import { getDocumentsTopManagementFormsDetailsRoute } from 'routes/documents/list';

import FiltersV2 from 'containers/filters-v2';

import { RenewFormItemType } from 'types/topManagement.types';

import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: RenewFormItemType[];
  loading?: boolean;
  totalCount?: number;
}

const Table = (props: Props) => {
  const { dataSource, loading, totalCount } = props;

  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = (record: RenewFormItemType) => ({
    onClick: () => navigate(getDocumentsTopManagementFormsDetailsRoute(record.id)),
  });

  return (
    <FiltersV2.Table
      onRow={onRow}
      rowKey="employerId"
      className={s.table}
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      total={totalCount}
      pagination={false}
    />
  );
};

export default Table;
