export const SURVEY_SETTLEMENT_FORM_NAME = 'SURVEY_SETTLEMENT_FORM_NAME';

export enum SURVEY_SETTLEMENT_FORM_ENUM {
  NAME = 'name',
  ANKETOLOG_ID = 'anketologId',
  REVISION_ID = 'revisionId',
  NOTIFICATION_INTERVAL = 'notificationInterval',
  SETTLEMENTS = 'settlementIds',
  UPDATE_SETTINGS = 'updateSettings',
  RE_ASSIGNMENT = 'reAssignment',
  RE_ASSIGNMENT_SETTINGS = 'reAssignmentSettings',
  RE_ASSIGNMENT_DATE_TO = 'reAssignmentDateTo',
  IS_PLAY_GIFT = 'isPlayGift',
  WINNERS_COUNT = 'winnersCount',
}

export interface SurveySettlementFormResponse {
  [SURVEY_SETTLEMENT_FORM_ENUM.NAME]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.REVISION_ID]: number;
  [SURVEY_SETTLEMENT_FORM_ENUM.NOTIFICATION_INTERVAL]?: number;
  [SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS]: string[];
  [SURVEY_SETTLEMENT_FORM_ENUM.UPDATE_SETTINGS]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_SETTINGS]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_DATE_TO]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.IS_PLAY_GIFT]: boolean;
  [SURVEY_SETTLEMENT_FORM_ENUM.WINNERS_COUNT]: number;
}
