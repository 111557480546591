import { useNavigate } from 'react-router-dom';
import { GUIDE_HANDBOOK_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import {
  useDeleteHandbookMutation,
  useDeleteSettlementsFromListMutation,
} from 'services/settlement/settlementApiService';

import { Button, ModalDefault, useHandleRequestWithModal } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  settlementId?: string;
  selectedRowKeys?: string[];
  onClose: () => void;
}

const DeleteModal = (props: Props) => {
  const navigate = useNavigate();
  const { open, settlementId, selectedRowKeys, onClose } = props;

  const [deleteSettlements, { isLoading: isDeleteSettlementsLoading }] =
    useDeleteSettlementsFromListMutation();
  const [deleteSingleSettlement, { isLoading: isDeleteSingleSettlementLoading }] =
    useDeleteHandbookMutation();

  const isLoading = isDeleteSettlementsLoading || isDeleteSingleSettlementLoading;

  const handleNavigateToList = () => navigate(GUIDE_HANDBOOK_ROUTE);

  const onAfterRemove = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: { children: t('guide_removed_successfully') },
      onConfirm: handleNavigateToList,
      onClose: handleNavigateToList,
    },
  });

  const handleRemove = () => {
    if (selectedRowKeys) {
      return deleteSettlements(selectedRowKeys).then(onAfterRemove).finally(onClose);
    }

    if (settlementId) {
      deleteSingleSettlement({ id: settlementId }).then(onAfterRemove).finally(onClose);
    }
  };

  return (
    <ModalDefault open={open} onCancel={onClose} width={320} closable>
      <ModalDefault.Icon name="bin" type="danger" />
      <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
      <ModalDefault.Description>{t('guide_remove_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button danger ghost fullWidth leftIcon="bin" loading={isLoading} onClick={handleRemove}>
          {t('common_delete')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DeleteModal;
