import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import SettingsEmployersTable from './containers/table';
import { DEFAULT_FORM_STATE } from './settingsEmployers.utils';
import useGetData from './useGetData.hook';

const SettingsEmployers = () => {
  const [form] = Form.useForm();

  const { data, isFetching, isLoading } = useGetData({ form });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('employee_group_directory')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.TableFilters>
          <FiltersV2.Search />
        </FiltersV2.TableFilters>
        <SettingsEmployersTable
          loading={isFetching}
          isInitialLoading={isLoading}
          dataSource={data}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SettingsEmployers;
