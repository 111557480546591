import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE,
  getKedoReviewTaskWithIdRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useCreateKedoReviewTaskMutation } from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { CreateKedoReviewTaskPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { useHandleRequestWithModal } from 'gazprom-ui-lib';

import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KedoReviewTaskFormResponse,
} from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';

import { KedoReviewTaskType } from 'types/review-task.types';

import { prepareKedoReviewFile } from '../documents-kedo-review-task-details/documentsKedoReviewTaskDetails.utils';
import { getDivisionIds } from '../documentsKedoReviewTasks.utils';

export const useSubmit = () => {
  const navigate = useNavigate();
  const handleGoBack = () => navigate(DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE);
  const kedoReviewTaskIdRef = useRef<string>(crypto.randomUUID());

  const [createKedoReviewTask, { isLoading }] = useCreateKedoReviewTaskMutation();
  const handleNavigateDetails = (data?: KedoReviewTaskType) => {
    if (data) {
      navigate(getKedoReviewTaskWithIdRoute(data.id));
    }
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccess: handleNavigateDetails,
    onSuccessModalConfig: {
      title: {
        children: t('common_saved_successfully'),
      },
    },
    onErrorModalConfig: {
      title: {
        children: t('common_error'),
      },
      icon: {
        name: 'error',
        type: 'warning',
      },
    },
  });

  const handleSubmit = (form: KedoReviewTaskFormResponse) => {
    const preparedFile = prepareKedoReviewFile(form[KEDO_REVIEW_TASK_FORM_ENUM.FILE]);

    const { checked, halfChecked } = form[KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS] ?? {};

    const requestBody: CreateKedoReviewTaskPropsType = {
      id: kedoReviewTaskIdRef.current,
      name: form[KEDO_REVIEW_TASK_FORM_ENUM.NAME],
      docName: form[KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME],
      files: [preparedFile],
      needEmployeeSign: form[KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED],
      docDate: form[KEDO_REVIEW_TASK_FORM_ENUM.DOC_DATE],
      docNumber: form[KEDO_REVIEW_TASK_FORM_ENUM.DOC_NUMBER],
      employerId: form[KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYER],
      employeeIds: form[KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEES] ?? [],
      divisions: {
        checkedDivisionIds: getDivisionIds(checked),
        partialCheckedDivisionIds: getDivisionIds(halfChecked),
      },
    };

    createKedoReviewTask(requestBody).then(handleCreate);
  };

  return { handleSubmit, handleGoBack, isLoading, kedoReviewTaskIdRef };
};
