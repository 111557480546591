import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { useGetHotContactsByEmployersQuery } from 'services/guide/guideApiService';

import { Table } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './GuideContacts.module.scss';
import useColumns from './useColumns.hook';

const ITEMS_PER_PAGE = 10;

const GuideContacts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: hotContacts, isLoading } = useGetHotContactsByEmployersQuery();
  const columns = useColumns();

  const { totalPages, itemsToShow } = useMemo(() => {
    const totalPages = Math.ceil(hotContacts?.length ?? 0 / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const itemsToShow = hotContacts?.slice(startIndex, endIndex);

    return { totalPages, itemsToShow };
  }, [currentPage, hotContacts]);

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('guide_important_numbers')}</ContentHeader.Title>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <Table
          className={s.table}
          dataSource={itemsToShow}
          columns={columns}
          pagination={{
            defaultCurrent: currentPage,
            defaultPageSize: ITEMS_PER_PAGE,
            total: totalPages,
          }}
          loading={isLoading}
          rowKey="id"
          onChange={(pagination) => setCurrentPage(pagination.current as number)}
          scroll={{ y: '50vh' }}
        />
      </WithLoader>
    </WithOverflow>
  );
};

export default GuideContacts;
