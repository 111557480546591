import { prepareQueryParams } from 'gazprom-common-lib';

import {
  ApproveShiftMedexamRequestPropsType,
  GetShiftMedexamRequestsPropsType,
  GetShiftMedexamRequestsResponseType,
  GetShiftmedexamRequestStatusesResponseType,
  GetUploadedShiftAnswerFilePropsType,
  GetUploadedShiftAnswerFileResponseType,
  RejectApproveShiftMedexamRequestResponseType,
  RejectShiftMedexamRequestPropsType,
  UploadSurveyXlsPropsType,
} from './shiftApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

import cInitialApiService from '../cApiService';
import { createShiftUrl } from './utils';

const shiftApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getShiftMedexamRequests: builder.query<
      GetShiftMedexamRequestsResponseType,
      GetShiftMedexamRequestsPropsType
    >({
      query: (params) => ({
        url: createShiftUrl(`medexam-requests${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_SHIFT_MEDEXAM_REQUESTS],
    }),
    getShiftMedexamRequestStatuses: builder.query<GetShiftmedexamRequestStatusesResponseType, void>(
      {
        query: () => ({
          url: createShiftUrl('medexam-requests/status'),
        }),
      },
    ),
    rejectShiftMedexamRequest: builder.mutation<
      RejectApproveShiftMedexamRequestResponseType,
      RejectShiftMedexamRequestPropsType
    >({
      query: ({ id, payload }) => ({
        url: createShiftUrl(`medexam-requests/${id}/reject`),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_SHIFT_MEDEXAM_REQUESTS],
    }),
    approveShiftMedexamRequest: builder.mutation<
      RejectApproveShiftMedexamRequestResponseType,
      ApproveShiftMedexamRequestPropsType
    >({
      query: ({ id, payload }) => ({
        url: createShiftUrl(`medexam-requests/${id}/approve`),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_SHIFT_MEDEXAM_REQUESTS],
    }),
    getUploadedShiftAnswerFile: builder.query<
      GetUploadedShiftAnswerFileResponseType,
      GetUploadedShiftAnswerFilePropsType
    >({
      query: (params) => ({
        url: `private/uploaded-shift-answer-file${prepareQueryParams(params)}`,
      }),
      providesTags: [C_SERVICE_TAGS.GET_UPLOADED_SHIFT_ANSWER_FILE],
    }),
    uploadSurveyXls: builder.mutation<void, UploadSurveyXlsPropsType>({
      query: ({ file }) => ({
        url: 'private/survey-xls/upload',
        method: 'POST',
        body: file,
      }),
    }),
  }),
});

export const {
  useGetShiftMedexamRequestsQuery,
  useRejectShiftMedexamRequestMutation,
  useApproveShiftMedexamRequestMutation,
  useGetShiftMedexamRequestStatusesQuery,
  useGetUploadedShiftAnswerFileQuery,
  useUploadSurveyXlsMutation,
} = shiftApiService;
