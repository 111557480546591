import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useGetNotificationNewslettersByIdQuery } from 'services/notificationV2/notificationV2ApiService';
import { useGetSettlementSurveyHistoryAssigmentByIdQuery } from 'services/surveys/surveysApiService';

import { Form } from 'gazprom-ui-lib';

import Notifications from '../notifications';
import {
  FormResponse,
  SURVEY_SETTLEMENT_GIFT_FORM_NAME,
} from '../notifications/notifications.utils';
import s from './GiftSettingsTab.module.scss';

const GiftSettingsTab = () => {
  const [form] = Form.useForm<FormResponse>();

  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const { data: historyItem } = useGetSettlementSurveyHistoryAssigmentByIdQuery(
    {
      id: giftId!,
      surveyId: surveyId!,
    },
    { skip: !giftId && !surveyId },
  );

  const { data: notificationData } = useGetNotificationNewslettersByIdQuery(
    historyItem?.notificationPackageId!,
    { skip: !historyItem?.notificationPackageId },
  );

  useEffect(() => {
    if (notificationData) {
      const prepareData: FormResponse = {
        allowFeedback: notificationData?.allowFeedback,
        sendApp: notificationData?.sendApp,
        sendEmail: notificationData?.sendEmail,
        sendPush: notificationData?.sendPush,
        title: notificationData?.title,
        body: notificationData?.body,
      };

      form.setFieldsValue(prepareData);
    }
  }, [form, notificationData]);

  return (
    <Form form={form} name={SURVEY_SETTLEMENT_GIFT_FORM_NAME} disabled className={s.container}>
      <Notifications />
    </Form>
  );
};

export default GiftSettingsTab;
