import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Flex, Form, Icon, Typography } from 'gazprom-ui-lib';

import CommonSelect from 'components/form/common-select';

import { SURVEY_SETTLEMENT_FORM_ENUM } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';

import { useGetFormData } from '../../useGetFormData.hook';
import s from './Anketa.module.scss';

const Anketa = () => {
  const { id: surveyId } = useParams<{ id: string }>();

  const { surveyOptions, surveysInfo } = useGetFormData();

  const form = Form.useFormInstance();
  const anketologId = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID, form);

  useEffect(() => {
    if (anketologId && surveysInfo) {
      form.setFieldValue(
        SURVEY_SETTLEMENT_FORM_ENUM.REVISION_ID,
        surveysInfo[anketologId].revisionId,
      );
      form.setFieldValue(SURVEY_SETTLEMENT_FORM_ENUM.NAME, surveysInfo[anketologId].name);
    }
  }, [anketologId, form, surveysInfo]);

  const onChange = () => {
    form.setFieldValue(SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS, []);
  };

  return (
    <Flex vertical gap="8">
      <CommonSelect
        name={SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID}
        title={t('surveys_select_survey_placeholder')}
        placeholder={t('common_select_second')}
        options={surveyOptions}
        className={s.noMargin}
        isRequired
        disabled={Boolean(surveyId)}
        onChange={onChange}
      />

      <Flex gap="8">
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">
          {t('surveys_settlement_anketa_subtitle')}
        </Typography.Text>
      </Flex>

      {anketologId && surveysInfo && (
        <Typography.Text type="secondary">
          <Flex gap="16">
            <Flex gap="8" align="center">
              <Icon name="solution" />
              {t('surveys_table_anketa_id', { id: anketologId })}
            </Flex>
            <Flex gap="8" align="center">
              <Icon name="tags" />
              {t('surveys_table_revision_id', { id: surveysInfo[anketologId].revisionId })}
            </Flex>
          </Flex>
        </Typography.Text>
      )}
      <Form.Item name={SURVEY_SETTLEMENT_FORM_ENUM.REVISION_ID} noStyle />
      <Form.Item name={SURVEY_SETTLEMENT_FORM_ENUM.NAME} noStyle />
    </Flex>
  );
};

export default Anketa;
