import { Dayjs } from 'dayjs';

import { CreatorEditorType } from './creatorEditor.types';

export enum ARTICLE_TYPE_ENUM {
  DOESNT_EXIST,
  ARTICLE,
  NEWS,
  EVENT,
  _,
  ORG_ARTICLE,
}

export type ArticleType = {
  id: string;
  employerId?: string;
  code: string;
  title: string;
  text: string;
  type: number;
  status: number;
  creator: CreatorEditorType;
  editor: CreatorEditorType;
  location: string;
  pinned: boolean;
  categories: {
    id: string;
    name: string;
  }[];
  cover?: {
    num?: number;
    link: string;
    name?: string;
    type?: string;
    size?: number;
    version?: number;
  };
  media?: {
    num: number;
    link: string;
    name: string;
    type: string;
    size: number;
    version: number;
  };
  createdAt?: string;
  updatedAt?: string | null;
  publishFrom?: Dayjs | string | null;
  meetingId?: string;
  liked: boolean;
  isNeedModerate: boolean;
  isPublish: boolean;
  settlements:
    | {
        id: string;
        name: string;
      }[]
    | null;
};
