import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_1C_INSTANCES_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import {
  useCheckCInstanceConnectionMutation,
  useLazyGetCInstanceByIdQuery,
  usePatchCInstanceMutation,
  useUpdateAuthCredentialsMutation,
  useUpdateCInstanceMutation,
} from 'services/1c-instances/cInstancesService';

import { Button, Tabs } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import { C_INSTANCE_FORM_NAME } from 'containers/c-instance-form/cInstanceForm.utils';
import ContentHeader from 'containers/content-header';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './Settings1cInstanceDetails.module.scss';
import CInstanceSynchronizationModal from './c-instance-synchronization-modal';
import {
  C_INSTANCE_SETTINGS_TAB,
  DEFAULT_REQUEST_BODY,
  RequestBody,
} from './settings1cInstanceDetails.utils';
import useSynclogRequestHook from './useSynclogRequest.hook';
import useTabItemsHook from './useTabItems.hook';

const Settings1cInstanceDetails = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [requestBody, setRequestBody] = useState<RequestBody>(DEFAULT_REQUEST_BODY);

  const [getCInstance, { data: cInstance, isFetching: isCInstanceLoading }] =
    useLazyGetCInstanceByIdQuery();
  const [updateCInstance, { isLoading: isCInstanceUpdating }] = useUpdateCInstanceMutation();
  const [updateAuthCredentials, { isLoading: isAuthCredentialsLoading }] =
    useUpdateAuthCredentialsMutation();
  const [patchCInstance, { isLoading: isCInstancePatching }] = usePatchCInstanceMutation();

  const { synclogData, isFetching: isSynclogFetching } = useSynclogRequestHook({
    requestBody,
    cInstanceId: params?.id,
  });

  useEffect(() => {
    if (params.id) {
      getCInstance(params.id);
    }
  }, [getCInstance, params.id]);

  const [activeTab, setActiveTab] = useState<string>(C_INSTANCE_SETTINGS_TAB);
  const handleGoBack = () => navigate(SETTINGS_1C_INSTANCES_ROUTE);

  const tabs = useTabItemsHook({
    cInstance,
    synclogData,
    isSynclogFetching,
    handleGoBack,
    updateCInstance,
    updateAuthCredentials,
    requestBody,
    setRequestBody,
  });

  const [checkConnection, { data: connectionData, isLoading: isCheckingConnection }] =
    useCheckCInstanceConnectionMutation();

  const [showConnectionInfo, setShowConnectionInfo] = useState<boolean>(false);
  const [showConnectionStart, setShowConnectionStart] = useState<boolean>(false);

  const handleTabChange = (e: string) => {
    setActiveTab(e);
    navigate(location.pathname, { state: {} });
  };

  const handleCheckConnection = () => {
    if (cInstance?.id) {
      checkConnection(cInstance.id).then(() => setShowConnectionInfo(true));
    }
  };

  const handleStartSyncModalOpenClose = () => {
    setShowConnectionStart((prev) => !prev);
  };

  const editConfig = {
    handleSave: async (name: string) => {
      if (cInstance) {
        await patchCInstance({
          id: cInstance.id,
          name,
        });
      }
    },
    isLoading: isCInstancePatching,
    value: cInstance?.name ?? '',
    title: t('1c_edit_name'),
    label: t('1c_instance_name'),
    length: 80,
  };

  const isLoading = isCInstanceLoading || isCInstanceUpdating || isAuthCredentialsLoading;

  return (
    <WithOverflow>
      <ContentHeader align="flex-start">
        <ContentHeader.Title
          goBackState={location?.state}
          goBackLink={SETTINGS_1C_INSTANCES_ROUTE}
          editConfig={editConfig}>
          {cInstance?.name}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {activeTab === C_INSTANCE_SETTINGS_TAB && (
            <Button leftIcon="ok" htmlType="submit" loading={isLoading} form={C_INSTANCE_FORM_NAME}>
              {t('common_save')}
            </Button>
          )}
        </ContentHeader.Actions>
      </ContentHeader>
      <div className={s.content}>
        <Tabs items={tabs} type="card" onChange={handleTabChange} />
        <div
          className={cn(s.actions, { [s.actionsHistory]: activeTab !== C_INSTANCE_SETTINGS_TAB })}>
          {activeTab === C_INSTANCE_SETTINGS_TAB && (
            <Button
              fullWidth
              type="primary"
              leftIcon="ok"
              htmlType="submit"
              loading={isLoading}
              form={C_INSTANCE_FORM_NAME}>
              {t('common_save')}
            </Button>
          )}
          <Button
            disabled={!cInstance?.syncTypes.length}
            loading={isLoading}
            fullWidth
            type="secondary"
            leftIcon="importStart"
            onClick={handleStartSyncModalOpenClose}>
            {t('1c_instance_start_synchronization')}
          </Button>
          <Button
            loading={isCheckingConnection}
            fullWidth
            leftIcon="connection"
            type="secondary"
            onClick={handleCheckConnection}>
            {t('1c_instance_check_connection')}
          </Button>
        </div>
      </div>
      {cInstance?.id && !!cInstance?.syncTypes.length && (
        <CInstanceSynchronizationModal
          isOpen={showConnectionStart}
          onCancel={handleStartSyncModalOpenClose}
          syncTypes={cInstance.syncTypes}
          instanceId={cInstance.id}
        />
      )}
      <RemoveConfirm
        title={
          connectionData?.isSuccess
            ? t('1c_instance_connection_success')
            : t('1c_instance_connection_error')
        }
        subtitle={
          connectionData?.isSuccess
            ? t('1c_instance_connection_success_description')
            : t('1c_instance_connection_error_description')
        }
        icon={connectionData?.isSuccess ? 'connection' : 'info'}
        buttonText={t('common_ok')}
        type={connectionData?.isSuccess ? 'success' : 'danger'}
        isOpen={showConnectionInfo}
        onCancel={() => setShowConnectionInfo(false)}
        onConfirm={() => setShowConnectionInfo(false)}
        buttonProps={{ type: 'primary' }}
      />
    </WithOverflow>
  );
};

export default Settings1cInstanceDetails;
