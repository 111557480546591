import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { formRules } from 'utils/formRules';

import { Form } from 'gazprom-ui-lib';

import FileField from 'containers/file-field';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

type CommunityManagementFormMediaProps = {
  fieldName: COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR | COMMUNITY_MANAGEMENT_FORM_ENUM.COVER | string;
  label: string;
};

const CommunityManagementFormMedia = ({ fieldName, label }: CommunityManagementFormMediaProps) => {
  const media = Form.useWatch(fieldName);

  return (
    <FileField
      formItemConfig={{
        label,
        name: fieldName,
        rules: [formRules.required],
      }}
      previewConfig={{
        title: media?.name,
        description: createMediaDownloadUrl(media?.link),
        showDelete: true,
      }}
    />
  );
};

export default CommunityManagementFormMedia;
