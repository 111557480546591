import { useState } from 'react';

import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { t } from 'tools/i18n';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { createDeclarationRoles } from 'permissions/declarations.permissions';

import ModalCreate from '../containers/modal-create';

const DocumentsDeclarationsHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const addButton = useRoleModelContent({
    content: (
      <Button leftIcon="plus" onClick={handleModal}>
        {t('documents_declarations_add_declarations')}
      </Button>
    ),
    roles: createDeclarationRoles,
  });

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>{t('common_declaration_types')}</ContentHeader.Title>
        <ContentHeader.Actions>{addButton}</ContentHeader.Actions>
      </ContentHeader>
      <ModalCreate
        open={isModalOpen}
        close={handleModal}
        type={DECLARATIONS_TYPE_TAB_ENUM.COMMON}
      />
    </>
  );
};

export default DocumentsDeclarationsHeader;
