export const SETTINGS_ROUTE = '/settings';

export enum DECLARATIONS_TYPE_TAB_ENUM {
  LOCAL = 'local',
  COMMON = 'common',
  KEDO = 'kedo',
}

export const SETTINGS_DECLARATIONS_ROUTE = `${SETTINGS_ROUTE}/declarations`;
export const SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/:declarationTypeTab`;
export const SETTINGS_DECLARATIONS_TYPE_COMMON_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/common`;
export const SETTINGS_DECLARATIONS_TYPE_LOCAL_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/local`;
export const SETTINGS_DECLARATIONS_EDO_ROUTE = `${SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE}/edo`;
export const SETTINGS_DECLARATIONS_KEDO_ROUTE = `${SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE}/kedo`;
export const SETTINGS_DECLARATIONS_SOCIAL_ROUTE = `${SETTINGS_DECLARATIONS_TYPE_TAB_ROUTE}/social`;
export const SETTINGS_DECLARATIONS_EDO_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_EDO_ROUTE}/:id`;
export const SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_KEDO_ROUTE}/:id`;
export const SETTINGS_DECLARATIONS_SOCIAL_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_SOCIAL_ROUTE}/:id`;

export const getDeclarationsTabTypeRoute = (tab: DECLARATIONS_TYPE_TAB_ENUM) =>
  `${SETTINGS_DECLARATIONS_ROUTE}/${tab}`;

export const getSettingsDeclarationsEdoEditRoute = (id: string, tab: DECLARATIONS_TYPE_TAB_ENUM) =>
  `${getDeclarationsTabTypeRoute(tab)}/edo/${id}`;
export const getSettingsDeclarationsKedoEditRoute = (id: string, tab: DECLARATIONS_TYPE_TAB_ENUM) =>
  `${getDeclarationsTabTypeRoute(tab)}/kedo/${id}`;
export const getSettingsDeclarationsSocialEditRoute = (
  id: string,
  tab: DECLARATIONS_TYPE_TAB_ENUM,
) => `${getDeclarationsTabTypeRoute(tab)}/social/${id}`;

export const SETTINGS_1C_INSTANCES_ROUTE = `${SETTINGS_ROUTE}/1c-instances`;

export const SETTINGS_1C_INSTANCES_CREATE_ROUTE = `${SETTINGS_1C_INSTANCES_ROUTE}/create`;
export const SETTINGS_1C_INSTANCES_CREATE_ZUP_ROUTE = `${SETTINGS_1C_INSTANCES_CREATE_ROUTE}/zup`;
export const SETTINGS_1C_INSTANCES_CREATE_SHIFT_ROUTE = `${SETTINGS_1C_INSTANCES_CREATE_ROUTE}/shift`;
export const SETTINGS_1C_INSTANCES_DETAILS_ROUTE = `${SETTINGS_1C_INSTANCES_ROUTE}/:id`;

export const SETTINGS_TELEGRAM_CHANNELS_ROUTE = `${SETTINGS_ROUTE}/telegram-channels`;

export const SETTINGS_TELEGRAM_CHANNEL_ADD_ROUTE = `${SETTINGS_ROUTE}/telegram-channels/create`;

export const SETTINGS_TELEGRAM_CHANNEL_DETAILS_ROUTE = `${SETTINGS_ROUTE}/telegram-channels/:id`;

export const getTelegramChannelWithIdRoute = (id: string) =>
  `${SETTINGS_ROUTE}/telegram-channels/${id}`;

export const get1CInstanceWithIdRoute = (id: string) => `${SETTINGS_1C_INSTANCES_ROUTE}/${id}`;

export const SETTINGS_LNA_EMPLOYMENT_ROUTE = `${SETTINGS_ROUTE}/lna-employment`;

export const SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE = `${SETTINGS_LNA_EMPLOYMENT_ROUTE}/:instanceId`;

export const SETTINGS_LNA_EMPLOYMENT_DETAILS_ROUTE = `${SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE}/lna/:id`;

export const get1CInstanceLnaWithIdRoute = (instanceId: string) =>
  `${SETTINGS_LNA_EMPLOYMENT_ROUTE}/${instanceId}`;

export const get1CInstanceLnaDetailsWithIdRoute = (instanceId: string, id: string) =>
  `${get1CInstanceLnaWithIdRoute(instanceId)}/lna/${id}`;

export const SETTINGS_POSITION_DIRECTORY_ROUTE = `${SETTINGS_ROUTE}/top-management`;
export const SETTINGS_POSITION_DIRECTORY_DETAILS_ROUTE = `${SETTINGS_POSITION_DIRECTORY_ROUTE}/:employerId`;

export const getSettingsPositionDirectoryDetailsRoute = (employerId: string) =>
  `${SETTINGS_POSITION_DIRECTORY_ROUTE}/${employerId}`;

export const SETTINGS_CERTIFICATION_AUTHORITIES = `${SETTINGS_ROUTE}/certification-authorities`;

export const SETTINGS_USER_AGREEMENTS_ROUTE = `${SETTINGS_ROUTE}/user-agreements`;
export const SETTINGS_USER_AGREEMENTS_DETAILS_ROUTE = `${SETTINGS_USER_AGREEMENTS_ROUTE}/:id`;

export const getSettingsUserAgreementsDetailsRoute = (id: string) =>
  `${SETTINGS_USER_AGREEMENTS_ROUTE}/${id}`;

export const SETTINGS_EMPLOYERS_ROUTE = `${SETTINGS_ROUTE}/employers`;
export const SETTINGS_EMPLOYERS_DETAILS_ROUTE = `${SETTINGS_EMPLOYERS_ROUTE}/:id`;

export const getSettingsEmployerDetailsRoute = (id: string) => `${SETTINGS_EMPLOYERS_ROUTE}/${id}`;
