import { useEffect, useState } from 'react';

import { formRules } from 'utils/formRules';

import { Form, TextArea } from 'gazprom-ui-lib';

interface Props {
  title: string;
  name: string;
  placeholder: string;
  totalLength: string;
  initialValue?: string;
}

const CommonTextArea = (props: Props) => {
  const { title, name, placeholder, totalLength, initialValue } = props;
  const [value, setValue] = useState('');
  const form = Form.useFormInstance();

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <Form.Item label={title} name={name} rules={[formRules.required]} initialValue={initialValue}>
      <TextArea
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={Number(totalLength)}
        showCount
      />
    </Form.Item>
  );
};

export default CommonTextArea;
