import { FormFileType } from 'containers/file-field';

export const POLICY_AGREEMENT_SETTINGS_TAB = 'POLICY_AGREEMENT_SETTINGS_TAB';
export const POLICY_AGREEMENT_CONSENT_TAB = 'POLICY_AGREEMENT_CONSENT_TAB';
export const POLICY_AGREEMENT_CHANGE_HISTORY = 'POLICY_AGREEMENT_CHANGE_HISTORY';

const FILE_UPLOAD_PREFIX = 'api/v1/media/download/';

export const prepareFile = (file: FormFileType) => ({
  fileKey: file.link.includes(FILE_UPLOAD_PREFIX)
    ? file.link.replace(FILE_UPLOAD_PREFIX, '')
    : file.link,
  fileName: file.name,
});
