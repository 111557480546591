import { t } from 'tools/i18n';

import { useGetReplacementStatusesQuery } from 'services/private/replacement/replacementApiService';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import useDocumentsFilters from 'pages/documents/useDocumentsFilters';

import { ReplacementStatusType } from 'types/replacement.types';

const Filters = () => {
  const { data: statuses } = useGetReplacementStatusesQuery();

  const statusIntoOption = (status: ReplacementStatusType) => ({
    label: status.name,
    value: status.code,
  });

  const { employersResult, employersSearchHandler } = useDocumentsFilters({
    skipKedoDocumentStatuses: true,
    skipTaskStatuses1c: true,
    useCategoriesFilter: false,
  });

  const items = [
    {
      key: 0,
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerId"
          optionFilterProp="value"
          options={employersResult.employersOptions}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
  ];

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersV2.RangePicker />
      <FiltersWrapper
        showClearAll
        overlay={
          <>
            <FilterCollapse items={items} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="status"
                options={(statuses ?? [])?.map(statusIntoOption)}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
