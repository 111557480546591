import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Icon, Input, Switch, Typography } from 'gazprom-ui-lib';

import { SURVEY_SETTLEMENT_FORM_ENUM } from '../../surveySettlementForm.utils';
import s from './NotificationInterval.module.scss';

const Draw = () => {
  const form = Form.useFormInstance();
  const showInput = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.IS_PLAY_GIFT, form);

  return (
    <>
      <Flex vertical gap="12" className={s.wrapper}>
        <Flex gap="8">
          <Form.Item name={SURVEY_SETTLEMENT_FORM_ENUM.IS_PLAY_GIFT}>
            <Switch />
          </Form.Item>
          <Typography.Text size="14">{t('surveys_play_gift')}</Typography.Text>
        </Flex>

        {showInput && (
          <Flex vertical className={s.additional} gap="8">
            <Form.Item
              name={SURVEY_SETTLEMENT_FORM_ENUM.WINNERS_COUNT}
              rules={[formRules.required]}
              label={<Typography.Text size="14">{t('surveys_play_gift')}</Typography.Text>}>
              <Input
                className={s.numberInput}
                placeholder={t('common_enter_number')}
                type="number"
                min={1}
                step={1}
              />
            </Form.Item>
            <Flex gap="8">
              <Icon name="info" color="var(--color-primary-6)" />
              <Typography.Text type="secondary">{t('surveys_play_gift_info')}</Typography.Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default Draw;
