import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { NEWS_FORM_ENUM } from 'containers/news-form/newsForm.utils';

const FormTitle = () => {
  return (
    <>
      <Typography.Text strong size="16">
        {t('guide_post_title')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={NEWS_FORM_ENUM.TITLE}
        rules={[formRules.required, { max: 255 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={255} showCount />
      </Form.Item>
    </>
  );
};

export default FormTitle;
