import { t } from 'tools/i18n';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from '../notificationNewsletterForm.utils';

interface Props {
  disabled?: boolean;
}
const NotificationNewsletterFormEmail = (props: Props) => {
  const { disabled } = props;

  return (
    <Form.Item name={NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL} valuePropName="checked">
      <Checkbox disabled={disabled}>
        <Typography.Text size="14">{t('common_by_email')}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};
export default NotificationNewsletterFormEmail;
