import SalaryProject from 'pages/salary-project';
import SalaryProjectCreate from 'pages/salary-project/salary-project-create';
import SalaryProjectDetails from 'pages/salary-project/salary-project-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { SALARY_PROJECT_ADD_ROUTE, SALARY_PROJECT_EDIT_ROUTE, SALARY_PROJECT_ROUTE } from './list';

export const salaryProjectRoutes: RouteConfig[] = [
  {
    path: SALARY_PROJECT_ROUTE,
    element: <SalaryProject />,
    roles: [GLOBAL_ROLES.KEDO_ADMIN, GLOBAL_ROLES.KEDO_MODERATOR],
  },
  {
    path: SALARY_PROJECT_ADD_ROUTE,
    element: <SalaryProjectCreate />,
    roles: [GLOBAL_ROLES.KEDO_ADMIN, GLOBAL_ROLES.KEDO_MODERATOR],
  },
  {
    path: SALARY_PROJECT_EDIT_ROUTE,
    element: <SalaryProjectDetails />,
    roles: [GLOBAL_ROLES.KEDO_ADMIN, GLOBAL_ROLES.KEDO_MODERATOR],
  },
];
