import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Button, DatePicker, Flex, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import FileField from 'containers/file-field';

import { RejectApproveModalType } from '../rejectApproveModal';
import s from './RejectApproveForm.module.scss';
import { MEDEXAM_COPY_REQUEST_FORM_ENUM } from './rejectApproveForm.utils';
import { useSubmit } from './useSubmit.hook';

interface Props {
  type: RejectApproveModalType;
  selectedMedexamRequestId: string;
  onCancel: () => void;
}

export const RejectApproveForm = (props: Props) => {
  const { type, selectedMedexamRequestId, onCancel } = props;
  const [form] = Form.useForm();
  const answerComment = Form.useWatch(MEDEXAM_COPY_REQUEST_FORM_ENUM.ANSWER_COMENT, form);

  const isReject = type === 'REJECT';

  const { isLoading, handleSubmit } = useSubmit({
    selectedMedexamRequestId,
    closeRejectApproveModal: onCancel,
    isReject,
  });

  return (
    <Form className={s.formWrapper} form={form} onFinish={handleSubmit}>
      {!isReject && (
        <>
          <FileField
            medExamRequestId={selectedMedexamRequestId}
            formItemConfig={{
              label: t('shift_change_medexam_files'),
              name: MEDEXAM_COPY_REQUEST_FORM_ENUM.FILES,
            }}
            previewConfig={{
              showDelete: true,
              showDownload: true,
            }}
            uploadConfig={{
              description: t('validation_upload_an_image_restrictions'),
            }}
          />
          <Flex vertical gap="8">
            <Typography.Text size="16" weight="500">
              {t('shift_change_medexam_med_doc_number_date')}
            </Typography.Text>

            <Flex gap="16">
              <Form.Item
                className={s.fullWidth}
                normalize={normalizeTextField}
                name={MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_NUM}>
                <Input placeholder={t('common_enter_the_number')} maxLength={80} />
              </Form.Item>

              <Form.Item
                className={s.fullWidth}
                rules={[formRules.required]}
                name={MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_DATE}>
                <DatePicker
                  className={s.fullWidth}
                  format="DD.MM.YYYY"
                  placeholder={t('documents_archive_date_placeholder')}
                />
              </Form.Item>
            </Flex>
          </Flex>

          <Form.Item
            label={t('shift_change_medexam_med_doc_expired_at')}
            className={s.fullWidth}
            name={MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_EXPIRED_AT}>
            <DatePicker
              className={s.fullWidth}
              format="DD.MM.YYYY"
              placeholder={t('common_select_date')}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={
          <Flex gap="4" align="center">
            <Typography.Text size="16" weight="500">
              {t('common_comment')}
            </Typography.Text>
            {!isReject && (
              <Typography.Text type="secondary">{t('common_optional')}</Typography.Text>
            )}
          </Flex>
        }
        name={MEDEXAM_COPY_REQUEST_FORM_ENUM.ANSWER_COMENT}
        rules={isReject ? [formRules.required] : []}>
        <TextArea placeholder={t('common_enter_text')} />
      </Form.Item>

      <Flex gap="8">
        <Button onClick={onCancel} type="secondary" fullWidth>
          {t('common_close')}
        </Button>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isReject && !answerComment}
          fullWidth>
          {t('common_send')}
        </Button>
      </Flex>
    </Form>
  );
};
