import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateGuideCategoryPropsType,
  CreateGuideCategoryResponseType,
  CreateHandbookInstructionsPropsType,
  CreateHandbookInstructionsResponseType,
  CreateHandbookPropsType,
  CreateHandbookResponseType,
  DeleteGuideCategoriesPropsType,
  DeleteGuideCategoriesResponseType,
  DeleteHandbookInstructionsPropsType,
  DeleteHandbookInstructionsResponseType,
  DeleteHandbookPropsType,
  DeleteHandbookResponseType,
  DeleteSettlementsFromListPropsType,
  DeleteSettlementsFromListResponseType,
  GetGuideCategoriesPropsType,
  GetGuideCategoriesResponseType,
  GetHandbookByIdPropsType,
  GetHandbookByIdResponseType,
  GetHandbookInstructionsPropsType,
  GetHandbookInstructionsResponseType,
  GetHandbooksPropsType,
  GetHandbooksResponseType,
  GetSettlementRegionsResponseType,
  PublishHandbookPropsType,
  PublishHandbookResponseType,
  UnpublishHandbookPropsType,
  UnpublishHandbookResponseType,
  UpdateGuideCategoryPropsType,
  UpdateGuideCategoryResponseType,
  UpdateHandbookInstructionsPropsType,
  UpdateHandbookInstructionsResponseType,
  UpdateHandbookPropsType,
  UpdateHandbookResponseType,
  UpdateMapHandbookPropsType,
  UpdateMapHandbookResponseType,
  UpdateMemoHandbookPropsType,
  UpdateMemoHandbookResponseType,
} from './settlementApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createSettlementRegionUrl, createSettlementUrl } from './utils';

const settlementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getHandbooks: builder.query<GetHandbooksResponseType, GetHandbooksPropsType>({
      query: (params) => ({
        url: createSettlementUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    getHandbookById: builder.query<GetHandbookByIdResponseType, GetHandbookByIdPropsType>({
      query: (id) => ({
        url: createSettlementUrl(`/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOK_BY_ID],
    }),
    createHandbook: builder.mutation<CreateHandbookResponseType, CreateHandbookPropsType>({
      query: (body) => ({
        url: createSettlementUrl(``),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    deleteHandbook: builder.mutation<DeleteHandbookResponseType, DeleteHandbookPropsType>({
      query: ({ id }) => ({
        url: createSettlementUrl(`/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    unpublishHandbook: builder.mutation<UnpublishHandbookResponseType, UnpublishHandbookPropsType>({
      query: (body) => ({
        url: createSettlementUrl(`/unpublish`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    publishHandbook: builder.mutation<PublishHandbookResponseType, PublishHandbookPropsType>({
      query: (body) => ({
        url: createSettlementUrl(`/publish`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    updateMapHandbook: builder.mutation<UpdateMapHandbookResponseType, UpdateMapHandbookPropsType>({
      query: (body) => ({
        url: createSettlementUrl(`/map`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    updateMemoHandbook: builder.mutation<
      UpdateMemoHandbookResponseType,
      UpdateMemoHandbookPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`/instruction`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
    updateHandbook: builder.mutation<UpdateHandbookResponseType, UpdateHandbookPropsType>({
      query: ({ id, ...body }) => ({
        url: createSettlementUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS, SERVICE_TAGS.GET_GUIDE_HANDBOOK_BY_ID],
    }),
    getHandbookInstructions: builder.query<
      GetHandbookInstructionsResponseType,
      GetHandbookInstructionsPropsType
    >({
      query: (id) => ({
        url: createSettlementUrl(`-instruction/by-settlement-id/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_HANDBOOK_INSTRUCTIONS],
    }),
    createHandbookInstructions: builder.mutation<
      CreateHandbookInstructionsResponseType,
      CreateHandbookInstructionsPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`-instruction`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_HANDBOOK_INSTRUCTIONS],
    }),
    updateHandbookInstructions: builder.mutation<
      UpdateHandbookInstructionsResponseType,
      UpdateHandbookInstructionsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createSettlementUrl(`-instruction/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_HANDBOOK_INSTRUCTIONS],
    }),
    deleteHandbookInstructions: builder.mutation<
      DeleteHandbookInstructionsResponseType,
      DeleteHandbookInstructionsPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`-instruction`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_HANDBOOK_INSTRUCTIONS],
    }),
    getGuideCategories: builder.query<GetGuideCategoriesResponseType, GetGuideCategoriesPropsType>({
      query: (params) => ({
        url: createSettlementUrl(`-categories${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_CATEGORIES],
    }),
    createGuideCategory: builder.mutation<
      CreateGuideCategoryResponseType,
      CreateGuideCategoryPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`-categories`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_CATEGORIES],
    }),
    updateGuideCategory: builder.mutation<
      UpdateGuideCategoryResponseType,
      UpdateGuideCategoryPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createSettlementUrl(`-categories/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_CATEGORIES],
    }),
    deleteGuideCategories: builder.mutation<
      DeleteGuideCategoriesResponseType,
      DeleteGuideCategoriesPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`-categories`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_CATEGORIES],
    }),
    getSettlementRegions: builder.query<GetSettlementRegionsResponseType, void>({
      query: () => ({
        url: createSettlementRegionUrl({ url: 'region' }),
      }),
    }),
    deleteSettlementsFromList: builder.mutation<
      DeleteSettlementsFromListResponseType,
      DeleteSettlementsFromListPropsType
    >({
      query: (body) => ({
        url: createSettlementUrl(`s`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HANDBOOKS],
    }),
  }),
});

export const {
  useUpdateMapHandbookMutation,
  useUpdateMemoHandbookMutation,
  usePublishHandbookMutation,
  useUnpublishHandbookMutation,
  useDeleteHandbookMutation,
  useUpdateHandbookInstructionsMutation,
  useGetHandbookInstructionsQuery,
  useLazyGetHandbookInstructionsQuery,
  useGetGuideCategoriesQuery,
  useUpdateHandbookMutation,
  useCreateHandbookMutation,
  useDeleteHandbookInstructionsMutation,
  useCreateHandbookInstructionsMutation,
  useGetHandbookByIdQuery,
  useLazyGetHandbookByIdQuery,
  useLazyGetHandbooksQuery,
  useLazyGetGuideCategoriesQuery,
  useDeleteGuideCategoriesMutation,
  useCreateGuideCategoryMutation,
  useUpdateGuideCategoryMutation,
  useGetSettlementRegionsQuery,
  useGetHandbooksQuery,
  useDeleteSettlementsFromListMutation,
} = settlementApiService;
