import { FC, useState } from 'react';

import { Flex, Table } from 'gazprom-ui-lib';

import { RecruitDocumentType } from 'types/recruit.types';

import AdditionalDocuments from '../additional-documents';
import AddDocumentModal from '../additional-documents/containers/add-document-modal';
import useColumns from './useColumns.hook';

interface LnaTableProps {
  data: RecruitDocumentType[];
  hideUpload?: boolean;
  isTaskEmployerCanceled?: boolean;
}

const DocumentsTable: FC<LnaTableProps> = (props) => {
  const { data, hideUpload = false, isTaskEmployerCanceled } = props;
  const columns = useColumns({ hideDownload: isTaskEmployerCanceled });

  const [page, setPage] = useState<number>(1);
  const [currentModal, setCurrentModal] = useState<RecruitDocumentType | null>(null);

  const handleOnRow = (record: RecruitDocumentType) => ({
    onClick: () => setCurrentModal(record),
  });

  const handleCloseModal = () => setCurrentModal(null);

  const isModalOpen = Boolean(currentModal);

  return (
    <>
      <Flex vertical>
        <AdditionalDocuments hideCreation={hideUpload} />
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          pagination={{
            current: page,
            total: data?.length ?? 1,
          }}
          onRow={handleOnRow}
          onChange={({ current }) => setPage(current ?? 1)}
        />
      </Flex>
      <AddDocumentModal open={isModalOpen} onCancel={handleCloseModal} docInfo={currentModal} />
    </>
  );
};

export default DocumentsTable;
