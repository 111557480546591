import cn from 'classnames';
import { t } from 'tools/i18n';

import { Flex, Steps, StepsProps, Typography } from 'gazprom-ui-lib';

import s from './History.module.scss';

interface Props {
  historyItems?: StepsProps['items'];
  title?: string;
  className?: string;
}

const History = (props: Props) => {
  const {
    title = `${t('documents_statements_status_history')}:`,
    historyItems = [],
    className,
  } = props;

  return (
    <Flex vertical gap="16" className={cn(s.wrapper, className)}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Steps items={historyItems} deadlineText={t('common_due')} />
    </Flex>
  );
};

export default History;
