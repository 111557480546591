import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateArticleCategoryMutation,
  useGetNewsCategoriesQuery,
} from 'services/category/categoryApiService';
import { GetNewsCategoriesResponseType } from 'services/category/categoryApiService.types';

import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { NewsCategoryType } from 'types/news.types';

import { MAX_CATEGORIES_PER_PAGE, NEWS_FORM_ENUM } from '../newsForm.utils';
import s from './NewsFormCategories.module.scss';

const NewsFormCategories = () => {
  const [form] = Form.useForm();
  const parentForm = Form.useFormInstance();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [createArticleCategory, { isLoading: createArticleIsLoading }] =
    useCreateArticleCategoryMutation();
  const { categoriesOptions, isFetching } = useGetNewsCategoriesQuery(
    { page: 1, size: MAX_CATEGORIES_PER_PAGE, visible: true, name: '' },
    {
      selectFromResult,
    },
  );

  const handleCheckValidation = () => form.validateFields().then(handleCategoryCreate);

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleCategoryCreate = () => {
    const alreadySelectedCategories = parentForm.getFieldValue(NEWS_FORM_ENUM.CATEGORIES) ?? [];
    const newCategoryName = form.getFieldValue(NEW_CATEGORY_NAME);
    const onSuccess = (data?: NewsCategoryType) => {
      if (data) {
        form.setFieldValue(NEWS_FORM_ENUM.CATEGORIES, alreadySelectedCategories.concat(data.id));
        handleModalOpenClose();
        form.resetFields([NEW_CATEGORY_NAME]);
      }
    };

    const onError = () => {
      form.setFields([
        {
          name: NEW_CATEGORY_NAME,
          errors: [t('errors_category_name_exist')],
        },
      ]);
    };

    createArticleCategory({ name: newCategoryName, visible: true, type: 'news' }).then(
      handleRequest({
        onSuccess,
        onError,
      }),
    );
  };

  return (
    <>
      <div className={s.wrapper}>
        <Typography.Text strong size="16">
          {t('news_category')}
        </Typography.Text>
        <Form.Item name={NEWS_FORM_ENUM.CATEGORIES} rules={[formRules.required]}>
          <Select
            mode="multiple"
            placeholder={t('news_select_category')}
            loading={isFetching}
            options={categoriesOptions}
            filterOption={(search, option) => {
              return JSON.stringify(option)?.toLowerCase().includes(search?.toLowerCase());
            }}
          />
        </Form.Item>
        <button type="button" className={s.modalOpenButton} onClick={handleModalOpenClose}>
          <Icon name="plus" />
          <Typography.Text size="14" weight="500">
            {t('common_add_category')}
          </Typography.Text>
        </button>
      </div>
      <Modal open={isModalOpen} onCancel={handleModalOpenClose} closable>
        <Form form={form}>
          <Typography.Title level={4}>{t('common_add_category')}</Typography.Title>
          <Form.Item
            normalize={(e: string) => e.trimStart()}
            name={NEW_CATEGORY_NAME}
            className={s.categoryInput}
            rules={[formRules.required]}>
            <Input placeholder={t('common_enter_category_name')} />
          </Form.Item>
          <div className={s.actions}>
            <Button htmlType="button" type="secondary" onClick={handleModalOpenClose}>
              {t('common_close')}
            </Button>
            <Button
              htmlType="button"
              onClick={handleCheckValidation}
              loading={createArticleIsLoading}>
              {t('common_save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const NEW_CATEGORY_NAME = 'NEW_CATEGORY_NAME';

const selectFromResult = ({
  data,
  ...props
}: {
  data?: GetNewsCategoriesResponseType;
  isFetching: boolean;
}) => ({
  categoriesOptions: data?.items.map(({ id, name }) => ({ value: id, label: name, key: id })) ?? [],
  ...props,
});

export default NewsFormCategories;
