import dayjs, { Dayjs } from 'dayjs';

export type FormFiltersType = {
  status?: string[];
  employerIds?: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  search: string;
  filters?: FormFiltersType;
  range?: [Dayjs, Dayjs];
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
};

export const transformReplacementPeriod = (dateFrom?: string, dateTo?: string) => {
  if (dateFrom && dateTo) {
    return `${dayjs.utc(dateFrom).format('DD.MM.YYYY')} - ${dayjs
      .utc(dateTo)
      .format('DD.MM.YYYY')}`;
  }

  return '';
};
