import { handleTableChange } from 'utils/table.utils';

import { Table } from 'gazprom-ui-lib';

import DocumentsDeclarationsFilters from '../../documents-declarations-filters';
import useCommonTabData from './useCommonTabData';

const CommonTab = () => {
  const {
    columns,
    data,
    isFetching,
    isLoading,
    debouncedRequestBody,
    requestBody,
    filters,
    setRequestBody,
    onFiltersSubmit,
    handleOnRow,
    handleSearchChange,
  } = useCommonTabData();

  return (
    <>
      <DocumentsDeclarationsFilters
        filters={filters}
        setFilters={onFiltersSubmit}
        search={requestBody.name}
        handleSearchChange={handleSearchChange}
      />

      <Table
        onRow={handleOnRow}
        dataSource={data?.items}
        columns={columns}
        pagination={{
          defaultCurrent: debouncedRequestBody.page,
          defaultPageSize: debouncedRequestBody.size,
          total: data?.totalCount,
          showSizeChanger: true,
        }}
        scroll={{ y: '50vh' }}
        loading={isLoading || isFetching}
        rowKey="id"
        onChange={handleTableChange(setRequestBody)}
      />
    </>
  );
};

export default CommonTab;
