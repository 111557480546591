import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import {
  GetReplacementByIdPropsType,
  GetReplacementByIdResponseType,
  GetReplacementStatusesPropsType,
  GetReplacementStatusesResponseType,
  GetReplacementsPropsType,
  GetReplacementsResponseType,
} from 'services/private/replacement/replacementApiService.types';
import { createReplacementUrl } from 'services/private/replacement/utils';

const replacementApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getReplacements: builder.query<GetReplacementsResponseType, GetReplacementsPropsType>({
      query: (params) => ({
        url: createReplacementUrl(prepareQueryParams(params)),
      }),
    }),
    getReplacementById: builder.query<GetReplacementByIdResponseType, GetReplacementByIdPropsType>({
      query: (id) => ({
        url: createReplacementUrl(`/${id}`),
      }),
    }),
    getReplacementStatuses: builder.query<
      GetReplacementStatusesResponseType,
      GetReplacementStatusesPropsType
    >({
      query: () => ({
        url: createReplacementUrl('/status'),
      }),
    }),
  }),
});

export const {
  useGetReplacementsQuery,
  useGetReplacementByIdQuery,
  useGetReplacementStatusesQuery,
} = replacementApiService;
