import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { t } from 'tools/i18n';

import { Checkbox, CheckboxValueType, Flex, Typography } from 'gazprom-ui-lib';

import s from './CommonSync.module.scss';

interface Props {
  syncTypeOptions: CheckboxOptionType[];
  selectedConnectionTypes: CheckboxValueType[];
  handleConnectionTypeSelection: (checkedValues: CheckboxValueType[]) => void;
}

const CommonSync = (props: Props) => {
  const { selectedConnectionTypes, handleConnectionTypeSelection, syncTypeOptions } = props ?? {};

  return (
    <Flex vertical gap="16">
      <Typography.Text size="16" weight="500">
        {t('1c_synchronization_select_types')}
      </Typography.Text>
      <Flex fullWidth vertical>
        <Checkbox.Group
          className={s.checkboxGroup}
          column
          value={selectedConnectionTypes}
          onChange={handleConnectionTypeSelection}
          options={syncTypeOptions}
        />
      </Flex>
    </Flex>
  );
};

export default CommonSync;
