import { SETTINGS_LNA_EMPLOYMENT_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import s from './Header.module.scss';

const Header = () => {
  const description = (
    <Flex align="center" gap="8" className={s.info}>
      <Icon name="info" />
      <Typography.Text size="14" weight="400">
        {t('1c_lna_tip')}
      </Typography.Text>
    </Flex>
  );

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={SETTINGS_LNA_EMPLOYMENT_ROUTE} description={description}>
        {t('1c_lna_active')}
      </ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
