import { useMatch, useParams } from 'react-router-dom';
import {
  SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_KEDO_ROUTE,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME } from 'pages/documents/documents-declarations/documents-declarations-additional/documentsDeclarationsAdditional.utils';
import { TEMPLATES_TAB_KEY } from 'pages/documents/documents-declarations/useTemplateTab.hook';

import { ADDITIONAL_TAB, COMMON_TAB, PARAMETERS_TAB } from '../../documentsDeclarationsEdo.utils';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from '../tab-common/tabCommon.utils';
import { DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME } from '../tab-parameters/tabParameters.utils';

interface Props {
  activeTab: string;
}

const Header = (props: Props) => {
  const { activeTab } = props;

  const { id } = useParams();

  const isKedoCreation = !!useMatch(SETTINGS_DECLARATIONS_KEDO_ROUTE);
  const isKedoEditing = !!useMatch(SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE);
  const isKedo = isKedoCreation || isKedoEditing;

  let formName = '';

  const isKedoEdit = isKedo && id;
  // Не отображаем "Сохранить", если вкладка Шаблон и тип не KEDO
  const shouldNotDisplaySave = activeTab === TEMPLATES_TAB_KEY && !isKedo;

  const notTemplateTab = activeTab !== TEMPLATES_TAB_KEY;
  if (notTemplateTab) {
    if (activeTab === COMMON_TAB) {
      formName = DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME;
    }
    if (activeTab === PARAMETERS_TAB) {
      formName = DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME;
    }
    if (activeTab === ADDITIONAL_TAB) {
      formName = DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME;
    }
  }

  let headerTitle = t('documents_declarations_for_edo_title');

  if (isKedo) {
    headerTitle = t('documents_declarations_for_kedo_title');
  }

  if (id) {
    headerTitle = t('documents_declarations_for_edo_edit_title');
  }

  if (isKedoEdit) {
    headerTitle = t('documents_declarations_for_kedo_edit_title');
  }

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title goBackLink={-1}>{headerTitle}</ContentHeader.Title>
      <ContentHeader.Actions>
        {!shouldNotDisplaySave && (
          <Button leftIcon="ok" htmlType="submit" form={formName}>
            {t('common_save')}
          </Button>
        )}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
