import { FC, RefObject, memo, useCallback, useMemo } from 'react';

import { Pie } from '@ant-design/charts';
import { useDispatch } from 'react-redux';

import { setQoa } from 'slices/surveysSlice';

import { HandlerParamsType } from 'types/surveys.types';

import {
  DEFAULT_COLORS,
  countStats,
  createConfig,
  extendColors,
  paintSelectedLegend,
  renderNumberedLegend,
} from '../../../../statsTab.utils';
import { Props } from '../../statsScale';
import s from './ScalePie.module.scss';

interface ScalePieProps {
  options: Props['options'];
  questionId: Props['questionId'];
  legendContainerRef: RefObject<HTMLDivElement>;
}

const ScalePie: FC<ScalePieProps> = (props) => {
  const { options, questionId, legendContainerRef } = props;

  const dispatch = useDispatch();
  const { mappedOptions } = countStats(options, questionId);
  const colorsForData = extendColors(DEFAULT_COLORS, mappedOptions.length);

  const sectionClickHandler = useCallback(
    (params: HandlerParamsType) => {
      dispatch(setQoa({ qId: params.questionId, oId: params.optionId }));
    },
    [dispatch],
  );

  const hoverLegendHandler = useCallback(
    (params: HandlerParamsType, isSelected: boolean) => {
      const { optionId, questionId } = params;
      const element = legendContainerRef.current?.querySelector(
        `[data-id="${questionId}${optionId}"]`,
      );
      element && paintSelectedLegend(element, isSelected);
    },
    [legendContainerRef],
  );

  const config = useMemo(
    () =>
      createConfig({
        data: mappedOptions,
        onclickHandler: sectionClickHandler,
        onHover: hoverLegendHandler,
      }),
    [hoverLegendHandler, mappedOptions, sectionClickHandler],
  );

  return (
    <div className={s.pieContainer}>
      <Pie {...config} />
      <div className={s.legendContainer} ref={legendContainerRef}>
        {mappedOptions.map((data, index) => renderNumberedLegend(data, index, colorsForData))}
      </div>
    </div>
  );
};

export default memo(ScalePie);
