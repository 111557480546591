import { t } from 'tools/i18n';

import { Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { HandbookType } from 'types/guide.types';

const useColumns = (): TableProps<HandbookType>['columns'] => {
  const fileInstructionRenderer = (fileInstruction: HandbookType['fileInstruction']) => {
    return (
      !!fileInstruction && (
        <Icon name="radar" color="var(--color-primary-6)" height={18} width={18} />
      )
    );
  };

  const publishRenderer = (isPublish: HandbookType['isPublish']) => {
    return (
      <Icon
        name="okFilled"
        color={isPublish ? 'var(--color-success)' : 'var(--color-placeholder-25)'}
        height={18}
        width={18}
      />
    );
  };

  return [
    {
      title: t('guide_handbook_name'),
      dataIndex: 'name',
      sorter: true,
      render: (name: string) => {
        return <Typography.Title level={5}>{name}</Typography.Title>;
      },
    },
    {
      title: t('common_subject_rf'),
      dataIndex: 'region.name',
      render: (_: HandbookType['region'], record: HandbookType) => {
        return (
          <TableText weight="500" type="secondary">
            {record.region?.name}
          </TableText>
        );
      },
      sorter: true,
    },
    {
      title: t('common_coordinates'),
      dataIndex: 'coordinates',
      render: (coordinates: HandbookType['coordinates']) => {
        return (
          <Typography.Text
            size="10"
            weight="500"
            type="secondary">{`${coordinates.x}, ${coordinates.y}`}</Typography.Text>
        );
      },
    },
    {
      title: t('common_map'),
      dataIndex: 'fileMap',
      width: 100,
      sorter: true,
      align: 'center',
      render: (fileMap: HandbookType['fileMap']) => {
        return (
          !!fileMap && <Icon name="map" color="var(--color-primary-6)" height={18} width={18} />
        );
      },
    },
    {
      title: t('common_memo'),
      dataIndex: 'fileInstruction',
      width: 100,
      sorter: true,
      align: 'center',
      render: fileInstructionRenderer,
    },
    {
      title: t('common_publication'),
      dataIndex: 'isPublish',
      sorter: true,
      width: 100,
      align: 'center',
      render: publishRenderer,
    },
  ];
};
export default useColumns;
