import { useMatch, useNavigate } from 'react-router-dom';
import {
  SETTINGS_1C_INSTANCES_CREATE_SHIFT_ROUTE,
  SETTINGS_1C_INSTANCES_ROUTE,
  get1CInstanceWithIdRoute,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useCreateCInstanceMutation } from 'services/1c-instances/cInstancesService';
import { CreateCInstancePropsType } from 'services/1c-instances/cInstancesService.types';

import { Button, Flex, Typography, useHandleRequestWithModal } from 'gazprom-ui-lib';

import CInstanceForm from 'containers/c-instance-form';
import {
  CInstanceFormResponse,
  C_INSTANCE_FORM_ENUM,
  C_INSTANCE_FORM_NAME,
} from 'containers/c-instance-form/cInstanceForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './Settings1cInstanceCreate.module.scss';

const Settings1cInstanceCreate = () => {
  const navigate = useNavigate();

  const isShiftType = !!useMatch(SETTINGS_1C_INSTANCES_CREATE_SHIFT_ROUTE);

  const [createCInstance, { isLoading }] = useCreateCInstanceMutation();

  const handleGoBack = () => navigate(SETTINGS_1C_INSTANCES_ROUTE);
  const handleNavigateDetails = (data?: { id: string }) => {
    if (data) {
      navigate(get1CInstanceWithIdRoute(data.id));
    }
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccess: handleNavigateDetails,
    onSuccessModalConfig: {
      title: {
        children: t('common_added_successfully'),
      },
    },
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'warning',
      },
      title: {
        children: t('1c_create_instance_error_modal_title'),
      },
      description: {
        children: t('1c_create_instance_error_modal_description'),
      },
    },
  });

  const onSubmit = (form: CInstanceFormResponse) => {
    const requestBody: CreateCInstancePropsType = {
      name: form[C_INSTANCE_FORM_ENUM.NAME],
      url: form[C_INSTANCE_FORM_ENUM.URL],
      login: form[C_INSTANCE_FORM_ENUM.LOGIN],
      password: form[C_INSTANCE_FORM_ENUM.PASSWORD],
      authLegacy: form[C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS],
      type: isShiftType ? 'SHIFT' : 'ZUP',
    };

    if (!isShiftType) {
      requestBody.active = form[C_INSTANCE_FORM_ENUM.ACTIVE];
      requestBody.structureType = form[C_INSTANCE_FORM_ENUM.STRUCTURE_TYPE];
    }

    if (!form[C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS]) {
      requestBody.authUsername = form[C_INSTANCE_FORM_ENUM.CONFIG_LOGIN];
      requestBody.authPassword = form[C_INSTANCE_FORM_ENUM.CONFIG_PASSWORD];
    }

    createCInstance(requestBody).then(handleCreate);
  };

  return (
    <WithOverflow>
      <div className={s.actionsHeader}>
        <div>
          <Button htmlType="button" type="link" leftIcon="arrowLeft" onClick={handleGoBack}>
            {t('common_go_back')}
          </Button>
          <Typography.Title level={3}>{t('1c_create_instance')}</Typography.Title>
        </div>
        <Button leftIcon="ok" htmlType="submit" loading={false} form={C_INSTANCE_FORM_NAME}>
          {t('common_save')}
        </Button>
      </div>
      <Flex vertical className={s.overflow}>
        <div className={s.content}>
          <CInstanceForm onSubmit={onSubmit} isShiftType={isShiftType} />
          <div className={s.actions}>
            <Button
              fullWidth
              type="primary"
              leftIcon="ok"
              htmlType="submit"
              loading={isLoading}
              form={C_INSTANCE_FORM_NAME}>
              {t('common_save')}
            </Button>

            <Button fullWidth type="secondary" onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          </div>
        </div>
      </Flex>
    </WithOverflow>
  );
};

export default Settings1cInstanceCreate;
