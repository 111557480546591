import { useState } from 'react';

import { t } from 'tools/i18n';
import { createPersonalFileUploadUrl } from 'utils/createDownloadUrl.utils';
import { createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import { userManager } from 'utils/keycloak';

import { UploadProps } from 'gazprom-ui-lib';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';

interface UseUploadFileProps {
  setErrors: (errors: string[]) => void;
  isPersonalFile?: boolean;
  kedoReviewTaskId?: string | null;
  customUploadUrl?: string;
  medExamRequestId?: string;
  customMetaUrl?: string;
  convertA1A?: boolean;
}

const CONVERT_ERROR_STATUS = [409];

const useUploadFile = (props: UseUploadFileProps) => {
  const {
    setErrors,
    isPersonalFile = false,
    kedoReviewTaskId,
    medExamRequestId,
    customUploadUrl,
    customMetaUrl,
    convertA1A,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState<{
    size?: number;
    name?: string;
    url?: string;
    fileKey?: string;
  }>({});

  const getStatus = async (key: string): Promise<{ status?: string; error: string }> => {
    const user = await userManager.getUser();
    const token = user?.access_token as string;

    let metaUrl = `${BACKEND_V1_BASE_URL}media/meta`;

    if (isPersonalFile) {
      metaUrl = `${BACKEND_V1_BASE_URL}me/files/meta`;
    }
    if (kedoReviewTaskId || medExamRequestId) {
      metaUrl = `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-files/meta`;
    }

    if (customMetaUrl) {
      metaUrl = customMetaUrl;
    }

    return fetch(`${metaUrl}/${key}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const { status } = response;

        if (status === 'INFECTED') {
          return Promise.reject('INFECTED');
        }
        if (status === 'VERIFIED') {
          return response;
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(getStatus(key));
          }, 1000);
        });
      })
      .catch((error) => {
        console.error('Request failed:', error);
        throw error;
      });
  };

  const uploadData = (file: File, token: string) => {
    const formData = new FormData();
    formData.append('file', file);

    let uploadUrl = `${BACKEND_V1_BASE_URL}media`;

    if (isPersonalFile) {
      uploadUrl = `${BACKEND_V1_BASE_URL}me/files`;
    }

    if (kedoReviewTaskId) {
      uploadUrl = `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-files/kedo-review-task?kedoReviewTaskId=${kedoReviewTaskId}`;
    }

    if (medExamRequestId) {
      uploadUrl = `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-files/medexam-request?medExamRequestId=${medExamRequestId}`;
    }

    if (customUploadUrl) {
      uploadUrl = customUploadUrl;
    }

    if (convertA1A) {
      uploadUrl += uploadUrl.includes('?') ? '&convert=true' : '?convert=true';
    }

    return fetch(uploadUrl, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw { status: res.status, ...error };
          });
        }
        return res.json();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const customRequest: UploadProps['customRequest'] = async (e) => {
    setIsLoading(true);
    setFileData({});

    const user = await userManager.getUser();
    const token = user?.access_token as string;
    const typedFile = e.file as File;

    uploadData(typedFile, token)
      .then(async (res) => {
        const fileKey = res?.fileKey;
        await getStatus(fileKey);

        let uploadUrl;

        if (isPersonalFile || kedoReviewTaskId) {
          uploadUrl = createPersonalFileUploadUrl(fileKey);
        } else {
          uploadUrl = createMediaUploadUrl(fileKey);
        }

        setFileData({
          fileKey,
          size: typedFile.size,
          name: typedFile.name,
          url: uploadUrl,
        });
      })
      .catch((error) => {
        if (CONVERT_ERROR_STATUS.includes(error.status)) {
          setErrors([t('validation_converting_went_wrong')]);
        } else {
          setErrors([t('validation_something_went_wrong')]);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    fileData,
    customRequest,
  };
};

export default useUploadFile;
