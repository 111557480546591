import { GLOBAL_ROLES } from 'types/roles.types';

export const suggestionCommissionPermissions = {
  roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.SUGGESTION_ADMIN],
};

export const suggestionPermissions = {
  roles: [
    GLOBAL_ROLES.ADMIN,
    GLOBAL_ROLES.ADMIN_MOBILE,
    GLOBAL_ROLES.SUGGESTION_ADMIN,
    GLOBAL_ROLES.SUGGESTION_MODERATOR,
    GLOBAL_ROLES.SUGGESTION_VIEWER,
  ],
};
