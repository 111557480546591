import { useState } from 'react';

import { ShiftMedExamRequest } from 'gazprom-common-lib';
import { useVisibility } from 'utils/useVisibility.hook';

import { RejectApproveModalType } from './containers/reject-approve-modal/rejectApproveModal';

export const useModalsConfig = () => {
  const [selectedMedexamRequest, setSelectedMedexamRequest] = useState<ShiftMedExamRequest | null>(
    null,
  );
  const [viewModalOpen, { open: openViewModal, close: closeViewModal }] = useVisibility();

  const [rejectApproveModalType, setRejectApproveModalType] =
    useState<RejectApproveModalType | null>();

  const onOpenViewModal = (record: ShiftMedExamRequest) => {
    setSelectedMedexamRequest(record);
    openViewModal();
  };

  const onCloseViewModal = () => {
    closeViewModal();
    setSelectedMedexamRequest(null);
  };

  const onOpenRejectApproveModal = (type: RejectApproveModalType) => {
    closeViewModal();
    setRejectApproveModalType(type);
  };

  const onCloseRejectApproveModal = () => {
    setRejectApproveModalType(null);
    setSelectedMedexamRequest(null);
  };

  return {
    onOpenViewModal,
    onCloseViewModal,
    onOpenRejectApproveModal,
    onCloseRejectApproveModal,
    viewModalOpen,
    rejectApproveModalType,
    selectedMedexamRequest,
  };
};
