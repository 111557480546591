export type FormResponse = {
  [FORM_ENUM.ALLOW_FEEDBACK]: boolean;
  [FORM_ENUM.SEND_APP]: boolean;
  [FORM_ENUM.SEND_MAIL]: boolean;
  [FORM_ENUM.SEND_PUSH]: boolean;
  [FORM_ENUM.TITLE]: string;
  [FORM_ENUM.BODY]: string;
};

export enum FORM_ENUM {
  ALLOW_FEEDBACK = 'allowFeedback',
  SEND_MAIL = 'sendEmail',
  SEND_APP = 'sendApp',
  SEND_PUSH = 'sendPush',
  TITLE = 'title',
  BODY = 'body',
}

export const SURVEY_SETTLEMENT_GIFT_FORM_NAME = 'SURVEY_SETTLEMENT_GIFT_FORM_NAME';
