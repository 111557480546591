import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { getDeclarationTypeSelector } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { useAppSelector } from 'store';

import { Form, Input, Select, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM } from '../../tabParameters.utils';
import { methodOptions, signerOptions } from './kedoFields.utils';

const KedoFields = () => {
  const { id } = useParams<{ id: string }>();

  const form = Form.useFormInstance();

  const hasDigitalForm = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM,
    form,
  );
  const currentMethod = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.DIGITAL_FLOW_TYPE,
    form,
  );

  const { data: declarationData } = useAppSelector(getDeclarationTypeSelector.select(id!));

  return (
    <>
      <Form.Item
        name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.DIGITAL_FLOW_TYPE}
        label={
          <Typography.Text size="14" weight="500">
            {t('social_program_type_method_generating_certificate')}
          </Typography.Text>
        }
        initialValue={declarationData?.digitalFlowType}>
        <Select
          placeholder={t('common_select_second')}
          options={methodOptions}
          showSearch
          filterOption
          optionFilterProp="label"
          disabled={!hasDigitalForm}
        />
      </Form.Item>
      {currentMethod === 'APP' && (
        <Form.Item
          name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.SIGNER_TYPE}
          label={
            <Typography.Text size="14" weight="500">
              {t('common_signatory')}
            </Typography.Text>
          }
          initialValue={declarationData?.signerType}>
          <Select
            placeholder={t('common_select_second')}
            options={signerOptions}
            showSearch
            filterOption
            optionFilterProp="label"
            disabled={!hasDigitalForm}
          />
        </Form.Item>
      )}
      {currentMethod === 'AUTO_1C' && (
        <Form.Item
          name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.CERTIFICATE_1C_ID}
          label={
            <Typography.Text size="14" weight="500">
              {t('social_program_identificator_of_1c')}
            </Typography.Text>
          }>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      )}
    </>
  );
};

export default KedoFields;
