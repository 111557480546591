import SurveysAdd from 'pages/surveys/surveys-add';
import SurveysAnketaImport from 'pages/surveys/surveys-anketa-import';
import SurveysDetails from 'pages/surveys/surveys-details';
import SurveysEvent from 'pages/surveys/surveys-event';
import SurveysGift from 'pages/surveys/surveys-gift';
import SurveysOnce from 'pages/surveys/surveys-once';
import SurveysSettlement from 'pages/surveys/surveys-settlement';
import SurveysSettlementAddUpdate from 'pages/surveys/surveys-settlement/surveys-settlement-add-update';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  SURVEYS_ADD_EVENT_ROUTE,
  SURVEYS_ADD_ONCE_ROUTE,
  SURVEYS_ANKETA_IMPORT_ROUTE,
  SURVEYS_EVENT_BY_ID_ROUTE,
  SURVEYS_EVENT_ROUTE,
  SURVEYS_ONCE_BY_ID_ROUTE,
  SURVEYS_ONCE_ROUTE,
  SURVEYS_SETTLEMENT_ADD_ROUTE,
  SURVEYS_SETTLEMENT_BY_ID_GIFT_ROUTE,
  SURVEYS_SETTLEMENT_BY_ID_ROUTE,
  SURVEYS_SETTLEMENT_ROUTE,
} from './list';

export const surveysRoutes: RouteConfig[] = [
  {
    path: SURVEYS_ONCE_ROUTE,
    element: <SurveysOnce />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_EVENT_ROUTE,
    element: <SurveysEvent />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_SETTLEMENT_ROUTE,
    element: <SurveysSettlement />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_SETTLEMENT_ADD_ROUTE,
    element: <SurveysSettlementAddUpdate />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_SETTLEMENT_BY_ID_ROUTE,
    element: <SurveysSettlementAddUpdate />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_ADD_ONCE_ROUTE,
    element: <SurveysAdd />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_ADD_EVENT_ROUTE,
    element: <SurveysAdd />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_ONCE_BY_ID_ROUTE,
    element: <SurveysDetails />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_EVENT_BY_ID_ROUTE,
    element: <SurveysDetails />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_SETTLEMENT_BY_ID_GIFT_ROUTE,
    element: <SurveysGift />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
  {
    path: SURVEYS_ANKETA_IMPORT_ROUTE,
    element: <SurveysAnketaImport />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
    ],
  },
];
