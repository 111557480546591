import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { NEWS_ROUTE } from 'routes/news/list';
import { t } from 'tools/i18n';

import { useLazyGetArticleByCodeQuery } from 'services/article/articleApiService';

import { Button, Tabs } from 'gazprom-ui-lib';

import Comments from 'containers/comments';
import ContentHeader from 'containers/content-header';
import NewsForm from 'containers/news-form';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './NewsDetails.module.scss';
import NewsAddEditActions from './news-details-actions';
import {
  COMMENTS_KEY,
  EDIT_KEY,
  resetCommentsButtonConfig,
  resetEditButtonConfig,
  saveCommentsButtonConfig,
  saveEditButtonConfig,
} from './newsDetails.utils';
import useActions from './useActions.hook';

const NewsDetails = () => {
  const { newsCode } = useParams<{ newsCode: string }>();

  const [selectedTab, setSelectedTab] = useState<string>(EDIT_KEY);

  const [getArticle, { data: currentArticle, isFetching: getCurrentArticleIsFetching }] =
    useLazyGetArticleByCodeQuery();

  useEffect(() => {
    if (newsCode) {
      getArticle(newsCode);
    }
  }, [getArticle, newsCode]);

  const { handleReset, handleSubmit, isFetching } = useActions({ code: newsCode });

  const saveButtonConfig = {
    disabled: getCurrentArticleIsFetching,
    loading: isFetching,
    ...(selectedTab === EDIT_KEY ? saveEditButtonConfig : saveCommentsButtonConfig),
  };

  const resetButtonConfig =
    selectedTab === EDIT_KEY ? resetEditButtonConfig : resetCommentsButtonConfig;

  const items = [
    {
      key: EDIT_KEY,
      label: t('news_edit'),
      children: (
        <>
          <NewsForm
            currentArticle={currentArticle}
            className={s.formWrapper}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
          />
          <NewsAddEditActions
            saveButtonConfig={saveButtonConfig}
            resetButtonConfig={resetButtonConfig}
          />
        </>
      ),
    },
    {
      key: COMMENTS_KEY,
      label: t('common_comments'),
      children: <Comments articleId={currentArticle?.id} />,
    },
  ];

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title goBackLink={NEWS_ROUTE}>{currentArticle?.title}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="ok" {...saveButtonConfig}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <Tabs onChange={setSelectedTab} items={items} type="card" className={s.tabs} />
    </WithOverflow>
  );
};
export default NewsDetails;
