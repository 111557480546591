import { t } from 'tools/i18n';

export const INSTANCES_OPTIONS_TYPE = {
  ZUP: 'ZUP',
  SHIFT: 'SHIFT',
} as const;

export enum INSTANCES_MODAL_FORM_ENUM {
  TYPE = 'type',
}

export type InstancesFieldType = {
  [INSTANCES_MODAL_FORM_ENUM.TYPE]:
    | typeof INSTANCES_OPTIONS_TYPE.ZUP
    | typeof INSTANCES_OPTIONS_TYPE.SHIFT;
};

export const selectOptions = [
  {
    value: INSTANCES_OPTIONS_TYPE.ZUP,
    label: t('1c_instance_zup'),
  },
  {
    value: INSTANCES_OPTIONS_TYPE.SHIFT,
    label: t('1c_instance_shift'),
  },
];
