import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import {
  Button,
  ModalDefault,
  ModalDefaultIconProps,
  ModalDefaultTitleProps,
  SaveModalConfigType,
} from 'gazprom-ui-lib';

import s from './SavedModal.module.scss';

interface Props {
  modalConfig: SaveModalConfigType;
  setModalConfig: Dispatch<SetStateAction<SaveModalConfigType>>;
}

const SavedModal = (props: Props) => {
  const { modalConfig, setModalConfig } = props;
  const {
    icon = DEFAULT_ICON_CONTENT,
    title = DEFAULT_TITLE_CONTENT,
    description,
    onClose,
    onConfirm,
    actions,
  } = modalConfig || {};

  const handleModalClose = () => {
    if (onClose) {
      onClose();
    }
    setModalConfig(null);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setModalConfig(null);
  };

  const defaultAction = (
    <Button fullWidth size="large" onClick={handleConfirm}>
      {t('common_double_ok')}
    </Button>
  );

  return (
    <ModalDefault
      open={!!modalConfig}
      onCancel={handleModalClose}
      width={320}
      closable
      className={s.wrapper}>
      <ModalDefault.Icon {...icon} />
      <ModalDefault.Title {...title} />
      {description && <ModalDefault.Description {...description} />}
      <ModalDefault.Actions>{actions ?? defaultAction}</ModalDefault.Actions>
    </ModalDefault>
  );
};

const DEFAULT_ICON_CONTENT: ModalDefaultIconProps = {
  name: 'ok',
  type: 'success',
};
const DEFAULT_TITLE_CONTENT: ModalDefaultTitleProps = { children: t('common_saved_successfully') };

export default SavedModal;
