import { GetDeclarationAttributeVariablesResponseType } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { Divider, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarationsTemplateKedo.module.scss';

export const renderAttribute = (attributesObj: Record<string, string>) => (current: string) =>
  (
    <div key={current}>
      <Divider />
      <div className={s.cardText}>
        <Typography.Text size="12" type="primary">
          {current}
        </Typography.Text>
        <Typography.Text size="12" type="secondary">
          {attributesObj[current]}
        </Typography.Text>
      </div>
    </div>
  );

export const getStatementVariableCode = (value: string) => `$\{${value}}`;

export const getInitialStatementData = (
  statementVariables: GetDeclarationAttributeVariablesResponseType['statementVariables'],
) => {
  const initialStatementVariableOptions = Object.entries(statementVariables).map(
    ([, { id, title }]) => ({
      value: id,
      label: title,
    }),
  );

  return initialStatementVariableOptions;
};
