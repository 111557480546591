import { useGetStatementVariablesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { getStatementVariableCode } from './documentDeclarationsTemplateKedo.utils';

export const useStatementVariables = () => {
  const { data: statementVariables = [] } = useGetStatementVariablesQuery();

  const statementVariableOptions = statementVariables.map(({ id, title }) => ({
    value: id,
    label: title,
  }));

  const statementVariablesIdCodeMap = Object.fromEntries(
    statementVariables.map(({ id, code }) => [id, getStatementVariableCode(code)]),
  );

  return { statementVariableOptions, statementVariablesIdCodeMap };
};
