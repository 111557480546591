import { useNavigate } from 'react-router-dom';
import { getSurveySettlementByIdRoute } from 'routes/surveys/list';
import { useVisibility } from 'utils/useVisibility.hook';

import {
  useCreateSurveySettlementMutation,
  usePublishSurveySettlementMutation,
  useUnpublishSurveySettlementMutation,
  useUpdateSurveySettlementMutation,
} from 'services/surveys/surveysApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { SurveySettlementFormResponse } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';

import { SurveySettlementCreateOrUpdateType } from 'types/surveys.types';

import { useGetSurvey } from './useGetSurvey.hook';

export const useFormHandlers = () => {
  const { survey } = useGetSurvey();
  const { isPublish, id: surveyId } = survey || {};

  const isUpdate = !!surveyId;
  const navigate = useNavigate();

  const [createSurvey, { isLoading: isCreating }] = useCreateSurveySettlementMutation();
  const [updateSurvey, { isLoading: isUpdating }] = useUpdateSurveySettlementMutation();

  const [publish, { isLoading: isPublishing }] = usePublishSurveySettlementMutation();
  const [unpublish, { isLoading: isUnpublishing }] = useUnpublishSurveySettlementMutation();

  const [isPublishModalOpen, { open: handleOpenPublishModal, close: handleClosePublishModal }] =
    useVisibility();

  const handleFinish = (formResponse: SurveySettlementFormResponse) => {
    const prepareBody: SurveySettlementCreateOrUpdateType = {
      ...formResponse,
      isPublish: false,
    };

    if (isUpdate) {
      updateSurvey({ surveyId, payload: prepareBody });

      return;
    }

    createSurvey(prepareBody).then(
      handleRequest({
        onSuccess: (data) => {
          const { id, anketologId, revisionId } = data || {};
          if (id && anketologId && revisionId) {
            navigate(getSurveySettlementByIdRoute(id, anketologId, revisionId));
          }
        },
      }),
    );
  };

  const handlePublish = () => {
    const mutation = isPublish ? unpublish : publish;

    if (surveyId) {
      mutation({ surveyId }).then(handleRequest({ onSuccess: handleClosePublishModal }));
    }
  };

  const isSubmittingForm = isCreating || isUpdating || isPublishing || isUnpublishing;

  return {
    handleFinish,
    handlePublish,
    isSubmittingForm,
    isPublishModalOpen,
    handleOpenPublishModal,
    handleClosePublishModal,
  };
};
