import { t } from 'tools/i18n';

export const MONTH_NAMES = [
  t('common_january'),
  t('common_february'),
  t('common_march'),
  t('common_april'),
  t('common_may'),
  t('common_june'),
  t('common_july'),
  t('common_august'),
  t('common_september'),
  t('common_october'),
  t('common_november'),
  t('common_december'),
];
