import { useNavigate } from 'react-router-dom';
import { get1CInstanceLnaWithIdRoute } from 'routes/settings/list';

import FiltersV2 from 'containers/filters-v2';

import { CInstanceLnaType } from 'types/cInstances.types';

import useColumns from './useColumns.hook';

interface Props {
  dataSource?: CInstanceLnaType[];
  loading: boolean;
  isInitialLoading: boolean;
}

const Table = (props: Props) => {
  const { dataSource, loading, isInitialLoading } = props;

  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = (record: CInstanceLnaType) => ({
    onClick: () => navigate(get1CInstanceLnaWithIdRoute(record.instanceId)),
  });

  return (
    <FiltersV2.Table
      isInitialLoading={isInitialLoading}
      onRow={onRow}
      rowKey="employerId"
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      pagination={false}
    />
  );
};

export default Table;
