import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { qoaParamsSelector } from 'selectors/surveysSlice.selector';
import {
  useLazyGetSurveySettlementAllFreeStatsByIdQuery,
  useLazyGetSurveySettlementStatsByIdQuery,
} from 'services/surveys/surveysApiService';
import {
  GetSurveySettlementAllFreeStatsByIdPropsType,
  GetSurveySettlementStatsByIdPropsType,
  GetSurveyStatsByIdResponseType,
} from 'services/surveys/surveysApiService.types';
import { useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import {
  getQueryQoaString,
  updateQuestionsWithStats,
} from 'pages/surveys/surveys-details/containers/stats-tab/statsTab.utils';

import {
  MappedFreeQuestionType,
  MappedQuestionStatType,
  QuestionStatsType,
} from 'types/surveys.types';

import { useGetSurvey } from '../../useGetSurvey.hook';
import {
  DEFAULT_FREE_PAGE,
  DEFAULT_FREE_SIZE,
  DEFAULT_REQUEST_BODY,
  RequestBodyType,
  getReportUrl,
} from './surveySettlementStats.utils';

const useStats = () => {
  const { id } = useParams<{ id: string }>();

  const { revisionId } = useGetSurvey();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isReportDownloadingInProgress, setIsReportDownloadingInProgress] =
    useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);
  const [questionStatsResult, setQuestionStatsResult] = useState<
    MappedQuestionStatType[] | QuestionStatsType[]
  >([]);

  const qoa = useAppSelector(qoaParamsSelector);

  const [getSurveysById, { data: surveyStats }] = useLazyGetSurveySettlementStatsByIdQuery();
  const [getFreeStats] = useLazyGetSurveySettlementAllFreeStatsByIdQuery();

  const handleDownLoadReport = () => {
    if (!id) {
      return;
    }

    setIsReportDownloadingInProgress(true);
    downloadHandler({
      url: getReportUrl(id),
      fileName: t('common_report'),
      method: 'POST',
      headersFileNameKey: `filename*=UTF-8''`,
    }).finally(() => setIsReportDownloadingInProgress(false));
  };

  const handleSurveyStats = useCallback(
    (response: GetSurveyStatsByIdResponseType | undefined) => {
      if (!response) {
        return;
      }

      const { questions } = response;
      const freeQuestions = response.questions.filter((question) => question.type === 'free');

      if (!freeQuestions?.length) {
        setIsDataLoading(false);
        setQuestionStatsResult(questions);
        return;
      }

      const freeQuestionsWithParams = freeQuestions.map((question) => {
        const preparedBody: GetSurveySettlementAllFreeStatsByIdPropsType = {
          surveyId: id!,
          questionId: String(question.questionId),
          revisionId: revisionId?.toString()!,
          page: DEFAULT_FREE_PAGE,
          size: DEFAULT_FREE_SIZE,
          from: requestBody.from,
          to: requestBody.to,
          freeQuestionId: question.freeQuestionId,
        };

        if (requestBody.company) {
          preparedBody.employerId = requestBody.company;
        }
        if (requestBody.staff) {
          preparedBody.positionId = requestBody.staff;
        }
        if (requestBody.division) {
          preparedBody.divisionId = requestBody.division;
        }
        if (requestBody.settlement) {
          preparedBody.settlementId = requestBody.settlement;
        }

        return preparedBody;
      });

      getFreeStats(freeQuestionsWithParams)
        .then((freeResponses) => {
          if (freeResponses.data) {
            const initialStats = updateQuestionsWithStats(
              questions,
              freeResponses.data as MappedFreeQuestionType[],
            );
            setQuestionStatsResult(initialStats);
          }
        })
        .finally(() => setIsDataLoading(false));
    },
    [getFreeStats, id, requestBody, revisionId],
  );

  useEffect(() => {
    if (id && revisionId) {
      const preparedBody: GetSurveySettlementStatsByIdPropsType = {
        surveyId: id,
        revisionId: revisionId.toString(),
      };

      if (requestBody.company) {
        preparedBody.employerId = requestBody.company;
      }
      if (requestBody.staff) {
        preparedBody.positionId = requestBody.staff;
      }
      if (requestBody.division) {
        preparedBody.divisionId = requestBody.division;
      }
      if (requestBody.settlement) {
        preparedBody.settlementId = requestBody.settlement;
      }
      if (requestBody.from) {
        preparedBody.from = requestBody.from;
      }
      if (requestBody.to) {
        preparedBody.to = requestBody.to;
      }

      if (qoa.length) {
        preparedBody.qoaQuery = getQueryQoaString(qoa);
      }

      setIsDataLoading(true);
      getSurveysById(preparedBody).then(
        handleRequest({
          onSuccess: handleSurveyStats,
        }),
      );
    }
  }, [getSurveysById, handleSurveyStats, id, qoa, requestBody, revisionId]);

  return {
    qoa,
    surveyStatistics: surveyStats?.statistics,
    isDataLoading,
    questionStatsResult,
    requestBody,
    isReportDownloadingInProgress,
    setRequestBody,
    handleDownLoadReport,
  };
};

export default useStats;
