import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useUploadFile from 'utils/useUploadFile.hook';

import { Form, FormInstance, FormItemProps, RcFile } from 'gazprom-ui-lib';

import FileUpload, { FileUploadProps } from 'components/file/file-upload';
import ImgPreview, { ImgPreviewProps } from 'components/img-preview';

interface Props {
  kedoReviewTaskId?: string;
  medExamRequestId?: string;
  initialData?: {
    fileName: string;
    fileKey: string;
  };
  getFileKey?: (url?: string) => string;
  getFileName?: (name?: string) => string;
  getDownloadLink?: (url: string) => string;
  availableTypes?: string[];
  uploadLink?: string;
  metaLink?: string;
  maxSize?: number;
  form?: FormInstance;
  formItemConfig: FormItemProps;
  previewConfig: Pick<
    ImgPreviewProps,
    | 'className'
    | 'downloadWithAuth'
    | 'iconName'
    | 'type'
    | 'showDelete'
    | 'showDownload'
    | 'resetImage'
    | 'description'
    | 'title'
  >;
  uploadConfig?: Pick<FileUploadProps, 'description' | 'className'> & {
    resetHandler?: () => void;
    title?: FileUploadProps['title'];
    icon?: FileUploadProps['icon'];
  };
  convert?: boolean;
}

export type FormFileType = { link: string; name: string; size: number };

const FileField = (props: Props) => {
  const {
    initialData,
    kedoReviewTaskId,
    medExamRequestId,
    availableTypes = AVAILABLE_TYPES,
    form: formFromProps,
    formItemConfig,
    previewConfig,
    uploadConfig,
    maxSize = 10,
    uploadLink,
    metaLink,
    getFileKey,
    getFileName,
    getDownloadLink = createMediaDownloadUrl,
    convert,
  } = props;

  const [isError, setIsError] = useState<boolean>(false);

  const formInstance = Form.useFormInstance();

  const form = formFromProps ?? formInstance;

  const resetHandler = () => {
    form.resetFields([formItemConfig.name]);
    setIsError(false);
    resetError();

    form.setFields([
      {
        name: formItemConfig.name,
        value: null,
      },
    ]);
  };

  const resetError = () => {
    if (form) {
      form.setFields([
        {
          name: formItemConfig.name,
          errors: [],
        },
      ]);
    }
  };

  const setErrors = (errors: string[]) => {
    setIsError(true);
    if (form) {
      form.setFields([
        {
          name: formItemConfig.name,
          errors,
        },
      ]);
    }
  };

  const handleResetPreview = () => {
    resetHandler();
    if (previewConfig.resetImage) {
      previewConfig.resetImage();
    }
  };

  const handleResetUpload = () => {
    resetHandler();
    if (uploadConfig?.resetHandler) {
      uploadConfig.resetHandler();
    }
  };

  const beforeUpload = (file: RcFile) => {
    setIsError(false);
    const moreThanLimit = file.size / 1024 / 1024 > maxSize;
    if (moreThanLimit) {
      form.setFields([
        {
          name: formItemConfig.name,
          errors: [t('validation_file_max_size', { size: `${maxSize} MB` })],
        },
      ]);
      return false;
    } else {
      resetError();

      form.setFieldsValue({
        [formItemConfig.name]: null,
      });

      return file;
    }
  };

  const { isLoading, fileData, customRequest } = useUploadFile({
    setErrors,
    customUploadUrl: uploadLink,
    customMetaUrl: metaLink,
    kedoReviewTaskId,
    medExamRequestId,
    convertA1A: convert,
  });

  useEffect(() => {
    if (fileData?.url) {
      form.setFields([
        {
          name: formItemConfig.name,
          value: {
            fileName: fileData.name,
            fileKey: fileData.fileKey,
            name: getFileName
              ? getFileName(fileData.name)
              : fileData.name ?? t('common_pdf_file_name_fallback'),
            link: getFileKey ? getFileKey(fileData.url) : fileData.url,
            size: fileData.size,
          },
        },
      ]);
    }
  }, [fileData, form, formItemConfig.name, getFileKey, getFileName]);

  useEffect(() => {
    if (initialData) {
      form.setFields([
        {
          name: formItemConfig.name,
          value: initialData,
        },
      ]);
    }
  }, [initialData, form, formItemConfig.name, getFileKey]);

  const file: undefined | FormFileType = Form.useWatch(formItemConfig.name, form);
  const size = file?.size
    ? t('common_mb', {
        count: (file?.size / 1024 / 1024).toLocaleString('ru', {
          maximumFractionDigits: 2,
        }),
      })
    : undefined;

  const { title, icon, description, ...otherUpload } = uploadConfig ?? {};
  return (
    <Form.Item {...formItemConfig}>
      {file ? (
        <ImgPreview
          title={file?.name ?? file.link}
          url={getDownloadLink(file.link)}
          description={previewConfig?.description ?? size ?? file.link}
          resetImage={handleResetPreview}
          {...previewConfig}
        />
      ) : (
        <FileUpload
          description={description ?? t('validation_upload_an_image_restrictions')}
          title={title ?? isError ? t('common_error') : t('common_upload_a_file')}
          icon={icon ?? isError ? 'info' : 'upload'}
          availableFileTypes={availableTypes}
          isLoading={isLoading}
          customRequest={customRequest}
          beforeUpload={beforeUpload}
          resetHandler={handleResetUpload}
          isError={isError}
          {...otherUpload}
        />
      )}
    </Form.Item>
  );
};

const AVAILABLE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export default FileField;
