import { CommunityAccessType } from 'types/community.types';
import { TCover } from 'types/cover';

export const COMMUNITY_MANAGEMENT_FORM_NAME = 'COMMUNITY_MANAGEMENT_FORM_NAME';

export enum COMMUNITY_MANAGEMENT_FORM_ENUM {
  NAME = 'name',
  DESCRIPTION = 'description',
  AVATAR = 'avatar',
  COVER = 'cover',
  TYPE = 'type',
  CATEGORIES = 'categories',
  LINKS = 'links',
  MODERATORS = 'moderators',
  IS_PUBLISH = 'isPublish',
}

export type CommunityManagementFormResponse = {
  [COMMUNITY_MANAGEMENT_FORM_ENUM.NAME]: string;
  [COMMUNITY_MANAGEMENT_FORM_ENUM.DESCRIPTION]: string;
  [COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR]: TCover;
  [COMMUNITY_MANAGEMENT_FORM_ENUM.COVER]: TCover;
  [COMMUNITY_MANAGEMENT_FORM_ENUM.TYPE]: CommunityAccessType;
  [COMMUNITY_MANAGEMENT_FORM_ENUM.CATEGORIES]: string[];
  [COMMUNITY_MANAGEMENT_FORM_ENUM.LINKS]: string[];
  [COMMUNITY_MANAGEMENT_FORM_ENUM.MODERATORS]: string[];
  [COMMUNITY_MANAGEMENT_FORM_ENUM.IS_PUBLISH]: boolean;
};
