import { PaginationPropsType } from 'gazprom-common-lib';
import { useParams } from 'react-router-dom';

import { useGetNotificationsQuery } from 'services/notification/notificationApiService';
import { useGetSettlementSurveyHistoryAssigmentByIdQuery } from 'services/surveys/surveysApiService';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import s from './FeedbackTab.module.scss';
import { columns } from './feedbackTab.utils';

export type FormResponseType = PaginationPropsType;

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
};

const FeedbackTab = () => {
  const [form] = Form.useForm();

  const formData: FormResponseType = Form.useWatch([], form);

  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const { data: historyItem } = useGetSettlementSurveyHistoryAssigmentByIdQuery(
    {
      id: giftId!,
      surveyId: surveyId!,
    },
    { skip: !giftId && !surveyId },
  );

  const { data: notificationsData, isLoading } = useGetNotificationsQuery(
    {
      packageId: historyItem?.notificationPackageId!,
      page: formData?.page ?? DEFAULT_FORM_STATE.page,
      size: formData?.size ?? DEFAULT_FORM_STATE.size,
    },
    {
      skip: !historyItem?.notificationPackageId,
    },
  );

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE} className={s.table}>
      <FiltersV2.Table
        rowKey="id"
        dataSource={notificationsData?.items}
        columns={columns}
        total={notificationsData?.totalCount}
        showSorterTooltip={false}
        loading={isLoading}
        showSizeChanger={false}
      />
    </FiltersV2>
  );
};

export default FeedbackTab;
