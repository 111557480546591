import { t } from 'tools/i18n';
import { useVisibility } from 'utils/useVisibility.hook';

import { Button, Flex, Icon } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import UploadModal from '../upload-modal';
import s from './Header.module.scss';

const Header = () => {
  const [isOpen, { open, close }] = useVisibility();
  return (
    <>
      <ContentHeader>
        <ContentHeader.Title
          description={
            <Flex gap="8">
              <Icon className={s.icon} size={18} name="info" />
              {t('surveys_anketa_import_description')}
            </Flex>
          }>
          {t('surveys_anketa_import')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={open}>
            {t('common_upload_file')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>

      <UploadModal open={isOpen} openUploadModal={open} closeUploadModal={close} />
    </>
  );
};

export default Header;
