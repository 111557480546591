export const GUIDE_ROUTE = '/guide';
export const GUIDE_HANDBOOK_ROUTE = `${GUIDE_ROUTE}/handbook`;
export const GUIDE_HANDBOOK_CREATE_ROUTE = `${GUIDE_HANDBOOK_ROUTE}/create`;
export const getHandbookWithIdRoute = (handbookId: string) =>
  `${GUIDE_HANDBOOK_ROUTE}/${handbookId}`;
export const GUIDE_HANDBOOK_DETAILS_ROUTE = `${GUIDE_HANDBOOK_ROUTE}/:handbookId`;
export const GUIDE_CONTACTS_ROUTE = `${GUIDE_ROUTE}/contacts`;
export const GUIDE_CONTACTS_EDIT_ROUTE = `${GUIDE_CONTACTS_ROUTE}/edit`;
export const GUIDE_CONTACTS_EDIT_ID_ROUTE = `${GUIDE_CONTACTS_ROUTE}/edit/:id`;
export const GUIDE_CATEGORIES_ROUTE = `${GUIDE_ROUTE}/categories`;
export const GUIDE_IMPROVEMENTS_ROUTE = `${GUIDE_ROUTE}/improvements`;
export const GUIDE_ADD_IMPROVEMENTS_ROUTE = `${GUIDE_ROUTE}/improvements/add`;
export const GUIDE_IMPROVEMENTS_BY_ID_ROUTE = `${GUIDE_ROUTE}/improvements/:code`;

export const getImprovementsByIdRoute = (code: string) => `${GUIDE_IMPROVEMENTS_ROUTE}/${code}`;
