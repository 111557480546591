import { SORT_ENUM } from 'types/sort.types';

export enum INSTANCES_TYPE_TAB_ENUM {
  ZUP = 'ZUP',
  SHIFT = 'SHIFT',
}

export type FormResponseType = {
  page: number;
  size: number;
  order: SORT_ENUM;
  property?: string;
  search: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'createDate',
  search: '',
};
