import { FC, useContext, useState } from 'react';

import { t } from 'tools/i18n';
import { normalizeToNumbers } from 'utils/formRules';

import { useCompleteAuthorizationMutation } from 'services/authorization/authorizationApiService';
import { CompleteAuthorizationResponseType } from 'services/authorization/authorizationApiService.types';

import {
  Button,
  Flex,
  Form,
  Input,
  SaveModalContext,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import {
  AuthorizationErrorCode,
  TELEGRAM_AUTHORIZATION_CODE_FORM_NAME,
  TELEGRAM_AUTHORIZATION_FORM_ENUM,
  TelegramAuthorizationCodeFormResponse,
  getAuthorizationErrorTitle,
} from '../../settingsTelegramAuthorization.utils';
import s from './AuthorizationConfirmForm.module.scss';

interface AuthorizationConfirmFormProps {
  authID: string;
  handleChangeNumber: () => void;
}

const AuthorizationConfirmForm: FC<AuthorizationConfirmFormProps> = (props) => {
  const { authID, handleChangeNumber } = props;

  const setModalConfig = useContext(SaveModalContext);

  const handleOpenSuccessModal = () => {
    if (setModalConfig) {
      setModalConfig({
        icon: {
          name: 'ok',
          type: 'success',
        },
        title: {
          children: t('telegram_authorization_success'),
        },
        description: {
          children: t('telegram_authorization_success_description'),
        },
      });
    }
  };

  const [confirmRequestError, setConfirmRequestError] = useState('');

  const clearAuthorizationError = () => {
    setConfirmRequestError('');
  };

  const [confirmAuthorization, { isLoading: isConfirmAuthorizationLoading }] =
    useCompleteAuthorizationMutation();
  const handleFinish = (formResponse: TelegramAuthorizationCodeFormResponse) => {
    const onSuccess = (data?: CompleteAuthorizationResponseType) => {
      if (data) {
        const { message, telegramError } = data;

        if (telegramError) {
          setConfirmRequestError(getAuthorizationErrorTitle(message as AuthorizationErrorCode));
        } else {
          handleOpenSuccessModal();
        }
      }
    };

    confirmAuthorization({
      code: formResponse[TELEGRAM_AUTHORIZATION_FORM_ENUM.CODE],
      authID: authID,
    }).then(handleRequest({ onSuccess }));
  };

  return (
    <Form name={TELEGRAM_AUTHORIZATION_CODE_FORM_NAME} onFinish={handleFinish}>
      <Flex vertical gap="8">
        <Typography.Text type="secondary" size="14">
          {t('telegram_enter_code')}
        </Typography.Text>
        <Form.Item
          normalize={normalizeToNumbers}
          name={TELEGRAM_AUTHORIZATION_FORM_ENUM.CODE}
          rules={[{ len: 5 }]}>
          <Input
            onChange={clearAuthorizationError}
            maxLength={5}
            className={s.input}
            allowClear
            placeholder="00000"
          />
        </Form.Item>
        {confirmRequestError && (
          <Typography.Text type="danger">{confirmRequestError}</Typography.Text>
        )}
      </Flex>
      <Flex className={s.actions} gap="8">
        <Button loading={isConfirmAuthorizationLoading} htmlType="submit" leftIcon="key">
          {t('common_accept')}
        </Button>
        <Button leftIcon="arrowLeft" onClick={handleChangeNumber}>
          {t('common_change_number')}
        </Button>
      </Flex>
    </Form>
  );
};

export default AuthorizationConfirmForm;
