import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { EmployeeType } from 'types/employee.types';

import s from './Leaders.module.scss';

interface Props {
  approvedLeader?: EmployeeType;
  firstLeader?: EmployeeType;
}

const Leaders = (props: Props) => {
  const { approvedLeader, firstLeader } = props;

  return (
    <Flex vertical gap="24" className={s.wrapper}>
      <Flex vertical gap="16">
        <Typography.Title level={5}>{t('documents_view_first_leader')}</Typography.Title>
        <Bio
          key={firstLeader?.id}
          firstName={firstLeader?.person.firstName}
          lastName={firstLeader?.person.lastName}
          staffName={firstLeader?.position?.name}
        />
      </Flex>
      <Flex vertical gap="16">
        <Typography.Title level={5}>{t('documents_view_order_approver')}</Typography.Title>
        <Bio
          key={approvedLeader?.id}
          firstName={approvedLeader?.person?.firstName}
          lastName={approvedLeader?.person?.lastName}
          staffName={approvedLeader?.position?.name}
        />
      </Flex>
    </Flex>
  );
};

export default Leaders;
