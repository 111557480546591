import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_IMPROVEMENTS_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { useCreateArticleMutation } from 'services/article/articleApiService';
import { CreateArticlePropsType } from 'services/article/articleApiService.types';

import { Button, Flex, useHandleRequestWithModal } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import ImprovementsForm from 'containers/improvements-form';
import {
  FORM_NAME,
  IMPROVEMENTS_FORM_ENUM,
  ImprovementsFormResponse,
} from 'containers/improvements-form/newsForm.utils';
import 'containers/news-form/newsForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';

import s from './ImprovementsAdd.module.scss';

enum SUBMIT_ACTION_ENUM {
  SAVE = 'save',
  SAVE_AND_PUBLISH = 'saveAndPublish',
}

const ImprovementsAdd = () => {
  const navigate = useNavigate();

  const submitActionRef = useRef<SUBMIT_ACTION_ENUM | null>(null);

  const [createArticle, { isLoading: createArticleIsFetching }] = useCreateArticleMutation();

  const handleReset = () => navigate(GUIDE_IMPROVEMENTS_ROUTE);

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
      title: {
        children: t('common_added_successfully'),
      },
    },
  });

  const handleSubmit = (form: ImprovementsFormResponse) => {
    const body: CreateArticlePropsType = {
      type: ARTICLE_TYPE_ENUM.ORG_ARTICLE,
      text: form[IMPROVEMENTS_FORM_ENUM.TEXT],
      title: form[IMPROVEMENTS_FORM_ENUM.TITLE],
      isPublish: submitActionRef.current === SUBMIT_ACTION_ENUM.SAVE_AND_PUBLISH ? true : false,
    };

    if (form[IMPROVEMENTS_FORM_ENUM.SETTLEMENT_IDS]) {
      body.settlementIds = form[IMPROVEMENTS_FORM_ENUM.SETTLEMENT_IDS];
    }

    if (form[IMPROVEMENTS_FORM_ENUM.COVER]) {
      body.cover = form[IMPROVEMENTS_FORM_ENUM.COVER];
    }

    createArticle(body).then(handleCreate);
  };

  const handleSetSubmitAction = (action: SUBMIT_ACTION_ENUM) => () =>
    (submitActionRef.current = action);

  return (
    <WithOverflow>
      <ContentHeader showBorder align="flex-start">
        <ContentHeader.Title goBackLink={GUIDE_IMPROVEMENTS_ROUTE}>
          {t('guide_add_improvement')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="ok" htmlType="submit" form={FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>

      <div className={s.formWrapper}>
        <ImprovementsForm handleReset={handleReset} handleSubmit={handleSubmit} />
        <Flex justify="space-between" gap="12">
          <Button
            leftIcon="ok"
            fullWidth
            type="primary"
            htmlType="submit"
            form={FORM_NAME}
            loading={createArticleIsFetching}
            onClick={handleSetSubmitAction(SUBMIT_ACTION_ENUM.SAVE)}>
            {t('common_save')}
          </Button>
          <Button
            leftIcon="ok"
            type="secondary"
            fullWidth
            htmlType="submit"
            form={FORM_NAME}
            loading={createArticleIsFetching}
            onClick={handleSetSubmitAction(SUBMIT_ACTION_ENUM.SAVE_AND_PUBLISH)}>
            {t('common_save_and_publish')}
          </Button>
        </Flex>
      </div>
    </WithOverflow>
  );
};

export default ImprovementsAdd;
