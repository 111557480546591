import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetArticlesQuery } from 'services/article/articleApiService';

import { Form, Input, Select } from 'gazprom-ui-lib';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { Employer1cShiftInfoType } from 'types/employer.types';

import s from './SettingsEmployerShiftForm.module.scss';
import {
  SETTINGS_EMPLOYER_SHIFT_FORM,
  SETTINGS_EMPLOYER_SHIFT_FORM_ENUM,
  SettingsEmployerShiftFormResponse,
} from './settingsEmployerShiftForm.utils';

interface CInstanceFormProps {
  onSubmit: (form: SettingsEmployerShiftFormResponse) => void;
  employerShiftData?: Employer1cShiftInfoType;
}

const SettingsEmployerShiftForm: FC<CInstanceFormProps> = (props) => {
  const { onSubmit, employerShiftData } = props;
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  useEffect(() => {
    if (employerShiftData) {
      form.setFieldsValue({
        [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.TELEGRAM_BOT]: employerShiftData.telegramBot,
        [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.ARTICLE]: employerShiftData.articleId,
        [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.EMAIL]: employerShiftData.email,
      });
    }
  }, [form, employerShiftData]);

  const { data, isLoading } = useGetArticlesQuery({
    page: 1,
    size: 500,
    type: [ARTICLE_TYPE_ENUM.ORG_ARTICLE],
    employerId: id,
  });

  const articleOptions = data?.items?.map((article) => ({
    label: article.title,
    value: article.id,
  }));

  return (
    <Form className={s.wrapper} form={form} name={SETTINGS_EMPLOYER_SHIFT_FORM} onFinish={onSubmit}>
      <Form.Item
        label={t('1c_instance_shift_telegram_bot')}
        name={SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.TELEGRAM_BOT}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>

      <Form.Item
        label={t('1c_instance_shift_article')}
        name={SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.ARTICLE}>
        <Select
          loading={isLoading}
          options={articleOptions}
          placeholder={t('common_select_second')}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>

      <Form.Item
        label={t('1c_instance_shift_email')}
        name={SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.EMAIL}
        rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
    </Form>
  );
};

export default SettingsEmployerShiftForm;
