import { useMemo, useRef, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetSettlementsWithAssignmentQuery } from 'services/surveys/surveysApiService';

import { Button, Divider, Flex, Form, TreeSelect, Typography } from 'gazprom-ui-lib';

import { SURVEY_SETTLEMENT_FORM_ENUM } from '../../surveySettlementForm.utils';
import { useGetFormData } from '../../useGetFormData.hook';
import s from './Settlement.module.scss';

interface DefaultOptionType {
  value?: string;
  title?: React.ReactNode;
  key?: React.Key;
}

const Settlements = () => {
  const [search, setSearch] = useState<string>('');
  const isAllSelectedRef = useRef<boolean>(false);

  const { survey } = useGetFormData();

  const form = Form.useFormInstance();
  const values: string[] = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS, form);
  const anketologId = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID, form);

  const { data: settlements } = useGetSettlementsWithAssignmentQuery(anketologId, {
    skip: !anketologId,
  });

  const settlementOptions = useMemo((): DefaultOptionType[] => {
    return (
      settlements?.map(({ label, value, assignment }) => ({
        key: value,
        title: label,
        value: value,
        disabled: assignment && !values.includes(value),
      })) ?? []
    );
  }, [settlements, values]);

  const handleSelectAll = () => {
    const possibleIds = settlements?.filter(({ value, assignment }) => !assignment && value) ?? [];
    const allIds = [...possibleIds, ...values];

    form.setFieldValue(SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS, allIds);
    isAllSelectedRef.current = true;
  };

  const resetAll = () => {
    form.setFieldValue(SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS, []);
    isAllSelectedRef.current = false;
  };

  return (
    <Flex vertical gap="24">
      <Typography.Text size="18" weight="500">
        {t('surveys_settings')}
      </Typography.Text>
      <Form.Item
        name={SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS}
        label={
          <Typography.Text size="16" weight="500">
            {t('common_settlement_respondents')}
          </Typography.Text>
        }
        rules={[formRules.required]}>
        <TreeSelect
          value={values}
          treeData={settlementOptions}
          placeholder={t('common_select_second')}
          treeCheckable
          allowClear
          showSearch
          searchValue={search}
          onSearch={setSearch}
          filterTreeNode
          treeNodeFilterProp="title"
          popupClassName={s.popup}
          maxTagCount="responsive"
          disabled={!anketologId || survey?.isPublish}
          dropdownRender={(menu) => (
            <>
              <Flex vertical fullWidth justify="flex-start" align="flex-start">
                <Button
                  onClick={isAllSelectedRef.current ? resetAll : handleSelectAll}
                  size="small"
                  type="link"
                  className={s.button}>
                  {isAllSelectedRef.current ? 'Отменить выбор' : 'Выбрать все'}
                </Button>
                <Divider />
              </Flex>

              {menu}
            </>
          )}
        />
      </Form.Item>
    </Flex>
  );
};

export default Settlements;
