import cn from 'classnames';
import { t } from 'tools/i18n';

import { Divider, Flex, Form, FormInstance, Typography } from 'gazprom-ui-lib';

import useFormSet from 'containers/notification-template-form/useFormSet.hook';

import { NotificationTemplateType } from 'types/notification.types';

import s from './NotificationTemplateForm.module.scss';
import NotificationTemplateFormApp from './notification-template-form-app';
import NotificationTemplateFormEmail from './notification-template-form-email';
import NotificationTemplateFormName from './notification-template-form-name';
import NotificationTemplateFormReceivers from './notification-template-form-receivers';
import NotificationTemplateFormText from './notification-template-form-text';
import NotificationTemplateFormTitle from './notification-template-form-title';
import {
  NOTIFICATION_TEMPLATE_FORM_ENUM,
  NOTIFICATION_TEMPLATE_FORM_NAME,
  NotificationTemplateFormResponse,
} from './notificationTemplateForm.utils';

interface Props {
  form: FormInstance<NotificationTemplateFormResponse>;
  isTemplateNameAvailable?: boolean;
  handleSubmit: (form: NotificationTemplateFormResponse) => void;
  handleReset: () => void;
  className?: string;
  currentTemplate?: NotificationTemplateType;
  type: 'add' | 'edit';
}

const NotificationTemplateForm = (props: Props) => {
  const { form, handleSubmit, handleReset, isTemplateNameAvailable, className, currentTemplate } =
    props;

  useFormSet({ currentTemplate, form });

  const onSubmit = (formResponse: NotificationTemplateFormResponse) => {
    if (isTemplateNameAvailable) {
      handleSubmit(formResponse);
    } else {
      form.setFields([
        {
          name: NOTIFICATION_TEMPLATE_FORM_ENUM.NAME,
          errors: [t('notification_save_as_template_exists')],
        },
      ]);
    }
  };

  return (
    <Form
      form={form}
      className={cn(s.wrapper, className)}
      onFinish={onSubmit}
      onReset={handleReset}
      id={NOTIFICATION_TEMPLATE_FORM_NAME}>
      <NotificationTemplateFormName />
      <NotificationTemplateFormReceivers />
      <Divider />
      <Flex vertical gap="32">
        <Typography.Title level={4}>{t('notification_newsletters_content')}</Typography.Title>
        <NotificationTemplateFormTitle title={t('common_message_title')} />
        <NotificationTemplateFormText title={t('notification_message_text')} />
        <Flex vertical gap="4">
          <NotificationTemplateFormApp />
          <NotificationTemplateFormEmail />
        </Flex>
      </Flex>
    </Form>
  );
};

export default NotificationTemplateForm;
