import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Button, ColumnsType, Input } from 'gazprom-ui-lib';

import { Employer1cShiftInfoHotContactType } from 'types/employer.types';

interface Props {
  handleFieldChange: (
    index: number,
    field: keyof Employer1cShiftInfoHotContactType,
    value: string,
  ) => void;
  handleDelete: (index: number) => void;
}

const useColumns = (props: Props): ColumnsType<Employer1cShiftInfoHotContactType> => {
  const { handleFieldChange, handleDelete } = props;

  return useMemo(
    () => [
      {
        title: t('common_phone'),
        dataIndex: 'phoneNumber',
        render: (text, _: Employer1cShiftInfoHotContactType, index) => (
          <Input
            placeholder={t('common_enter_text')}
            value={text}
            onChange={(e) => handleFieldChange(index, 'phoneNumber', e.target.value)}
          />
        ),
      },
      {
        title: t('common_comment'),
        dataIndex: 'comment',
        render: (text, _: Employer1cShiftInfoHotContactType, index) => (
          <Input
            placeholder={t('common_enter_text')}
            value={text}
            onChange={(e) => handleFieldChange(index, 'comment', e.target.value)}
          />
        ),
      },
      {
        title: t('common_work_period'),
        dataIndex: 'timetable',
        render: (text, _: Employer1cShiftInfoHotContactType, index) => (
          <Input
            placeholder={t('common_enter_text')}
            value={text}
            onChange={(e) => handleFieldChange(index, 'timetable', e.target.value)}
          />
        ),
      },
      {
        title: '',
        width: 120,
        align: 'center',
        render: (_, _row: Employer1cShiftInfoHotContactType, index) => (
          <Button type="link" leftIcon="bin" danger onClick={() => handleDelete(index)}>
            {t('common_delete')}
          </Button>
        ),
      },
    ],
    [handleDelete, handleFieldChange],
  );
};

export default useColumns;
