import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, Form, Input, ModalDefault } from 'gazprom-ui-lib';

import { C_INSTANCE_FORM_ENUM } from '../../cInstanceForm.utils';
import s from './SetPasswordModal.module.scss';

interface Props {
  onClose: () => void;
  field: C_INSTANCE_FORM_ENUM | null;
}

const SetPasswordModal = (props: Props) => {
  const { field, onClose } = props;

  const form = Form.useFormInstance();

  const [password, setPassword] = useState<string>('');

  const onSubmit = () => {
    const submitPassword = password.length && form && field;

    if (submitPassword) {
      form.setFieldValue(field, password);
      setPassword('');
      onClose();
    }
  };

  return (
    <ModalDefault open={!!field} onCancel={onClose} closable width={420} className={s.wrapper}>
      <ModalDefault.Title>{t('common_set_password')}</ModalDefault.Title>
      <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
      <ModalDefault.Actions>
        <Flex gap="8">
          <Button fullWidth type="secondary" onClick={close}>
            {t('common_cancel')}
          </Button>
          <Button
            fullWidth
            type="primary"
            leftIcon="ok"
            onClick={onSubmit}
            disabled={!password.length}>
            {t('common_confirm')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default SetPasswordModal;
