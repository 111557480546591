import { t } from 'i18next';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  onCancel: () => void;
  onActionClick: () => void;
  isLoading: boolean;
}

const UpdateModal = (props: Props) => {
  const { open, onActionClick, onCancel, isLoading } = props;

  return (
    <ModalDefault open={open} onCancel={onCancel} width={320} closable>
      <ModalDefault.Icon type="primary" name="refresh" />
      <ModalDefault.Title>{t('surveys_update_anketa_revision_modal_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('surveys_update_anketa_revision_modal_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          type="primary"
          onClick={onActionClick}
          leftIcon="ok">
          {t('common_accept')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default UpdateModal;
