import { t } from 'tools/i18n';

import { Form, TextArea } from 'gazprom-ui-lib';

import { GUIDE_FORM_ENUM } from 'pages/guide/guide-contacts/guideContacts.utils';

import TextCounter from '../text-counter';
import s from './ModalBody.module.scss';

interface Props {
  text: string;
}

const ModalBody = (props: Props) => {
  const { text } = props;
  const form = Form.useFormInstance();
  const textField = Form.useWatch(GUIDE_FORM_ENUM.TEXT, form);

  return (
    <div className={s.container}>
      <TextCounter current={textField?.length ?? 0} total={240} text={text} />
      <Form.Item name={GUIDE_FORM_ENUM.TEXT}>
        <TextArea className={s.area} placeholder={t('common_enter_text')} maxLength={240} />
      </Form.Item>
    </div>
  );
};

export default ModalBody;
