import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { SUGGESTIONS_DECISIONS_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { useTakeCoordinationDecisionsByCommissionMutation } from 'services/suggestion/suggestionApiService';

import { Button, Flex, handleRequest } from 'gazprom-ui-lib';

import { SuggestionDecisionType } from 'types/suggestion.types';

import s from './Actions.module.scss';
import ModalApprove from './containers/modal-approve';
import ModalConsideration from './containers/modal-consideration';
import ModalReject from './containers/modal-reject';

interface Props {
  suggestionId?: string;
  curStatus?: SuggestionDecisionType['suggestion']['curStatus'];
}

export type ModalType = '' | 'approve' | 'reject' | 'consideration';

const Actions = (props: Props) => {
  const { suggestionId, curStatus } = props;

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [modalType, setModalType] = useState<ModalType>('');

  const [takeCoordination, { isLoading: takeCoordinationIsLoading }] =
    useTakeCoordinationDecisionsByCommissionMutation();

  const handleCloseModal = () => {
    setModalType('');
  };

  const handleOpenApproveModal = () => {
    setModalType('approve');
  };

  const handleOpenRejectModal = () => {
    setModalType('reject');
  };

  const handleGoBack = () => {
    navigate(SUGGESTIONS_DECISIONS_ROUTE);
  };

  const handleCoordination = () => {
    if (params.id) {
      takeCoordination({ id: params.id }).then(
        handleRequest({
          onSuccess: () => {
            setModalType('consideration');
          },
        }),
      );
    }
  };

  let actions = (
    <Button type="secondary" className={s.closeButton} onClick={handleGoBack}>
      {t('common_close')}
    </Button>
  );

  if (curStatus?.code === 'COORDINATOR_WAITING') {
    actions = (
      <>
        <Button
          onClick={handleCoordination}
          loading={takeCoordinationIsLoading}
          fullWidth
          leftIcon="ok">
          {t('common_accept_for_consideration')}
        </Button>
        <Button type="secondary" onClick={handleGoBack} fullWidth>
          {t('common_close')}
        </Button>
      </>
    );
  }
  const showApproveAndDecline = curStatus?.code === 'COORDINATOR_CHECKING';

  if (showApproveAndDecline) {
    actions = (
      <>
        <Button leftIcon="ok" fullWidth onClick={handleOpenApproveModal}>
          {t('common_approve')}
        </Button>
        <Button danger fullWidth type="secondary" onClick={handleOpenRejectModal}>
          {t('thank_you_reject')}
        </Button>
        <Button type="secondary" fullWidth onClick={handleGoBack}>
          {t('common_close')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Flex gap="16" className={showApproveAndDecline ? s.longActions : s.actions}>
        {actions}
      </Flex>
      <ModalConsideration isOpen={modalType === 'consideration'} onCancel={handleCloseModal} />
      <ModalApprove
        isOpen={modalType === 'approve'}
        onCancel={handleCloseModal}
        suggestionId={suggestionId}
      />
      <ModalReject isOpen={modalType === 'reject'} onCancel={handleCloseModal} />
    </>
  );
};

export default Actions;
