import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea } from 'gazprom-ui-lib';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

const CommunityManagementFormDescription = () => {
  return (
    <Form.Item
      label={t('common_description')}
      normalize={normalizeTextField}
      name={COMMUNITY_MANAGEMENT_FORM_ENUM.DESCRIPTION}
      rules={[formRules.required, { max: 380 }]}>
      <TextArea placeholder={t('common_enter_text')} maxLength={380} showCount />
    </Form.Item>
  );
};

export default CommunityManagementFormDescription;
