import { handleFormTableChange } from 'utils/table.utils';

import {
  Form,
  FormInstance,
  Table as TableLib,
  TablePaginationConfig,
  TableProps as TablePropsLib,
} from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import WithLoader from 'containers/wrappers/with-loader';

import { SortOrderPropertyType } from 'types/sort.types';

import s from './Table.module.scss';

interface TableProps<T> extends TablePropsLib<T> {
  total?: number;
  hasFiltersApplied?: boolean;
  fallbackSort?: SortOrderPropertyType;
  isInitialLoading?: boolean;
  showSizeChanger?: TablePaginationConfig['showSizeChanger'];
}

const VERTICAL_SCROLL_HEIGHT = '50vh';
const HORIZONTAL_SCROLL_WIDTH = 700;

const Table = <T extends object, F extends object>(props: TableProps<T>) => {
  const {
    fallbackSort,
    hasFiltersApplied,
    pagination = true,
    total,
    loading,
    isInitialLoading,
    showSizeChanger = true,
    ...otherProps
  } = props;

  const form = Form.useFormInstance<FormInstance<F>>();

  const defaultCurrent: number = form.getFieldValue('page');
  const defaultPageSize: number = form.getFieldValue('size');

  let paginationToUse: TablePropsLib<T>['pagination'];

  if (typeof pagination === 'boolean') {
    if (pagination) {
      paginationToUse = {
        current: defaultCurrent,
        defaultCurrent,
        defaultPageSize,
        total,
      };
    } else {
      paginationToUse = false;
    }
  } else if (typeof pagination === 'object') {
    paginationToUse = pagination;
  }

  const emptyText = (
    <Empty
      className={s.emptyContent}
      title="common_no_records_found"
      description={hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''}
    />
  );

  return (
    <>
      <WithLoader isLoading={isInitialLoading}>
        <TableLib<T>
          pagination={{ ...paginationToUse, showSizeChanger }}
          onChange={handleFormTableChange(form, fallbackSort)}
          locale={{ emptyText }}
          loading={loading}
          scroll={{ y: VERTICAL_SCROLL_HEIGHT, x: HORIZONTAL_SCROLL_WIDTH }}
          {...otherProps}
        />
      </WithLoader>
      <Form.Item name="page" noStyle />
      <Form.Item name="size" noStyle />
      <Form.Item name="order" noStyle />
      <Form.Item name="property" noStyle />
    </>
  );
};

export default Table;
