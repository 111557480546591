import { useGetSurveysListQuery } from 'services/surveys/surveysApiService';

import { useGetSurvey } from '../../useGetSurvey.hook';

export const useGetFormData = () => {
  const { survey, isLoading: surveyIsLoading } = useGetSurvey();

  const {
    data: { surveysInfo, options: surveyOptions = [] } = {},
    isLoading: surveysListIsLoading,
  } = useGetSurveysListQuery();

  const isLoading = surveysListIsLoading || surveyIsLoading;

  return { isLoading, surveyOptions, survey, surveysInfo };
};
