import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { useUpdateFeedbackByIdMutation } from 'services/thank-you/thankYouApiService';

import { Button, Divider, Icon, Modal, Typography } from 'gazprom-ui-lib';

import TablePerson from 'components/table/table-person';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { TMappedFeedBack, TStatusFeedback } from 'types/thankYou.types';

import s from './ThankYouRowModal.module.scss';

interface Props {
  isModalOpen: boolean;
  onCancel: () => void;
  data: TMappedFeedBack;
}

const textProps = { size: '14', weight: '500' } as const;

const ThankYouRowModal = (props: Props) => {
  const { data, isModalOpen, onCancel } = props;
  const [updateFeedback] = useUpdateFeedbackByIdMutation();
  const { key, status, date, sender, recipient, thankText } = data;
  const formattedDate = dayjs(date).format('DD.MM.YYYY');
  const formattedTime = dayjs(date).format('HH:mm');

  const onClickHandler = (status: TStatusFeedback) => {
    updateFeedback({ id: key, status });
    onCancel();
  };

  return (
    <Modal open={isModalOpen} onCancel={onCancel}>
      <div className={s.header}>
        <TableStatus status={status as STATUSES} />
        <Typography.Text {...textProps} type="secondary">
          {formattedDate} в {formattedTime}
        </Typography.Text>
        <button onClick={onCancel} className={s.close}>
          <Icon name="delete" />
        </button>
      </div>
      <Divider />
      <div className={s.from}>
        <div className={s.fromItem}>
          <Typography.Text {...textProps} type="primary">
            {t('thank_you_from')}
          </Typography.Text>
          <TablePerson {...sender} />
        </div>
        <div className={s.fromItem}>
          <Typography.Text {...textProps} type="primary">
            {t('thank_you_to')}
          </Typography.Text>
          <TablePerson {...recipient} />
        </div>
      </div>
      <Divider />
      <div className={s.body}>
        <Typography.Text {...textProps} type="primary">
          Благодарность за работу
        </Typography.Text>
        <Typography.Text>{thankText}</Typography.Text>
      </div>
      <Divider />
      <div className={s.buttons}>
        <Button leftIcon="ok" fullWidth onClick={() => onClickHandler('APPROVED')}>
          {t('common_approve')}
        </Button>
        <Button leftIcon="delete" fullWidth onClick={() => onClickHandler('DECLINED')}>
          {t('thank_you_reject')}
        </Button>
      </div>
    </Modal>
  );
};

export default ThankYouRowModal;
