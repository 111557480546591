import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_REPLACEMENT_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetReplacementByIdQuery } from 'services/private/replacement/replacementApiService';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { EmployeeType } from 'types/employee.types';

import { transformReplacementPeriod } from '../documentsReplacement.utils';
import s from './DocumentsReplacementDetails.module.scss';
import Header from './containers/header';

const DocumentsReplacementDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: replacement,
    isFetching: replacementIsFetching,
    isError: replacementIsError,
  } = useGetReplacementByIdQuery(id ?? skipToken);

  const handleGoBack = () => {
    navigate(DOCUMENTS_REPLACEMENT_ROUTE);
  };

  const { fromEmployee, toEmployee, startDate, endDate, employer, assignmentQuantity } =
    replacement ?? {};

  return (
    <WithOverflow>
      <Header replacement={replacement} />
      <WithLoader isLoading={replacementIsFetching}>
        <DocumentView isError={replacementIsError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('social_program_organization')}</Typography.Title>
                <Typography.Text size="14">
                  {employer?.name ?? fromEmployee?.employer?.name}
                </Typography.Text>
              </Flex>
              <DocumentView.Employee
                showAvatar
                employee={fromEmployee as EmployeeType}
                title={t('common_manager')}
              />
              <DocumentView.Employee
                showAvatar
                employee={toEmployee as EmployeeType}
                title={t('replacement_person')}
              />
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('replacement_period')}</Typography.Title>
                <Typography.Text size="14">
                  {transformReplacementPeriod(startDate, endDate)}
                </Typography.Text>
              </Flex>
              <Flex vertical gap="4">
                <Typography.Title level={5}>{t('replacement_task_count')}</Typography.Title>
                <Typography.Text size="14">{assignmentQuantity ?? 0}</Typography.Text>
              </Flex>
            </Flex>
          </DocumentView.Content>
          <Flex gap="8" className={s.actions}>
            <Button fullWidth type="secondary" onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          </Flex>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsReplacementDetails;
