import { useMatch } from 'react-router-dom';
import {
  SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_KEDO_ROUTE,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Icon, Popover, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarations.module.scss';
import DocumentsDeclarationsTemplate from './documents-declarations-template';
import DocumentsDeclarationsTemplateKedo from './documents-declarations-template-kedo';

type Props = {
  isDisabled: boolean;
  popoverIsOpen: boolean;
  onClose: () => void;
};

const useTemplateTab = (props: Props) => {
  const { popoverIsOpen, onClose, isDisabled } = props;

  const isKedoCreation = !!useMatch(SETTINGS_DECLARATIONS_KEDO_ROUTE);
  const isKedoEditing = !!useMatch(SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE);
  const isKedo = isKedoCreation || isKedoEditing;

  const popoverContent = (
    <div className={s.popoverContent}>
      <button type="button" onClick={onClose}>
        <Icon name="delete" />
      </button>
      <Typography.Text size="14" weight="500">
        {t('common_saved_successfully')}
      </Typography.Text>
      <Typography.Text size="12" type="secondary">
        {t('documents_declarations_template_popover_hint')}
      </Typography.Text>
    </div>
  );

  return {
    key: TEMPLATES_TAB_KEY,
    label: (
      <Popover
        overlayClassName={s.popover}
        open={popoverIsOpen}
        content={popoverContent}
        arrow={false}
        placement="rightTop"
        align={{ offset: [0, 20] }}>
        {t(isKedo ? 'documents_declarations_kedo_template' : 'documents_declarations_template')}
      </Popover>
    ),
    children: isKedo ? (
      <DocumentsDeclarationsTemplateKedo isDisabled={isDisabled} />
    ) : (
      <DocumentsDeclarationsTemplate isDisabled={isDisabled} />
    ),
  };
};

export const TEMPLATES_TAB_KEY = 'template';

export default useTemplateTab;
