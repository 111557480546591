import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useSaveCommunitySurveyMutation } from 'services/communities/communitiesApiService';
import { useGetSurveysListQuery } from 'services/surveys/surveysApiService';

import {
  Button,
  Flex,
  Form,
  ModalDefault,
  Select,
  Typography,
  useHandleRequestWithModal,
} from 'gazprom-ui-lib';

import s from './SurveyCreateModal.module.scss';

interface Props {
  open: boolean;
  close: () => void;
}

const SurveyCreateModal = (props: Props) => {
  const { open, close } = props;

  const { id } = useParams<{ id: string }>();

  const { data: surveysData } = useGetSurveysListQuery();
  const [request, { isLoading }] = useSaveCommunitySurveyMutation();

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const [form] = Form.useForm();

  const { options, surveysInfo } = surveysData || {};
  const isAnketaSelected = Form.useWatch('anketaName', form);
  const selectedAnketaInfo = surveysInfo?.[isAnketaSelected];

  const handleSave = (isPublish: boolean) => () => {
    if (!id || !selectedAnketaInfo) {
      return;
    }

    const { anketologId, revisionId, name } = selectedAnketaInfo;
    const prepareRequestBody = {
      communityId: id,
      body: {
        anketologId: anketologId.toString(),
        revisionId: revisionId,
        name: name,
        isPublish,
      },
    };

    request(prepareRequestBody).then(handleCreate).then(close);
  };

  return (
    <ModalDefault open={open} onCancel={close} closable className={s.modal}>
      <Flex vertical gap="24" fullWidth>
        <Typography.Text size="18" weight="500">
          {t('common_add_survey')}
        </Typography.Text>
        <Flex vertical gap="8">
          <Typography.Text size="16" weight="500">
            {t('surveys_select_survey')}
          </Typography.Text>
          <Form form={form}>
            <Form.Item name="anketaName">
              <Select
                placeholder={t('surveys_select_survey_placeholder')}
                options={options}
                showSearch
                filterOption
                optionFilterProp="label"
              />
            </Form.Item>
          </Form>
        </Flex>
        <Flex gap="8">
          <Button
            type="secondary"
            onClick={handleSave(false)}
            fullWidth
            disabled={!isAnketaSelected}
            loading={isLoading}>
            {t('common_save')}
          </Button>
          <Button
            type="primary"
            onClick={handleSave(true)}
            fullWidth
            disabled={!isAnketaSelected}
            loading={isLoading}>
            {t('common_save_and_publish')}
          </Button>
        </Flex>
      </Flex>
    </ModalDefault>
  );
};

export default SurveyCreateModal;
