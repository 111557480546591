export const SETTINGS_EMPLOYER_SHIFT_FORM = 'SETTINGS_EMPLOYER_SHIFT_FORM';

export enum SETTINGS_EMPLOYER_SHIFT_FORM_ENUM {
  TELEGRAM_BOT = 'TELEGRAM_BOT',
  ARTICLE = 'ARTICLE',
  EMAIL = 'EMAIL',
}

export interface SettingsEmployerShiftFormResponse {
  [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.TELEGRAM_BOT]: string;
  [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.ARTICLE]: string;
  [SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.EMAIL]: string;
}
