import { useNavigate } from 'react-router-dom';
import { SUGGESTIONS_COMMISSION_CREATE_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(SUGGESTIONS_COMMISSION_CREATE_ROUTE);
  };

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title>{t('suggestions_commissions')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button onClick={handleClick} leftIcon="plus">
          {t('suggestions_commissions_create')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
