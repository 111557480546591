import { SORT_ENUM } from 'types/sort.types';

export type FormFiltersType = {
  statuses?: string[];
  category?: string[];
  realizationStates?: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  search: string;
  order: SORT_ENUM;
  property: string;
  filters?: FormFiltersType;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  order: SORT_ENUM.DESC,
  property: 'createdAt',
};
