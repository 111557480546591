import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Checkbox, Form, Input, Typography } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';
import s from './Participants.module.scss';

const Participants = () => {
  const form = Form.useFormInstance();

  const isUnlimitedParticipation = Form.useWatch(EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION);
  const participants = Form.useWatch(EVENT_FORM_ENUM.PARTICIPANTS);

  const handleCheckboxNormalize = (flag: boolean) => {
    if (flag) {
      form.setFields([{ name: EVENT_FORM_ENUM.PARTICIPANTS, errors: [], value: participants }]);
    }

    return flag;
  };

  const handleInputNormalize = (counter: string) => {
    return Number(counter) > 0 ? counter : 1;
  };

  return (
    <>
      <Form.Item
        label={FORM_LABELS[EVENT_FORM_ENUM.PARTICIPANTS]}
        className={s.participants}
        name={EVENT_FORM_ENUM.PARTICIPANTS}
        rules={isUnlimitedParticipation ? undefined : [formRules.required]}
        normalize={handleInputNormalize}>
        <Input
          placeholder={t('common_enter_number')}
          type="number"
          min={1}
          step={1}
          disabled={isUnlimitedParticipation}
        />
      </Form.Item>
      <Form.Item
        name={EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION}
        valuePropName="checked"
        normalize={handleCheckboxNormalize}>
        <Checkbox>
          <Typography.Text size="14">
            {FORM_LABELS[EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]}
          </Typography.Text>
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default Participants;
