import cn from 'classnames';

import s from './UniversalLoader.module.scss';

interface Props {
  className?: string;
}

const UniversalLoader = (props: Props) => {
  const { className } = props;

  return <div className={cn(s.loader, className)} />;
};

export default UniversalLoader;
