import { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  RangePickerProps as ARangePickerProps,
  Button,
  DatePicker,
  Flex,
  Typography,
} from 'gazprom-ui-lib';

import s from './RangePicker.module.scss';
import {
  DEFAULT_FORMAT,
  DatePickerButtonType,
  OnDateChangeValueType,
  datePickerButtons,
} from './rangePicker.utils';

interface RangePickerProps {
  onDateChange: (value: OnDateChangeValueType) => void;
}

const RangePicker: FC<RangePickerProps> = (props) => {
  const { onDateChange } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { settlementSurveyInitialDate } = location.state || {};
    if (settlementSurveyInitialDate?.from) {
      const { from, to } = settlementSurveyInitialDate;

      const fromDate = dayjs(from);
      const toDate = to ? dayjs(to) : dayjs();
      const transformedFrom = fromDate?.startOf('day')?.format(DEFAULT_FORMAT);
      const transformedTo = to
        ? toDate?.endOf('day')?.format(DEFAULT_FORMAT)
        : toDate.format(DEFAULT_FORMAT);

      setPickerValue([fromDate, toDate]);
      onDateChange([transformedFrom, transformedTo]);
      navigate(location.pathname, { state: { settlementSurveyInitialDate: null } });
    }
  }, [location.pathname, location.state, navigate, onDateChange]);

  const [pickerValue, setPickerValue] = useState<ARangePickerProps['value']>(null);

  const onChange = (value: ARangePickerProps['value']) => {
    console.log(value);
    const transformedFrom = value?.[0]?.startOf('day')?.format(DEFAULT_FORMAT);
    const transformedTo = value?.[1]?.endOf('day')?.format(DEFAULT_FORMAT);

    setPickerValue(value);
    onDateChange([transformedFrom, transformedTo]);
  };

  const renderButtons = (buttonProps: DatePickerButtonType) => {
    const { id, text, onClickValue } = buttonProps;
    const clickHandler = () => onChange(onClickValue as ARangePickerProps['value']);

    return (
      <Button type="link" key={id} onClick={clickHandler} size="small" className={s.button}>
        {text}
      </Button>
    );
  };

  return (
    <Flex align="center" gap="16">
      <Flex gap="8" align="center">
        <Typography.Text size="14" type="secondary">
          {t('common_by_period')}
        </Typography.Text>
        <DatePicker.RangePicker value={pickerValue} onChange={onChange} className={s.input} />
      </Flex>
      <Flex align="center" gap="16">
        {datePickerButtons.map(renderButtons)}
      </Flex>
    </Flex>
  );
};

export default RangePicker;
