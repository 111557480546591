import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import { GuideHotContactType } from 'types/guide.types';

import s from './GuideContactsRowChildren.module.scss';

interface Props {
  hotContacts: GuideHotContactType[];
}

const contactTextProps = {
  size: '14',
  weight: '500',
} as const;

const GuideContactsRowChildren = (props: Props) => {
  const { hotContacts } = props;

  const renderPhones = (contact: GuideHotContactType['contacts'][0], index: number) => {
    if (contact.phone) {
      return (
        <div className={s.phoneContainer} key={index + contact.phone}>
          <Icon name="phoneFilled" size={18} />
          <div className={s.phonesContainer}>
            <Typography.Text {...contactTextProps}>{contact.phone}</Typography.Text>
            {contact.internalPhone && (
              <Typography.Text size="12" weight="500" type="secondary">
                {t('guide_internal_phone', { phone: contact.internalPhone })}
              </Typography.Text>
            )}
          </div>
        </div>
      );
    }
  };

  const renderMails = (contact: GuideHotContactType['contacts'][0], index: number) => {
    if (contact.email) {
      return (
        <div className={s.mailContainer} key={index + contact.email}>
          <Icon name="mail" size={17} />
          <Typography.Text {...contactTextProps}>{contact.email}</Typography.Text>
        </div>
      );
    }
  };

  const renderHotContacRows = (data: GuideHotContactType) => {
    const { contacts } = data;
    return (
      <div className={s.containerItem} key={data.id}>
        <Typography.Text {...contactTextProps} className={s.name}>
          {data.name}
        </Typography.Text>
        <div className={s.contactsContainer}>
          {contacts.map(renderPhones)}
          {contacts.map(renderMails)}
        </div>
      </div>
    );
  };

  return <div className={s.container}>{hotContacts.map(renderHotContacRows)}</div>;
};

export default GuideContactsRowChildren;
