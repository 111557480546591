import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { SETTINGS_USER_AGREEMENTS_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button, Form, FormInstance } from 'gazprom-ui-lib';

import { FullPolicyAgreementType } from 'types/policyAgreement.types';

import { createMediaUploadUrl } from '../../../../../../utils/createMediaUrl.utils';
import s from './UserAgreementForm.module.scss';
import FormDescription from './form-description';
import FormFile from './form-file';
import FormName from './form-name';
import {
  USER_AGREEMENT_ENUM,
  USER_AGREEMENT_FORM_NAME,
  UserAgreementFormResponse,
} from './userAgreementForm.utils';

interface UserAgreementFormProps {
  onSubmit: (form: UserAgreementFormResponse) => void;
  userAgreement?: FullPolicyAgreementType;
  form?: FormInstance;
  isLoading: boolean;
}

const UserAgreementForm = (props: UserAgreementFormProps) => {
  const { onSubmit, userAgreement, isLoading, form: formFromProps } = props;
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useEffect(() => {
    if (userAgreement) {
      formToUse.setFieldsValue({
        [USER_AGREEMENT_ENUM.NAME]: userAgreement.name,
        [USER_AGREEMENT_ENUM.DESCRIPTION]: userAgreement.description,
        [USER_AGREEMENT_ENUM.FILE]: {
          name: userAgreement.fileName,
          link: createMediaUploadUrl(userAgreement.fileKey),
        },
      });
    }
  }, [formToUse, userAgreement]);

  return (
    <Form form={formToUse} name={USER_AGREEMENT_FORM_NAME} onFinish={onSubmit}>
      <FormName />
      <FormDescription />
      <FormFile />
      <div className={s.actions}>
        <Button type="secondary" fullWidth onClick={() => navigate(SETTINGS_USER_AGREEMENTS_ROUTE)}>
          {t('common_close')}
        </Button>
        <Button
          leftIcon="ok"
          fullWidth
          type="primary"
          htmlType="submit"
          form={USER_AGREEMENT_FORM_NAME}
          loading={isLoading}>
          {t('common_save')}
        </Button>
      </div>
    </Form>
  );
};

export default UserAgreementForm;
