import { Form } from 'gazprom-ui-lib';

import CommunityMeetForm from 'containers/community-meet-form';
import {
  COMMUNITY_MEETS_FORM_NAME,
  CommunityMeetsFormResponse,
} from 'containers/community-meet-form/communityMeetForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './MeetsCreate.module.scss';
import Header from './containers/header';
import { useHandleSubmit } from './useHandleSubmit.hook';

const MeetsCreate = () => {
  const [form] = Form.useForm<CommunityMeetsFormResponse>();
  const { handleFinish, isCreatingCommunityMeet } = useHandleSubmit();

  return (
    <WithOverflow>
      <Header isLoading={isCreatingCommunityMeet} />
      <Form onFinish={handleFinish} form={form} name={COMMUNITY_MEETS_FORM_NAME} className={s.form}>
        <CommunityMeetForm isCreatingOrUpdating={isCreatingCommunityMeet} />
      </Form>
    </WithOverflow>
  );
};

export default MeetsCreate;
