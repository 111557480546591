import { t } from 'tools/i18n';

import { Flex, Modal, Typography } from 'gazprom-ui-lib';

import { RejectApproveForm } from './reject-approve-form/rejectApproveForm';

export type RejectApproveModalType = 'REJECT' | 'APPROVE';

interface Props {
  type: RejectApproveModalType;
  onCancel: () => void;
  selectedMedexamRequestId: string;
}

const RejectApproveModal = (props: Props) => {
  const { onCancel, selectedMedexamRequestId, type } = props;

  return (
    <Modal open={!!type} onCancel={onCancel} closable>
      <Flex vertical gap="16">
        <Typography.Text size="18" weight="500">
          {t(
            type === 'APPROVE'
              ? 'shift_change_medexam_request_approve'
              : 'shift_change_medexam_request_reject',
          )}
        </Typography.Text>

        <RejectApproveForm
          onCancel={onCancel}
          type={type}
          selectedMedexamRequestId={selectedMedexamRequestId}
        />
      </Flex>
    </Modal>
  );
};

export default RejectApproveModal;
