import { t } from 'tools/i18n';

import { Flex, Form, Modal, Typography } from 'gazprom-ui-lib';

import { UploadSurveyAnketaForm } from './upload-survey-anketa-form/uploadSurveyAnketaForm';

interface Props {
  open: boolean;
  openUploadModal: () => void;
  closeUploadModal: () => void;
}

const UploadModal = (props: Props) => {
  const { open, openUploadModal, closeUploadModal } = props;
  const [form] = Form.useForm();

  const handleClose = () => {
    closeUploadModal();
    form.resetFields();
  };

  return (
    <Modal closable open={open} onCancel={handleClose}>
      <Flex vertical gap="16">
        <Typography.Text size="18" weight="500">
          {t('common_upload_file')}
        </Typography.Text>

        <UploadSurveyAnketaForm
          form={form}
          openUploadModal={openUploadModal}
          handleClose={handleClose}
        />
      </Flex>
    </Modal>
  );
};

export default UploadModal;
