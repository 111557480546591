import { useState } from 'react';

import { t } from 'tools/i18n';

import { Flex, Form, Switch, Typography } from 'gazprom-ui-lib';

import NumberFieldWithText from 'pages/surveys/containers/formBoody/containers/number-field-with-text';

import s from './NotificationInterval.module.scss';

interface Props {
  initialValue: number | undefined;
  fieldName: string;
}

const NotificationInterval = (props: Props) => {
  const { initialValue, fieldName } = props;

  const [showInput, setShowInput] = useState(!!initialValue);
  const form = Form.useFormInstance();

  const toggleShowInput = () =>
    setShowInput((prev) => {
      if (prev) {
        form.resetFields([fieldName]);
      }
      return !prev;
    });

  return (
    <>
      <Typography.Text size="18" weight="500">
        {t('common_notifications_settings')}
      </Typography.Text>
      <Flex vertical gap="12" className={s.wrapper}>
        <Flex gap="8">
          <Switch value={showInput} onChange={toggleShowInput} />
          <Typography.Text size="14">{t('surveys_send_notifications')}</Typography.Text>
        </Flex>

        <NumberFieldWithText
          initialValue={initialValue}
          fieldName={fieldName}
          shouldRender={showInput}
          firstPart={t('surveys_send_notifications_first_part')}
          secondPart={t('surveys_send_notifications_second_part')}
        />
      </Flex>
    </>
  );
};

export default NotificationInterval;
