import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from 'containers/event-form/eventForm.utils';

const Registration = () => {
  return (
    <Form.Item name={EVENT_FORM_ENUM.REGISTRATION_AVAILABLE} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">
          {FORM_LABELS[EVENT_FORM_ENUM.REGISTRATION_AVAILABLE]}
        </Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default Registration;
