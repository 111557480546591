import { useCallback, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { treeNodeUtils, updateNode } from 'utils/treeNode.utils';

import {
  useGetEmployeeGroupTreeQuery,
  useLazyGetEmployeeGroupTreeQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';

import { DataNode, Form, FormInstance, Tree, handleRequest } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import { KEDO_REVIEW_TASK_FORM_ENUM } from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import {
  FirstArgumentOnCheckType,
  OnLoadArguments,
  SecondArgumentOnCheckType,
} from 'types/employeeGroups.types';
import { DivisionTreeDataType } from 'types/review-task.types';

import s from './Tree.module.scss';
import { initializeTree } from './employeeGroupTree.utils';

interface Props {
  employerId: string;
  form: FormInstance;
  disabled: boolean | undefined;
  divisionTreeData: DivisionTreeDataType[] | null | undefined;
}

const EmployeeGroupTree = (props: Props) => {
  const { employerId, form, disabled, divisionTreeData } = props;

  const [getTree] = useLazyGetEmployeeGroupTreeQuery();
  const { data: initialData = [], isLoading: initialDataIsLoading } = useGetEmployeeGroupTreeQuery({
    parentId: employerId,
    type: 'EMPLOYER',
  });

  const treeIsEmpty = !initialData.length;

  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { checked = [], halfChecked = [] } =
    Form.useWatch(KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS) ?? {};

  const [isTreeInitialized, setIsTreeInitialized] = useState(false);

  useEffect(() => {
    if (initialData && !initialDataIsLoading) {
      if (divisionTreeData && divisionTreeData.length && !isTreeInitialized) {
        setTreeData(initializeTree(initialData, divisionTreeData));
        setIsTreeInitialized(true);
        return;
      }
      if (!isTreeInitialized) {
        setTreeData(initialData);
        setIsTreeInitialized(true);
      }
    }
  }, [initialData, initialDataIsLoading, divisionTreeData, isTreeInitialized]);

  const onLoadData = useCallback(
    (treeNode: OnLoadArguments) =>
      new Promise<void>((resolve) => {
        const { key, hasChildren, type } = treeNode as OnLoadArguments & {
          hasChildren: boolean;
          type: string;
        };

        if (hasChildren) {
          const onSuccess = (res?: DataNode[]) => {
            if (res) {
              setTreeData((prevState) => updateNode(prevState, key, res));
            }
            return resolve();
          };

          return getTree({
            parentId: (key as string).split('.')[0],
            type,
          }).then(handleRequest({ onSuccess }));
        }

        return resolve();
      }),
    [getTree],
  );

  const onCheck = (_: FirstArgumentOnCheckType, info: SecondArgumentOnCheckType) => {
    const checked = info.checkedNodes.map((el) => el.key) as string[];
    const halfCheckedKeys = info.halfCheckedKeys as string[];
    const halfChecked = halfCheckedKeys.map((id) => treeNodeUtils(treeData, id));

    form.setFieldValue(KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS, { checked, halfChecked });
  };

  if (treeIsEmpty && !initialDataIsLoading) {
    return <Empty title={t('common_empty_title')} />;
  }

  return (
    <WithLoader isLoading={initialDataIsLoading}>
      <Form.Item name={KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS}>
        {isTreeInitialized && (
          <Tree
            disabled={disabled}
            defaultExpandedKeys={halfChecked}
            checkedKeys={checked}
            checkable
            treeData={treeData}
            loadData={onLoadData}
            onCheck={onCheck}
            key="employeeGroupTreeStructure"
            selectable={false}
            className={s.tree}
          />
        )}
      </Form.Item>
    </WithLoader>
  );
};

export default EmployeeGroupTree;
