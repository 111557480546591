import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetExpertCommissionsQuery } from 'services/expert-commissions/expertCommissionsApiService';
import { GetExpertCommissionsPropsType } from 'services/expert-commissions/expertCommissionsApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { DEFAULT_FORM_STATE, FormResponseType } from './suggestionsCommissions.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: Props) => {
  const { form } = props;

  const [getCommissions, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetExpertCommissionsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);
  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.page) {
      const { page, size, property, order, search, filters } = debouncedRequestBody ?? {};

      const initialRequestBody: GetExpertCommissionsPropsType = {
        page: page ?? DEFAULT_FORM_STATE.page,
        size: size ?? DEFAULT_FORM_STATE.size,
      };

      if (search) {
        initialRequestBody.search = search;
      }

      if (filters?.employerIds) {
        initialRequestBody.employerId = filters?.employerIds;
      }

      if (property && order) {
        initialRequestBody.sort = [{ order, property }];
      }

      getCommissions(initialRequestBody);
    }
  }, [debouncedRequestBody, getCommissions]);

  return {
    data,
    isLoading: isLoading || isUninitialized,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
