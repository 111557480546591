import { useMemo, useRef } from 'react';

import { Bar, BarConfig } from '@ant-design/plots';
import cn from 'classnames';

import { StatisticsType } from 'services/surveys/surveysApiService.types';

import { Collapse, Flex, Typography } from 'gazprom-ui-lib';

import ScalePie from 'pages/surveys/surveys-details/containers/stats-tab/containers/stats-scale/containers/scale-pie';

import s from './CommonStatistics.module.scss';

interface Props {
  surveyStatistics?: StatisticsType[];
}

const defaultBarConfig = {
  xField: 'value',
  yField: 'type',
};

const CommonStatistics = (props: Props) => {
  const { surveyStatistics } = props;

  const { circularData, columnarData } = useMemo(() => {
    return (surveyStatistics ?? [])?.reduce(
      (acc, cur) => {
        if (cur.title === 'Возраст' || cur.title === 'Должность') {
          acc.columnarData.push(cur);
          return acc;
        }

        acc.circularData.push(cur);

        return acc;
      },
      {
        circularData: [] as StatisticsType[],
        columnarData: [] as StatisticsType[],
      },
    );
  }, [surveyStatistics]);

  const legendContainerRef = useRef<HTMLDivElement>(null);

  const barRenderer = (item: StatisticsType, index: number) => {
    const { title, options } = item;
    const config: BarConfig = {
      ...defaultBarConfig,
      data: options,
    };

    return (
      <Flex vertical gap="16" key={index} flex={1} className={s.common}>
        <Typography.Text size="14" weight="500">
          {title}
        </Typography.Text>
        <Bar {...config} />
      </Flex>
    );
  };

  const pieRenderer = (item: StatisticsType, index: number) => {
    const { title, options, questionId } = item;

    if (!options.length) {
      return null;
    }

    return (
      <Flex vertical gap="16" key={index} className={cn(s.common, s.pie)}>
        <Typography.Text size="14" weight="500">
          {title}
        </Typography.Text>
        <ScalePie
          legendContainerRef={legendContainerRef}
          options={options}
          questionId={questionId}
        />
      </Flex>
    );
  };

  return (
    <Collapse
      noLeftBorder
      noPadding
      className={s.collapse}
      items={[
        {
          key: '1',
          label: (
            <Typography.Text size="18" weight="500">
              Опрошенные сотрудники
            </Typography.Text>
          ),
          children: (
            <Flex vertical gap="8">
              <Flex gap="8">{columnarData?.map(barRenderer)}</Flex>
              <Flex gap="8">{circularData.map(pieRenderer)}</Flex>
            </Flex>
          ),
        },
      ]}
    />
  );
};

export default CommonStatistics;
