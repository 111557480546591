import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Trans, t } from 'tools/i18n';

import { Avatar, Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import Popover from 'components/popover';

import { DOCUMENT_VIEW_PERSON_ENUM } from 'types/documents.types';

import s from './DocumentsViewPerson.module.scss';

interface DocumentsStatementsViewFilesPersonProps {
  firstName: string;
  lastName: string;
  position?: string | null;
  icon?: TIconsLiteral;
  type?: DOCUMENT_VIEW_PERSON_ENUM;
  button?: ReactNode;
  isMe?: boolean;
  date?: string;
}

const DocumentsViewPerson: FC<DocumentsStatementsViewFilesPersonProps> = (props) => {
  const { firstName, lastName, position, button, type, icon, isMe, date } = props;
  const shouldShowBadge = icon && type;
  const i18nCurrentKey = isMe ? 'documents_statements_you' : 'documents_statements_person';

  const popoverContent = (
    <Typography.Text className={s.popoverContent}>
      {t(type === DOCUMENT_VIEW_PERSON_ENUM.ERROR ? 'common_reject_date' : 'common_sign_date', {
        date: date
          ? dayjs(date).format('DD.MM.YYYY, HH:mm')
          : t('common_waiting_sign').toLocaleLowerCase(),
      })}
    </Typography.Text>
  );

  return (
    <div className={s.container}>
      <div className={s.avatarContainer}>
        <div className={s.avatarBadge}>
          <Avatar
            firstName={firstName}
            lastName={lastName ?? ''}
            alt="аватар пользователя"
            size="40"
            className={s.avatar}
          />
          {shouldShowBadge && (
            <Popover destroyTooltipOnHide content={popoverContent}>
              <div className={classNames(s.badgeBackgound, s[type])}>
                <Icon name={icon} size={10} className={s.badge} />
              </div>
            </Popover>
          )}
        </div>
        <div className={s.info}>
          <Trans i18nKey={i18nCurrentKey} values={{ firstName, lastName }} className={s.name}>
            <Typography.Text size="14" weight="500" />
          </Trans>
          {position && (
            <Typography.Text size="10" type="secondary" className={s.position} title={position}>
              {position}
            </Typography.Text>
          )}
        </div>
      </div>
      {button}
    </div>
  );
};

export default DocumentsViewPerson;
