import { FormFileType } from 'containers/file-field/fileField';

export const FORM_NAME = 'IMPROVEMENTS_FORM_NAME';
export const MAX_CATEGORIES_PER_PAGE = 500;

export enum IMPROVEMENTS_FORM_ENUM {
  TITLE = 'title',
  TEXT = 'text',
  COVER = 'cover',
  SETTLEMENT_IDS = 'settlementIds',
}

export interface ImprovementsFormResponse {
  [IMPROVEMENTS_FORM_ENUM.TITLE]: string;
  [IMPROVEMENTS_FORM_ENUM.TEXT]: string;
  [IMPROVEMENTS_FORM_ENUM.COVER]?: FormFileType;
  [IMPROVEMENTS_FORM_ENUM.SETTLEMENT_IDS]?: string[];
}
