import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

const SYNC_IN_PROGRESS_ERROR_CODE = 409;

export enum CONNECTION_TYPES_ENUM {
  ORG_STRUCTURE = 'ORG_STRUCTURE',
  PERSON = 'PERSON',
  PAYSLIP = 'PAYSLIP',
  SCHEDULE = 'SCHEDULE',
  VACATION = 'VACATION',
  EMPLOYER_PAY_DATE = 'EMPLOYER_PAY_DATE',
  PAYSLIP_ON_PAYMENT_DAY = 'PAYSLIP_ON_PAYMENT_DAY',
  INSURANCE = 'INSURANCE',
}

const hiddenConnectionTypes = [
  CONNECTION_TYPES_ENUM.PAYSLIP,
  CONNECTION_TYPES_ENUM.PAYSLIP_ON_PAYMENT_DAY,
];

export const CONNECTION_TYPE_LABELS: Record<string, string> = {
  [CONNECTION_TYPES_ENUM.ORG_STRUCTURE]: '1c_instance_sync_type_org_structure',
  [CONNECTION_TYPES_ENUM.PERSON]: '1c_instance_sync_type_org_structure',
  [CONNECTION_TYPES_ENUM.PAYSLIP]: '1c_instance_sync_type_payslip',
  [CONNECTION_TYPES_ENUM.SCHEDULE]: 'common_schedule',
  [CONNECTION_TYPES_ENUM.VACATION]: '1c_instance_sync_type_vacation',
  [CONNECTION_TYPES_ENUM.EMPLOYER_PAY_DATE]:
    '1c_instance_sync_type_employer_pay_date_sync_strategy',
  [CONNECTION_TYPES_ENUM.PAYSLIP_ON_PAYMENT_DAY]: '1c_instance_sync_type_payslip_on_payment_day',
  [CONNECTION_TYPES_ENUM.INSURANCE]: '1c_instance_sync_type_insurance',
};

export const getSyncTypeOptions = (syncTypes: string[]): CheckboxOptionType[] => {
  return syncTypes.reduce((acc: CheckboxOptionType[], type) => {
    const label = t(`${CONNECTION_TYPE_LABELS[type] ?? ''}`);
    const disabled = type === CONNECTION_TYPES_ENUM.ORG_STRUCTURE;

    if (label && !hiddenConnectionTypes.includes(type as CONNECTION_TYPES_ENUM)) {
      acc.push({ label, value: type, disabled });
    }

    return acc;
  }, []);
};

export const getStartSyncErrorConfig = (
  error?: SerializedError | FetchBaseQueryError,
): { title: string; description: string } => {
  if (error && 'status' in error) {
    if (error.status === SYNC_IN_PROGRESS_ERROR_CODE) {
      return {
        title: t('1c_instance_synchronization_error'),
        description: t('1c_instance_synchronization_in_progress_description'),
      };
    }
  }

  return {
    title: t('1c_instance_connection_error'),
    description: t('1c_instance_connection_error_description'),
  };
};

export const SEGMENT_OPTIONS = [
  {
    label: (
      <Flex gap="8" align="center">
        <Icon name="resume" />
        <Typography.Title level={5}>{t('common_basic_data')}</Typography.Title>
      </Flex>
    ),
    value: 'common',
  },
  {
    label: (
      <Flex gap="8" align="center">
        <Icon name="payment" />
        <Typography.Title level={5}>
          {t('1c_instance_sync_type_payslip_on_payment_day')}
        </Typography.Title>
      </Flex>
    ),
    value: 'payslip',
  },
];
