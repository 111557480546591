import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

const Docs = () => {
  return (
    <Form.Item name={EVENT_FORM_ENUM.DOCS} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">{FORM_LABELS[EVENT_FORM_ENUM.DOCS]}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default Docs;
