import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetDeclarationAttributeVariablesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Collapse, Divider, Form, Typography } from 'gazprom-ui-lib';

import DocumentsDescriptionsFormUpload from 'containers/documents-declarations-form/documents-declarations-form-upload';
import WithLoader from 'containers/wrappers/with-loader';

import MultiSelect from 'pages/documents/documents-declarations/documents-declarations-template-kedo/containers/multi-select';

import s from './DocumentsDeclarationsTemplateKedo.module.scss';
import MultiSelectSubtitleCopy from './containers/multi-select-subtitle-copy';
import { getInitialStatementData, renderAttribute } from './documentDeclarationsTemplateKedo.utils';
import { useLinkUnlinkStatementVariable } from './useLinkUnlinkStatementVariable.hook';
import { useStatementVariables } from './useStatementVariables.hook';

interface Props {
  isDisabled?: boolean;
}

const DocumentsDeclarationsTemplateKedo = (props: Props) => {
  const { isDisabled } = props;

  const [form] = Form.useForm();

  const { id } = useParams() as { id: string };
  const { data: attributeVariables, isLoading: attributeVariablesIsLoading } =
    useGetDeclarationAttributeVariablesQuery(id!);
  const { handleLinkStatementVariable, handleUnlinkStatementVariable } =
    useLinkUnlinkStatementVariable({ statementId: id });

  const renderAttributeGroup = (attributesObj?: Record<string, string>) => {
    if (!attributesObj) {
      return null;
    }

    return Object.keys(attributesObj).map(renderAttribute(attributesObj));
  };

  const hasAdditionalVariables = !!Object.keys(attributeVariables?.attributeVariables ?? {}).length;

  const { statementVariableOptions, statementVariablesIdCodeMap } = useStatementVariables();
  const initialStatementVariableOptions = getInitialStatementData(
    attributeVariables?.statementVariables ?? {},
  );

  return (
    <WithLoader isLoading={attributeVariablesIsLoading}>
      <div className={s.wrapper}>
        <div className={s.container}>
          <Form form={form}>
            <DocumentsDescriptionsFormUpload isDisabled={isDisabled} />
          </Form>

          <Collapse
            className={s.sectionContainer}
            rootClassName={s.collapse}
            items={[
              {
                key: '1',
                label: (
                  <Typography.Text size="16" weight="500">
                    {t('documents_declarations_template_main_variables')}
                  </Typography.Text>
                ),
                children: (
                  <div className={s.section}>
                    <div className={s.list}>
                      {renderAttributeGroup(attributeVariables?.commonVariables)}
                    </div>
                  </div>
                ),
              },
              ...(hasAdditionalVariables
                ? [
                    {
                      key: '2',
                      label: (
                        <Typography.Text size="16" weight="500">
                          {t('documents_declarations_template_additional_variables')}
                        </Typography.Text>
                      ),
                      children: (
                        <div className={s.section}>
                          <div className={s.list}>
                            {renderAttributeGroup(attributeVariables?.attributeVariables)}
                          </div>
                        </div>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </div>

        <Divider />

        <MultiSelect
          classNameWrapper={s.multiSelectWrapper}
          label={
            <Typography.Text size="16" weight="500">
              {t('documents_declarations_template_additional_variables_title')}
            </Typography.Text>
          }
          buttonText={t('common_add_field')}
          placeholder={t('common_choose_parameter_from_list')}
          options={statementVariableOptions}
          initialValues={initialStatementVariableOptions}
          deleteHandler={handleUnlinkStatementVariable}
          selectHandler={handleLinkStatementVariable}
          renderSubtitle={(option) => (
            <MultiSelectSubtitleCopy
              option={option}
              statementVariablesIdCodeMap={statementVariablesIdCodeMap}
            />
          )}
        />
      </div>
    </WithLoader>
  );
};

export default DocumentsDeclarationsTemplateKedo;
