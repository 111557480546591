import { prepareQueryParams } from 'utils/prepareQueryParams';

import {
  CInstancesResponseType,
  CheckCInstanceConnectionPropsType,
  CheckCInstanceConnectionResponseType,
  CreateCInstancePropsType,
  CreateCInstanceResponseType,
  DeleteCInstancePropsType,
  DeleteCInstanceResponseType,
  GetCInstanceByIdPropsType,
  GetCInstanceByIdResponseType,
  GetCInstanceLnaPropsType,
  GetCInstanceLnaResponseType,
  GetCInstancesPropsType,
  PatchCInstancePropsType,
  PatchCInstanceResponseType,
  StartCInstanceSyncPropsType,
  StartCInstanceSyncResponseType,
  UpdateCInstanceAuthCredPropsType,
  UpdateCInstancePropsType,
  UpdateCInstanceResponseType,
  UpdateInstanceAuthCredResponseType,
} from 'services/1c-instances/cInstancesService.types';
import { createCInstanceUrl } from 'services/1c-instances/utils';
import cInitialApiService from 'services/1c/cApiService';

import { C_SERVICE_TAGS } from 'constants/serviceTags';

const cInstancesService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getCInstances: builder.query<CInstancesResponseType, GetCInstancesPropsType>({
      query: (params) => ({
        url: createCInstanceUrl(`${prepareQueryParams(params)}`),
        method: 'GET',
      }),
      providesTags: [C_SERVICE_TAGS.C_INSTANCES],
    }),
    getCInstanceById: builder.query<GetCInstanceByIdResponseType, GetCInstanceByIdPropsType>({
      query: (id) => ({
        url: createCInstanceUrl(`/${id}`),
        method: 'GET',
      }),
      providesTags: [C_SERVICE_TAGS.C_INSTANCE_BY_ID],
    }),
    createCInstance: builder.mutation<CreateCInstanceResponseType, CreateCInstancePropsType>({
      query: (body) => ({
        url: createCInstanceUrl(''),
        method: 'POST',
        body,
      }),
    }),
    updateCInstance: builder.mutation<UpdateCInstanceResponseType, UpdateCInstancePropsType>({
      query: ({ id, ...body }) => ({
        url: createCInstanceUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.C_INSTANCES, C_SERVICE_TAGS.C_INSTANCE_BY_ID],
    }),
    patchCInstance: builder.mutation<PatchCInstanceResponseType, PatchCInstancePropsType>({
      query: ({ id, ...body }) => ({
        url: createCInstanceUrl(`/${id}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.C_INSTANCES, C_SERVICE_TAGS.C_INSTANCE_BY_ID],
    }),
    updateAuthCredentials: builder.mutation<
      UpdateInstanceAuthCredResponseType,
      UpdateCInstanceAuthCredPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createCInstanceUrl(`/${id}/auth-cred`),
        method: 'PATCH',
        body,
      }),
    }),
    deleteCInstance: builder.mutation<DeleteCInstanceResponseType, DeleteCInstancePropsType>({
      query: (id) => ({
        url: createCInstanceUrl(`/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [C_SERVICE_TAGS.C_INSTANCES],
    }),
    checkCInstanceConnection: builder.mutation<
      CheckCInstanceConnectionResponseType,
      CheckCInstanceConnectionPropsType
    >({
      query: (id) => ({
        url: createCInstanceUrl(`/${id}/test`),
        method: 'PUT',
      }),
    }),
    startCInstanceSync: builder.mutation<
      StartCInstanceSyncResponseType,
      StartCInstanceSyncPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createCInstanceUrl(`/${id}/sync`),
        method: 'PUT',
        body,
      }),
    }),
    getCInstanceLna: builder.query<GetCInstanceLnaResponseType, GetCInstanceLnaPropsType>({
      query: (params) => ({
        url: createCInstanceUrl(`/lna${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const {
  useLazyGetCInstancesQuery,
  useLazyGetCInstanceByIdQuery,
  useCreateCInstanceMutation,
  useUpdateCInstanceMutation,
  useUpdateAuthCredentialsMutation,
  useDeleteCInstanceMutation,
  useCheckCInstanceConnectionMutation,
  useStartCInstanceSyncMutation,
  usePatchCInstanceMutation,
  useLazyGetCInstanceLnaQuery,
} = cInstancesService;
