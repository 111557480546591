import { useNavigate } from 'react-router-dom';
import { SUGGESTIONS_COMMISSION_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { useCreateExpertCommissionMutation } from 'services/expert-commissions/expertCommissionsApiService';

import { Button, Flex, useHandleRequestWithModal } from 'gazprom-ui-lib';

import ExpertCommissionForm from 'containers/expert-commission-form';
import {
  EXPERT_COMMISSION_FORM_NAME,
  ExpertCommissionFormResponseType,
} from 'containers/expert-commission-form/expertCommissionForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SuggestionCommissionCreate.module.scss';
import Header from './containers/header';
import { prepareRequestBody } from './suggestionsCommissionsCreate.utils';

const SuggestionsCommissionsCreate = () => {
  const navigate = useNavigate();

  const [createExpertCommission, { isLoading: isCreateLoading }] =
    useCreateExpertCommissionMutation();

  const handleClose = () => {
    navigate(SUGGESTIONS_COMMISSION_ROUTE);
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleClose,
      onConfirm: handleClose,
      title: {
        children: t('common_created_successfully'),
      },
      description: {
        children: t('suggestions_commission_create_success_description'),
      },
    },
    onErrorModalConfig: {
      icon: {
        type: 'warning',
        name: 'info',
      },
      title: {
        children: t('suggestions_commission_create_error_title'),
      },
      description: {
        children: t('suggestions_commission_create_error_description'),
      },
    },
  });

  const onSubmit = (values: ExpertCommissionFormResponseType) => {
    const requestBody = prepareRequestBody(values);

    createExpertCommission(requestBody).then(handleCreate);
  };

  return (
    <WithOverflow>
      <Header />
      <div className={s.wrapper}>
        <ExpertCommissionForm onSubmit={onSubmit} />
        <Flex className={s.actions} gap="8">
          <Button
            loading={isCreateLoading}
            form={EXPERT_COMMISSION_FORM_NAME}
            fullWidth
            type="primary"
            leftIcon="ok"
            htmlType="submit">
            {t('common_save')}
          </Button>
          <Button fullWidth type="secondary" onClick={handleClose}>
            {t('common_close')}
          </Button>
        </Flex>
      </div>
    </WithOverflow>
  );
};

export default SuggestionsCommissionsCreate;
