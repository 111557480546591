import { FC, useEffect } from 'react';

import { formRules } from 'utils/formRules';

import { Form, Select } from 'gazprom-ui-lib';

interface CommonSelectProps {
  title: string;
  name: string;
  placeholder: string;
  options: { label: string; value: string }[];
  initialValue?: string;
  className?: string;
  isRequired?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const CommonSelect: FC<CommonSelectProps> = (props) => {
  const {
    title,
    name,
    placeholder,
    options,
    initialValue,
    className,
    isRequired = true,
    hidden,
    disabled = false,
    onChange,
  } = props;
  const form = Form.useFormInstance();
  const rules = isRequired ? [formRules.required] : [];

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <Form.Item
      className={className}
      name={name}
      valuePropName="value"
      initialValue={initialValue}
      rules={rules}
      label={title}
      hidden={hidden}>
      <Select
        placeholder={placeholder}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        disabled={disabled}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default CommonSelect;
