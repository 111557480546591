import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { NOTIFICATION_FILTER_OPTIONS } from './filters.utils';

const Filters = () => {
  const form = Form.useFormInstance();

  const filters = Form.useWatch('filters', form);

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer
        showClearAll
        initialValues={filters}
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('notification_waiting_for_call')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="callbackExists"
              options={NOTIFICATION_FILTER_OPTIONS}
            />
            <FilterHeader valueKey="status">{t('common_read')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="viewed"
              options={NOTIFICATION_FILTER_OPTIONS}
            />
            <FilterHeader valueKey="status">{t('common_feedback')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="feedbackExists"
              options={NOTIFICATION_FILTER_OPTIONS}
            />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
