import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Icon, Input } from 'gazprom-ui-lib';

import s from './TelephoneField.module.scss';

interface Props {
  id: string | number;
  onRemove: (id: string | number) => void;
  initialPhone?: string;
  initialAdditionalPhone?: string;
}

const TelephoneField = (props: Props) => {
  const { id, initialAdditionalPhone, initialPhone, onRemove } = props;
  const phoneName = `${id}.phone`;
  const additionalPhoneName = `${id}.internalPhone`;

  return (
    <div className={s.container}>
      <Form.Item name={phoneName} initialValue={initialPhone} rules={[formRules.required]}>
        <Input placeholder={t('common_enter_phone_number')} width={233} maxLength={12} type="tel" />
      </Form.Item>
      <div className={s.additionalContainer}>
        {t('common_internal_phone_number_short')}
        <Form.Item name={additionalPhoneName} initialValue={initialAdditionalPhone}>
          <Input placeholder="0000" maxLength={12} width={160} />
        </Form.Item>
      </div>
      <button className={s.removeButton} onClick={() => onRemove(id)}>
        <Icon name="binFilled" />
        {t('common_remove')}
      </button>
    </div>
  );
};

export default TelephoneField;
