import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';

import { ExpertCommissionType } from 'types/expertCommission.types';

interface Props extends ExpertCommissionType {}

const Commission = (props: Props) => {
  const { head, members } = props;

  const renderMember = (member: ExpertCommissionType['members'][0], indx: number) => (
    <DocumentView.Employee
      showAvatar
      showEmployer={false}
      key={member.id}
      employee={member}
      showTitle={!indx}
      title={t('suggestions_commission_composition')}
    />
  );

  return (
    <Flex vertical gap="24">
      <DocumentView.Employee
        showAvatar
        employee={head}
        title={t('suggestions_commission_commission_head')}
        showEmployer={false}
      />
      {members.map(renderMember)}
    </Flex>
  );
};

export default Commission;
