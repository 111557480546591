import { ShiftMedExamRequest } from 'gazprom-common-lib';
import { t } from 'tools/i18n';

import { ColumnsType, Icon, Tag } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import TableText from 'components/table/table-text';

import s from './Table.module.scss';
import { getTagConfig } from './table.utils';

export const useColumns = (): ColumnsType<ShiftMedExamRequest> => [
  {
    title: t('common_employee'),
    dataIndex: 'employee',
    render: ({ person, employer: { name } }: ShiftMedExamRequest['employee']) => (
      <TablePerson department={name} {...person} />
    ),
  },
  {
    title: t('shift_change_medexam_request_comment'),
    dataIndex: 'requestComment',
    render: (requestComment: ShiftMedExamRequest['requestComment']) => (
      <TableText>{requestComment}</TableText>
    ),
  },
  {
    title: t('common_status'),
    dataIndex: 'status',
    render: ({ state, title }: ShiftMedExamRequest['status']) => {
      const { iconName, tagType } = getTagConfig(state);
      return (
        <Tag className={s.tag} type={tagType}>
          <Icon size={14} name={iconName} />
          {title}
        </Tag>
      );
    },
  },
  {
    title: t('common_created'),
    dataIndex: 'createdAt',
    align: 'center',
    render: (createdAt: ShiftMedExamRequest['createdAt']) => <TableDate date={createdAt} />,
  },
  {
    title: t('common_updated'),
    dataIndex: 'updatedAt',
    align: 'center',
    render: (updatedAt: ShiftMedExamRequest['updatedAt']) => <TableDate date={updatedAt} />,
  },
];
