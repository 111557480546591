import { FormFileType } from 'containers/file-field';

export const USER_AGREEMENT_FORM_NAME = 'USER_AGREEMENT_FORM_NAME';

export enum USER_AGREEMENT_ENUM {
  NAME = 'NAME',
  FILE = 'FILE',
  DESCRIPTION = 'DESCRIPTION',
}

export interface UserAgreementFormResponse {
  [USER_AGREEMENT_ENUM.NAME]: string;
  [USER_AGREEMENT_ENUM.FILE]: FormFileType;
  [USER_AGREEMENT_ENUM.DESCRIPTION]: string;
}
