import SuggestionCommissions from 'pages/suggestions/suggestions-commissions';
import SuggestionsCommissionsCreate from 'pages/suggestions/suggestions-commissions/suggestions-commissions-create';
import SuggestionsCommissionsDetails from 'pages/suggestions/suggestions-commissions/suggestions-commissions-details';
import SuggestionsDecisions from 'pages/suggestions/suggestions-decisions';
import SuggestionDecisionDetails from 'pages/suggestions/suggestions-decisions/suggestions-decision-details';
import SuggestionsList from 'pages/suggestions/suggestions-list';
import SuggestionsListDetails from 'pages/suggestions/suggestions-list/suggestions-list-details';

import {
  suggestionCommissionPermissions,
  suggestionPermissions,
} from 'permissions/suggestions.permissions';

import { RouteConfig } from '../routes';
import {
  SUGGESTIONS_COMMISSION_CREATE_ROUTE,
  SUGGESTIONS_COMMISSION_ROUTE,
  SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
  SUGGESTIONS_DECISIONS_ROUTE,
  SUGGESTIONS_LIST_ROUTE,
  SUGGESTION_COMMISSION_DETAILS_ROUTE,
  SUGGESTION_LIST_DETAILS_ROUTE,
} from './list';

export const suggestionsRoutes: RouteConfig[] = [
  {
    path: SUGGESTIONS_COMMISSION_ROUTE,
    element: <SuggestionCommissions />,
    ...suggestionCommissionPermissions,
  },
  {
    path: SUGGESTIONS_COMMISSION_CREATE_ROUTE,
    element: <SuggestionsCommissionsCreate />,
    ...suggestionCommissionPermissions,
  },
  {
    path: SUGGESTION_COMMISSION_DETAILS_ROUTE,
    element: <SuggestionsCommissionsDetails />,
    ...suggestionCommissionPermissions,
  },
  {
    path: SUGGESTIONS_LIST_ROUTE,
    element: <SuggestionsList />,
    ...suggestionPermissions,
  },
  {
    path: SUGGESTION_LIST_DETAILS_ROUTE,
    element: <SuggestionsListDetails />,
    ...suggestionPermissions,
  },
  {
    path: SUGGESTIONS_DECISIONS_ROUTE,
    element: <SuggestionsDecisions />,
    ...suggestionPermissions,
  },
  {
    path: SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
    element: <SuggestionDecisionDetails />,
    ...suggestionPermissions,
  },
];
