import { ShiftMedExamRequest } from 'gazprom-common-lib';
import { t } from 'tools/i18n';

import { useDownloadKedoFileMutation } from 'services/1c/cApiService';

import { Button, Flex, Icon, Modal, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { RejectApproveModalType } from '../reject-approve-modal/rejectApproveModal';
import { getTagConfig } from '../table/table.utils';
import s from './ViewModal.module.scss';

interface Props {
  open: boolean;
  onCancel: () => void;
  selectedMedexamRequest: ShiftMedExamRequest;
  onOpenRejectApproveModal: (type: RejectApproveModalType) => void;
}

const ViewModal = (props: Props) => {
  const { open, onCancel, selectedMedexamRequest, onOpenRejectApproveModal } = props;

  const {
    employee: {
      person,
      employer: { name },
    },
    status: { state, title, code },
    requestComment,
    answerComment,
    medDocNum,
    medDocDate,
    files,
    medDocExpiredAt,
  } = selectedMedexamRequest;

  const [downloadFile, { isLoading }] = useDownloadKedoFileMutation();
  const { iconName, tagType } = getTagConfig(state);

  const isCreated = code === 'CREATED';
  const isApproved = code === 'APPROVED';
  const isRejected = code === 'REJECTED';
  const isRejectedOrApproved = isApproved || isRejected;
  const file = files?.[0];

  const handleDownloadFile = () => {
    if (file) downloadFile(file);
  };

  return (
    <Modal open={open} closable onCancel={onCancel}>
      <Flex vertical gap="24">
        <Tag className={s.tag} type={tagType}>
          <Icon size={14} name={iconName} />
          {title}
        </Tag>

        {isRejectedOrApproved && answerComment && (
          <Flex vertical>
            <Typography.Text type="secondary">
              {t('shift_change_medexam_request_answer_comment')}
            </Typography.Text>
            <Typography.Text size="14" weight="500">
              {answerComment}
            </Typography.Text>
          </Flex>
        )}
        {!!file && (
          <Flex vertical>
            <Typography.Text type="secondary">
              {t('shift_change_medexam_request_attached_copy')}
            </Typography.Text>
            <Button
              size="small"
              type="link"
              leftIcon="link"
              onClick={handleDownloadFile}
              loading={isLoading}
              className={s.download}>
              {file?.fileName}
            </Button>
          </Flex>
        )}
        {isApproved && (
          <Flex gap="24" vertical>
            <Flex gap="8">
              {medDocNum && (
                <Flex fullWidth vertical>
                  <Typography.Text type="secondary">
                    {t('shift_change_medexam_request_doc_num')}
                  </Typography.Text>
                  <Typography.Text size="14" weight="500">
                    {medDocNum}
                  </Typography.Text>
                </Flex>
              )}

              <Flex fullWidth vertical>
                <Typography.Text type="secondary">
                  {t('shift_change_medexam_request_doc_date')}
                </Typography.Text>
                <Typography.Text size="14" weight="500">
                  {medDocDate}
                </Typography.Text>
              </Flex>
            </Flex>

            {medDocExpiredAt && (
              <Flex vertical>
                <Typography.Text type="secondary">
                  {t('shift_change_medexam_request_expired_at')}
                </Typography.Text>
                <Typography.Text size="14" weight="500">
                  {medDocExpiredAt}
                </Typography.Text>
              </Flex>
            )}
          </Flex>
        )}

        <Bio {...person} employerName={name} />

        <Flex vertical>
          <Typography.Text type="secondary">
            {t('shift_change_medexam_request_comment_modal')}
          </Typography.Text>
          <Typography.Text size="14" weight="500">
            {requestComment}
          </Typography.Text>
        </Flex>

        {isCreated && (
          <Flex gap="8">
            <Button
              onClick={() => onOpenRejectApproveModal('REJECT')}
              fullWidth
              leftIcon="delete"
              type="secondary"
              danger>
              {t('common_decline')}
            </Button>
            <Button onClick={() => onOpenRejectApproveModal('APPROVE')} fullWidth leftIcon="ok">
              {t('common_approve')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default ViewModal;
