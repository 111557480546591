import { useEffect, useState } from 'react';

import { useMatch, useNavigate } from 'react-router-dom';
import {
  DECLARATIONS_TYPE_TAB_ENUM,
  SETTINGS_DECLARATIONS_TYPE_LOCAL_ROUTE,
  getDeclarationsTabTypeRoute,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DocumentsDeclarations.module.scss';
import CommonTab from './containers/common-tab';
import LocalTab from './containers/local-tab';
import DocumentsDeclarationsHeader from './documents-declarations-header';

const DocumentsDeclarations = () => {
  const navigate = useNavigate();
  const isLocal = useMatch(SETTINGS_DECLARATIONS_TYPE_LOCAL_ROUTE);

  const [activeTab, setActiveTab] = useState(DECLARATIONS_TYPE_TAB_ENUM.COMMON);

  const handleNavigateTab = (tab: string) =>
    navigate(getDeclarationsTabTypeRoute(tab as DECLARATIONS_TYPE_TAB_ENUM));

  const tabItems = [
    {
      key: DECLARATIONS_TYPE_TAB_ENUM.COMMON,
      label: t('common_common'),
      children: <CommonTab />,
    },
    {
      key: DECLARATIONS_TYPE_TAB_ENUM.LOCAL,
      label: t('common_local'),
      children: <LocalTab />,
    },
  ];

  useEffect(() => {
    setActiveTab(isLocal ? DECLARATIONS_TYPE_TAB_ENUM.LOCAL : DECLARATIONS_TYPE_TAB_ENUM.COMMON);
  }, [isLocal]);

  return (
    <WithOverflow>
      <DocumentsDeclarationsHeader />
      <Tabs
        type="card"
        items={tabItems}
        onChange={handleNavigateTab}
        activeKey={activeTab}
        className={s.tabs}
      />
    </WithOverflow>
  );
};

export default DocumentsDeclarations;
