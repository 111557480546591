import { DocumentsDeclarationsAdditionalSectionType } from 'types/documents.types';

export const DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME =
  'DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME';

export const DISABLED_HIDDEN_FIELD = 'isDisabled';

export const getMaxNum = (array: DocumentsDeclarationsAdditionalSectionType[]) =>
  array.reduce((max, current) => {
    if (current.num > max) {
      return current.num;
    }
    return max;
  }, 0);
