import { useState } from 'react';

import Cron, { CronProps } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useReAssignSettlementSurveyMutation } from 'services/surveys/surveysApiService';

import {
  Button,
  DatePicker,
  Flex,
  Form,
  Icon,
  Radio,
  Typography,
  useHandleRequestWithModal,
} from 'gazprom-ui-lib';

import { SURVEY_SETTLEMENT_FORM_ENUM } from '../../surveySettlementForm.utils';
import { useGetFormData } from '../../useGetFormData.hook';
import s from './ReAssignment.module.scss';
import {
  allowedDropdowns,
  allowedPeriods,
  defaultValue,
  locale,
  options,
} from './ReAssignment.utils';

const ReAssignment = () => {
  const { id } = useParams<{ id: string }>();
  const [missingDateSelected, setMissingDateSelecte] = useState<boolean>(false);

  const [reassign, { isLoading }] = useReAssignSettlementSurveyMutation();

  const { survey } = useGetFormData();

  const { isPublish } = survey || {};

  const form = Form.useFormInstance();
  const optionValue = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT, form);
  const dateValue =
    Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_SETTINGS, form) || defaultValue;

  const isAuto = optionValue === 'AUTO';

  const handleSetValue: CronProps['setValue'] = (value: string) => {
    const regex = /\b(29|30|31)\b/;
    const [_a, _b, day] = value.split(' ');

    if (regex.test(day)) {
      setMissingDateSelecte(true);
    } else if (missingDateSelected) {
      setMissingDateSelecte(false);
    }

    form.setFieldValue(SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_SETTINGS, value);
  };

  const afterAssign = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('documents_declarations_modal_publish_title'),
      },
      description: {
        children: t('surveys_republished_successfully'),
      },
    },
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'danger',
      },
      title: {
        children: t('common_something_went_wrong'),
      },
      description: {
        children: t('surveys_republished_error'),
      },
      actions: (
        <Button type="secondary" leftIcon="refresh" onClick={handleReAssign} loading={isLoading}>
          {t('common_retry_again')}
        </Button>
      ),
    },
  });

  function handleReAssign() {
    if (!id) return;

    reassign({ surveyId: id }).then(afterAssign);
  }

  return (
    <Flex vertical gap="16" className={s.wrapper}>
      <Flex vertical gap="8">
        <Flex fullWidth align="flex-start" justify="space-between">
          <Form.Item
            name={SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT}
            label={t('surveys_re_assignment_survey')}>
            <Radio.Group options={options} />
          </Form.Item>
          {!isAuto && isPublish && (
            <Button
              type="link"
              leftIcon="refresh"
              className={s.button}
              onClick={handleReAssign}
              loading={isLoading}
              disabled={false}>
              {t('common_republish')}
            </Button>
          )}
        </Flex>

        <Flex gap="8">
          <Icon name="info" color="var(--color-primary-6)" />
          <Typography.Text type="secondary">
            {t('surveys_re_assignment_manual_option')}
          </Typography.Text>
        </Flex>
      </Flex>

      {isAuto && (
        <Flex vertical className={s.cronWrapper} gap="8">
          <Form.Item
            name={SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_SETTINGS}
            label={`${t('common_repeat')}:`}>
            <Cron
              value={dateValue}
              setValue={handleSetValue}
              locale={locale}
              clearButton={false}
              allowedDropdowns={allowedDropdowns}
              allowedPeriods={allowedPeriods}
              clockFormat="24-hour-clock"
              allowEmpty="for-default-value"
              defaultPeriod="year"
              className={s.cron}
            />
          </Form.Item>
          {missingDateSelected && (
            <Flex gap="8">
              <Icon name="attention" color="var(--color-error)" />
              <Typography.Text type="secondary">
                {t('surveys_re_assignment_survey_help_info')}
              </Typography.Text>
            </Flex>
          )}
          <Flex gap="8" align="center" fullWidth>
            <Typography.Text size="14">до</Typography.Text>
            <Form.Item name={SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_DATE_TO} noStyle>
              <DatePicker format="DD.MM.YYYY" />
            </Form.Item>
          </Flex>
          <Flex gap="8">
            <Icon name="info" color="var(--color-primary-6)" />
            <Typography.Text type="secondary">
              {t('surveys_re_assignment_survey_help_info_period')}
            </Typography.Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ReAssignment;
