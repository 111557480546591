import initialApiService from 'services/initialApiService';

import {
  GetAgreementConsentsPropsType,
  GetAgreementConsentsResponseType,
  GetAgreementFileHistoryPropsType,
  GetAgreementFileHistoryResponseType,
  GetPolicyAgreementByIdPropsType,
  GetPolicyAgreementByIdResponseType,
  GetPolicyAgreementsPropsType,
  GetPolicyAgreementsResponseType,
  UpdatePolicyAgreementByIdPropsType,
  UpdatePolicyAgreementByIdResponseType,
} from './policyAgreementApiService.types';

import { prepareQueryParams } from '../../../utils/prepareQueryParams';
import { createPolicyAgreementUrl } from './utils';

const policyAgreementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPolicyAgreements: builder.query<
      GetPolicyAgreementsResponseType,
      GetPolicyAgreementsPropsType
    >({
      query: () => ({
        url: createPolicyAgreementUrl(''),
      }),
    }),
    getAgreementConsents: builder.query<
      GetAgreementConsentsResponseType,
      GetAgreementConsentsPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createPolicyAgreementUrl(`-consent/${id}${prepareQueryParams(params)}`),
      }),
    }),
    getAgreementFileHistory: builder.query<
      GetAgreementFileHistoryResponseType,
      GetAgreementFileHistoryPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createPolicyAgreementUrl(`-file/${id}${prepareQueryParams(params)}`),
      }),
    }),
    getPolicyAgreementById: builder.query<
      GetPolicyAgreementByIdResponseType,
      GetPolicyAgreementByIdPropsType
    >({
      query: (id) => ({
        url: createPolicyAgreementUrl(`/${id}`),
      }),
    }),
    updatePolicyAgreementById: builder.mutation<
      UpdatePolicyAgreementByIdResponseType,
      UpdatePolicyAgreementByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createPolicyAgreementUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAgreementFileHistoryQuery,
  useLazyGetAgreementConsentsQuery,
  useUpdatePolicyAgreementByIdMutation,
  useGetPolicyAgreementsQuery,
  useLazyGetPolicyAgreementByIdQuery,
} = policyAgreementApiService;
