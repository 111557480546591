import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSuggestionDecisionsQuery } from 'services/suggestion/suggestionApiService';
import { GetSuggestionDecisionsPropsType } from 'services/suggestion/suggestionApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './suggestionsDecisions.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: Props) => {
  const { form } = props;

  const [getDecisions, { data, isLoading, isFetching }] = useLazyGetSuggestionDecisionsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    const { page, size, search, order, property, filters } = debouncedRequestBody ?? {};

    if (page) {
      const initialRequestBody: GetSuggestionDecisionsPropsType = {
        page,
        size,
        types: 'COORDINATOR',
      };

      if (search) {
        initialRequestBody.text = search;
      }

      if (property && order) {
        if (debouncedRequestBody.property === 'createdAt') {
          initialRequestBody.sort = [
            {
              property: `Suggestion.createdAt`,
              order,
            },
          ];
        }
      }

      if (filters?.category?.length) {
        initialRequestBody.categoryId = filters.category;
      }

      if (filters?.statuses?.length) {
        initialRequestBody.statuses = filters.statuses;
      }

      if (filters?.realizationStates?.length) {
        initialRequestBody.realizationStates = filters.realizationStates;
      }

      getDecisions(initialRequestBody);
    }
  }, [debouncedRequestBody, getDecisions]);

  return {
    data,
    isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
