import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import s from './TextCounter.module.scss';

interface Props {
  current: number;
  total: number;
  text: string;
}

const TextCounter = (props: Props) => {
  const { current, text, total } = props;
  return (
    <div className={s.container}>
      <Typography.Text size="14" weight="500" type="primary">
        {text}
      </Typography.Text>
      <Typography.Text size="12" weight="400" type="secondary">
        {t('common_symbol_count', { current, total })}
      </Typography.Text>
    </div>
  );
};

export default TextCounter;
