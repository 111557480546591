import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployeeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Select } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';
import { ExpertCommissionType } from 'types/expertCommission.types';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';

const DEFAULT_REQUEST_BODY = {
  page: 1,
  size: 500,
};

interface Props {
  head?: ExpertCommissionType['head'];
}

const Head = (props: Props) => {
  const { head } = props;

  const { id } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string>('');

  const form = Form.useFormInstance();
  const organizationId = Form.useWatch(EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION);

  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { data: employeeData, isLoading } = useGetEmployeeQuery({
    ...DEFAULT_REQUEST_BODY,
    employerId: organizationId,
    search: debouncedSearch || undefined,
  });

  useEffect(() => {
    if (!id && organizationId) {
      form.setFieldValue(EXPERT_COMMISSION_FORM_ENUM.HEAD, null);
    }
  }, [form, id, organizationId]);

  const data = useMemo(() => {
    if (head && !debouncedSearch) {
      return employeeData?.employees?.concat([head] as EmployeeType[]);
    }

    return employeeData?.employees;
  }, [debouncedSearch, employeeData, head]);

  const options = data?.map((employee) => ({
    label: `${employee.person.firstName} ${employee.person.lastName} ${employee.position.name}`,
    value: employee.id,
  }));

  return (
    <Form.Item
      label={t('suggestions_commission_head_name')}
      name={EXPERT_COMMISSION_FORM_ENUM.HEAD}
      rules={[formRules.required]}>
      <Select
        placeholder={t('suggestions_commission_head_choose_placeholder')}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        loading={isLoading}
        disabled={!organizationId}
        searchValue={search}
        onSearch={setSearch}
      />
    </Form.Item>
  );
};

export default Head;
