import { DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import KedoReviewTaskForm from 'containers/kedo-review-task-form';
import { KEDO_REVIEW_TASK_FORM_NAME } from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DocumentsKedoReviewTaskCreate.module.scss';
import { useSubmit } from './useSubmit.hook';

const DocumentsKedoReviewTaskCreate = () => {
  const { handleSubmit, isLoading, kedoReviewTaskIdRef, handleGoBack } = useSubmit();

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE}>
          {t('kedo_review_tasks_create_title')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={KEDO_REVIEW_TASK_FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <div className={s.content}>
        <KedoReviewTaskForm
          kedoReviewTaskId={kedoReviewTaskIdRef.current}
          onSubmit={handleSubmit}
        />
        <div className={s.actions}>
          <Button
            fullWidth
            type="primary"
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={KEDO_REVIEW_TASK_FORM_NAME}>
            {t('common_save')}
          </Button>
          <Button fullWidth type="secondary" onClick={handleGoBack}>
            {t('common_close')}
          </Button>
        </div>
      </div>
    </WithOverflow>
  );
};

export default DocumentsKedoReviewTaskCreate;
