import { CronProps, Locale } from 'react-js-cron';
import { t } from 'tools/i18n';

export const options = [
  { value: 'AUTO', label: t('common_on_schedule') },
  { value: 'MANUAL', label: t('common_manually') },
];
export const allowedDropdowns: CronProps['allowedDropdowns'] = [
  'months',
  'month-days',
  'hours',
  'minutes',
  'period',
];
export const allowedPeriods: CronProps['allowedPeriods'] = ['year', 'month', 'day'];
export const defaultValue = '';
export const locale: Locale = {
  altMonths: [
    'Янв.',
    'Февр.',
    'Март',
    'Апр.',
    'Май',
    'Июнь',
    'Июль',
    'Авг.',
    'Сент.',
    'Окт.',
    'Нояб.',
    'Дек.',
  ],
  months: [
    'Янв.',
    'Февр.',
    'Март',
    'Апр.',
    'Май',
    'Июнь',
    'Июль',
    'Авг.',
    'Сент.',
    'Окт.',
    'Нояб.',
    'Дек.',
  ],
  prefixMonths: 'Каждый',
  prefixMonthDays: 'Каждое',
  prefixHours: 'в',
  emptyMonths: t('common_month'),
  emptyMonthDays: 'Число',
  emptyHours: '00',
  emptyMinutes: '00',
  prefixPeriod: 'Каждый',
  monthOption: 'месяц',
  yearOption: 'год',
  dayOption: 'день',
  everyText: ' ',
};
