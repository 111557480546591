import { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getSettingsEmployerDetailsRoute } from 'routes/settings/list';
import { handleTableChange } from 'utils/table.utils';

import { useLazyGetEmployer1cQuery } from 'services/1c/cApiService';
import { CEmployerType } from 'services/1c/cApiService.types';

import { Table, TableProps } from 'gazprom-ui-lib';

import { CInstanceType } from 'types/cInstances.types';

import s from './ImportedOrganizationsTable.module.scss';
import { INITIAL_REQUEST_BODY, RequestBody } from './importedOrganizationsTable.utils';
import useColumns from './useColumns.hook';

interface ImportedOrganizationsTableProps {
  cInstance?: CInstanceType;
}

const ImportedOrganizationsTable: FC<ImportedOrganizationsTableProps> = (props) => {
  const { cInstance } = props;

  const navigate = useNavigate();

  const [requestBody, setRequestBody] = useState<RequestBody>({
    ...INITIAL_REQUEST_BODY,
  });

  const columns = useColumns();

  const [getEmployer1c, { data, isLoading }] = useLazyGetEmployer1cQuery();

  useEffect(() => {
    if (cInstance)
      getEmployer1c({
        instance1cId: cInstance.id,
        ...requestBody,
      });
  }, [cInstance, getEmployer1c, requestBody]);

  const pagination: TableProps<CEmployerType>['pagination'] = {
    defaultCurrent: requestBody.page,
    defaultPageSize: requestBody.size,
    total: data && data?.totalCount,
    current: requestBody.page,
  };

  const onRow = (record: CEmployerType) => ({
    onClick: () => navigate(getSettingsEmployerDetailsRoute(record.id)),
  });

  return (
    <Table
      className={s.table}
      dataSource={data?.employers}
      columns={columns}
      loading={isLoading}
      rowKey="id"
      pagination={pagination}
      onRow={onRow}
      onChange={handleTableChange(setRequestBody)}
    />
  );
};

export default ImportedOrganizationsTable;
