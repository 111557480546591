import { useEffect } from 'react';

import { Form } from 'gazprom-ui-lib';

import { ExpertCommissionType } from 'types/expertCommission.types';

import s from './ExpertCommissionForm.module.scss';
import Coordinators from './containers/coordinators';
import Head from './containers/head';
import Members from './containers/members';
import Name from './containers/name';
import Organization from './containers/organization';
import {
  EXPERT_COMMISSION_FORM_NAME,
  ExpertCommissionFormResponseType,
  initExpertCommissionForm,
} from './expertCommissionForm.utils';

export interface Props {
  onSubmit: (values: ExpertCommissionFormResponseType) => void;
  expertCommission?: ExpertCommissionType;
}

const ExpertCommissionForm = (props: Props) => {
  const { onSubmit, expertCommission } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    initExpertCommissionForm(form, expertCommission);
  }, [expertCommission, form]);

  return (
    <Form className={s.wrapper} id={EXPERT_COMMISSION_FORM_NAME} form={form} onFinish={onSubmit}>
      <Name />
      <Organization disabled={!!expertCommission} />
      <Head head={expertCommission?.head} />
      <Members members={expertCommission?.members} />
      <Coordinators coordinators={expertCommission?.coordinators} />
    </Form>
  );
};

export default ExpertCommissionForm;
