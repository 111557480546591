import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  settlement: string | null;
  staff: string | null;
  company: string | null;
  division: string | null;
  from?: string;
  to?: string;
  options?: string;
};

export type RequestBody = PaginationPropsType & {
  company?: string | null;
  division?: string | null;
  staff?: string | null;
  search: string;
  property: string;
  order: SORT_ENUM;
};

export const getReportUrl = (id: string) => `${BACKEND_V1_BASE_URL}reports/survey/${id}/answers`;

export const DEFAULT_FREE_PAGE = 1;
export const DEFAULT_FREE_SIZE = 5;
export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  staff: null,
  company: null,
  division: null,
  settlement: null,
};
