import { useDebounce } from 'utils/useDebounce.hook';

import { useGetShiftMedexamRequestsQuery } from 'services/1c/shift/shiftApiService';
import { GetShiftMedexamRequestsPropsType } from 'services/1c/shift/shiftApiService.types';

import { TableFormResponseType } from './shiftChangeMedexamRequests.utils';

interface Props {
  requestBody: TableFormResponseType;
}

export const useRequest = (props: Props) => {
  const { requestBody } = props;

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, property, order, filters, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetShiftMedexamRequestsPropsType = {
    ...clearedRequestBody,
    ...filters,
    ...(search && { search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { content: dataSource, totalCount = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetShiftMedexamRequestsQuery(actualRequestBody, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.search?.trim();

  return { isInitialLoading, loading, dataSource, totalCount, hasFiltersApplied };
};
