import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { useRefreshRegistrationCodeMutation } from 'services/person/personApiService';
import { useProfileConfirmMutation } from 'services/private/privateApiService';
import { UpdateProfileConfirmResponseType } from 'services/private/privateApiService.types';

import {
  Button,
  Flex,
  ModalDefault,
  handleRequest,
  useHandleRequestWithModal,
} from 'gazprom-ui-lib';

import { REFRESH_CODE_TYPE } from 'types/person.types';

interface Props {
  open: boolean;
  close: () => void;
  personId: string | null;
  setSendUsersCount?: Dispatch<SetStateAction<number | null>>;
}

const AccessCodeModal = ({ open, close, personId, setSendUsersCount }: Props) => {
  const [refreshRegistrationCode, { isLoading }] = useRefreshRegistrationCodeMutation();
  const [refreshRegistrationCodeAll, { isLoading: isRefreshAllLoading }] =
    useProfileConfirmMutation();

  const handleRefreshRegistrationCode = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_successfully_sent'),
      },
    },
  });

  const onSuccess = (data?: UpdateProfileConfirmResponseType) => {
    if (setSendUsersCount) {
      setSendUsersCount(data?.numOfUsersSent ?? 0);
      close();
    }
  };

  const handleSubmit = (refreshCodeType: REFRESH_CODE_TYPE) => {
    if (personId) {
      refreshRegistrationCode({ personId, payload: { refreshCodeType } }).then((data) => {
        close();
        handleRefreshRegistrationCode(data);
      });
    } else {
      refreshRegistrationCodeAll({ refreshCodeType }).then(handleRequest({ onSuccess }));
    }
  };

  return (
    <ModalDefault open={open} onCancel={close} closable>
      <ModalDefault.Icon type="primary" name="sendFilled" />
      <ModalDefault.Title>{t('common_registration_code_send')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t(personId ? 'common_access_code_description' : 'common_access_code_all_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Flex gap="8">
          <Button
            disabled={isLoading || isRefreshAllLoading}
            onClick={() => handleSubmit(REFRESH_CODE_TYPE.SMS)}
            fullWidth
            leftIcon="sms"
            type="primary">
            {t('common_via_sms')}
          </Button>
          <Button
            disabled={isLoading || isRefreshAllLoading}
            onClick={() => handleSubmit(REFRESH_CODE_TYPE.EMAIL)}
            fullWidth
            leftIcon="mail"
            type="secondary">
            {t('common_via_mail')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default AccessCodeModal;
