import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetStatementsQuery } from 'services/1c/cApiService';
import { GetStatementsRequestType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import { FormResponseType } from './documentsInquiries.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getStatements, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetStatementsQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.size) {
      const { filters, range, search } = debouncedRequestBody;

      let initialRequestBody: GetStatementsRequestType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        search: search,
        type: [KEDO_TYPE_ENUM.KEDO],
      };

      if (filters) {
        initialRequestBody = {
          ...initialRequestBody,
          ...filters,
        };
      }

      if (range) {
        initialRequestBody.from = dayjs.utc(range[0]).format();
        initialRequestBody.to = dayjs.utc(range[1]).format();
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `kedo_task.${debouncedRequestBody.property}`,
          },
        ];
      }

      getStatements(initialRequestBody);
    }
  }, [debouncedRequestBody, getStatements]);

  return {
    data,
    isFetching: isFetching || isDebouncing,
    isLoading: isLoading || isUninitialized,
  };
};

export default useGetData;
