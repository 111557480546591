import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';
import {
  GetFreeStatsByIdResponseType,
  MappedFreeStatsResponseType,
} from 'services/surveys/surveysApiService.types';
import { mapFreetable } from 'services/surveys/utils';

import {
  BlockSubscriberPropsType,
  CreateCommunityArticleCategoryPropsType,
  CreateCommunityArticleCategoryResponseType,
  CreateCommunityCategoryPropsType,
  CreateCommunityDiscussionCommentPropsType,
  CreateCommunityDiscussionCommentResponseType,
  CreateCommunityDiscussionPropsType,
  CreateCommunityDiscussionResponseType,
  CreateCommunityMeetPropsType,
  CreateCommunityNewsPropsType,
  CreateCommunityPropsType,
  CreateUpdateCommunityCategoryPropsType,
  CreateUpdateCommunityCategoryResponseType,
  CreateUpdateCommunityMeetResponseType,
  CreateUpdateCommunityResponseType,
  DeleteCommunityCategoryPropsType,
  GetCommentByCommunityNewsIdPropsType,
  GetCommentByCommunityNewsIdResponseType,
  GetCommunitiesPropsType,
  GetCommunitiesResponseType,
  GetCommunityArticleCategoriesPropsType,
  GetCommunityArticleCategoriesResponseType,
  GetCommunityCategoriesPropsType,
  GetCommunityCategoriesResponseType,
  GetCommunityCommentsByDiscussionIdPropsType,
  GetCommunityCommentsByDiscussionIdResponseType,
  GetCommunityDiscussionByIdPropsType,
  GetCommunityDiscussionByIdResponseType,
  GetCommunityDiscussionCommentPropsType,
  GetCommunityDiscussionCommentResponseType,
  GetCommunityDiscussionsListPropsType,
  GetCommunityDiscussionsListResponseType,
  GetCommunityInvitesPropsType,
  GetCommunityInvitesResponseType,
  GetCommunityMeetByIdPropsType,
  GetCommunityMeetByIdResponseType,
  GetCommunityMeetParticipantsPropsType,
  GetCommunityMeetParticipantsResponseType,
  GetCommunityMeetsPropsType,
  GetCommunityMeetsResponseType,
  GetCommunityNewsByIdPropsType,
  GetCommunityNewsByIdResponseType,
  GetCommunityNewsListPropsType,
  GetCommunityNewsListResponseType,
  GetCommunityPropsType,
  GetCommunityResponseType,
  GetCommunitySubscribersEmployersOrDivisionsPropsType,
  GetCommunitySubscribersEmployersOrDivisionsResponseType,
  GetCommunitySubscribersPropsType,
  GetCommunitySubscribersResponseType,
  GetCommunitySurveyAllFreeStatsByIdPropsType,
  GetCommunitySurveyAllFreeStatsByIdResponseType,
  GetCommunitySurveyByIdPropsType,
  GetCommunitySurveyByIdResponseType,
  GetCommunitySurveyRespondentsPropsType,
  GetCommunitySurveyRespondentsResponseType,
  GetCommunitySurveyStatsByIdPropsType,
  GetCommunitySurveyStatsByIdResponseType,
  GetCommunitySurveysListPropsType,
  GetCommunitySurveysListResponseType,
  InviteUserPropsType,
  MassSubscribeInvitePropsType,
  PublishCommunitySurveyPropsType,
  PublishCommunitySurveyResponseType,
  ReinviteUserPropsType,
  ResponseToCommentByCommunityNewsIdPropsType,
  ResponseToCommentByCommunityNewsIdResponseType,
  SaveCommunitySurveyPropsType,
  SaveCommunitySurveyResponseType,
  TargetSubscribePropsType,
  UnblockSubscriberPropsType,
  UnpublishCommunitySurveyPropsType,
  UnpublishCommunitySurveyResponseType,
  UpdateCommentByCommunityNewsIdPropsType,
  UpdateCommentByCommunityNewsIdResponseType,
  UpdateCommunityDiscussionCommentPropsType,
  UpdateCommunityDiscussionCommentResponseType,
  UpdateCommunityDiscussionPropsType,
  UpdateCommunityDiscussionResponseType,
  UpdateCommunityMeetPropsType,
  UpdateCommunityNewsPropsType,
  UpdateCommunityPropsType,
} from './communityApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

const communitiesApiService = initialApiService.injectEndpoints({
  // ! COMMUNITY CATEGORIES
  endpoints: (builder) => ({
    getCommunityCategories: builder.query<
      GetCommunityCategoriesResponseType,
      GetCommunityCategoriesPropsType
    >({
      query: (params) => ({
        url: `category_communities${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    createCommunityCategory: builder.mutation<
      CreateUpdateCommunityCategoryResponseType,
      CreateCommunityCategoryPropsType
    >({
      query: ({ payload }) => ({
        url: `category_communities`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    updateCommunityCategory: builder.mutation<
      CreateUpdateCommunityCategoryResponseType,
      CreateUpdateCommunityCategoryPropsType
    >({
      query: ({ id, payload }) => ({
        url: `category_communities/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),
    deleteCommunityCategory: builder.mutation<void, DeleteCommunityCategoryPropsType>({
      query: ({ id }) => ({
        url: `category_communities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_CATEGORIES],
    }),

    // ! COMMUNITY MANAGEMENT
    getCommunities: builder.query<GetCommunitiesResponseType, GetCommunitiesPropsType>({
      query: (params) => ({
        url: `community${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITIES],
    }),
    getCommunitiesModerator: builder.query<GetCommunitiesResponseType, GetCommunitiesPropsType>({
      query: (params) => ({
        url: `me/community/moderator${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITIES_MODERATOR],
    }),
    getCommunity: builder.query<GetCommunityResponseType, GetCommunityPropsType>({
      query: ({ id }) => ({
        url: `community/${id}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY],
    }),
    createCommunity: builder.mutation<CreateUpdateCommunityResponseType, CreateCommunityPropsType>({
      query: ({ payload }) => ({
        url: `community`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
      ],
    }),
    updateCommunity: builder.mutation<CreateUpdateCommunityResponseType, UpdateCommunityPropsType>({
      query: ({ payload, id }) => ({
        url: `community/${id}`,
        body: payload,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
      ],
    }),

    // ! SUBSCRIBERS
    getCommunitySubscribers: builder.query<
      GetCommunitySubscribersResponseType,
      GetCommunitySubscribersPropsType
    >({
      query: ({ id, params }) => ({
        url: `subscribers/community/${id}${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS],
    }),
    getCommunityDiscussions: builder.query<
      GetCommunityDiscussionsListResponseType,
      GetCommunityDiscussionsListPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/discussion${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST],
    }),
    createCommunityDiscussion: builder.mutation<
      CreateCommunityDiscussionResponseType,
      CreateCommunityDiscussionPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/discussion`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID,
      ],
    }),
    updateCommunityDiscussion: builder.mutation<
      UpdateCommunityDiscussionResponseType,
      UpdateCommunityDiscussionPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSIONS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID,
      ],
    }),
    getCommunityDiscussionById: builder.query<
      GetCommunityDiscussionByIdResponseType,
      GetCommunityDiscussionByIdPropsType
    >({
      query: ({ communityId, discussionId }) => ({
        url: `community/${communityId}/discussion/${discussionId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_BY_ID],
    }),
    getCommunityCommentsByDiscussionId: builder.query<
      GetCommunityCommentsByDiscussionIdResponseType,
      GetCommunityCommentsByDiscussionIdPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments${prepareQueryParams(
          params,
        )}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS],
    }),
    createCommunityDiscussionComment: builder.mutation<
      CreateCommunityDiscussionCommentResponseType,
      CreateCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID,
      ],
    }),
    getCommunityDiscussionComment: builder.query<
      GetCommunityDiscussionCommentResponseType,
      GetCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, commentId }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments/${commentId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID],
    }),
    updateCommunityDiscussionComment: builder.mutation<
      UpdateCommunityDiscussionCommentResponseType,
      UpdateCommunityDiscussionCommentPropsType
    >({
      query: ({ communityId, discussionId, commentId, ...params }) => ({
        url: `community/${communityId}/discussion/${discussionId}/comments/${commentId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENTS,
        SERVICE_TAGS.GET_COMMUNITY_DISCUSSION_COMMENT_BY_ID,
      ],
    }),
    getCommunityNewsList: builder.query<
      GetCommunityNewsListResponseType,
      GetCommunityNewsListPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/article${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST],
    }),
    createCommunityNews: builder.mutation<void, CreateCommunityNewsPropsType>({
      query: ({ communityId, body }) => {
        return {
          url: `community/${communityId}/article`,
          body: body,
          method: 'POST',
        };
      },
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST],
    }),
    updateCommunityNewsById: builder.mutation<void, UpdateCommunityNewsPropsType>({
      query: ({ communityId, newsId, body }) => {
        return {
          url: `community/${communityId}/article/${newsId}`,
          body: body,
          method: 'PUT',
        };
      },
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_NEWS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_NEWS_BY_ID,
      ],
    }),
    getCommunityNewsById: builder.query<
      GetCommunityNewsByIdResponseType,
      GetCommunityNewsByIdPropsType
    >({
      query: ({ communityId, newsId }) => ({
        url: `community/${communityId}/article/${newsId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_NEWS_BY_ID],
    }),
    getCommentByCommunityNewsId: builder.query<
      GetCommentByCommunityNewsIdResponseType,
      GetCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, ...params }) => ({
        url: `community/${communityId}/article/${newsId}/comments${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_ENTITY_COMMENTS],
    }),
    updateCommentByCommunityNewsId: builder.mutation<
      UpdateCommentByCommunityNewsIdResponseType,
      UpdateCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, commentId, body }) => ({
        url: `community/${communityId}/article/${newsId}/comments/${commentId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_ENTITY_COMMENTS],
    }),
    responseToCommentByCommunityNewsId: builder.mutation<
      ResponseToCommentByCommunityNewsIdResponseType,
      ResponseToCommentByCommunityNewsIdPropsType
    >({
      query: ({ communityId, newsId, body }) => ({
        url: `community/${communityId}/article/${newsId}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_ENTITY_COMMENTS],
    }),
    blockSubscriber: builder.mutation<void, BlockSubscriberPropsType>({
      query: ({ payload, communityId, subscriberId }) => ({
        url: `community/${communityId}/subscriber/${subscriberId}/block`,
        body: payload,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
        SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS,
      ],
    }),
    unblockSubscriber: builder.mutation<void, UnblockSubscriberPropsType>({
      query: ({ communityId, subscriberId }) => ({
        url: `community/${communityId}/subscriber/${subscriberId}/unblock`,
        method: 'PUT',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
        SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS,
      ],
    }),
    massSubscribe: builder.mutation<void, MassSubscribeInvitePropsType>({
      query: ({ payload, communityId }) => ({
        url: `community/${communityId}/subscribe`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS],
    }),
    targetSubscribe: builder.mutation<void, TargetSubscribePropsType>({
      query: ({ communityId, params }) => ({
        url: `community/${communityId}/subscribers${prepareQueryParams(params)}`,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY,
        SERVICE_TAGS.GET_COMMUNITIES,
        SERVICE_TAGS.GET_COMMUNITY_CATEGORIES,
        SERVICE_TAGS.GET_COMMUNITIES_MODERATOR,
        SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS,
      ],
    }),

    // ! INVITES
    getCommunityInvites: builder.query<
      GetCommunityInvitesResponseType,
      GetCommunityInvitesPropsType
    >({
      query: ({ id, params }) => ({
        url: `invites/community/${id}${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    reinviteUser: builder.mutation<void, ReinviteUserPropsType>({
      query: ({ communityId, inviteId }) => ({
        url: `community/${communityId}/invites/${inviteId}`,
        method: 'PUT',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    inviteUser: builder.mutation<void, InviteUserPropsType>({
      query: ({ params, communityId }) => ({
        url: `community/${communityId}/invites${prepareQueryParams(params)}`,
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),
    massInvite: builder.mutation<void, MassSubscribeInvitePropsType>({
      query: ({ payload, communityId }) => ({
        url: `community/${communityId}/mass-invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_INVITES],
    }),

    // ! COMMUNITY DIVISION & EMPLOYER FILTERS
    getCommunitySubscribersEmployers: builder.query<
      GetCommunitySubscribersEmployersOrDivisionsResponseType,
      GetCommunitySubscribersEmployersOrDivisionsPropsType
    >({
      query: ({ id }) => ({
        url: `subscribers/community/${id}/employer`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS_EMPLOYERS],
    }),
    getCommunitySubscribersDivisions: builder.query<
      GetCommunitySubscribersEmployersOrDivisionsResponseType,
      GetCommunitySubscribersEmployersOrDivisionsPropsType
    >({
      query: ({ id }) => ({
        url: `subscribers/community/${id}/division`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SUBSCRIBERS_DIVISIONS],
    }),

    // ! COMMUNITY MEETS
    getCommunityMeets: builder.query<GetCommunityMeetsResponseType, GetCommunityMeetsPropsType>({
      query: ({ communityId, ...params }) => ({
        url: `community/${communityId}/meet${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_MEETS],
    }),
    getCommunityMeetById: builder.query<
      GetCommunityMeetByIdResponseType,
      GetCommunityMeetByIdPropsType
    >({
      query: ({ communityId, meetingId }) => ({
        url: `community/${communityId}/meet/${meetingId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_MEET],
    }),
    createCommunityMeet: builder.mutation<
      CreateUpdateCommunityMeetResponseType,
      CreateCommunityMeetPropsType
    >({
      query: ({ communityId, payload }) => ({
        url: `community/${communityId}/meet`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_MEETS],
    }),
    updateCommunityMeet: builder.mutation<
      CreateUpdateCommunityMeetResponseType,
      UpdateCommunityMeetPropsType
    >({
      query: ({ communityId, meetingId, payload }) => ({
        url: `community/${communityId}/meet/${meetingId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_MEETS, SERVICE_TAGS.GET_COMMUNITY_MEET],
    }),
    getCommunityMeetParticipants: builder.query<
      GetCommunityMeetParticipantsResponseType,
      GetCommunityMeetParticipantsPropsType
    >({
      query: ({ communityId, meetId, params }) => ({
        url: `community-participant/${communityId}/meet/${meetId}${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_MEET_PARTICIPANTS],
    }),
    // ! COMMUNITY ARTICLE CATEGORIES
    getCommunityArticleCategories: builder.query<
      GetCommunityArticleCategoriesResponseType,
      GetCommunityArticleCategoriesPropsType
    >({
      query: (params) => ({
        url: `community-article-category${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_ARTICLE_CATEGORIES],
    }),
    createCommunityArticleCategory: builder.mutation<
      CreateCommunityArticleCategoryResponseType,
      CreateCommunityArticleCategoryPropsType
    >({
      query: (payload) => ({
        url: `community-article-category`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_ARTICLE_CATEGORIES],
    }),
    getCommunitySurveysList: builder.query<
      GetCommunitySurveysListResponseType,
      GetCommunitySurveysListPropsType
    >({
      query: ({ communityId, ...params }) => ({
        url: `private/community/${communityId}/survey${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SURVEYS_LIST],
    }),
    getCommunitySurveyById: builder.query<
      GetCommunitySurveyByIdResponseType,
      GetCommunitySurveyByIdPropsType
    >({
      query: ({ communityId, surveyId }) => ({
        url: `private/community/${communityId}/survey/${surveyId}`,
      }),
      providesTags: [SERVICE_TAGS.GET_COMMUNITY_SURVEY_BY_ID],
    }),
    saveCommunitySurvey: builder.mutation<
      SaveCommunitySurveyResponseType,
      SaveCommunitySurveyPropsType
    >({
      query: ({ communityId, body }) => ({
        url: `private/community/${communityId}/survey`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMUNITY_SURVEYS_LIST],
    }),
    getCommunitySurveyStatsById: builder.query<
      GetCommunitySurveyStatsByIdResponseType,
      GetCommunitySurveyStatsByIdPropsType
    >({
      query: ({ communityId, surveyId, revisionId, qoaQuery, ...query }) => ({
        url: `private/community/${communityId}/survey/${surveyId}/revision/${revisionId}/stats${prepareQueryParams(
          query,
        )}${qoaQuery ?? ''}`,
      }),
    }),
    getCommunitySurveyAllFreeStatsById: builder.query<
      GetCommunitySurveyAllFreeStatsByIdResponseType[],
      GetCommunitySurveyAllFreeStatsByIdPropsType[]
    >({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const results = await Promise.all(
            params.map(
              async ({
                communityId,
                surveyId,
                revisionId,
                questionId,
                qoaQuery,
                freeQuestionId,
                ...body
              }) => {
                const url = `private/community/${communityId}/survey/${surveyId}/revision/${revisionId}/stats/question/${questionId}${prepareQueryParams(
                  body,
                )}${qoaQuery ?? ''}`;

                const result = await fetchWithBQ({ url });

                if (result.error) {
                  return { error: result.error };
                }

                const data = result.data as GetFreeStatsByIdResponseType;

                return mapFreetable({ ...data, freeQuestionId });
              },
            ),
          );

          return { data: results as MappedFreeStatsResponseType[] };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
    }),
    getCommunitySurveyRespondents: builder.query<
      GetCommunitySurveyRespondentsResponseType,
      GetCommunitySurveyRespondentsPropsType
    >({
      query: ({ communityId, surveyId, ...params }) => ({
        url: `private/community/${communityId}/survey/${surveyId}/respondents${prepareQueryParams(
          params,
        )}`,
      }),
    }),
    unpublishCommunitySurvey: builder.mutation<
      UnpublishCommunitySurveyResponseType,
      UnpublishCommunitySurveyPropsType
    >({
      query: ({ communityId, surveyId }) => ({
        url: `private/community/${communityId}/survey/${surveyId}/unpublish`,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_SURVEYS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_SURVEY_BY_ID,
      ],
    }),
    publishCommunitySurvey: builder.mutation<
      PublishCommunitySurveyResponseType,
      PublishCommunitySurveyPropsType
    >({
      query: ({ communityId, surveyId }) => ({
        url: `private/community/${communityId}/survey/${surveyId}/publish`,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_COMMUNITY_SURVEYS_LIST,
        SERVICE_TAGS.GET_COMMUNITY_SURVEY_BY_ID,
      ],
    }),
  }),
});

export const {
  usePublishCommunitySurveyMutation,
  useGetCommunitySurveyByIdQuery,
  useUnpublishCommunitySurveyMutation,
  useLazyGetCommunitySurveyStatsByIdQuery,
  useLazyGetCommunitySurveyAllFreeStatsByIdQuery,
  useSaveCommunitySurveyMutation,
  useGetCommunitySurveysListQuery,
  useLazyGetCommunitySurveyRespondentsQuery,
  useGetCommunityCategoriesQuery,
  useCreateCommunityCategoryMutation,
  useUpdateCommunityCategoryMutation,
  useDeleteCommunityCategoryMutation,
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useCreateCommunityMutation,
  useUpdateCommunityMutation,
  useGetCommunitiesModeratorQuery,
  useGetCommunitySubscribersQuery,
  useCreateCommunityNewsMutation,
  useUpdateCommunityNewsByIdMutation,
  useLazyGetCommunityNewsListQuery,
  useGetCommunityNewsListQuery,
  useGetCommunityNewsByIdQuery,
  useLazyGetCommentByCommunityNewsIdQuery,
  useUpdateCommentByCommunityNewsIdMutation,
  useResponseToCommentByCommunityNewsIdMutation,
  useGetCommunityInvitesQuery,
  useBlockSubscriberMutation,
  useUnblockSubscriberMutation,
  useReinviteUserMutation,
  useInviteUserMutation,
  useLazyGetCommunityDiscussionsQuery,
  useGetCommunityDiscussionsQuery,
  useLazyGetCommunityCommentsByDiscussionIdQuery,
  useUpdateCommunityDiscussionMutation,
  useGetCommunityDiscussionByIdQuery,
  useCreateCommunityDiscussionMutation,
  useCreateCommunityDiscussionCommentMutation,
  useUpdateCommunityDiscussionCommentMutation,
  useLazyGetCommunityDiscussionCommentQuery,
  useTargetSubscribeMutation,
  useMassSubscribeMutation,
  useMassInviteMutation,
  useGetCommunitySubscribersEmployersQuery,
  useGetCommunitySubscribersDivisionsQuery,
  useCreateCommunityMeetMutation,
  useGetCommunityMeetsQuery,
  useGetCommunityArticleCategoriesQuery,
  useCreateCommunityArticleCategoryMutation,
  useGetCommunityMeetByIdQuery,
  useUpdateCommunityMeetMutation,
  useGetCommunityMeetParticipantsQuery,
} = communitiesApiService;
