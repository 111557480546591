import { Dispatch, FC, SetStateAction, memo, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import {
  usePublishHandbookMutation,
  useUnpublishHandbookMutation,
} from 'services/settlement/settlementApiService';

import { Button, Flex, Form, Switch, Typography } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import DeleteModal from '../delete-modal';
import UploadActions from '../upload-actions';
import s from './Filters.module.scss';

interface GuideHandbookFiltersProps {
  selectedRowKeys: string[];
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>;
}

const GuideHandbookFilters: FC<GuideHandbookFiltersProps> = (props) => {
  const { selectedRowKeys, setSelectedRowKeys } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [unpublishHandbook, { isLoading: unpublishIsFetching }] = useUnpublishHandbookMutation();
  const [publishHandbook, { isLoading: publishIsFetching }] = usePublishHandbookMutation();

  const areRowsSelected = !!selectedRowKeys.length;

  const handlePublish = () => {
    publishHandbook(selectedRowKeys);
  };
  const handleUnpublish = () => {
    unpublishHandbook(selectedRowKeys);
  };

  const handleClearSelections = () => setSelectedRowKeys([]);

  const handleOpenModal = () => setIsModalOpen((prev) => !prev);

  return (
    <FiltersV2.TableFilters>
      <Flex gap="32" align="center">
        <FiltersV2.Search hidden={areRowsSelected} />

        <Flex gap="8" align="center" className={cn({ [s.hidden]: areRowsSelected })}>
          <Form.Item name="isPublish">
            <Switch defaultChecked />
          </Form.Item>
          <Typography.Text size="14">{t('guide_show_hidden')}</Typography.Text>
        </Flex>
      </Flex>

      <Flex align="center" wrap="wrap" justify="space-between" fullWidth={areRowsSelected}>
        {areRowsSelected && (
          <Flex align="center">
            <Button
              onClick={handleClearSelections}
              size="small"
              ghost
              leftIcon="delete"
              type="link">
              {t('common_remove_selection')}
            </Button>
            <Button
              className={s.publish}
              onClick={handlePublish}
              size="small"
              type="link"
              leftIcon="publishFilled"
              loading={publishIsFetching}>
              {t('common_publish')}
            </Button>
            <Button
              onClick={handleUnpublish}
              size="small"
              type="link"
              leftIcon="unpublishFilled"
              loading={unpublishIsFetching}>
              {t('common_unpublish')}
            </Button>
            <Button onClick={handleOpenModal} size="small" type="link" danger leftIcon="bin">
              {t('common_remove')}
            </Button>
          </Flex>
        )}
        <DeleteModal
          open={isModalOpen}
          selectedRowKeys={selectedRowKeys}
          onClose={handleOpenModal}
        />
        <UploadActions selectedRowKeys={selectedRowKeys} />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default memo(GuideHandbookFilters);
