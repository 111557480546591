import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Checkbox, Flex, Form, Input, Switch, TextArea, Typography } from 'gazprom-ui-lib';

import s from './Notifications.module.scss';
import { FORM_ENUM } from './notifications.utils';

const Notifications = () => {
  return (
    <Flex vertical gap="24" className={s.container}>
      <Typography.Text size="18" weight="500">
        {t('surveys_play_gift_notifications_title')}
      </Typography.Text>

      <Flex vertical gap="8">
        <Typography.Text size="16" weight="500">
          {t('surveys_play_gift_notifications_destination_settings')}
        </Typography.Text>

        <Form.Item noStyle name={FORM_ENUM.SEND_APP} valuePropName="checked" initialValue={true}>
          <Checkbox disabled defaultChecked>
            {t('common_in_app')}
          </Checkbox>
        </Form.Item>

        <Flex gap="8" align="center" className={s.notification}>
          <Form.Item noStyle name={FORM_ENUM.ALLOW_FEEDBACK} initialValue={false}>
            <Switch />
          </Form.Item>
          <Typography.Text size="14">{t('common_allow_feedback')}</Typography.Text>
        </Flex>

        <Form.Item noStyle name={FORM_ENUM.SEND_MAIL} valuePropName="checked" initialValue={false}>
          <Checkbox>{t('common_by_mail')}</Checkbox>
        </Form.Item>
      </Flex>

      <Form.Item
        label={
          <Typography.Text size="16" weight="500">
            {t('common_notification_title')}
          </Typography.Text>
        }
        name={FORM_ENUM.TITLE}
        rules={[formRules.required]}>
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <Typography.Text size="16" weight="500">
            {t('events_notification_text')}
          </Typography.Text>
        }
        name={FORM_ENUM.BODY}
        rules={[formRules.required]}>
        <TextArea maxLength={250} />
      </Form.Item>
    </Flex>
  );
};

export default Notifications;
