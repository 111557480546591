import { t } from 'tools/i18n';

import { GetTaskByIdResponseType, LeaderType } from 'services/1c/cApiService.types';

import { Flex, Typography } from 'gazprom-ui-lib';

import { getParticipantStatusConfig } from 'pages/documents/documents-view/documents-view-participants/documentsViewParticipants.utils';
import DocumentsViewPerson from 'pages/documents/documents-view/documents-view-person';

import s from './Participants.module.scss';

interface Props {
  data?: GetTaskByIdResponseType;
}

const Participants = (props: Props) => {
  const { data } = props;

  const { approvers } = data ?? {};
  const { firstLeader, functionalLeader, leaders, check1cExecutors, prepare1cExecutors, signer } =
    approvers ?? {};

  const renderLeader = (leader: LeaderType) => {
    const { date, type, icon } = getParticipantStatusConfig(leader);

    return (
      <Flex justify="space-between">
        <DocumentsViewPerson
          date={date}
          type={type}
          icon={icon}
          key={leader.employeeId}
          firstName={leader.person.firstName}
          lastName={leader.person.lastName}
          position={leader.position}
          employeeId={leader.employeeId}
        />
      </Flex>
    );
  };

  return (
    <>
      {firstLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_first_leader')}</Typography.Title>
          {renderLeader(firstLeader)}
        </div>
      )}
      {!!check1cExecutors?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_check1c_executors')}</Typography.Title>
          {check1cExecutors.map(renderLeader)}
        </div>
      )}
      {!!leaders?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_common_leaders')}</Typography.Title>
          {leaders.map(renderLeader)}
        </div>
      )}
      {functionalLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_functional_leaders')}</Typography.Title>
          {renderLeader(functionalLeader)}
        </div>
      )}
      {!!prepare1cExecutors?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_prepare1c_executors')}</Typography.Title>
          {prepare1cExecutors.map(renderLeader)}
        </div>
      )}
      {signer && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_signer_leaders')}</Typography.Title>
          {renderLeader(signer)}
        </div>
      )}
    </>
  );
};

export default Participants;
