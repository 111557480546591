import { Fragment, Suspense, lazy } from 'react';

import { matchRoutes, useLocation } from 'react-router-dom';
import {
  SURVEYS_EVENT_BY_ID_ROUTE,
  SURVEYS_ONCE_BY_ID_ROUTE,
  SURVEYS_SETTLEMENT_BY_ID_ROUTE,
} from 'routes/surveys/list';

import { MappedFreeQuestionType, QuestionStatsType, RequestBodyType } from 'types/surveys.types';

import { QUESTION_TYPES } from '../../statsTab.utils';

const SurveysSingleStatsMatrix = lazy(() => import('../stats-matrix'));
const SurveysSingleStatsFreeForOnceAndEventRoutes = lazy(() => import('../stats-free'));
const StatsFreeForSettlementRoute = lazy(() => import('../stats-free-settlement'));
const SurveysSingleStatsScale = lazy(() => import('../stats-scale'));
const SurveysSingleStatsSelect = lazy(() => import('../stats-select'));

interface Props {
  question: QuestionStatsType | MappedFreeQuestionType;
  index: number;
  requestBody: RequestBodyType;
}

const SURVYES_ROUTES = [
  { path: SURVEYS_ONCE_BY_ID_ROUTE },
  { path: SURVEYS_EVENT_BY_ID_ROUTE },
  { path: SURVEYS_SETTLEMENT_BY_ID_ROUTE },
];

const freeComponents = {
  [SURVEYS_ONCE_BY_ID_ROUTE]: SurveysSingleStatsFreeForOnceAndEventRoutes,
  [SURVEYS_EVENT_BY_ID_ROUTE]: SurveysSingleStatsFreeForOnceAndEventRoutes,
  [SURVEYS_SETTLEMENT_BY_ID_ROUTE]: StatsFreeForSettlementRoute,
};

const Renderer = (props: Props) => {
  const location = useLocation();
  const matches = matchRoutes(SURVYES_ROUTES, location);

  const { question, index, requestBody } = props;
  const { FREE, SCALE, MATRIX, SELECT } = QUESTION_TYPES;
  const currentIndex = index + 1;

  if (question.type === MATRIX) {
    const { title, rows } = question;

    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsMatrix title={title} rows={rows} number={currentIndex} />
      </Suspense>
    );
  }

  if (question.type === FREE) {
    const CurrentComponent = freeComponents[matches?.[0].route.path as keyof typeof freeComponents];

    if (!CurrentComponent) {
      return null;
    }

    return (
      <Suspense fallback={<Fragment />}>
        <CurrentComponent
          {...(question as MappedFreeQuestionType)}
          number={currentIndex}
          requestBody={requestBody}
        />
      </Suspense>
    );
  }

  if (question.type === SCALE) {
    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsScale {...question} number={currentIndex} requestBody={requestBody} />
      </Suspense>
    );
  }

  if (question.type === SELECT) {
    const { title, options, questionId } = question;

    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsSelect
          title={title}
          options={options}
          number={currentIndex}
          questionId={questionId}
        />
      </Suspense>
    );
  }

  return null;
};

export default Renderer;
