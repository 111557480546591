import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetSettlementSurveyHistoryAssigmentByIdQuery } from 'services/surveys/surveysApiService';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

const Respondents = () => {
  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const { data } = useGetSettlementSurveyHistoryAssigmentByIdQuery(
    {
      id: giftId!,
      surveyId: surveyId!,
    },
    { skip: !giftId && !surveyId },
  );
  return (
    <Flex vertical gap="4">
      <Typography.Text size="16" weight="500">
        {t('surveys_play_gift_settlement_respondents')}
      </Typography.Text>
      <Flex gap="8" align="center">
        <Icon name="group" color="var(--color-primary-6)" />
        <Typography.Text size="14">{data?.lotteryEntriesCount}</Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Respondents;
