import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TablePerson from 'components/table/table-person';

import { CurrentKedoStatusType } from 'types/documents.types';
import { ReplacementType } from 'types/replacement.types';

import { transformReplacementPeriod } from './documentsReplacement.utils';

const useColumns = (): TableProps<ReplacementType>['columns'] => {
  const fromEmployeeRenderer = (employee: ReplacementType['fromEmployee']) => {
    const { firstName, lastName } = employee.person;
    const { name: employerName } = employee.employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={employerName} />;
  };
  const toEmployeeRenderer = (employee: ReplacementType['toEmployee']) => {
    const { firstName, lastName } = employee.person;
    const { name: employerName } = employee.employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={employerName} />;
  };

  const periodRenderer = (replacement: ReplacementType) => {
    const { startDate, endDate } = replacement;

    return (
      <Typography.Text weight="500" size="12">
        {transformReplacementPeriod(startDate, endDate)}
      </Typography.Text>
    );
  };

  const statusRenderer = (status: ReplacementType['status']) => {
    const kedoStatus: CurrentKedoStatusType = {
      comment: '',
      createdAt: '',
      description: status.description ?? '',
      state: status.state ?? '',
      status: status.code ?? '',
      title: status.name ?? '',
      titleAlt: status.name ?? '',
    };

    return <KedoStatusTag currentKedoStatus={kedoStatus} size="30" />;
  };

  return [
    {
      title: t('common_manager'),
      dataIndex: 'fromEmployee',
      align: 'left',
      render: fromEmployeeRenderer,
    },
    {
      title: t('replacement_person'),
      dataIndex: 'toEmployee',
      align: 'left',
      render: toEmployeeRenderer,
    },
    {
      title: t('common_period'),
      render: periodRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      align: 'left',
      render: statusRenderer,
    },
  ];
};

export default useColumns;
