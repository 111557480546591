import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  CreateNotificationNewsletterPropsType,
  CreateNotificationNewsletterResponseType,
  DeleteNotificationNewsletterPropsType,
  DeleteNotificationNewsletterResponseType,
  GetNotificationNewslettersByIdPropsType,
  GetNotificationNewslettersByIdResponseType,
  GetNotificationNewslettersPropsType,
  GetNotificationNewslettersResponseType,
} from './notificationV2ApiService.types';
import { SERVICE_API_V2_TAGS } from 'constants/serviceTags';

import { notificationV2Url } from './utils';

const notificationV2ApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationNewsletters: builder.query<
      GetNotificationNewslettersResponseType,
      GetNotificationNewslettersPropsType
    >({
      query: (params) => ({
        url: notificationV2Url(`/package${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER],
    }),
    getNotificationNewslettersById: builder.query<
      GetNotificationNewslettersByIdResponseType,
      GetNotificationNewslettersByIdPropsType
    >({
      query: (id) => ({
        url: notificationV2Url(`/package/${id}`),
      }),
      providesTags: [SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER],
    }),
    createNotificationNewsletter: builder.mutation<
      CreateNotificationNewsletterResponseType,
      CreateNotificationNewsletterPropsType
    >({
      query: (body) => ({
        url: notificationV2Url(`/package`),
        method: 'POST',
        body: {
          notificationType: 'invite',
          ...body,
        },
      }),
      invalidatesTags: [
        SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER_BY_ID,
        SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER,
      ],
    }),
    deleteNotificationNewsletter: builder.mutation<
      DeleteNotificationNewsletterResponseType,
      DeleteNotificationNewsletterPropsType
    >({
      query: (id) => ({
        url: notificationV2Url(`/package/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [
        SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER_BY_ID,
        SERVICE_API_V2_TAGS.GET_NOTIFICATION_NEWSLETTER,
      ],
    }),
  }),
});

export const {
  useLazyGetNotificationNewslettersQuery,
  useLazyGetNotificationNewslettersByIdQuery,
  useGetNotificationNewslettersByIdQuery,
  useCreateNotificationNewsletterMutation,
  useDeleteNotificationNewsletterMutation,
} = notificationV2ApiService;
