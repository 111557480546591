import { DataNode } from 'gazprom-ui-lib';

import { DivisionTreeDataType } from 'types/review-task.types';

export const initializeTree = (
  initialData: DataNode[],
  divisionTreeData: DivisionTreeDataType[],
): DataNode[] => {
  const divisionMap = new Map<string, DivisionTreeDataType>();

  const buildDivisionMap = (nodes: DivisionTreeDataType[]) => {
    nodes.forEach((node) => {
      divisionMap.set(node.id, node);
      if (node.children) {
        buildDivisionMap(node.children);
      }
    });
  };

  buildDivisionMap(divisionTreeData);

  const replaceNode = (node: DataNode): DataNode => {
    // @ts-ignore
    const divisionNode = divisionMap.get(node.id);
    if (divisionNode) {
      return {
        ...node,
        // @ts-ignore
        name: divisionNode.name,
        title: divisionNode.name,
        // @ts-ignore
        hasChildren: divisionNode.hasChildren,
        isLeaf: !divisionNode.hasChildren,
        children: divisionNode.children
          ? divisionNode.children.map((child) =>
              replaceNode({
                ...node,
                // @ts-ignore
                id: child.id,
                key: `${child.id}.DIVISION`,
                name: child.name,
                title: child.name,
                hasChildren: child.hasChildren,
                isLeaf: !child.hasChildren,
                children: [],
              }),
            )
          : [],
      };
    }

    return {
      ...node,
      children: node.children ? node.children.map(replaceNode) : [],
    };
  };

  return initialData.map(replaceNode);
};
