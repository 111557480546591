import { t } from 'tools/i18n';

import { ColumnsType, Flex, Icon, Typography } from 'gazprom-ui-lib';

import Popover from 'components/popover';
import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { SuggestionDecisionType, SuggestionType } from 'types/suggestion.types';

import s from './SuggestionsDecisions.module.scss';

const useColumns = (): ColumnsType<SuggestionDecisionType> => {
  const employeeRenderer = (suggestion: SuggestionType) => {
    const { firstName, lastName, patronymic } = suggestion?.employee?.person ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {firstName} {patronymic} {lastName}
      </Typography.Text>
    );
  };

  const suggestionNameRenderer = (suggestion: SuggestionType) => {
    const { name } = suggestion ?? {};

    return (
      <Typography.Text size="12" weight="500">
        {name}
      </Typography.Text>
    );
  };

  const organizationRenderer = (employee: SuggestionType['employee']) => {
    const { name } = employee?.employer ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  const statusRenderer = (status: SuggestionDecisionType['status']) => {
    return <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>;
  };
  const realizationRenderer = (suggestion: SuggestionType) => {
    let content = null;
    switch (suggestion.realizationStatus.state) {
      case 'FINISH':
        content = <Icon name="okFilled" color="var(--color-success)" size={18} />;
        break;
      case 'REFUSE':
        content = <Icon name="deleteFilled" color="var(--color-error)" size={18} />;
        break;
      case 'IN_PROGRESS':
        content = (
          <Icon
            name="loading"
            color="var(--color-character-secondary-45)"
            size={18}
            className={s.loader}
          />
        );
        break;
    }

    return (
      <Flex fullWidth justify="center">
        <Popover content={suggestion?.realizationStatus?.statusName}>
          <div>{content}</div>
        </Popover>
      </Flex>
    );
  };

  const dateRenderer = (date: SuggestionDecisionType['createdAt']) => {
    return <TableDate date={date} />;
  };

  const numberRenderer = (suggestion: SuggestionType) => {
    return suggestion.number;
  };

  return [
    {
      title: t('common_number'),
      dataIndex: 'suggestion',
      render: numberRenderer,
    },
    {
      title: t('common_employee'),
      dataIndex: 'suggestion',
      render: employeeRenderer,
    },
    {
      title: t('common_employee_organization'),
      dataIndex: 'employee',
      render: organizationRenderer,
    },
    {
      title: t('suggestion_name'),
      dataIndex: 'suggestion',
      render: suggestionNameRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      render: statusRenderer,
    },
    {
      align: 'center',
      title: t('common_realization'),
      dataIndex: 'suggestion',
      render: realizationRenderer,
    },
    {
      align: 'center',
      title: t('common_created'),
      dataIndex: 'createdAt',
      render: dateRenderer,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
