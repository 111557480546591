import { t } from 'tools/i18n';

import { IconProps } from 'gazprom-ui-lib';

import { USER_STATUS_ENUM } from 'types/person.types';

const USER_STATUS_MAP: Record<USER_STATUS_ENUM, { icon: IconProps['name']; text: string }> = {
  USER: {
    icon: 'user',
    text: t('common_user'),
  },
  RECRUITED: {
    icon: 'user',
    text: t('common_employed'),
  },
  PRE_USER: {
    icon: 'groupAdd',
    text: t('common_candidate'),
  },
  FIRED: {
    icon: 'deleteFilled',
    text: t('common_fired'),
  },
  UNKNOWN: {
    icon: 'question',
    text: t('common_undefined'),
  },
};

export const getUserStatusTagConfig = (status: USER_STATUS_ENUM | null | undefined) =>
  USER_STATUS_MAP[status ?? 'UNKNOWN'];
