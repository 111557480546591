import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from '../notificationTemplateForm.utils';

interface Props {
  title: string;
}

const NotificationTemplateFormTitle = (props: Props) => {
  const { title } = props;

  return (
    <div>
      <Typography.Text strong size="16">
        {title}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={NOTIFICATION_TEMPLATE_FORM_ENUM.TITLE}
        rules={[formRules.required, { max: 240 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={240} showCount />
      </Form.Item>
    </div>
  );
};

export default NotificationTemplateFormTitle;
