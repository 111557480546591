const SURVEYS_BY_ID_SLUG = ':id/:anketologId/:revisionId';

export const SURVEYS_ROUTE = `/surveys`;

export const SURVEYS_SETTLEMENT_ROUTE = `${SURVEYS_ROUTE}/settlement`;
export const SURVEYS_SETTLEMENT_ADD_ROUTE = `${SURVEYS_SETTLEMENT_ROUTE}/add`;
export const SURVEYS_SETTLEMENT_BY_ID_ROUTE = `${SURVEYS_SETTLEMENT_ROUTE}/${SURVEYS_BY_ID_SLUG}`;
export const SURVEYS_SETTLEMENT_BY_ID_GIFT_ROUTE = `${SURVEYS_SETTLEMENT_BY_ID_ROUTE}/gift/:giftId`;

export const SURVEYS_ONCE_ROUTE = `${SURVEYS_ROUTE}/once`;
export const SURVEYS_ADD_ONCE_ROUTE = `${SURVEYS_ONCE_ROUTE}/add`;
export const SURVEYS_ONCE_BY_ID_ROUTE = `${SURVEYS_ONCE_ROUTE}/${SURVEYS_BY_ID_SLUG}`;

export const SURVEYS_EVENT_ROUTE = `${SURVEYS_ROUTE}/event`;
export const SURVEYS_ADD_EVENT_ROUTE = `${SURVEYS_EVENT_ROUTE}/add`;
export const SURVEYS_EVENT_BY_ID_ROUTE = `${SURVEYS_EVENT_ROUTE}/${SURVEYS_BY_ID_SLUG}`;

export const SURVEYS_ANKETA_IMPORT_ROUTE = `${SURVEYS_ROUTE}/import`;

export const getSurveyOnceByIdRoute = (
  id: string | number,
  anketologId: string | number,
  revisionId: string | number,
) => {
  return `${SURVEYS_ONCE_ROUTE}/${id}/${anketologId}/${revisionId}`;
};

export const getSurveyEventByIdRoute = (
  id: string | number,
  anketologId: string | number,
  revisionId: string | number,
) => {
  return `${SURVEYS_EVENT_ROUTE}/${id}/${anketologId}/${revisionId}`;
};

export const getSurveySettlementByIdRoute = (
  id: string | number,
  anketologId: string | number,
  revisionId: string | number,
) => `${SURVEYS_SETTLEMENT_ROUTE}/${id}/${anketologId}/${revisionId}`;

export const getSurveySettlementByIdGiftRoute = (
  id: string | number,
  anketologId: string | number,
  revisionId: string | number,
  giftId: string,
) => `${SURVEYS_SETTLEMENT_ROUTE}/${id}/${anketologId}/${revisionId}/gift/${giftId}`;
