import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';
import WithLoader from 'containers/wrappers/with-loader';

import { TableFormFiltersType } from '../../shiftChangeMedexamRequests.utils';
import { useFilterOptions } from './useFilterOptions.hook';

const Filters = () => {
  const { filterOptions, isLoadingStatuses } = useFilterOptions();
  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search placeholder={t('common_search_by_name_placeholder')} />
      </Flex>

      <FiltersContainer<TableFormFiltersType>
        showClearAll
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('presents_delivery_type')}</FilterHeader>
            <WithLoader isLoading={isLoadingStatuses}>
              <FilterCheckboxGroup usePadding={false} valueKey="status" options={filterOptions} />
            </WithLoader>
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
