import { PieConfig } from '@ant-design/charts';
import { SorterResult } from 'antd/lib/table/interface';

import { MappedFreeStatsResponseType } from 'services/surveys/surveysApiService.types';

import { TableProps } from 'gazprom-ui-lib';

import { PaginationPropsType } from './pagination.types';

export enum SURVEY_CONDITIONS {
  ONCE = 'ONCE',
  SHIFT_END = 'SHIFT_END',
  NDAYS = 'NDAYS',
  FIRSTDAY = 'FIRSTDAY',
}

export type SurveyType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  name: string;
  anketologId: string;
  revisionId: number;
  isMandatory: boolean;
  condition: SURVEY_CONDITIONS;
  active: boolean;
  receivers: ReceiversType[] | [];
  isMain: boolean;
  status: SURVEY_STATUSES_ENUM | null;
  repeatNotificationIntervalDays?: number;
  daysCount?: number;
  sendAllAtFirstDayOfMonth: boolean;
};

export enum RECEIVERS_ENUM {
  EMPLOYEE_GROUP = 'EMPLOYEE_GROUP',
  EMPLOYER = 'EMPLOYER',
}

export type ReceiversType = {
  id: string;
  receiverType: RECEIVERS_ENUM;
  receivers: {
    id: string;
    name: string;
  }[];
};

export enum SURVEY_ACTIVE_ENUM {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export enum SURVEY_STATUSES_ENUM {
  FINISHED = 'FINISHED',
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED',
}

export type SurveyStatusType = {
  chronoUnit: string | null;
  linkedCount: number | null;
  startedAt: string | null;
  status: SURVEY_STATUSES_ENUM | null;
  timePassed: number | null;
  totalCount: number | null;
};

export type SurveysListInfo = { anketologId: number; revisionId: number; name: string };

export type SurveysListOptions = { value: string; label: string };

export type EmployeeGroup = {
  id: string;
  title: string;
  description: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  creator: {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
  };
  editor: {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
  };
};

export type NewSurveyFinishFormType = {
  anketologId: string;
  condition: SURVEY_CONDITIONS;
  isMandatory: boolean;
  name: string;
  revision_id: number;
  receivers: string[];
  organization: string[];
  daysCount?: string;
  repeatNotificationIntervalDays?: string;
};

export type RecieverRequestType = {
  receiverType: RECEIVERS_ENUM.EMPLOYEE_GROUP;
  receivers: { id: string; name: string }[];
};

export type NewSurveyType = {
  name: string;
  anketologId: string;
  revisionId: number;
  condition: SURVEY_CONDITIONS;
  isMandatory: boolean;
  receivers?: RecieverRequestType[];
  active?: boolean;
  daysCount?: number;
  repeatNotificationIntervalDays?: number;
};

export type QuestionStatsType = MatrixType | SelectType | FreeType | ScaleType;
export type MappedQuestionStatType = MatrixType | SelectType | ScaleType | MappedFreeQuestionType;

export type OptionType = {
  optionId: string | number;
  type: string;
  value: number;
};

export type CustomPieConfig = Omit<PieConfig, 'data'>;

export type RowType = {
  questionId: number;
  title: string;
  type: string;
  options: OptionType[];
};

type CommonQuestionType = {
  hasFreeAnswers: boolean;
  freeQuestionId: number | null;
  questionId: number;
  title: string;
};

export type MatrixType = {
  rows: RowType[];
  type: 'matrix';
} & CommonQuestionType;

export type SelectType = {
  options: OptionType[];
  type: 'select';
} & CommonQuestionType;

export type FreeType = {
  type: 'free';
} & CommonQuestionType;

export type ScaleType = {
  type: 'scale';
  options: OptionType[];
} & CommonQuestionType;

export type FreeTableData = {
  answerText: string;
  employeeId: string;
  employeeName: string;
  employerName: string;
  positionName: string;
};

export type MappedReceivers = { EMPLOYEE_GROUP: string[]; EMPLOYER: string[] };

export type HandlerParamsType = {
  optionId: string | number;
  questionId: number;
  type: string;
  value: number;
};

export type QoaParamType = { qId: string | number; oId?: string | number; aT?: string };

export type RequestBodyType = {
  staff: string | null;
  company: string | null;
  division: string | null;
  from?: string;
  to?: string;
  options?: string;
};

export type RespondentType = {
  anketaId: string;
  anketaLinkEmployee: string;
  fio: string;
  employer: {
    id: string;
    name: string;
  };
  division: {
    id: string;
    name: string;
  };
  position: {
    id: string;
    name: string;
  };
  completedDate: string;
};

export type HeatMapDataType = {
  label: string;
  answer: string;
  value: number;
};

export type MappedFreeQuestionType = FreeType & MappedFreeStatsResponseType;

export type FreeAnswersRequestBodyType = PaginationPropsType & {
  search?: string;
  from?: string;
  to?: string;
  qoa?: string;
};

export type FreeTableSorterBody = {
  answerText: string;
  employeeId: string;
  preson: {
    firstName: string;
    department: string;
    position: string;
  };
};
type OnChangeType = NonNullable<TableProps<MappedFreeStatsResponseType>['onChange']>;
type AllOnChangeParamsType = Parameters<OnChangeType>;
export type FreeTableFiltersType = AllOnChangeParamsType['1'];
export type FreeTableSorterType =
  | SorterResult<FreeTableSorterBody>
  | SorterResult<FreeTableSorterBody>[];

enum COMMUNITY_SETTLEMENT_SURVEY_ENUM {
  SETTLEMENT = 'SETTLEMENT',
  COMMUNITY = 'COMMUNITY',
}

export type SurveySettlementType = {
  id: string; // UUID - Уникальный идентификатор анкеты
  name: string; // Название анкеты
  anketologId: string; // Id анкеты в анкетологе
  revisionId: number; // Id ревизии в анкетологе
  isPublish: boolean; // Состояние публикации
  creator: string; // UUID - Идентификатор создателя анкеты
  editor: string; // UUID - Идентификатор редактора анкеты
  createdAt: string; // Date-Time - Дата и время создания анкеты
  updatedAt: string; // Date-Time - Дата и время последнего обновления анкеты
  count: number; // Количество сотрудников, которые прошли анкету до конца
  type: COMMUNITY_SETTLEMENT_SURVEY_ENUM; // Тип анкеты (Enum)
  publishDate: string; // Date-Time - Дата публикации анкеты
  lastNotificationDate: string; // Date-Time - Дата последнего уведомления о анкете
  notificationInterval: number; // Интервал уведомлений (в днях)
  settlements?: { id: string; name: string }[];
  updateSettings: string;
  reAssignment: string;
  reAssignmentSettings: string;
  reAssignmentDateTo: string;
  isPlayGift: boolean;
  winnersCount: number;
};

export type SurveySettlementCreateOrUpdateType = {
  name: string;
  anketologId: string;
  revisionId: number;
  isPublish: boolean;
  notificationInterval?: number;
  settlementIds: string[];
  updateSettings: string;
  reAssignment: string;
  reAssignmentSettings?: string;
  reAssignmentDateTo?: string;
  isPlayGift?: boolean;
  winnersCount?: number;
};

export type SettlementSurveyHistoryAssigment = {
  id: string;
  publishDate: string;
  unpublishDate: string;
  notificationPackageId: string;
  isLotteried: boolean;
  settlementCount: number;
  lotteryEntriesCount: number;
};

export type UploadedShiftAnswerFile = {
  id: string;
  fileName: string;
  uploadedAt: string;
  personId: string;
  personLastName: string;
  personFirstName: string;
  personMiddleName: string;
  employeeId: string;
  employerId: string;
  employerName: string;
};
