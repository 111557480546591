import { t } from 'tools/i18n';

import { Flex, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import TableDate from 'components/table/table-date';

import { ArticleType } from 'types/article.types';

const useColumns = (): TableProps<ArticleType>['columns'] => {
  const nameRenderer = (_: unknown, row: ArticleType) => {
    const { cover, title } = row;
    return (
      <Flex gap="12" align="center">
        <Cover src={cover?.link} alt="event cover" />
        <Typography.Text size="12" weight="500">
          {title}
        </Typography.Text>
      </Flex>
    );
  };

  const isPublishRenderer = (isPublish: ArticleType['isPublish']) => (
    <Icon
      name="okFilled"
      color={`var(${isPublish ? '--color-success' : '--color-placeholder-25'})`}
    />
  );

  const updatedAtRenderer = (date: ArticleType['updatedAt'] | ArticleType['createdAt']) => (
    <TableDate date={date} />
  );

  const settlementRenderer = (settlements: ArticleType['settlements']) => {
    const settlementsNames = settlements?.map(({ id, name }, index, array) => {
      const isNeedComma = index < array.length - 1;

      return (
        <Typography.Text size="12" weight="500" type="secondary" key={id}>
          {name}
          {isNeedComma && ', '}
        </Typography.Text>
      );
    });

    return (
      <Flex gap="8" wrap="wrap">
        {settlementsNames}
      </Flex>
    );
  };

  return [
    {
      title: t('news_news'),
      dataIndex: 'title',
      sorter: true,
      render: nameRenderer,
    },
    {
      title: t('common_settlement'),
      dataIndex: 'settlements',
      render: settlementRenderer,
    },
    {
      title: t('common_publication'),
      align: 'center',
      width: '80px',
      dataIndex: 'isPublish',
      render: isPublishRenderer,
    },
    {
      title: t('common_updated'),
      sorter: true,
      align: 'center',
      dataIndex: 'updatedAt',
      render: updatedAtRenderer,
      defaultSortOrder: 'descend',
      width: 160,
    },
    {
      title: t('common_created'),
      sorter: true,
      align: 'center' as const,
      dataIndex: 'createdAt',
      render: updatedAtRenderer,
      width: 160,
    },
  ];
};

export default useColumns;
