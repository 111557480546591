import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateArticleCategoryMutation,
  useGetNewsCategoriesQuery,
} from 'services/category/categoryApiService';
import {
  CreateArticleCategoryPropsType,
  GetNewsCategoriesResponseType,
} from 'services/category/categoryApiService.types';

import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  SelectProps,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { NewsCategoryType } from 'types/news.types';

import { EVENT_FORM_ENUM, FORM_LABELS, MAX_CATEGORIES_PER_PAGE } from '../../../eventForm.utils';
import s from './Categories.module.scss';

const Categories = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const parentForm = Form.useFormInstance();

  const [createArticleCategory, { isLoading: createArticleIsLoading }] =
    useCreateArticleCategoryMutation();
  const { categoriesOptions, isFetching } = useGetNewsCategoriesQuery(
    { page: 1, size: MAX_CATEGORIES_PER_PAGE, visible: true, type: 'meet' },
    {
      selectFromResult,
    },
  );

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleCategoryCreate = async () => {
    const alreadySelectedCategories = parentForm.getFieldValue(EVENT_FORM_ENUM.CATEGORIES) ?? [];
    const newCategoryName = form.getFieldValue(NEW_CATEGORY_NAME);
    const createArticleCategoryProps: CreateArticleCategoryPropsType = {
      name: newCategoryName,
      type: 'meet',
      visible: true,
    };

    const onSuccess = (data?: NewsCategoryType) => {
      if (data) {
        parentForm.setFieldValue(
          EVENT_FORM_ENUM.CATEGORIES,
          alreadySelectedCategories.concat([data.id]),
        );
        handleModalOpenClose();
        form.resetFields([NEW_CATEGORY_NAME]);
      }
    };

    const onError = () => {
      form.setFields([
        {
          name: NEW_CATEGORY_NAME,
          errors: [t('errors_category_name_exist')],
        },
      ]);
    };

    createArticleCategory(createArticleCategoryProps).then(
      handleRequest({
        onSuccess,
        onError,
      }),
    );
  };

  const handleCheckValidation = () => form.validateFields().then(handleCategoryCreate);

  const filterOptions: SelectProps['filterOption'] = (search, option) => {
    return JSON.stringify(option)?.toLowerCase().includes(search?.toLowerCase());
  };

  return (
    <>
      <div className={s.wrapper}>
        <Form.Item
          name={EVENT_FORM_ENUM.CATEGORIES}
          rules={[formRules.required]}
          label={FORM_LABELS[EVENT_FORM_ENUM.CATEGORIES]}>
          <Select
            mode="multiple"
            placeholder={t('common_choose_category_from_list_or_add')}
            loading={isFetching}
            options={categoriesOptions}
            filterOption={filterOptions}
          />
        </Form.Item>
        <button type="button" className={s.modalOpenButton} onClick={handleModalOpenClose}>
          <Icon name="plus" />
          <Typography.Text size="14" weight="500">
            {t('common_add_category')}
          </Typography.Text>
        </button>
      </div>
      <Modal open={isModalOpen} onCancel={handleModalOpenClose} closable>
        <Form form={form}>
          <Form.Item
            label={t('common_add_category')}
            normalize={(e: string) => e.trimStart()}
            name={NEW_CATEGORY_NAME}
            className={s.categoryInput}
            rules={[formRules.required]}>
            <Input placeholder={t('common_enter_category_name')} />
          </Form.Item>
          <div className={s.actions}>
            <Button htmlType="button" type="secondary" onClick={handleModalOpenClose}>
              {t('common_close')}
            </Button>
            <Button
              htmlType="button"
              onClick={handleCheckValidation}
              loading={createArticleIsLoading}>
              {t('common_save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const NEW_CATEGORY_NAME = 'NEW_CATEGORY_NAME';

const selectFromResult = ({
  data,
  ...props
}: {
  data?: GetNewsCategoriesResponseType;
  isFetching: boolean;
}) => ({
  categoriesOptions: data?.items.map(({ id, name }) => ({ value: id, label: name, key: id })) ?? [],
  ...props,
});

export default Categories;
