import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useMassInviteMutation,
  useMassSubscribeMutation,
} from 'services/communities/communitiesApiService';

import { FormInstance, useHandleRequestWithModal } from 'gazprom-ui-lib';

import {
  MassSubscribeInviteFormResponse,
  prepareMassInviteSubscribePayload,
} from 'containers/mass-subscribe-invite-form/massSubscribeInviteForm.utils';

interface Props {
  isMassSubscribePage: boolean;
  closeConfirmModal: () => void;
  form: FormInstance;
}

export const useHandleFinish = (props: Props) => {
  const { isMassSubscribePage, closeConfirmModal, form } = props;

  const { id: communityId } = useParams<{ id: string }>() as { id: string };

  const [massSubscribe, { isLoading: isSubscribing }] = useMassSubscribeMutation();
  const [massInvite, { isLoading: isInviting }] = useMassInviteMutation();

  const handleResponse = useHandleRequestWithModal({
    onSuccess: () => {
      form.resetFields();
      closeConfirmModal();
    },
    callOnSuccessWithoutData: true,
    onErrorModalConfig: {
      title: {
        children: t('common_error'),
      },
      description: {
        children: t(
          isMassSubscribePage ? 'community_mass_subscribe_error' : 'community_mass_invite_error',
        ),
      },
      icon: {
        name: 'error',
        type: 'warning',
      },
    },
  });

  const handleFinish = (payload: MassSubscribeInviteFormResponse) => {
    const preparedPayload = prepareMassInviteSubscribePayload(payload);
    const request = isMassSubscribePage ? massSubscribe : massInvite;

    request({ communityId, payload: preparedPayload }).then(handleResponse);
  };

  const isSubscribingOrInviting = isSubscribing || isInviting;

  return { handleFinish, isSubscribingOrInviting };
};
