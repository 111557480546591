import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Trans, t } from 'tools/i18n';

import { Avatar, Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import Popover from 'components/popover';

import { DOCUMENT_VIEW_PERSON_ENUM } from 'types/documents.types';

import s from './DocumentsViewPerson.module.scss';

interface DocumentsStatementsViewFilesPersonProps {
  firstName?: string;
  lastName?: string;
  position?: string | null;
  icon?: TIconsLiteral;
  type?: DOCUMENT_VIEW_PERSON_ENUM;
  button?: ReactNode;
  isMe?: boolean;
  date?: string;
  employeeId: string | null;
}

const DocumentsViewPerson: FC<DocumentsStatementsViewFilesPersonProps> = (props) => {
  const {
    firstName = '',
    lastName = '',
    position,
    button,
    type,
    icon,
    isMe,
    date,
    employeeId,
  } = props;
  const shouldShowBadge = icon && type;
  const i18nCurrentKey = isMe ? 'documents_statements_you' : 'documents_statements_person';

  const formattedDate = dayjs.utc(date).local().format('DD.MM.YYYY, HH:mm');

  const popoverContent = (
    <Typography.Text className={s.popoverContent}>
      {`${t(
        type === DOCUMENT_VIEW_PERSON_ENUM.SUCCESS ? 'common_agreed' : 'thank_you_declined',
      )}: ${formattedDate}`}
    </Typography.Text>
  );

  return (
    <div className={s.container}>
      <div className={s.avatarContainer}>
        <div className={s.avatarBadge}>
          <Avatar
            firstName={employeeId ? firstName : '–'}
            lastName={employeeId ? lastName : ''}
            alt="аватар пользователя"
            size="40"
            className={s.avatar}
          />
          {shouldShowBadge && (
            <Popover content={date && popoverContent}>
              <div className={classNames(s.badgeBackgound, s[type as keyof typeof s])}>
                <Icon name={icon} size={10} className={s.badge} />
              </div>
            </Popover>
          )}
        </div>
        <div className={s.info}>
          <Trans
            i18nKey={i18nCurrentKey}
            values={{ firstName: firstName || t('common_person_is_not_defined'), lastName }}
            className={s.name}>
            <Typography.Text size="14" weight="500" />
          </Trans>
          {position && (
            <Typography.Text size="12" type="secondary" className={s.position} title={position}>
              {position}
            </Typography.Text>
          )}
        </div>
      </div>
      {button}
    </div>
  );
};

export default DocumentsViewPerson;
