import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, Icon, TextArea, Typography } from 'gazprom-ui-lib';

import { USER_AGREEMENT_ENUM } from '../userAgreementForm.utils';
import s from './FormDescription.module.scss';

const FormDescription = () => {
  return (
    <>
      <Typography.Text strong size="16">
        {t('user_agreement_text_for_users')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={USER_AGREEMENT_ENUM.DESCRIPTION}
        rules={[formRules.required, { max: 250 }]}
        className={s.item}>
        <TextArea placeholder={t('common_enter_text')} maxLength={250} showCount />
      </Form.Item>
      <Flex gap="4" align="start" className={s.info}>
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">
          {t('user_agreement_visible_mobile_only')}
        </Typography.Text>
      </Flex>
    </>
  );
};

export default FormDescription;
