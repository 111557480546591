import { t } from 'tools/i18n';

import { UpdateEmployerSettingsPropsType } from 'services/employer-settings/employerSettingsApiService.types';

export const SETTINGS_EMPLOYER_GROUP = 'SETTINGS_EMPLOYER_GROUP';

export enum SETTINGS_EMPLOYER_FORM_ENUM {
  ACTUAL_NAME_CODE = 'ACTUAL_NAME_CODE',
  SIGN_ENABLED = 'SIGN_ENABLED',
  CAN_ISSUE_CERT = 'CAN_ISSUE_CERT',
  TIMEZONE = 'TIMEZONE',
}

export interface SettingsEmployerFormResponse {
  [SETTINGS_EMPLOYER_FORM_ENUM.ACTUAL_NAME_CODE]: UpdateEmployerSettingsPropsType['employerNameCode'];
  [SETTINGS_EMPLOYER_FORM_ENUM.SIGN_ENABLED]: boolean;
  [SETTINGS_EMPLOYER_FORM_ENUM.CAN_ISSUE_CERT]: boolean;
  [SETTINGS_EMPLOYER_FORM_ENUM.TIMEZONE]: string;
}

export const DEFAULT_TIMEZONE = '03:00:00';

export const timezoneOptions = Array.from({ length: 11 }, (_, i) => {
  const offset = i + 2;
  const value = offset.toString().padStart(2, '0');
  return {
    label: `${t('common_timezone_msk')} +${offset}`,
    value: `${value}:00:00`,
  };
});
