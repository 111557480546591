import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { UploadedShiftAnswerFile } from 'types/surveys.types';

import { TableFormResponseType } from '../../surveyAnketaImport.utils';
import { useColumns } from './useColumns.hook';

interface Props {
  dataSource?: UploadedShiftAnswerFile[];
  loading?: boolean;
  total?: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
}

const Table = (props: Props) => {
  const locale = {
    emptyText: () => (
      <Empty
        title={`${
          props.hasFiltersApplied ? 'common_no_records_found' : 'surveys_anketa_import_empty'
        }`}
        description={`${
          props.hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''
        }`}
      />
    ),
  };

  const columns = useColumns();

  return (
    <FiltersV2.Table<UploadedShiftAnswerFile, TableFormResponseType>
      rowKey="id"
      locale={locale}
      columns={columns}
      {...props}
    />
  );
};

export default Table;
