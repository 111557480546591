import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateNode } from 'utils/treeNode.utils';

import { EMPLOYEE_GROUPS_FILTER_ENUM } from 'services/employee-groups-new/employeeGroupsNewApiService.types';

import { DataNode } from 'gazprom-ui-lib';

export const SLICE_NAME = 'employeeGroupsSlice';
export interface EmployeeGroupsSlice {
  employerTree: DataNode[];
  checkedNodes: string[];
  partialChecked: string[];
  employees: string[];
  isChanged: boolean;
  isSsoRegistered: EMPLOYEE_GROUPS_FILTER_ENUM;
  linkSettlement: EMPLOYEE_GROUPS_FILTER_ENUM;
  shiftWorker: EMPLOYEE_GROUPS_FILTER_ENUM;
}

const initialState: EmployeeGroupsSlice = {
  employerTree: [],
  checkedNodes: [],
  partialChecked: [],
  employees: [],
  isChanged: false,
  isSsoRegistered: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
  linkSettlement: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
  shiftWorker: EMPLOYEE_GROUPS_FILTER_ENUM.NOT_SET,
};

export const employeeGroupsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setDefaultData(state, action) {
      const newState = {
        ...state,
        employerTree: action.payload.tree,
        employees: action.payload.employees,
        checkedNodes: action.payload.checkedNodes,
        partialChecked: action.payload.partialChecked,
        isSsoRegistered: action.payload.isSsoRegistered,
        linkSettlement: action.payload.linkSettlement,
        shiftWorker: action.payload.shiftWorker,
      };
      return newState;
    },
    setFlag<K extends keyof EmployeeGroupsSlice>(
      state: EmployeeGroupsSlice,
      action: PayloadAction<{ flag: K; value: EmployeeGroupsSlice[K] }>,
    ) {
      state[action.payload.flag] = action.payload.value;
    },
    setEmployerTree(state, action) {
      state.employerTree = action.payload;
    },
    updateEmployerTree(
      state,
      { payload: { key, children } }: PayloadAction<{ key: React.Key; children: DataNode[] }>,
    ) {
      state.employerTree = updateNode(state.employerTree, key, children);
    },
    setCheckedNodes(
      state,
      action: PayloadAction<{ checkedNodes: string[]; partialChecked: string[] }>,
    ) {
      state.checkedNodes = action.payload.checkedNodes;
      state.partialChecked = action.payload.partialChecked;
      state.isChanged = true;
    },
    setEmployees(state, action: PayloadAction<string[]>) {
      state.employees = action.payload;
      state.isChanged = true;
    },
    setIsChanged(state, action) {
      state.isChanged = action.payload;
    },
    setInitialState() {
      return initialState;
    },
  },
});

export const {
  setEmployerTree,
  updateEmployerTree,
  setCheckedNodes,
  setEmployees,
  setDefaultData,
  setInitialState,
  setIsChanged,
  setFlag,
} = employeeGroupsSlice.actions;

export default employeeGroupsSlice.reducer;
