import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Button, Divider, Flex, Form, Input, Select, Switch, Typography } from 'gazprom-ui-lib';

import { CInstanceType } from 'types/cInstances.types';

import s from './cInstanceForm.module.scss';
import {
  CInstanceFormResponse,
  C_INSTANCE_FORM_ENUM,
  C_INSTANCE_FORM_NAME,
  DEFAULT_PASSWORD_VALUE,
  STRUCTURE_TYPE_OPTIONS,
} from './cInstanceForm.utils';
import SetPasswordModal from './containers/set-password-modal';

interface CInstanceFormProps {
  onSubmit: (form: CInstanceFormResponse) => void;
  cInstance?: CInstanceType;
  isShiftType?: boolean;
}

const CInstanceForm: FC<CInstanceFormProps> = (props) => {
  const { onSubmit, cInstance, isShiftType } = props;

  const [passwordFieldToSet, setPasswordFieldToSet] = useState<null | C_INSTANCE_FORM_ENUM>(null);

  const [form] = Form.useForm();
  const useConfigSettings = Form.useWatch(C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS, form);

  useEffect(() => {
    if (cInstance) {
      form.setFieldsValue({
        [C_INSTANCE_FORM_ENUM.NAME]: cInstance.name,
        [C_INSTANCE_FORM_ENUM.URL]: cInstance.url,
        [C_INSTANCE_FORM_ENUM.LOGIN]: cInstance.login,
        [C_INSTANCE_FORM_ENUM.PASSWORD]: DEFAULT_PASSWORD_VALUE,
        [C_INSTANCE_FORM_ENUM.ACTIVE]: cInstance.active,
        [C_INSTANCE_FORM_ENUM.STRUCTURE_TYPE]: cInstance.structureType,
        [C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS]: cInstance.authLegacy,
        [C_INSTANCE_FORM_ENUM.CONFIG_LOGIN]: cInstance.authUsername,
        [C_INSTANCE_FORM_ENUM.CONFIG_PASSWORD]: DEFAULT_PASSWORD_VALUE,
      });
    } else {
      form.setFieldValue(C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS, true);
    }
  }, [form, cInstance]);

  const handleClosePasswordSetModal = () => {
    setPasswordFieldToSet(null);
  };

  return (
    <Form className={s.wrapper} form={form} name={C_INSTANCE_FORM_NAME} onFinish={onSubmit}>
      <Form.Item
        label={t('1c_instance_base_name')}
        normalize={normalizeTextField}
        name={C_INSTANCE_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}>
        <Input placeholder={t('common_enter_text')} maxLength={80} />
      </Form.Item>

      {!isShiftType && (
        <Flex gap="8" align="center" className={s.switchFormItem}>
          <Form.Item name={C_INSTANCE_FORM_ENUM.ACTIVE} valuePropName="checked">
            <Switch />
          </Form.Item>
          {t('1c_schedule_synchronization')}
        </Flex>
      )}

      {!isShiftType && (
        <Form.Item
          name={C_INSTANCE_FORM_ENUM.STRUCTURE_TYPE}
          label={t('1c_structure_type')}
          rules={[formRules.required]}>
          <Select placeholder={t('common_select_second')} options={STRUCTURE_TYPE_OPTIONS} />
        </Form.Item>
      )}

      <Flex vertical gap="16" className={s.divider}>
        <Divider />

        <Typography.Text size="18" weight="500">
          {t('1c_instance_url_address_settings')}
        </Typography.Text>
      </Flex>

      <Form.Item
        label={t('common_url')}
        normalize={normalizeTextField}
        name={C_INSTANCE_FORM_ENUM.URL}
        rules={[formRules.required]}>
        <Input placeholder={t('common_url_placeholder')} />
      </Form.Item>

      <Form.Item
        label={t('common_login')}
        normalize={normalizeTextField}
        name={C_INSTANCE_FORM_ENUM.LOGIN}
        rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>

      <Form.Item
        label={t('common_password')}
        normalize={normalizeTextField}
        name={C_INSTANCE_FORM_ENUM.PASSWORD}
        rules={[formRules.required]}>
        <Input.Password autoComplete="new-password" placeholder={t('common_enter_text')} />
      </Form.Item>

      <Flex vertical gap="16" className={s.divider}>
        <Divider />

        <Typography.Text size="18" weight="500">
          {t('1c_instance_input_url_address_settings')}
        </Typography.Text>
      </Flex>

      <Flex gap="8" align="center" className={s.switchFormItem}>
        <Form.Item name={C_INSTANCE_FORM_ENUM.USE_CONFIG_SETTINGS} valuePropName="checked">
          <Switch defaultChecked />
        </Form.Item>
        {t('1c_instance_use_config_settings')}
      </Flex>

      <Form.Item
        label={t('common_login')}
        normalize={normalizeTextField}
        name={C_INSTANCE_FORM_ENUM.CONFIG_LOGIN}
        rules={useConfigSettings ? [] : [formRules.required, { max: 80 }]}>
        <Input disabled={useConfigSettings} placeholder={t('common_enter_text')} maxLength={80} />
      </Form.Item>

      <Form.Item
        name={C_INSTANCE_FORM_ENUM.CONFIG_PASSWORD}
        label={t('common_password')}
        rules={useConfigSettings ? [] : [formRules.required]}>
        <Input.Password
          readOnly={!!cInstance}
          disabled={useConfigSettings || !!cInstance}
          placeholder={t('common_enter_text')}
        />
      </Form.Item>

      {cInstance && (
        <Button
          disabled={useConfigSettings}
          type="link"
          leftIcon="passwordHorizontal"
          onClick={() => setPasswordFieldToSet(C_INSTANCE_FORM_ENUM.CONFIG_PASSWORD)}>
          {t('common_set_password')}
        </Button>
      )}

      <SetPasswordModal field={passwordFieldToSet} onClose={handleClosePasswordSetModal} />
    </Form>
  );
};

export default CInstanceForm;
