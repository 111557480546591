import { RouteConfig } from 'routes/routes';

import ShiftChangeMedexamRequests from 'pages/shift-change/shift-change-medexam-requests';

import { SHIFT_CHANGE_MEDEXAM_REQUESTS_ROUTE } from './list';

export const shiftChangeRoutes: RouteConfig[] = [
  {
    path: SHIFT_CHANGE_MEDEXAM_REQUESTS_ROUTE,
    element: <ShiftChangeMedexamRequests />,
  },
];
