import dayjs from 'dayjs';
import { DOCUMENTS_REPLACEMENT_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';

import { ReplacementType } from 'types/replacement.types';

interface Props {
  replacement?: ReplacementType;
}

const Header = (props: Props) => {
  const { replacement } = props;
  const { createdAt } = replacement ?? {};

  const headerDescription = replacement && createdAt && (
    <>
      {`${t('common_created')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(createdAt).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  const { name, description, code, state } = replacement?.status ?? {};
  const currentStatusAsKedo = {
    state: state ?? '',
    status: code ?? '',
    title: name ?? '',
    titleAlt: name ?? '',
    description: description ?? '',
    comment: '',
    createdAt: '',
  };

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title description={headerDescription} goBackLink={DOCUMENTS_REPLACEMENT_ROUTE}>
        {t('replacement_view')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        {replacement && <KedoStatusTag size="30" currentKedoStatus={currentStatusAsKedo} />}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
