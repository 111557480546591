import { t } from 'tools/i18n';

import { CEmployerType } from 'services/1c/cApiService.types';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

const useColumns = (): ColumnsType<CEmployerType> => {
  return [
    {
      title: t('common_name'),
      dataIndex: 'actualNameCode',
      render: (codeName: CEmployerType['actualNameCode'], row: CEmployerType) => {
        let actualName = row.name;

        if (codeName === 'SHORT_NAME') {
          actualName = row.shortName;
        }

        if (codeName === 'FULL_NAME') {
          actualName = row.fullName;
        }

        return (
          <Typography.Text size="14" weight="500">
            {actualName ?? ''}
          </Typography.Text>
        );
      },
    },
  ];
};

export default useColumns;
