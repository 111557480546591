import cn from 'classnames';

import { Popover as PopoverLib, PopoverProps } from 'gazprom-ui-lib';

import s from './Popover.module.scss';

interface Props extends PopoverProps {}

const Popover = (props: Props) => {
  const { overlayClassName, ...otherProps } = props;

  return (
    <PopoverLib
      destroyTooltipOnHide
      overlayClassName={cn(s.wrapper, overlayClassName)}
      arrow={false}
      placement="rightTop"
      align={{ offset: [0, 20] }}
      trigger="hover"
      {...otherProps}
    />
  );
};

export default Popover;
