import { t } from 'i18next';

import { Form, Modal, TIconsLiteral } from 'gazprom-ui-lib';

import ModalBody from './components/modal-body';
import ModalButtons from './components/modal-buttons';
import ModalHeader from './components/modal-header';

interface Props {
  open: boolean;
  onClose: () => void;
  onFinish: (id: string) => void;
  initialValue?: string;
  actionButtonText?: string;
  actionButtonIcon?: TIconsLiteral;
  isLoading?: boolean;
}

const GuideContactsEditModalAdd = (props: Props) => {
  const [form] = Form.useForm();
  const { onClose, open, initialValue, actionButtonIcon, actionButtonText, isLoading, onFinish } =
    props;

  const handleFinish = (formRes: { text: string }) => {
    onFinish(formRes.text);
    form.resetFields();
  };

  const onCloseHandler = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal open={open} onCancel={onCloseHandler}>
      <ModalHeader text={t('guide_hot_contacts_modal_title')} onClose={onCloseHandler} />
      <Form form={form} onFinish={handleFinish} initialValues={{ text: initialValue }}>
        <ModalBody text={t('guide_hot_contacts_modal_modal_name')} />
        <ModalButtons
          onClose={onClose}
          actionButtonText={actionButtonText}
          actionButtonIcon={actionButtonIcon}
          loading={isLoading}
        />
      </Form>
    </Modal>
  );
};

export default GuideContactsEditModalAdd;
