import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import s from './ModalButtons.module.scss';

interface Props {
  onClose: () => void;
  disabled: boolean;
  loading: boolean;
}

const ModalButtons = (props: Props) => {
  const { onClose, disabled, loading } = props;

  return (
    <div className={s.buttons}>
      <Button
        type="primary"
        leftIcon="plus"
        disabled={disabled}
        htmlType="submit"
        loading={loading}>
        {t('common_add')}
      </Button>
      <Button type="secondary" onClick={onClose}>
        {t('common_close')}
      </Button>
    </div>
  );
};

export default ModalButtons;
