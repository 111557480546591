import { ShiftMedExamRequest } from 'gazprom-common-lib';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { TableFormResponseType } from '../../shiftChangeMedexamRequests.utils';
import s from './Table.module.scss';
import { useColumns } from './useColumns.hook';

interface Props {
  dataSource?: ShiftMedExamRequest[];
  loading?: boolean;
  totalCount?: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
  onOpenViewModal: (record: ShiftMedExamRequest) => void;
}

const Table = (props: Props) => {
  const { totalCount, onOpenViewModal, ...tableProps } = props;

  const columns = useColumns();

  const locale = {
    emptyText: () => (
      <Empty
        title={`${
          tableProps.hasFiltersApplied
            ? 'shift_change_medexam_requests_empty_with_filters'
            : 'shift_change_medexam_requests_empty'
        }`}
        description={`${
          tableProps.hasFiltersApplied
            ? 'shift_change_medexam_requests_empty_with_filters_description'
            : ''
        }`}
      />
    ),
  };

  const onRow = (record: ShiftMedExamRequest) => ({
    onClick: () => {
      onOpenViewModal(record);
    },
  });

  return (
    <FiltersV2.Table<ShiftMedExamRequest, TableFormResponseType>
      rowKey="id"
      className={s.table}
      columns={columns}
      onRow={onRow}
      locale={locale}
      total={totalCount}
      {...tableProps}
    />
  );
};

export default Table;
