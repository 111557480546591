import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetExpertCommissionByIdQuery } from 'services/expert-commissions/expertCommissionsApiService';
import { useGetSuggestionDecisionByIdQuery } from 'services/suggestion/suggestionApiService';

import { Flex, StepsProps, Tabs, TabsProps } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SuggestionsDecisionDetails.module.scss';
import Actions from './containers/actions';
import Commission from './containers/commission';
import Description from './containers/description';
import Header from './containers/header';
import Performer from './containers/performer';
import TextField from './containers/text-field';

const SuggestionDecisionDetails = () => {
  const params = useParams<{ id: string }>();

  const { data, isFetching } = useGetSuggestionDecisionByIdQuery(
    { id: params.id! },
    { skip: !params.id },
  );
  const {
    id: suggestionId,
    employee,
    category,
    name,
    statusHistory,
    commissionId,
    curStatus,
    performer,
    releaseReportFile,
  } = data?.suggestion ?? {};

  const { status, createdAt: decisionCreatedAt, executor } = data ?? {};

  const { data: commission } = useGetExpertCommissionByIdQuery(
    commissionId ? { id: commissionId } : skipToken,
  );

  const items = useMemo<TabsProps['items']>(() => {
    const tabs = [
      { label: t('common_description'), key: 'description', children: <Description /> },
    ];

    if (commission) {
      tabs.push({
        label: t('common_commission'),
        key: 'commission',
        children: <Commission {...commission} />,
      });
    }

    const showPerformerTab = performer && suggestionId;
    if (showPerformerTab) {
      tabs.push({
        label: t('common_realization'),
        key: 'performer',
        children: (
          <Performer
            releaseReportFile={releaseReportFile}
            performer={performer}
            suggestionId={suggestionId}
          />
        ),
      });
    }

    return tabs;
  }, [commission, performer, releaseReportFile, suggestionId]);

  return (
    <WithOverflow>
      <Header status={status} createdDate={decisionCreatedAt} />
      <WithLoader isLoading={isFetching}>
        <DocumentView>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              <DocumentView.Employee employee={employee} showAvatar />
              <TextField title={t('suggestion_category')} value={category?.name} />
              <TextField title={t('suggestion_name')} value={name} />
              {executor && (
                <DocumentView.Employee
                  showAvatar
                  title={t('suggestions_coordinator')}
                  employee={executor}
                  showEmployer={false}
                />
              )}
              <Tabs items={items} type="card" className={s.tabs} />
            </Flex>
            <DocumentView.History historyItems={statusHistory as StepsProps['items']} />
          </DocumentView.Content>
          <Actions suggestionId={suggestionId} curStatus={curStatus} />
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default SuggestionDecisionDetails;
