import { useNavigate } from 'react-router-dom';
import { getTelegramChannelWithIdRoute } from 'routes/settings/list';

import { ImportChannelPropsType } from 'services/import/importApiService.types';

import { Table as TableLib, TableProps } from 'gazprom-ui-lib';

import { TelegramChannelType } from 'types/telegramChannel.types';

import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  pagination?: TableProps<TelegramChannelType[]>['pagination'];
  telegramChannels?: TelegramChannelType[];
  isLoading: boolean;
  selectedRowKeys: Array<TelegramChannelType['id']>;
  onSelectedRowKeysChange: (value: Array<TelegramChannelType['id']>) => void;
  onChange: TableProps<TelegramChannelType>['onChange'];
  importIsLoading: boolean;
  handleStartImport: (links: ImportChannelPropsType) => Promise<void>;
}

const Table = (props: Props) => {
  const {
    onChange,
    pagination,
    telegramChannels,
    isLoading,
    selectedRowKeys,
    onSelectedRowKeysChange,
    importIsLoading,
    handleStartImport,
  } = props;
  const navigate = useNavigate();

  const columns = useColumns({
    handleStartImport,
    importIsLoading,
    selectedChannels: selectedRowKeys,
    channels: telegramChannels,
  });

  const onRow = ({ id }: { id: string }) => ({
    onClick: () => navigate(getTelegramChannelWithIdRoute(id)),
  });

  return (
    <TableLib
      className={s.table}
      rowSelection={{
        selectedRowKeys,
        onChange: (keys) => onSelectedRowKeysChange(keys as string[]),
      }}
      pagination={pagination}
      rowKey="id"
      columns={columns}
      dataSource={telegramChannels}
      loading={isLoading}
      onRow={onRow}
      onChange={onChange}
    />
  );
};

export default Table;
