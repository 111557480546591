import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

export const useShouldDisplayTemplateTab = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);

  // Если тип заявления не KEDO - всегда рендерим
  if (!data || data.type !== 'KEDO') return true;

  // Если тип KEDO, то рендерим, только если digitalFlowType APP
  return data.digitalFlowType === 'APP';
};
