import { SUGGESTIONS_DECISIONS_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';
import ContentHeader from 'containers/content-header';
import CreationInfo from 'containers/creation-info';

import { SuggestionType } from 'types/suggestion.types';

interface Props {
  createdDate?: string;
  updatedDate?: string;
  status?: SuggestionType['curStatus'];
}

const Header = (props: Props) => {
  const { createdDate, status } = props;

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title
        goBackLink={SUGGESTIONS_DECISIONS_ROUTE}
        description={
          <CreationInfo
            createdDate={createdDate}
            createdByLabel={t('common_coordinator')}
            createdBy=""
          />
        }>
        {t('suggestion_for_improvement')}
      </ContentHeader.Title>
      {!!status && (
        <ContentHeader.Actions>
          <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>
        </ContentHeader.Actions>
      )}
    </ContentHeader>
  );
};

export default Header;
