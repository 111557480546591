import { t } from 'tools/i18n';

import { Checkbox, Form, FormInstance, Typography } from 'gazprom-ui-lib';

import {
  NOTIFICATION_NEWSLETTER_FORM_ENUM,
  NotificationNewsletterFormResponse,
} from '../notificationNewsletterForm.utils';
import s from './NotificationNewsletterFormApp.module.scss';

interface Props {
  disabled?: boolean;
}

const NotificationNewsletterFormApp = (props: Props) => {
  const { disabled } = props;

  const form = Form.useFormInstance();

  const isFeedbackAvailable = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON, form);

  const shouldUpdate = (
    prevValues: Partial<NotificationNewsletterFormResponse>,
    currentValues: Partial<NotificationNewsletterFormResponse>,
  ) => {
    return (
      prevValues[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION] !==
      currentValues[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION]
    );
  };

  const normalizeFeedbackOn = (e: boolean) => {
    if (!e) {
      form.resetFields([NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED]);
    }

    return e;
  };

  const normalizeNotificationOn = (e: boolean) => {
    if (!e) {
      form.resetFields([
        NOTIFICATION_NEWSLETTER_FORM_ENUM.NOTIFICATION_TEMPLATE,
        NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON,
        NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED,
        NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED,
      ]);
    } else {
      form.setFields([
        { name: NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL, errors: [] },
        { name: NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION, errors: [] },
        { name: NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED, value: true },
      ]);
    }

    return e;
  };

  return (
    <>
      <Form.Item
        name={NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION}
        valuePropName="checked"
        normalize={normalizeNotificationOn}>
        <Checkbox disabled={disabled}>
          <Typography.Text size="14">{t('notification_inside_app')}</Typography.Text>
        </Checkbox>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={shouldUpdate}>
        {/*@ts-ignore*/}
        {({ getFieldValue }: FormInstance<NotificationNewsletterFormResponse>) => {
          const appNotification = getFieldValue(NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION);

          if (!appNotification) return <></>;

          return (
            <div className={s.wrapper}>
              <Form.Item
                name={NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON}
                valuePropName="checked"
                normalize={normalizeFeedbackOn}>
                <Checkbox disabled={disabled}>
                  <Typography.Text size="14">{t('notification_allow_callback')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON}
                valuePropName="checked"
                normalize={normalizeFeedbackOn}>
                <Checkbox disabled={disabled}>
                  <Typography.Text size="14">{t('notification_feedback_on')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED}
                valuePropName="checked">
                <Checkbox disabled={!isFeedbackAvailable || disabled}>
                  <Typography.Text size="14">{t('notification_feedback_required')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED}
                valuePropName="checked">
                <Checkbox disabled>
                  <Typography.Text size="14">{t('notification_push_added')}</Typography.Text>
                </Checkbox>
              </Form.Item>
            </div>
          );
        }}
      </Form.Item>
    </>
  );
};
export default NotificationNewsletterFormApp;
