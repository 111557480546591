import { t } from 'tools/i18n';

import { ColumnsType } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import TableText from 'components/table/table-text';

import { UploadedShiftAnswerFile } from 'types/surveys.types';

export const useColumns = (): ColumnsType<UploadedShiftAnswerFile> => [
  {
    title: t('survey_anketa_upload_uploaded_fiile'),
    dataIndex: 'fileName',
    render: (fileName: UploadedShiftAnswerFile['fileName']) => (
      <TableText size="12" weight="500" type="secondary">
        {fileName}
      </TableText>
    ),
  },
  {
    title: t('survey_anketa_upload_employee_fio'),
    dataIndex: 'id',
    render: (_, { employerName, personFirstName, personLastName }) => (
      <TablePerson
        department={employerName}
        firstName={personFirstName}
        lastName={personLastName}
      />
    ),
  },
  {
    title: t('survey_anketa_upload_date'),
    dataIndex: 'uploadedAt',
    align: 'center',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (uploadedAt: UploadedShiftAnswerFile['uploadedAt']) => <TableDate date={uploadedAt} />,
  },
];
