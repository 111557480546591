import { t } from 'tools/i18n';

import {
  useApproveShiftMedexamRequestMutation,
  useRejectShiftMedexamRequestMutation,
} from 'services/1c/shift/shiftApiService';
import { ApproveShiftMedexamRequestPropsType } from 'services/1c/shift/shiftApiService.types';

import { useHandleRequestWithModal } from 'gazprom-ui-lib';

import {
  ApproveMedexamCopyRequestFormResponse,
  MEDEXAM_COPY_REQUEST_FORM_ENUM,
  RejectMedexamCopyRequestFormResponse,
} from './rejectApproveForm.utils';

interface Props {
  selectedMedexamRequestId: string;
  closeRejectApproveModal: () => void;
  isReject: boolean;
}

export const useSubmit = (props: Props) => {
  const { selectedMedexamRequestId, closeRejectApproveModal, isReject } = props;

  const [rejectMedexamRequest, { isLoading: isLoadingReject }] =
    useRejectShiftMedexamRequestMutation();
  const [approveMedexamRequest, { isLoading: isLoadingApprove }] =
    useApproveShiftMedexamRequestMutation();

  const isLoading = isLoadingReject || isLoadingApprove;

  const handleRequestWithModal = useHandleRequestWithModal({
    onSuccessModalConfig: {
      icon: {
        name: isReject ? 'delete' : 'ok',
        type: isReject ? 'danger' : 'success',
      },
      title: {
        children: t(
          isReject
            ? 'shift_change_medexam_request_rejected'
            : 'shift_change_medexam_request_approved',
        ),
      },
      description: {
        children: t(
          isReject
            ? 'shift_change_medexam_request_rejected_descriotion'
            : 'shift_change_medexam_request_approved_description',
        ),
      },
    },
  });

  const handleSubmit = (
    formResponse: RejectMedexamCopyRequestFormResponse | ApproveMedexamCopyRequestFormResponse,
  ) => {
    const file = (formResponse as ApproveMedexamCopyRequestFormResponse)[
      MEDEXAM_COPY_REQUEST_FORM_ENUM.FILES
    ];

    const mutation = isReject
      ? rejectMedexamRequest({ id: selectedMedexamRequestId, payload: formResponse })
      : approveMedexamRequest({
          id: selectedMedexamRequestId,
          payload: {
            ...formResponse,
            files: [{ fileName: file.fileName, fileKey: file.fileKey }],
          },
        } as ApproveShiftMedexamRequestPropsType);

    mutation.then((res) => {
      closeRejectApproveModal();
      handleRequestWithModal(res);
    });
  };

  return { isLoading, handleSubmit };
};
