import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useUpdateRevisionSettlementSurveyMutation } from 'services/surveys/surveysApiService';

import { Button, Flex, Form, Radio, useHandleRequestWithModal } from 'gazprom-ui-lib';

import { SURVEY_SETTLEMENT_FORM_ENUM } from '../../surveySettlementForm.utils';
import UpdateModal from '../update-modal';
import s from './Update.module.scss';

const options = [
  { value: 'AUTO', label: t('common_auto_update') },
  { value: 'MANUAL', label: t('common_manual_update') },
];

const Update = () => {
  const { id } = useParams<{ id: string }>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [update, { isLoading }] = useUpdateRevisionSettlementSurveyMutation();

  const form = Form.useFormInstance();
  const value = Form.useWatch(SURVEY_SETTLEMENT_FORM_ENUM.UPDATE_SETTINGS, form);
  const isAuto = value === 'AUTO';

  const afterAssign = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_created_updated'),
      },
      description: {
        children: t('surveys_update_anketa_revision_success_description'),
      },
    },
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'danger',
      },
      title: {
        children: t('common_something_went_wrong'),
      },
      description: {
        children: t('surveys_update_anketa_revision_error_description'),
      },
      actions: (
        <Button type="secondary" leftIcon="refresh" onClick={handleUpdate} loading={isLoading}>
          {t('common_retry_again')}
        </Button>
      ),
    },
  });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  function handleUpdate() {
    if (!id) return;

    update({ surveyId: id }).then(afterAssign).finally(handleCloseModal);
  }

  return (
    <Flex justify="space-between" align="flex-start">
      <Form.Item
        name={SURVEY_SETTLEMENT_FORM_ENUM.UPDATE_SETTINGS}
        label={t('surveys_revision_update')}>
        <Radio.Group options={options} />
      </Form.Item>
      {!isAuto && id && (
        <Button
          type="link"
          leftIcon="refresh"
          className={s.button}
          onClick={handleOpenModal}
          disabled={false}>
          {t('surveys_update_anketa_revision')}
        </Button>
      )}
      <UpdateModal
        open={isModalOpen}
        onCancel={handleCloseModal}
        onActionClick={handleUpdate}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default Update;
