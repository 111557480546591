import { useNavigate } from 'react-router-dom';
import { getSuggestionCommissionDetailsRoute } from 'routes/suggestions/list';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ExpertCommissionType } from 'types/expertCommission.types';

import s from './SuggestionsCommissions.module.scss';
import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './suggestionsCommissions.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const SuggestionCommissions = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching, isLoading } = useRequest({ form });

  const columns = useColumns();

  const onRow = (record: ExpertCommissionType) => ({
    onClick: () => navigate(getSuggestionCommissionDetailsRoute(record.id)),
  });

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<ExpertCommissionType, FormResponseType>
          isInitialLoading={isLoading}
          loading={isFetching}
          onRow={onRow}
          rowKey="employerId"
          className={s.table}
          dataSource={data?.items}
          columns={columns}
          total={data?.totalCount}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SuggestionCommissions;
