import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetSettlementSurveyHistoryAssigmentByIdQuery } from 'services/surveys/surveysApiService';

import { Button, Flex, Form, Tabs } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './SurveysGift.module.scss';
import Header from './containers/header';
import Notifications from './containers/notifications';
import {
  FormResponse,
  SURVEY_SETTLEMENT_GIFT_FORM_NAME,
} from './containers/notifications/notifications.utils';
import Respondents from './containers/respondents';
import Settlements from './containers/settlements';
import Winners from './containers/winners';
import { SURVEYS_GIFT_TABS } from './surveysGift.utils';
import useSendNotification from './useSendNotification';

const SurveysGift = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(SURVEYS_GIFT_TABS[0].key);

  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const [form] = Form.useForm<FormResponse>();

  const { data, isLoading } = useGetSettlementSurveyHistoryAssigmentByIdQuery(
    {
      id: giftId!,
      surveyId: surveyId!,
    },
    { skip: !giftId && !surveyId },
  );

  const { isLoading: isSendNotificationLoading, onFinishHandler } = useSendNotification();

  const hasNotificationPackageId = data?.notificationPackageId;

  const handleBack = () => navigate(-1);

  return (
    <WithLoader isLoading={isLoading}>
      <Header isLoading={isSendNotificationLoading} />
      <Flex vertical gap="24">
        <Flex vertical gap="24" className={s.container}>
          <Settlements />
          <Respondents />
          <Winners />
          {!hasNotificationPackageId && (
            <Form form={form} onFinish={onFinishHandler} name={SURVEY_SETTLEMENT_GIFT_FORM_NAME}>
              <Notifications />
            </Form>
          )}
        </Flex>
        {hasNotificationPackageId && (
          <Tabs
            className={s.tabs}
            items={SURVEYS_GIFT_TABS}
            type="card"
            onTabClick={setActiveTab}
            activeKey={activeTab}
          />
        )}
      </Flex>
      <Flex gap="8" className={s.buttons}>
        {!hasNotificationPackageId && (
          <Button
            form={SURVEY_SETTLEMENT_GIFT_FORM_NAME}
            htmlType="submit"
            leftIcon="send"
            fullWidth
            loading={isSendNotificationLoading}>
            {t('common_send_notifications')}
          </Button>
        )}
        <Button type="secondary" fullWidth onClick={handleBack}>
          {t('common_close')}
        </Button>
      </Flex>
    </WithLoader>
  );
};

export default SurveysGift;
