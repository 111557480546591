import { useNavigate } from 'react-router-dom';
import { getSuggestionDecisionDetailsRoute } from 'routes/suggestions/list';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { SuggestionDecisionType } from 'types/suggestion.types';

import s from './SuggestionsDecisions.module.scss';
import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './suggestionsDecisions.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const SuggestionsDecisions = () => {
  const [form] = Form.useForm<FormResponseType>();

  const navigate = useNavigate();
  const columns = useColumns();

  const { data, isFetching, isLoading } = useRequest({ form });

  const onRow = (record: SuggestionDecisionType) => {
    return {
      onClick: () => navigate(getSuggestionDecisionDetailsRoute(record.id)),
    };
  };

  return (
    <WithOverflow>
      <Header total={data?.items?.length ?? 0} />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<SuggestionDecisionType, FormResponseType>
          isInitialLoading={isLoading}
          onRow={onRow}
          rowKey="id"
          className={s.table}
          dataSource={data?.items}
          columns={columns}
          loading={isFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SuggestionsDecisions;
