import { useEffect } from 'react';

import dayjs from 'dayjs';

import { FormInstance } from 'gazprom-ui-lib';

import { useGetSurvey } from '../../useGetSurvey.hook';
import {
  SURVEY_SETTLEMENT_FORM_ENUM,
  SurveySettlementFormResponse,
} from './surveySettlementForm.utils';

interface Props {
  form: FormInstance<SurveySettlementFormResponse>;
}

export const useSetFormData = (props: Props) => {
  const { form } = props;
  const { survey } = useGetSurvey();

  useEffect(() => {
    if (survey) {
      const reAssignmentDateTo = survey.reAssignmentDateTo
        ? (dayjs(survey.reAssignmentDateTo) as unknown as string)
        : undefined;

      form.setFieldsValue({
        [SURVEY_SETTLEMENT_FORM_ENUM.NAME]: survey.name,
        [SURVEY_SETTLEMENT_FORM_ENUM.REVISION_ID]: survey.revisionId,
        [SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID]: survey.anketologId,
        [SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS]: survey?.settlements?.map(({ id }) => id) ?? [],
        [SURVEY_SETTLEMENT_FORM_ENUM.NOTIFICATION_INTERVAL]: survey.notificationInterval,
        [SURVEY_SETTLEMENT_FORM_ENUM.UPDATE_SETTINGS]: survey.updateSettings,
        [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT]: survey.reAssignment,
        [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_SETTINGS]: survey.reAssignmentSettings,
        [SURVEY_SETTLEMENT_FORM_ENUM.RE_ASSIGNMENT_DATE_TO]: reAssignmentDateTo,
        [SURVEY_SETTLEMENT_FORM_ENUM.IS_PLAY_GIFT]: survey.isPlayGift,
        [SURVEY_SETTLEMENT_FORM_ENUM.WINNERS_COUNT]: survey.winnersCount,
      });
    }
  }, [survey, form]);

  return;
};
