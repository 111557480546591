import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, Table } from 'gazprom-ui-lib';

import SettingsEmployerShiftForm from 'containers/settings-employer-shift-form';
import { SettingsEmployerShiftFormResponse } from 'containers/settings-employer-shift-form/settingsEmployerShiftForm.utils';

import { Employer1cShiftInfoHotContactType, Employer1cShiftInfoType } from 'types/employer.types';

import s from './ShiftTab.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  onSubmit: (form: SettingsEmployerShiftFormResponse) => void;
  employerShiftData?: Employer1cShiftInfoType;
  employerShiftHotContacts: Employer1cShiftInfoHotContactType[];
  setEmployerShiftHotContacts: Dispatch<SetStateAction<Employer1cShiftInfoHotContactType[]>>;
}

const ShiftTab = (props: Props) => {
  const { onSubmit, employerShiftData, employerShiftHotContacts, setEmployerShiftHotContacts } =
    props;

  const handleFieldChange = (
    index: number,
    field: keyof Employer1cShiftInfoHotContactType,
    value: string,
  ) => {
    const newData = [...employerShiftHotContacts];
    newData[index] = { ...newData[index], [field]: value };
    setEmployerShiftHotContacts(newData);
  };

  const handleDelete = (index: number) => {
    setEmployerShiftHotContacts(
      employerShiftHotContacts.filter(
        (_: Employer1cShiftInfoHotContactType, i: number) => i !== index,
      ),
    );
  };

  const handleAddRow = () => {
    setEmployerShiftHotContacts([
      ...employerShiftHotContacts,
      { phoneNumber: '', comment: '', timetable: '' },
    ]);
  };

  const columns = useColumns({ handleFieldChange, handleDelete });

  return (
    <Flex vertical>
      <SettingsEmployerShiftForm employerShiftData={employerShiftData} onSubmit={onSubmit} />
      <Table
        className={s.table}
        dataSource={employerShiftHotContacts}
        columns={columns}
        rowKey={(_, index) => index!.toString()}
        pagination={false}
      />
      <Flex fullWidth justify="flex-start">
        <Button onClick={handleAddRow} leftIcon="plus" type="link">
          {t('common_add_contact')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ShiftTab;
