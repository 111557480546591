export enum MEDEXAM_COPY_REQUEST_FORM_ENUM {
  FILES = 'files',
  MED_DOC_NUM = 'medDocNum',
  MED_DOC_DATE = 'medDocDate',
  MED_DOC_EXPIRED_AT = 'medDocExpiredAt',
  ANSWER_COMENT = 'answerComment',
}

export type RejectMedexamCopyRequestFormResponse = {
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.ANSWER_COMENT]: string;
};

export type ApproveMedexamCopyRequestFormResponse = {
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.FILES]: { fileKey: string; fileName: string };
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_NUM]: string;
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_DATE]: string;
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.MED_DOC_EXPIRED_AT]: string;
  [MEDEXAM_COPY_REQUEST_FORM_ENUM.ANSWER_COMENT]: string;
};
