import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { getDeclarationTypeSelector } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { useAppSelector } from 'store';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import DocumentsDeclarationsFormBpmnProcess from 'containers/documents-declarations-form/documnets-declarations-form-bpnm-process/documentsDeclarationsFormBpmnProcess';
import useBPMNProcesses from 'containers/documents-declarations-form/useBPMNProcess.hook';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useDeclarationRoleModel from 'pages/documents/documents-declarations/useDeclarationRoleModel';

import { DeclarationParamType, ProcessParamType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './TabParameters.module.scss';
import KedoFields from './containers/kedo-fields';
import ParametersTree from './containers/parameters-tree';
import StageDeadline from './containers/stage-deadline';
import {
  CANCEL_ORDERS_PROCESS,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME,
  ProcessParamMapType,
} from './tabParameters.utils';
import useSubmit from './useSubmit.hook';

const TabParameters = () => {
  const { id } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();
  const { dataObject, isLoading: bpmnIsLoading } = useBPMNProcesses();
  const { data: declarationData, isLoading: declarationDataIsLoading } = useAppSelector(
    getDeclarationTypeSelector.select(id!),
  );

  const [form] = Form.useForm();

  const bpmnProcess = Form.useWatch(DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS, form);
  const processParameters = dataObject[bpmnProcess]?.params;
  const cancelProcessParameters = dataObject[CANCEL_ORDERS_PROCESS]?.params ?? [];

  const showIsCancellableParameter = declarationData?.type === KEDO_TYPE_ENUM.BPMN;
  const showHasDigitalForm = declarationData?.type === KEDO_TYPE_ENUM.KEDO;
  const showBpmnProcess = declarationData?.type !== KEDO_TYPE_ENUM.KEDO;
  const showCommonDeadline = declarationData?.type !== KEDO_TYPE_ENUM.KEDO;

  const isCancellable = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE,
    form,
  );

  const { isLoading, handleFormSubmit, setParamValues } = useSubmit({
    isCancellable,
    form,
  });

  const renderComponent = (params: ProcessParamType) => {
    const { type, code, name } = params;
    const fieldName = `${code}`;
    const currentParam = declarationData?.cancelProcessParams?.find((el) => el.code === code);
    const defaultValue = currentParam?.value;

    return (
      <DocumentsDeclarationsFormCheck
        disabled={isDisabled}
        label={name}
        formName={fieldName}
        className={s.check}
        key={`${type}.${code}.${name}`}
        defaultValue={defaultValue === 'true'}
      />
    );
  };

  useEffect(() => {
    if (processParameters && declarationData?.params) {
      const paramsMap = new Map<string, ProcessParamMapType>();
      // ресетим в самом начале, чтоб убрать предыдущие значения
      setParamValues(paramsMap);
      const setParamsMap = (param: ProcessParamType | DeclarationParamType) => {
        const { code, type } = param;

        let value = '';
        let numberOfDays = 0;

        if ('value' in param) {
          value = param.value;
        }
        if ('numberOfDays' in param) {
          numberOfDays = param.numberOfDays || 0;
        }

        paramsMap.set(code, {
          code,
          type,
          value,
          numberOfDays,
        });

        if ('children' in param && param.children?.length) {
          param.children.forEach(setParamsMap);
        }
      };

      processParameters.forEach(setParamsMap);
      declarationData?.params.forEach(setParamsMap);
      setParamValues(paramsMap);
      declarationData?.params?.forEach((param) => {
        if (param.type === 'STRING') {
          form.setFieldValue(param.code, param.value);
        }
        form.setFieldValue(`${param.code}.numberOfDays`, param.numberOfDays);
      });
      if (declarationData?.totalDaysLimit) {
        form.setFieldValue(
          DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT,
          declarationData?.totalDaysLimit,
        );
      }
    }
  }, [declarationData, form, processParameters, setParamValues]);

  const { parametersDisabled: isDisabled } = useDeclarationRoleModel({});

  return (
    <WithLoader isLoading={bpmnIsLoading || declarationDataIsLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME}>
        <div className={s.container}>
          {showCommonDeadline ? (
            <StageDeadline
              required
              disabled={isDisabled}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT}
              label={
                <Typography.Title level={5}>
                  {t('documents_declarations_common_deadline')}
                </Typography.Title>
              }
              initialValue={declarationData?.totalDaysLimit}
            />
          ) : (
            <KedoFields />
          )}
          {showBpmnProcess && (
            <DocumentsDeclarationsFormBpmnProcess
              defaultValue={declarationData?.bpmnProcess}
              disabled={isDisabled}
            />
          )}
          {processParameters && (
            <ParametersTree
              disabled={isDisabled}
              processParameters={processParameters}
              setParamValues={setParamValues}
              defaultParams={declarationData?.params ?? []}
            />
          )}
          {showIsCancellableParameter && (
            <Form.Item
              initialValue={declarationData?.isCancellable}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE}
              valuePropName="checked">
              <Checkbox disabled={isDisabled}>
                {t('documents_declarations_parameters_cancel_title')}
              </Checkbox>
            </Form.Item>
          )}
          {isCancellable && cancelProcessParameters?.map(renderComponent)}
          {showHasDigitalForm && (
            <Form.Item
              initialValue={declarationData?.hasDigitalForm}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM}
              valuePropName="checked">
              <Checkbox disabled={isDisabled}>
                {t('documents_declarations_parameters_digital_title')}
              </Checkbox>
            </Form.Item>
          )}
        </div>
        <DocumentsDeclarationsButtons isLoading={isLoading} />
      </Form>
    </WithLoader>
  );
};

export default TabParameters;
