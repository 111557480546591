import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface Props {
  handleCreate: () => void;
}

const Header = (props: Props) => {
  const { handleCreate } = props;

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('1c_import')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleCreate}>
          {t('1c_create_instance')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
