import { Table } from 'gazprom-ui-lib';

import { ReviewTaskAssignmentType } from 'types/review-task.types';

import useColumns from './useColumns.hook';

interface Props {
  data: ReviewTaskAssignmentType[];
  recruitId?: string;
  isTaskEmployerCanceled?: boolean;
}

const LnaTable = (props: Props) => {
  const { data, recruitId, isTaskEmployerCanceled } = props;

  const columns = useColumns({
    recruitId,
    hideDownload: isTaskEmployerCanceled,
  });

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      pagination={{
        current: 1,
        total: 1,
      }}
    />
  );
};

export default LnaTable;
