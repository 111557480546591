import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const { preparedFilters, filterItems } = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <Flex align="center">
        <FiltersV2.Search />
      </Flex>
      <FiltersV2.RangePicker />
      <FiltersContainer
        showClearAll
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="statuses"
                options={preparedFilters}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};
export default Filters;
