import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FAQ_ROUTE } from 'routes/faq/list';

import { useGetFaqByIdQuery, useUpdateFaqMutation } from 'services/faq/faqApiService';

import { Flex, useHandleRequestWithModal } from 'gazprom-ui-lib';

import FaqQuestionForm from 'containers/faq-question-form';
import { FORM_ENUM, FormResponseType } from 'containers/faq-question-form/faqQuestionForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './FaqEdit.module.scss';
import Actions from './containers/actions';
import Header from './containers/header';

const FaqEdit = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useGetFaqByIdQuery(id ?? skipToken);
  const [updateFaq, { isLoading: updateFaqIsLoading }] = useUpdateFaqMutation();

  const handleReset = () => {
    navigate(FAQ_ROUTE, { state: location.state });
  };

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
    },
  });

  const handleFinish = (formResponse: FormResponseType) => {
    if (id) {
      updateFaq({
        id,
        title: formResponse[FORM_ENUM.TITLE],
        answer: formResponse[FORM_ENUM.TEXT],
        isPublish: !!formResponse[FORM_ENUM.PUBLISH],
        organizationId: formResponse[FORM_ENUM.ORGANIZATION],
        idCategory: formResponse[FORM_ENUM.PARENT],
      }).then(handleUpdate);
    }
  };

  return (
    <WithOverflow>
      <Header isLoading={updateFaqIsLoading} />
      <Flex vertical className={s.contentWrapper}>
        <WithLoader isLoading={isLoading}>
          <FaqQuestionForm
            handleFinish={handleFinish}
            handleReset={handleReset}
            faqQuestion={data}
          />
        </WithLoader>
        <Actions isLoading={updateFaqIsLoading} />
      </Flex>
    </WithOverflow>
  );
};

export default FaqEdit;
