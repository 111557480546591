import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE } from './documentsTopManagementForms.utils';
import { FormResponseType } from './documentsTopManagementForms.utils';
import useRequest from './useRequest.hook';

const DocumentsTopManagementForms = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { forms, isLoading, isFetching } = useRequest({ form });

  const loading = isLoading || isFetching;

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <WithLoader isLoading={isLoading}>
          <Table totalCount={forms?.totalCount} dataSource={forms?.items} loading={loading} />
        </WithLoader>
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsTopManagementForms;
