import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployer1cQuery } from 'services/1c/cApiService';
import { GetEmployer1cPropsType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './settingsEmployers.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getEmployers, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetEmployer1cQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    const { page, size, search } = debouncedRequestBody ?? {};

    const requestBody: GetEmployer1cPropsType = {
      page,
      size,
    };

    if (search) {
      requestBody.nameFilterParam = search;
    }

    getEmployers(requestBody);
  }, [debouncedRequestBody, getEmployers]);

  return {
    data,
    isFetching: isFetching || isDebouncing,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
