import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  getDeclarationTypeSelector,
  usePostBPMNProcessMutation,
  useUpdateDeclarationTypesMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { CreateDeclarationTypesPropsType } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';
import { useAppSelector } from 'store';

import { FormInstance } from 'gazprom-ui-lib';

import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import {
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM,
  ProcessParamMapType,
} from './tabParameters.utils';

interface Props {
  isCancellable?: boolean;
  form: FormInstance;
}

const useSubmit = (props: Props) => {
  const { isCancellable, form } = props;
  const { id } = useParams();

  const { data: declarationData } = useAppSelector(getDeclarationTypeSelector.select(id!));

  const isKedo = declarationData?.type === KEDO_TYPE_ENUM.KEDO;

  const [paramValues, setParamValues] = useState<Map<string, ProcessParamMapType>>(new Map());

  const [updateDeclaration, { isLoading: updateIsLoading }] = useUpdateDeclarationTypesMutation();
  const [setParamsDeclaration, { isLoading: setParamsIsLoading }] = usePostBPMNProcessMutation();

  const handleFormSubmit = (
    formValues: Record<DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS | string, string>,
  ) => {
    if (formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS]) {
      const totalDays = formValues[
        DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT
      ] as unknown as number;

      const needBuhApprove = formValues[
        DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.NEED_BUH_APPROVE
      ] as unknown as boolean;

      const { paramTotalDays, paramValues: paramValuesToSet } = getParamValues(
        formValues,
        paramValues,
      );

      if (totalDays < paramTotalDays) {
        form.setFields([
          {
            name: DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.TOTAL_DAYS_LIMIT,
            errors: [t('documents_declaration_errors_count', { days: paramTotalDays })],
          },
        ]);
        return;
      }

      setParamsDeclaration({
        id: id!,
        body: {
          isCancellable: Boolean(isCancellable),
          totalDaysLimit: totalDays,
          params: {
            bpmnProcess: formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS],
            paramValues: paramValuesToSet,
            cancelProcessParamValues: [
              {
                code: 'needBuhApprove',
                type: 'BOOL',
                value: needBuhApprove,
                numberOfDays: 0,
              },
            ],
          },
        },
      });
    }
  };

  const handleKedoFormSubmit = (
    form: Record<DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM, string>,
  ) => {
    if (!declarationData || !id) return;

    const prepareBody: CreateDeclarationTypesPropsType = {
      ...(declarationData as CreateDeclarationTypesPropsType),
    };

    if (form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM]) {
      prepareBody.hasDigitalForm = form[
        DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM
      ] as unknown as boolean;
    }

    if (form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.DIGITAL_FLOW_TYPE]) {
      prepareBody.digitalFlowType =
        form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.DIGITAL_FLOW_TYPE];
    }

    if (form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.CERTIFICATE_1C_ID]) {
      prepareBody.certificate1cId =
        form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.CERTIFICATE_1C_ID];
    }

    if (form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.SIGNER_TYPE]) {
      prepareBody.signerType = form[DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.SIGNER_TYPE];
    }

    updateDeclaration({ id, body: prepareBody });
  };

  return {
    isLoading: setParamsIsLoading || updateIsLoading,
    handleFormSubmit: isKedo ? handleKedoFormSubmit : handleFormSubmit,
    setParamValues,
  };
};

const getParamValues = (
  formValues: Record<string, string>,
  paramValues: Map<string, ProcessParamMapType>,
) => {
  const updatedParams = new Map(paramValues);
  let paramTotalDays = 0;

  Object.entries(formValues).forEach(([fullKey, value]) => {
    const [mapKey, fieldKey] = fullKey.split('.');

    if (updatedParams.has(mapKey)) {
      const param = updatedParams.get(mapKey);

      if (param) {
        if (param.type === 'STRING') {
          param.value = value;
        } else if (fieldKey === 'numberOfDays') {
          param.numberOfDays = Number.isInteger(value) ? Number(value) : param.numberOfDays;
        } else if (fieldKey === undefined && !param.value) {
          param.value = value;
        }

        if (param.value === 'true' && Number(value)) {
          paramTotalDays += Number(value);
        }
        updatedParams.set(mapKey, param);
      }
    }
  });

  return { paramValues: Array.from(updatedParams.values()), paramTotalDays };
};

export default useSubmit;
