import { useNavigate } from 'react-router-dom';
import { ACCESS_CONTROL_USER_ROLES_ROUTE } from 'routes/access-control/list';

import { Table, TablePaginationConfig, TableProps } from 'gazprom-ui-lib';

import { RoleType } from 'types/roles.types';

import s from './AccessControlRolesTable.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: RoleType[];
  paginationConfig: TablePaginationConfig;
  loading: boolean;
  onChange: TableProps<RoleType>['onChange'];
}

const AccessControlRolesTable = (props: Props) => {
  const { dataSource, paginationConfig, loading, onChange } = props;
  const navigate = useNavigate();

  const columns = useColumns();

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={paginationConfig}
      onRow={(record) => ({
        onClick: () => navigate(`${ACCESS_CONTROL_USER_ROLES_ROUTE}/${record.code}`),
      })}
      loading={loading}
      rowKey="code"
      className={s.table}
      onChange={onChange}
      scroll={{ y: '50vh' }}
    />
  );
};

export default AccessControlRolesTable;
