import { FC, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, Radio, Select, Switch } from 'gazprom-ui-lib';

import { Employer1cResType } from 'types/employer.types';

import s from './SettingsEmployer.module.scss';
import {
  DEFAULT_TIMEZONE,
  SETTINGS_EMPLOYER_FORM_ENUM,
  SETTINGS_EMPLOYER_GROUP,
  SettingsEmployerFormResponse,
  timezoneOptions,
} from './settingsEmployerForm.utils';

interface CInstanceFormProps {
  onSubmit: (form: SettingsEmployerFormResponse) => void;
  employerData?: Employer1cResType;
}

const SettingsEmployerForm: FC<CInstanceFormProps> = (props) => {
  const { onSubmit, employerData } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (employerData) {
      form.setFieldsValue({
        [SETTINGS_EMPLOYER_FORM_ENUM.ACTUAL_NAME_CODE]: employerData.actualNameCode,
        [SETTINGS_EMPLOYER_FORM_ENUM.SIGN_ENABLED]: employerData.signEnabled,
        [SETTINGS_EMPLOYER_FORM_ENUM.CAN_ISSUE_CERT]: employerData.canIssueCert,
        [SETTINGS_EMPLOYER_FORM_ENUM.TIMEZONE]: employerData?.timezone ?? DEFAULT_TIMEZONE,
      });
    }
  }, [form, employerData]);

  const { shortName, name1c, fullName } = employerData ?? {};

  return (
    <Form className={s.wrapper} form={form} name={SETTINGS_EMPLOYER_GROUP} onFinish={onSubmit}>
      <Form.Item
        label={t('common_displayed_name')}
        normalize={normalizeTextField}
        name={SETTINGS_EMPLOYER_FORM_ENUM.ACTUAL_NAME_CODE}
        rules={[formRules.required, { max: 80 }]}>
        <Radio.Group direction="vertical">
          <Radio.Button value="SHORT_NAME" disabled={!shortName?.length}>
            {shortName?.length ? shortName : t('common_no_value')}
          </Radio.Button>
          <Radio.Button value="NAME" disabled={!name1c?.length}>
            {name1c?.length ? name1c : t('common_no_value')}
          </Radio.Button>
          <Radio.Button value="FULL_NAME" disabled={!fullName?.length}>
            {fullName?.length ? fullName : t('common_no_value')}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Flex gap="8" align="center" className={s.switchFormItem}>
        <Form.Item name={SETTINGS_EMPLOYER_FORM_ENUM.SIGN_ENABLED} valuePropName="checked">
          <Switch />
        </Form.Item>
        {t('common_kedo')}
      </Flex>

      <Flex gap="8" align="center" className={s.switchFormItem}>
        <Form.Item name={SETTINGS_EMPLOYER_FORM_ENUM.CAN_ISSUE_CERT} valuePropName="checked">
          <Switch />
        </Form.Item>
        {t('1с_instance_certificate_issue')}
      </Flex>

      <Flex gap="8" align="center" className={s.timezoneFormItem}>
        <Form.Item name={SETTINGS_EMPLOYER_FORM_ENUM.TIMEZONE} label={t('common_timezone')}>
          <Select
            showSearch
            filterOption
            optionFilterProp="label"
            placeholder={t('common_select_second')}
            options={timezoneOptions}
          />
        </Form.Item>
      </Flex>
    </Form>
  );
};

export default SettingsEmployerForm;
