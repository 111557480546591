import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  getCommunityManagementMeetsCreateRoute,
  getCommunityManagementNewsCreateRoute,
} from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button, Dropdown, DropdownProps, HandleItemClickType } from 'gazprom-ui-lib';

import DiscussionCreateEditModal from 'containers/discussion-create-edit-modal';

import SurveyCreateModal from './containers/survey-create-modal';

enum BUTTON_KEYS_ENUM {
  NEWS = 'NEWS',
  MEET = 'MEET',
  SURVEY = 'SURVEY',
  DISCUSSION = 'DISCUSSION',
}

enum MODALS_ENUM {
  DISCUSSION = 'DISCUSSION',
  SURVEY = 'SURVEY',
  EMPTY = '',
}

const handlers = {
  [BUTTON_KEYS_ENUM.NEWS]: getCommunityManagementNewsCreateRoute,
  [BUTTON_KEYS_ENUM.MEET]: getCommunityManagementMeetsCreateRoute,
};

const dropdownItems: DropdownProps['items'] = [
  { key: BUTTON_KEYS_ENUM.NEWS, label: t('news_news') },
  { key: BUTTON_KEYS_ENUM.MEET, label: t('common_event_singular') },
  { key: BUTTON_KEYS_ENUM.SURVEY, label: t('common_survey_singular') },
  { key: BUTTON_KEYS_ENUM.DISCUSSION, label: t('common_discussion_singular') },
];

const AddButtonDropdown = () => {
  const [activeModal, setActiveModal] = useState(MODALS_ENUM.EMPTY);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const closeModal = () => setActiveModal(MODALS_ENUM.EMPTY);

  const handleClick: HandleItemClickType = (value) => {
    if (id) {
      const navigationHandler = handlers[value.key as keyof typeof handlers];
      if (value.key === BUTTON_KEYS_ENUM.DISCUSSION) {
        setActiveModal(MODALS_ENUM.DISCUSSION);
      } else if (value.key === BUTTON_KEYS_ENUM.SURVEY) {
        setActiveModal(MODALS_ENUM.SURVEY);
      } else {
        const route = navigationHandler(id);

        navigate(route);
      }
    }
  };

  return (
    <>
      <DiscussionCreateEditModal
        communityId={id!}
        open={activeModal === MODALS_ENUM.DISCUSSION}
        onClose={closeModal}
      />
      <SurveyCreateModal open={activeModal === MODALS_ENUM.SURVEY} close={closeModal} />
      <Dropdown handleItemClick={handleClick} items={dropdownItems}>
        <Button leftIcon="plus">{t('community_add_publication')}</Button>
      </Dropdown>
    </>
  );
};

export default AddButtonDropdown;
