import { useNavigate } from 'react-router-dom';
import { getSettingsEmployerDetailsRoute } from 'routes/settings/list';

import { GetEmployer1cResponseType } from 'services/1c/cApiService.types';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import s from './SettingsEmployersTable.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: GetEmployer1cResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

const SettingsEmployersTable = (props: Props) => {
  const navigate = useNavigate();

  const { dataSource, loading, isInitialLoading } = props;

  const handleRowClick = (record: { id: string }) => {
    navigate(getSettingsEmployerDetailsRoute(record.id));
  };

  const onRow = (record: { id: string }) => ({
    onClick: () => handleRowClick(record),
  });

  const columns = useColumns();

  const emptyText = <Empty className={s.emptyContent} title="common_empty_title" />;

  return (
    <FiltersV2.Table
      columns={columns}
      isInitialLoading={isInitialLoading}
      dataSource={dataSource?.employers}
      total={dataSource?.totalCount}
      className={s.table}
      onRow={onRow}
      loading={loading}
      rowKey="id"
      showSorterTooltip={false}
      locale={{ emptyText }}
    />
  );
};

export default SettingsEmployersTable;
