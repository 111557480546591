import { EmployeeType } from './employee.types';
import { FileSignType } from './file.types';

export enum SUGGESTION_NEED_EXPENSES_ENUM {
  NEED_MONEY = 'NEED-MONEY',
  NO_NEED_MONEY = 'NO-NEED-MONEY',
  UNKNOWN_NEED_MONEY = 'UNKNOWN-NEED-MONEY',
}

export type SuggestionFileType = {
  fileKey: string;
  fileName: string;
  signs: FileSignType[] | null;
  description: string | null;
};

export type SuggestionType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  employee: EmployeeType;
  performer?: EmployeeType;
  category: {
    id: string;
    name: string;
  };
  name: string;
  curStatus: {
    code: string;
    name: string;
    state: string;
    description: string;
    comment: string;
  };
  statusHistory: {
    createdAt: string;
    status: string;
    title: string;
    state: string;
    description: string;
    comment: string;
  }[];
  number: number;
  problem: string;
  suggestion: string;
  expectation: string;
  problemFiles: SuggestionFileType[];
  suggestionFiles: SuggestionFileType[];
  needExpenses: SUGGESTION_NEED_EXPENSES_ENUM;
  personalParticipation: boolean;
  releaseReportFile: SuggestionFileType[];
  realizationStatus: {
    state: 'FINISH' | 'REFUSE' | 'IN_PROGRESS';
    statusName: string;
  };
  commissionId: string;
  coordinator?: EmployeeType;
};

export type SuggestionDecisionStatus = {
  code: string;
  name: string;
  state: string;
  description: string;
};

export type SuggestionStatus = {
  code: string;
  name: string;
};

export type SuggestionCategory = {
  id: string;
  name: string;
  icon: string;
};

export type SuggestionDecisionType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  status: {
    code: string;
    name: string;
    state: 'draft' | 'pending' | 'done' | 'error';
    description: string;
    comment: string;
  };
  executor: EmployeeType;
  employee: EmployeeType;
  type:
    | 'FIRST_LEADER'
    | 'MEMBER'
    | 'COMMISSION_1L_REVIEW'
    | 'COMMISSION_2L_REVIEW'
    | 'COORDINATOR'
    | 'PERFORMER';
  suggestion: SuggestionType;
};

export type SuggestionDecisionPerformerType = EmployeeType;
