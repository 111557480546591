import dayjs from 'dayjs';
import { Trans } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

interface Props {
  isOrderType: boolean;
  dateDoc: string | null;
  numberDocPrint: string | null;
}

const FileInfo = (props: Props) => {
  const { dateDoc, numberDocPrint, isOrderType } = props;

  const isInfoAvailable = dateDoc && numberDocPrint && isOrderType;

  if (isInfoAvailable) {
    return (
      <div>
        <Trans
          i18nKey="sign_document_number"
          values={{
            docNum: numberDocPrint,
            date: dayjs.utc(dateDoc).local().format('DD.MM.YYYY'),
          }}>
          <Typography.Text size="12" type="secondary" />
          <Typography.Text size="12" type="secondary" />
        </Trans>
      </div>
    );
  }

  return <></>;
};

export default FileInfo;
