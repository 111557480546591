import { t } from 'tools/i18n';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterHeader from 'containers/filters/filter-header';

import useFilterData from './useFilterData';

const Filters = () => {
  const { employersResult, employersSearchHandler } = useFilterData();

  const items = [
    {
      key: 0,
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="value"
          options={employersResult.employersOptions}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
  ];

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersWrapper
        showClearAll
        overlay={<FilterCollapse items={items} defaultActiveKey={[0]} />}
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
