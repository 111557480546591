import cn from 'classnames';

import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalProps,
  TIconsLiteral,
  Typography,
} from 'gazprom-ui-lib';

import s from './RemoveConfirm.module.scss';

interface Props {
  buttonProps?: ButtonProps;
  modalProps?: ModalProps;
  type: 'danger' | 'success' | 'warning';
  title: string;
  subtitle?: string;
  isOpen: boolean;
  buttonText: string;
  buttonIcon?: TIconsLiteral;
  onCancel: () => void;
  onConfirm?: () => void;
  icon?: TIconsLiteral;
  isLoading?: boolean;
}

const RemoveConfirm = (props: Props) => {
  const {
    buttonProps = {},
    modalProps = {},
    type,
    isOpen,
    onCancel,
    onConfirm,
    title,
    subtitle,
    icon = 'notification',
    buttonText,
    isLoading,
    buttonIcon,
  } = props;

  return (
    <Modal open={isOpen} onCancel={onCancel} width={320} closable {...modalProps}>
      <div className={cn(s.container, s[type])}>
        <div className={s.icon}>
          <Icon name={icon} size={40} color="var(--color-neutral-1)" />
        </div>
        <div className={s.text}>
          <Typography.Title level={3}>{title}</Typography.Title>
          <Typography.Text size="16" weight="400" type="secondary">
            {subtitle}
          </Typography.Text>
        </div>
        <Button
          leftIcon={buttonIcon}
          loading={isLoading}
          fullWidth
          type={type === 'success' ? 'primary' : 'secondary'}
          onClick={onConfirm}
          {...buttonProps}>
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveConfirm;
