import { useNavigate } from 'react-router-dom';
import { GUIDE_ADD_IMPROVEMENTS_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header = () => {
  const navigate = useNavigate();

  const handleAddEvent = () => {
    navigate(GUIDE_ADD_IMPROVEMENTS_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('guide_improvements_list')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleAddEvent}>
          {t('community_add_publication')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
