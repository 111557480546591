import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

import { KedoCategoryType } from 'types/documents.types';

import s from './CategorySelect.module.scss';

interface Props extends Pick<KedoCategoryType, 'name' | 'icon'> {}
const RequirementsSelectIcon = (props: Props) => {
  const { icon, name } = props;
  return (
    <div className={s.icon}>
      <Icon name={icon as TIconsLiteral} />
      {name}
    </div>
  );
};

export default RequirementsSelectIcon;
