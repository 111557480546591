import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetArticlesQuery } from 'services/article/articleApiService';
import { GetArticlesPropsType } from 'services/article/articleApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './table.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getArticles, { data, isLoading, isFetching }] = useLazyGetArticlesQuery();

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.size) {
      const preparedBody: GetArticlesPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        type: debouncedRequestBody.type,
        ...debouncedRequestBody.filters,
      };

      if (debouncedRequestBody.search) {
        preparedBody.title = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: `ARTICLE.${debouncedRequestBody.property}`,
            order: debouncedRequestBody.order,
          },
        ];
      }
      getArticles(preparedBody);
    }
  }, [debouncedRequestBody, getArticles]);

  return {
    isLoading,
    isFetching,
    data,
  };
};

export default useRequest;
