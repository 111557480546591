import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useRejectDecisionsByCommissionsMutation,
  useRevisionDecisionsByCommissionMutation,
} from 'services/suggestion/suggestionApiService';

import { Button, Flex, Form, ModalDefault, TextArea, handleRequest } from 'gazprom-ui-lib';

import s from './ModalReject.module.scss';
import { FORM_ENUM, TFormResponse } from './modalReject.utils';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const ModalReject = (props: Props) => {
  const { isOpen, onCancel } = props;

  const params = useParams<{ id: string }>();

  const [form] = Form.useForm<TFormResponse>();

  const [rejectDecision, { isLoading: rejectIsLoading }] =
    useRejectDecisionsByCommissionsMutation();

  const [sendToRevisionDecision, { isLoading: isRevisionLoading }] =
    useRevisionDecisionsByCommissionMutation();

  const handleRejectDecision = () => {
    if (params.id) {
      form.validateFields().then(() => {
        rejectDecision({ id: params.id!, comment: form.getFieldValue(FORM_ENUM.COMMENT) }).then(
          handleRequest({
            onSuccess: onCancel,
          }),
        );
      });
    }
  };

  const handleSendToRevisionDecision = () => {
    if (params.id) {
      form.validateFields().then(() => {
        sendToRevisionDecision({
          id: params.id!,
          comment: form.getFieldValue(FORM_ENUM.COMMENT),
        }).then(
          handleRequest({
            onSuccess: onCancel,
          }),
        );
      });
    }
  };

  const isLoading = rejectIsLoading || isRevisionLoading;

  return (
    <ModalDefault open={isOpen} onCancel={onCancel} closable>
      <ModalDefault.Icon name="delete" type="danger" />
      <ModalDefault.Title>{t('suggestions_decision_reject_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestions_decision_reject_description')}
      </ModalDefault.Description>

      <Form form={form} className={s.form}>
        <Form.Item
          label={t('common_comment')}
          name={FORM_ENUM.COMMENT}
          rules={[formRules.required]}>
          <TextArea placeholder={t('common_enter_text')} maxLength={240} showCount />
        </Form.Item>
        <ModalDefault.Actions>
          <Flex gap="16">
            <Button
              fullWidth
              leftIcon="return"
              htmlType="button"
              loading={isLoading}
              onClick={handleSendToRevisionDecision}>
              {t('common_send_to_revision')}
            </Button>
            <Button
              danger
              ghost
              fullWidth
              htmlType="button"
              onClick={handleRejectDecision}
              loading={isLoading}>
              {t('common_reject')}
            </Button>
          </Flex>
        </ModalDefault.Actions>
      </Form>
    </ModalDefault>
  );
};

export default ModalReject;
