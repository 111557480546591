import { useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { GUIDE_IMPROVEMENTS_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import {
  useEditArticleMutation,
  useGetArticleByCodeQuery,
} from 'services/article/articleApiService';
import { EditArticlePropsType } from 'services/article/articleApiService.types';

import { Button, Flex, useHandleRequestWithModal } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import ImprovementsForm from 'containers/improvements-form';
import {
  FORM_NAME,
  IMPROVEMENTS_FORM_ENUM,
  ImprovementsFormResponse,
} from 'containers/improvements-form/newsForm.utils';
import 'containers/news-form/newsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';

import s from './ImprovementsDetails.module.scss';

enum SUBMIT_ACTION_ENUM {
  SAVE = 'save',
  SAVE_AND_PUBLISH = 'saveAndPublish',
  UNPUBLISH = 'unpublish',
}

const ImprovementsDetails = () => {
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();

  const submitActionRef = useRef<SUBMIT_ACTION_ENUM | null>(null);

  const { data: currentArticle, isFetching: getCurrentArticleIsFetching } =
    useGetArticleByCodeQuery(code!, { skip: !code });

  const [editArticle, { isLoading: editArticleIsFetching }] = useEditArticleMutation();

  const handleReset = () => navigate(GUIDE_IMPROVEMENTS_ROUTE);

  const handleEdit = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
    },
  });

  const handleSubmit = (form: ImprovementsFormResponse) => {
    if (!currentArticle?.code) return;

    const body: EditArticlePropsType = {
      type: ARTICLE_TYPE_ENUM.ORG_ARTICLE,
      text: form[IMPROVEMENTS_FORM_ENUM.TEXT],
      title: form[IMPROVEMENTS_FORM_ENUM.TITLE],
      ...(submitActionRef.current === SUBMIT_ACTION_ENUM.SAVE_AND_PUBLISH
        ? { isPublish: true }
        : submitActionRef.current === SUBMIT_ACTION_ENUM.UNPUBLISH
        ? { isPublish: false }
        : {}),
      code: currentArticle?.code,
    };

    if (form[IMPROVEMENTS_FORM_ENUM.COVER]) {
      body.cover = form[IMPROVEMENTS_FORM_ENUM.COVER];
    }
    editArticle(body).then(handleEdit);
  };

  const handleSetSubmitAction = (action: SUBMIT_ACTION_ENUM) => () =>
    (submitActionRef.current = action);

  return (
    <WithLoader isLoading={getCurrentArticleIsFetching}>
      <WithOverflow>
        <ContentHeader showBorder align="flex-start">
          <ContentHeader.Title goBackLink={GUIDE_IMPROVEMENTS_ROUTE}>
            {currentArticle?.title}
          </ContentHeader.Title>
          <ContentHeader.Actions>
            <Button leftIcon="ok" htmlType="submit" form={FORM_NAME}>
              {t('common_save')}
            </Button>
          </ContentHeader.Actions>
        </ContentHeader>

        <div className={s.formWrapper}>
          <ImprovementsForm
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            currentArticle={currentArticle}
          />
          <Flex justify="space-between" gap="12">
            <Button
              leftIcon="ok"
              fullWidth
              type="primary"
              htmlType="submit"
              form={FORM_NAME}
              loading={editArticleIsFetching}
              onClick={handleSetSubmitAction(SUBMIT_ACTION_ENUM.SAVE)}>
              {t('common_save')}
            </Button>
            {!currentArticle?.isPublish && (
              <Button
                leftIcon="ok"
                type="secondary"
                fullWidth
                htmlType="submit"
                form={FORM_NAME}
                loading={editArticleIsFetching}
                onClick={handleSetSubmitAction(SUBMIT_ACTION_ENUM.SAVE_AND_PUBLISH)}>
                {t('common_save_and_publish')}
              </Button>
            )}
            {currentArticle?.isPublish && (
              <Button
                leftIcon="unpublish"
                type="secondary"
                fullWidth
                danger
                htmlType="submit"
                form={FORM_NAME}
                loading={editArticleIsFetching}
                onClick={handleSetSubmitAction(SUBMIT_ACTION_ENUM.UNPUBLISH)}>
                {t('common_unpublish')}
              </Button>
            )}
          </Flex>
        </div>
      </WithOverflow>
    </WithLoader>
  );
};

export default ImprovementsDetails;
