import { FormInstance } from 'gazprom-ui-lib';

import { ExpertCommissionType } from 'types/expertCommission.types';

export const EXPERT_COMMISSION_FORM_NAME = 'EXPERT_COMMISSION_FORM_NAME';

export enum EXPERT_COMMISSION_FORM_ENUM {
  NAME = 'NAME',
  ORGANIZATION = 'ORGANIZATION',
  HEAD = 'HEAD',
  MEMBERS = 'MEMBERS',
  COORDINATORS = 'COORDINATORS',
}

export type ExpertCommissionFormResponseType = {
  [EXPERT_COMMISSION_FORM_ENUM.NAME]: string;
  [EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION]: string;
  [EXPERT_COMMISSION_FORM_ENUM.HEAD]: string;
  [EXPERT_COMMISSION_FORM_ENUM.MEMBERS]: string[];
  [EXPERT_COMMISSION_FORM_ENUM.COORDINATORS]: string[];
};

export const initExpertCommissionForm = (
  form: FormInstance,
  expertCommission?: ExpertCommissionType,
) => {
  if (expertCommission) {
    form.setFieldsValue({
      [EXPERT_COMMISSION_FORM_ENUM.NAME]: expertCommission.name,
      [EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION]: expertCommission.employer.id,
      [EXPERT_COMMISSION_FORM_ENUM.HEAD]: expertCommission.head.id,
      [EXPERT_COMMISSION_FORM_ENUM.MEMBERS]: expertCommission.members?.map((m) => m.id),
      [EXPERT_COMMISSION_FORM_ENUM.COORDINATORS]: expertCommission.coordinators?.map((m) => m.id),
    });
  }
};
