import { GLOBAL_ROLES } from 'types/roles.types';

export const kedoRoles = {
  roles: [
    GLOBAL_ROLES.KEDO_ADMIN,
    GLOBAL_ROLES.KEDO_MODERATOR,
    GLOBAL_ROLES.ADMIN_MOBILE,
    GLOBAL_ROLES.ADMIN,
  ],
};

export const replacementsRoles = {
  roles: [GLOBAL_ROLES.KEDO_ADMIN, GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN],
};
