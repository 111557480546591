import { t } from 'tools/i18n';
import { createMediaDownloadUrl, createMediaUploadUrl } from 'utils/createMediaUrl.utils';

import { Button, Flex, TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import TableText from 'components/table/table-text';

import { ShortCreatorEditorType } from 'types/creatorEditor.types';

const useColumns = (): TableProps<{ name: string }>['columns'] => {
  const personRenderer = (person: ShortCreatorEditorType) => {
    const { firstName, lastName } = person;

    return <TablePerson firstName={firstName} lastName={lastName} />;
  };

  return [
    {
      title: t('common_fio'),
      dataIndex: 'person',
      width: 260,
      render: personRenderer,
    },
    {
      title: t('user_agreement_date'),
      dataIndex: 'consentDate',
      align: 'center',
      render: (date) => <TableDate date={date} />,
    },
    {
      title: t('user_agreement_file'),
      dataIndex: 'fileName',
      render: (fileName) => (
        <TableText size="14" weight="500">
          {fileName}
        </TableText>
      ),
    },
    {
      title: '',
      dataIndex: 'fileKey',
      align: 'right',
      render: (fileKey) => (
        <Flex justify="flex-end">
          <Button
            leftIcon="download"
            type="link"
            size="small"
            onClick={(e) => {
              e.stopPropagation();

              if (fileKey) window.open(createMediaDownloadUrl(createMediaUploadUrl(fileKey)));
            }}>
            {t('common_download_file')}
          </Button>
        </Flex>
      ),
    },
  ];
};

export default useColumns;
