import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';
import {
  ApproveDecisionsByCommissionsPropsType,
  ApproveDecisionsByCommissionsResponseType,
  GetSuggestionByIdPropsType,
  GetSuggestionByIdResponseType,
  GetSuggestionCategoriesPropsType,
  GetSuggestionCategoriesResponseType,
  GetSuggestionDecisionByIdPropsType,
  GetSuggestionDecisionByIdResponseType,
  GetSuggestionDecisionPerformersPropsType,
  GetSuggestionDecisionPerformersResponseType,
  GetSuggestionDecisionsPropsType,
  GetSuggestionDecisionsResponseType,
  GetSuggestionDecisionsStatusesPropsType,
  GetSuggestionDecisionsStatusesResponseType,
  GetSuggestionStatusesPropsType,
  GetSuggestionStatusesResponseType,
  GetSuggestionsPropsType,
  GetSuggestionsResponseType,
  RejectDecisionsByCommissionsPropsType,
  RejectDecisionsByCommissionsResponseType,
  RevisionDecisionsByCommissionsPropsType,
  RevisionDecisionsByCommissionsResponseType,
  TakeCoordinationDecisionsByCommissionsPropsType,
  TakeCoordinationDecisionsByCommissionsResponseType,
} from 'services/suggestion/suggestionApiService.types';
import { createSuggestionUrl } from 'services/suggestion/utils';

import { SERVICE_TAGS } from 'constants/serviceTags';

const suggestionApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<GetSuggestionsResponseType, GetSuggestionsPropsType>({
      query: (params) => ({
        url: createSuggestionUrl(prepareQueryParams(params)),
      }),
    }),
    getSuggestionById: builder.query<GetSuggestionByIdResponseType, GetSuggestionByIdPropsType>({
      query: ({ id, ...params }) => ({
        url: createSuggestionUrl(`/${id}${prepareQueryParams(params)}`),
      }),
    }),
    getSuggestionStatuses: builder.query<
      GetSuggestionStatusesResponseType,
      GetSuggestionStatusesPropsType
    >({
      query: (params) => ({
        url: createSuggestionUrl(`-status${prepareQueryParams(params)}`),
      }),
    }),
    getSuggestionDecisionsStatuses: builder.query<
      GetSuggestionDecisionsStatusesResponseType,
      GetSuggestionDecisionsStatusesPropsType
    >({
      query: () => ({
        url: createSuggestionUrl(`-decisions-status`),
      }),
    }),
    getSuggestionCategories: builder.query<
      GetSuggestionCategoriesResponseType,
      GetSuggestionCategoriesPropsType
    >({
      query: () => ({
        url: createSuggestionUrl('-category'),
      }),
    }),
    getSuggestionDecisions: builder.query<
      GetSuggestionDecisionsResponseType,
      GetSuggestionDecisionsPropsType
    >({
      query: (params) => ({
        url: createSuggestionUrl(`-decisions${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SUGGESTION_DECISIONS],
    }),
    getSuggestionDecisionById: builder.query<
      GetSuggestionDecisionByIdResponseType,
      GetSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createSuggestionUrl(`-decisions/${id}${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID],
    }),
    getSuggestionDecisionPerformers: builder.query<
      GetSuggestionDecisionPerformersResponseType,
      GetSuggestionDecisionPerformersPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createSuggestionUrl(`/${id}/performers${prepareQueryParams(params)}`),
      }),
    }),
    rejectDecisionsByCommissions: builder.mutation<
      RejectDecisionsByCommissionsResponseType,
      RejectDecisionsByCommissionsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createSuggestionUrl(`-decisions/${id}/reject`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_SUGGESTION_DECISIONS,
        SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID,
      ],
    }),
    revisionDecisionsByCommission: builder.mutation<
      RevisionDecisionsByCommissionsResponseType,
      RevisionDecisionsByCommissionsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createSuggestionUrl(`-decision/${id}/revision`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_SUGGESTION_DECISIONS,
        SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID,
      ],
    }),
    approveDecisionsByCommission: builder.mutation<
      ApproveDecisionsByCommissionsResponseType,
      ApproveDecisionsByCommissionsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createSuggestionUrl(`-decision/${id}/approve`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_SUGGESTION_DECISIONS,
        SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID,
      ],
    }),
    takeCoordinationDecisionsByCommission: builder.mutation<
      TakeCoordinationDecisionsByCommissionsResponseType,
      TakeCoordinationDecisionsByCommissionsPropsType
    >({
      query: ({ id }) => ({
        url: createSuggestionUrl(`-decision/${id}/coordinator-take`),
        method: 'PATCH',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_SUGGESTION_DECISIONS,
        SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID,
      ],
    }),
  }),
});

export const {
  useTakeCoordinationDecisionsByCommissionMutation,
  useGetSuggestionDecisionsStatusesQuery,
  useLazyGetSuggestionsQuery,
  useGetSuggestionByIdQuery,
  useLazyGetSuggestionDecisionsQuery,
  useGetSuggestionDecisionByIdQuery,
  useGetSuggestionDecisionPerformersQuery,
  useApproveDecisionsByCommissionMutation,
  useRejectDecisionsByCommissionsMutation,
  useRevisionDecisionsByCommissionMutation,
  useGetSuggestionCategoriesQuery,
  useGetSuggestionStatusesQuery,
} = suggestionApiService;
