import { useNavigate } from 'react-router-dom';
import {
  SETTINGS_TELEGRAM_CHANNELS_ROUTE,
  getTelegramChannelWithIdRoute,
} from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useCreateTelegramChannelMutation } from 'services/telegram-channel/telegramChannelApiService';
import { CreateTelegramChannelPropsType } from 'services/telegram-channel/telegramChannelApiService.types';

import { Button, Flex, Icon, Typography, useHandleRequestWithModal } from 'gazprom-ui-lib';

import TelegramChannelForm from 'containers/telegram-channel-form/telegramChannelForm';
import {
  TELEGRAM_CHANNEL_FORM_ENUM,
  TELEGRAM_CHANNEL_FORM_NAME,
  TelegramChannelFormResponse,
} from 'containers/telegram-channel-form/telegramChannelForm.utils';

import { SETTINGS_TELEGRAM_CHANNELS_TAB } from '../../settingsTelegramChannels.utils';
import s from './SettingsTelegramAdd.module.scss';

const SettingsTelegramAdd = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(SETTINGS_TELEGRAM_CHANNELS_ROUTE, {
      state: {
        activeTab: SETTINGS_TELEGRAM_CHANNELS_TAB,
      },
    });
  };

  const [createTelegramChannel, { isLoading: createTelegramChannelIsFetching }] =
    useCreateTelegramChannelMutation();

  const handleNavigateDetails = (data?: { id: string }) => {
    if (data) {
      navigate(getTelegramChannelWithIdRoute(data.id));
    }
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccess: handleNavigateDetails,
    onSuccessModalConfig: {
      title: {
        children: t('common_created_successfully'),
      },
    },
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'warning',
      },
      title: {
        children: t('common_something_went_wrong'),
      },
      description: {
        children: t('telegram_create_error_description'),
      },
    },
  });

  const onSubmit = (form: TelegramChannelFormResponse) => {
    const requestBody: CreateTelegramChannelPropsType = {
      telegramTitle: form[TELEGRAM_CHANNEL_FORM_ENUM.TELEGRAM_TITLE],
      link: form[TELEGRAM_CHANNEL_FORM_ENUM.LINK],
      categories: form[TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES],
      active: form[TELEGRAM_CHANNEL_FORM_ENUM.ACTIVE],
    };

    createTelegramChannel(requestBody).then(handleCreate);
  };

  return (
    <>
      <div className={s.actionsHeader}>
        <Button htmlType="button" type="link" leftIcon="arrowLeft" onClick={handleGoBack}>
          {t('common_go_back')}
        </Button>
        <Button leftIcon="ok" htmlType="submit" form={TELEGRAM_CHANNEL_FORM_NAME}>
          {t('common_save')}
        </Button>
      </div>
      <div className={s.formWrapper}>
        <Flex vertical gap="8">
          <Typography.Title level={3}>{t('telegram_add')}</Typography.Title>
          <Flex gap="8">
            <Icon color="var(--color-error)" name="info" />
            <Typography.Text type="secondary" size="14">
              {t('telegram_create_channel_hint')}
            </Typography.Text>
          </Flex>
        </Flex>

        <TelegramChannelForm onSubmit={onSubmit} />
        <div className={s.actionsFooter}>
          <Button
            leftIcon="ok"
            fullWidth
            type="primary"
            htmlType="submit"
            form={TELEGRAM_CHANNEL_FORM_NAME}
            loading={createTelegramChannelIsFetching}>
            {t('common_save')}
          </Button>
          <Button
            onClick={handleGoBack}
            type="secondary"
            fullWidth
            htmlType="reset"
            form={TELEGRAM_CHANNEL_FORM_NAME}>
            {t('common_close')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SettingsTelegramAdd;
