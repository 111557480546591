import { useDebounce } from 'utils/useDebounce.hook';

import { useGetUploadedShiftAnswerFileQuery } from 'services/1c/shift/shiftApiService';
import { GetUploadedShiftAnswerFilePropsType } from 'services/1c/shift/shiftApiService.types';

import { TableFormResponseType } from './surveyAnketaImport.utils';

interface Props {
  requestBody: TableFormResponseType;
}

export const useRequest = (props: Props) => {
  const { requestBody } = props;

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, property, order, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetUploadedShiftAnswerFilePropsType = {
    ...clearedRequestBody,
    ...(search && { search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { items: dataSource, totalCount = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetUploadedShiftAnswerFileQuery(actualRequestBody, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.search?.trim();

  return { isInitialLoading, loading, dataSource, total: totalCount, hasFiltersApplied };
};
