import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import { SuggestionType } from 'types/suggestion.types';

import { getNeedExpensesTitle } from '../../suggestionsListDetails.utils';
import FilesField from '../files-field';
import TextField from '../text-field';

interface Props extends SuggestionType {}

const Description = (props: Props) => {
  const {
    id,
    problem,
    suggestion,
    expectation,
    needExpenses,
    personalParticipation,
    problemFiles,
    suggestionFiles,
  } = props;
  const participationTitle = personalParticipation ? t('common_yes') : t('common_no');

  return (
    <Flex vertical gap="24">
      <TextField title={t('suggestion_problem_description')} value={problem} />
      <FilesField
        title={t('suggestion_photo_video_problem')}
        files={problemFiles}
        suggestionId={id}
      />
      <TextField title={t('suggestion_idea')} value={suggestion} />
      <FilesField
        title={t('suggestion_photo_video_idea')}
        files={suggestionFiles}
        suggestionId={id}
      />
      <TextField title={t('suggestion_expected_result')} value={expectation} />
      <TextField title={t('suggestion_need_expenses')} value={getNeedExpensesTitle(needExpenses)} />
      <TextField title={t('suggestion_participation')} value={participationTitle} />
    </Flex>
  );
};

export default Description;
