import { useContext } from 'react';

import { t } from 'tools/i18n';

import cInitialApiService from 'services/1c/cApiService';
import { useUploadSurveyXlsMutation } from 'services/1c/shift/shiftApiService';
import { useAppDispatch } from 'store';

import { Button, SaveModalContext, useHandleRequestWithModal } from 'gazprom-ui-lib';

import { C_SERVICE_TAGS } from 'constants/serviceTags';

import { SurveysAnketaFormResponse } from './uploadSurveyAnketaForm.utils';

interface Props {
  handleClose: () => void;
  openUploadModal: () => void;
}

export const useSubmit = (props: Props) => {
  const { handleClose, openUploadModal } = props;

  const [uploadSurveyXls, { isLoading }] = useUploadSurveyXlsMutation();
  const dispatch = useAppDispatch();

  const invalidateTaskAssignmentQueries = () => {
    dispatch(
      cInitialApiService.util.invalidateTags([C_SERVICE_TAGS.GET_UPLOADED_SHIFT_ANSWER_FILE]),
    );
  };

  const handleSuccess = () => {
    invalidateTaskAssignmentQueries();
    handleClose();
  };

  const setModalConfig = useContext(SaveModalContext);

  const handleOnErrorModalClick = () => {
    if (setModalConfig) {
      setModalConfig(null);
      openUploadModal();
    }
  };

  const handleUploadWithModal = useHandleRequestWithModal({
    callOnSuccessWithoutData: true,
    onSuccess: handleSuccess,
    onSuccessModalConfig: {
      icon: {
        name: 'ok',
        type: 'success',
      },
      title: {
        children: t('common_ulpoaded_successfully'),
      },
      description: {
        children: t('survey_anketa_upload_success_description'),
      },
    },
    onError: handleClose,
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'danger',
      },
      title: {
        children: t('common_something_went_wrong'),
      },
      description: {
        children: t('survey_anketa_upload_error_description'),
      },
      actions: (
        <Button onClick={handleOnErrorModalClick} leftIcon="refresh" type="secondary">
          {t('common_retry_again')}
        </Button>
      ),
    },
  });

  const handleSubmit = (formResponse: SurveysAnketaFormResponse) => {
    const { file } = formResponse;

    const formData = new FormData();
    formData.append('file', file);

    uploadSurveyXls({ file: formData }).then(handleUploadWithModal);
  };

  return { handleSubmit, isLoading };
};
