import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';
import TableText from 'components/table/table-text';

import { CInstanceType } from 'types/cInstances.types';

import s from './Table.module.scss';

interface UseColumnsProps {
  setCInstanceToDelete: Dispatch<SetStateAction<string[]>>;
  isShiftTab?: boolean;
}

const useColumns = (props: UseColumnsProps): TableProps<CInstanceType>['columns'] => {
  const { setCInstanceToDelete, isShiftTab } = props;

  const nameRenderer = (name: string) => (
    <TableText size="16" weight="500">
      {name}
    </TableText>
  );

  const urlRenderer = (url: string) => (
    <TableText size="16" weight="500">
      {url}
    </TableText>
  );

  const statusRenderer = (value: boolean) => <TableIsPublish isPublish={value} />;

  const createDateRenderer = (date: string) => <TableDate date={date} />;

  const actionRenderer = (row: CInstanceType) => {
    return (
      <Flex justify="flex-start" align="center" className={s.actions}>
        <Button
          leftIcon="binFilled"
          type="text"
          danger
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setCInstanceToDelete([row.id]);
          }}>
          {t('common_delete')}
        </Button>
      </Flex>
    );
  };

  return [
    {
      width: 300,
      title: t('1c_instance_base_name'),
      dataIndex: 'name',
      key: 'name',
      render: nameRenderer,
    },
    {
      title: t('1c_instance_url_address'),
      dataIndex: 'url',
      key: 'url',
      render: urlRenderer,
    },
    {
      hidden: isShiftTab,
      title: t('common_synchronization'),
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      sorter: true,
      render: statusRenderer,
    },
    {
      width: 120,
      title: t('common_created'),
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      sorter: true,
      render: createDateRenderer,
      defaultSortOrder: 'descend',
    },
    {
      width: 120,
      render: actionRenderer,
    },
  ];
};

export default useColumns;
