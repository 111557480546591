import { RouteConfig } from 'routes/routes';

import GuideCategories from 'pages/guide/guide-categories';
import GuideContacts from 'pages/guide/guide-contacts';
import GuideContactsEdit from 'pages/guide/guide-contacts/guide-contacts-edit';
import GuideHandbook from 'pages/guide/guide-handbook';
import GuideHandbookCreate from 'pages/guide/guide-handbook/guide-handbook-create';
import GuideHandbookDetails from 'pages/guide/guide-handbook/guide-handbook-details';
import GuideImprovements from 'pages/guide/guide-improvements';
import ImprovementsAdd from 'pages/guide/guide-improvements/improvements-add';
import ImprovementsDetails from 'pages/guide/guide-improvements/improvements-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import {
  GUIDE_ADD_IMPROVEMENTS_ROUTE,
  GUIDE_CATEGORIES_ROUTE,
  GUIDE_CONTACTS_EDIT_ID_ROUTE,
  GUIDE_CONTACTS_ROUTE,
  GUIDE_HANDBOOK_CREATE_ROUTE,
  GUIDE_HANDBOOK_DETAILS_ROUTE,
  GUIDE_HANDBOOK_ROUTE,
  GUIDE_IMPROVEMENTS_BY_ID_ROUTE,
  GUIDE_IMPROVEMENTS_ROUTE,
} from './list';

export const guideRoutes: RouteConfig[] = [
  {
    path: GUIDE_HANDBOOK_ROUTE,
    element: <GuideHandbook />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_CONTACTS_ROUTE,
    element: <GuideContacts />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_CONTACTS_EDIT_ID_ROUTE,
    element: <GuideContactsEdit />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_CATEGORIES_ROUTE,
    element: <GuideCategories />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_HANDBOOK_CREATE_ROUTE,
    element: <GuideHandbookCreate />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_HANDBOOK_DETAILS_ROUTE,
    element: <GuideHandbookDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_IMPROVEMENTS_ROUTE,
    element: <GuideImprovements />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_ADD_IMPROVEMENTS_ROUTE,
    element: <ImprovementsAdd />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: GUIDE_IMPROVEMENTS_BY_ID_ROUTE,
    element: <ImprovementsDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
];
