import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetHandbooksQuery } from 'services/settlement/settlementApiService';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

const useFilterItems = () => {
  const [searchCategory, setSearchCategory] = useState<string>();

  const { isDebouncing } = useDebounce(searchCategory);

  const { data: { items: categoriesOptions = [] } = {} } = useGetHandbooksQuery(
    { page: 1, size: 500, name: searchCategory },
    { skip: isDebouncing },
  );

  const options = useMemo(() => {
    return categoriesOptions.map((el) => ({ value: el.id, label: el.name }));
  }, [categoriesOptions]);

  return useMemo(
    () => [
      {
        key: 'settlementIds',
        label: <FilterHeader valueKey="settlementIds">{t('common_settlement')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="settlementIds"
            options={options}
            showSearch
            searchProps={{
              placeholder: t('news_filters_category'),
              value: searchCategory,
              onChange: (e) => setSearchCategory(e.target.value || undefined),
            }}
          />
        ),
      },
    ],
    [options, searchCategory],
  );
};

export default useFilterItems;
