import { useState } from 'react';

import { useAuth } from 'react-oidc-context';
import useFullSelectedEmployee, { SELECTED_EMPLOYEE_KEY } from 'utils/useFullSelectedEmployee.hook';

import cInitialApiService from 'services/1c/cApiService';
import { useAppDispatch } from 'store';

import { Avatar, Dropdown, Flex, HandleItemClickType, Icon, Typography } from 'gazprom-ui-lib';

import { C_SERVICE_TAGS } from 'constants/serviceTags';

import s from './Header.module.scss';
import HeaderLogo from './header-logo';
import useMenuItems from './useMenuItems';

const APP_CONTENT = 'app-content';
const APP_FOOTER = 'app-footer';

const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedEmployee] = useFullSelectedEmployee();

  const { staff, employer, person } = selectedEmployee || {};
  const { firstName, lastName, patronymic } = person || {
    firstName: '',
    lastName: '',
    patronymic: '',
  };
  const staffName = staff?.name;
  const employerName = employer?.name;
  const fullName = `${lastName} ${firstName} ${patronymic}`;

  const auth = useAuth();

  const items = useMenuItems();

  const onLogout = () => {
    auth.signoutSilent();
    localStorage.removeItem(SELECTED_EMPLOYEE_KEY);
  };

  const onOpenChange = (flag: boolean) => {
    setIsOpen(flag);
    if (flag) {
      document.getElementById(APP_CONTENT)!.style.opacity = '0.3';
      document.getElementById(APP_FOOTER)!.style.opacity = '0.3';
    } else {
      document.getElementById(APP_CONTENT)!.style.opacity = '1';
      document.getElementById(APP_FOOTER)!.style.opacity = '1';
    }
  };

  const dispatch = useAppDispatch();

  const invalidateTaskAssignmentQueries = () => {
    dispatch(
      cInitialApiService.util.invalidateTags([
        C_SERVICE_TAGS.GET_TASK_ASSIGNMENTS,
        C_SERVICE_TAGS.ASSIGNMENT_BY_ID,
      ]),
    );
  };

  const handleItemClick: HandleItemClickType = (item) => {
    if (item.key === 'logout') {
      return onLogout();
    } else {
      invalidateTaskAssignmentQueries();
    }
  };

  return (
    <div className={s.wrapper}>
      <HeaderLogo />
      <Dropdown
        align={{ offset: [16, 8] }}
        items={items}
        handleItemClick={handleItemClick}
        onOpenChange={onOpenChange}
        overlayClassName={s.overlay}>
        <Flex gap="8" align="center" className={s.container}>
          <Avatar
            firstName={lastName}
            lastName={firstName}
            alt="аватар пользователя"
            size="40"
            color="#21587A"
          />
          <Flex vertical className={s.textContainer}>
            <Typography.Text size="10" weight="400" type="secondary">
              {employerName}
            </Typography.Text>
            <Typography.Text size="12" weight="500">
              {fullName}
            </Typography.Text>
            <Typography.Text size="10" weight="400" type="secondary">
              {staffName}
            </Typography.Text>
          </Flex>
          <Icon name={isOpen ? 'up' : 'down'} />
        </Flex>
      </Dropdown>
    </div>
  );
};

export default Header;
