import {
  GenericPaginationResponseType,
  PaginationPropsType,
  PaginationResponseType,
} from 'types/pagination.types';
import { SortType } from 'types/sort.types';
import {
  EmployeeGroup,
  FreeTableData,
  NewSurveyType,
  QuestionStatsType,
  RespondentType,
  SURVEY_STATUSES_ENUM,
  SettlementSurveyHistoryAssigment,
  SurveySettlementCreateOrUpdateType,
  SurveySettlementType,
  SurveyType,
} from 'types/surveys.types';

import { mapFreetable } from './utils';

export enum CONDITION_SURVEY_ENUM {
  ONCE = 'ONCE',
  EVENT = 'EVENT',
}

export type GetSurveysPropsType = PaginationPropsType & {
  name?: string;
  condition: CONDITION_SURVEY_ENUM;
} & SortType;

export type GetSurveysResponseType = PaginationResponseType & {
  items: SurveyType[];
};

export type GetSurveysListResponseType = {
  id: number;
  revision_id: number;
  settings: {
    name: string;
  };
};

export type GetSurveysEmployeeGroupsResponseType = {
  content: EmployeeGroup[];
};

export type CreateSurveyRequestType = NewSurveyType;
export type CreateSurveyResponseType = {
  id: string;
  anketologId: string;
  revisionId: number;
};

export type UpdateSurveyRequestType = {
  id: string;
  body: NewSurveyType;
};

export type GetSurveyByIdRequestType = { survey_id: string };
export type GetSurveyByIdResponseType = {
  id: number;
  create_date: number;
  update_date: number;
  lock_date: null;
  archive_date: null;
  suspend_date: null;
  is_suspended: false;
  is_locked: false;
  is_archive: false;
  revision_id: 2265310;
  meta: {
    start_count: 0;
    answer_count: 0;
    answer_count_today: 0;
    disqualify_count: 0;
    disqualify_count_today: 0;
    page_count: 1;
    question_count: 18;
  };
  settings: {
    name: string;
    survey_is_time_limited: false;
    survey_time_limit: 0;
    archive_on_date: false;
    archive_date: 0;
    archive_on_answer_count: false;
    archive_answer_count: null;
  };
};

export type GetSurveyStatsByIdPropsType = {
  id: string;
  revisionId: string;
  positionId?: string;
  employerId?: string;
  divisionId?: string;
  from?: string;
  to?: string;
  options?: string;
  qoaQuery?: string;
};

export type StatisticsType = {
  freeQuestionId: string | null;
  hasFreeAnswers: boolean;
  options: { type: string; optionId: string; value: number }[];
  questionId: number;
  title: string;
  type: string;
};

export type GetSurveyStatsByIdResponseType = {
  questions: QuestionStatsType[];
  statistics: StatisticsType[];
};

export type GetFreeStatsByIdPropsType = {
  id: string;
  revisionId: string;
  questionId: string | number;
  text?: string;
  positionId?: string;
  employerId?: string;
  divisionId?: string;
  from?: string;
  to?: string;
  qoaQuery?: string | undefined;
  freeQuestionId?: number | null;
} & PaginationPropsType;

export type GetFreeStatsByIdResponseType = {
  items: FreeTableData[];
} & PaginationResponseType;

export type MappedFreeStatsResponseType = ReturnType<typeof mapFreetable>;

export type GetEmployerTransformedResponseType = { value: string; label: string }[];
export type GetEmployerResponseType = {
  content: {
    id: string;
    name: string;
  }[];
} & PaginationResponseType;
export type GetEmployerRequestType = PaginationPropsType;

export type GetSurveysEmployeeGroupsRequestType = PaginationPropsType;

export type GetRespondentsPropsType = {
  anketaId?: string;
} & PaginationPropsType;

export type GetRespondentsResponseType = {
  items: RespondentType[];
} & PaginationResponseType;

export type GetSurveyStatusByIdResponseType = {
  status: SURVEY_STATUSES_ENUM | null;
  startedAt: string;
  timePassed: number;
  chronoUnit: string;
  linkedCount: number;
  totalCount: number;
};

export type GetSurveyStatusByIdPropsType = {
  id: string;
};

export type GetSurveySettlementsResponseType = GenericPaginationResponseType<
  SurveySettlementType[]
>;
export type GetSurveySettlementsPropsType = {
  name?: string;
  isPublish?: boolean;
} & PaginationPropsType &
  SortType;

export type GetSurveySettlementByIdPropsType = { surveyId: string };
export type GetSurveySettlementByIdResponseType = SurveySettlementType;

export type CreateUpdateSurveySettlementResponseType = SurveySettlementType;
export type CreateSurveySettlementPropsType = SurveySettlementCreateOrUpdateType;
export type UpdateSurveySettlementPropsType = {
  surveyId: string;
  payload: SurveySettlementCreateOrUpdateType;
};
export type PublishUnpublishSettlementPropsType = { surveyId: string };

export type GetSurveySettlementStatsByIdPropsType = {
  surveyId: string;
  revisionId: string;
  employerId?: string;
  divisionId?: string;
  positionId?: string;
  settlementId?: string;
  text?: string;
  from?: string;
  to?: string;
  qoaQuery?: string | undefined;
};

export type GetSurveySettlementStatsByIdResponseType = GetSurveyStatsByIdResponseType;

export type GetSurveySettlementAllFreeStatsByIdResponseType = MappedFreeStatsResponseType;
export type GetSurveySettlementAllFreeStatsByIdPropsType = {
  surveyId: string;
  revisionId: string;
  questionId: string | number;
  text?: string;
  positionId?: string;
  employerId?: string;
  divisionId?: string;
  settlementId?: string;
  from?: string;
  to?: string;
  qoaQuery?: string | undefined;
  freeQuestionId?: number | null;
} & PaginationPropsType;

export type GetSurveySettlementRespondentsPropsType = {
  surveyId: string;
} & PaginationPropsType;

export type GetSurveySettlementRespondentsResponseType = GetRespondentsResponseType;

export type ReAssignSettlementSurveyPropsType = {
  surveyId: string;
};

export type GetSettlementsWithAssignmentPropsType = string;
export type GetSettlementsWithAssignmentResponseType = {
  value: string;
  label: string;
  assignment: boolean;
}[];

export type GetSettlementSurveyHistoryAssigmentPropsType = {
  surveyId: string;
} & PaginationPropsType;

export type GetSettlementSurveyHistoryAssigmentResponseType = {
  items: SettlementSurveyHistoryAssigment[];
} & PaginationResponseType;

export type GetSettlementSurveyHistoryLotteryPropsType = {
  surveyAssignmentHistoryId: string;
  isWinner?: boolean;
} & PaginationPropsType;

export type GetSettlementSurveyHistoryLotteryResponseType = {
  items: SettlementSurveyHistoryAssigment[];
} & PaginationResponseType;

export type GetSettlementSurveyHistoryAssigmentByIdPropsType = {
  id: string;
  surveyId: string;
};
export type GetSettlementSurveyHistoryAssigmentByIdResponseType = SettlementSurveyHistoryAssigment;

export type GetSettlementSurveyHistoryAssigmentByIdSettlementsListPropsType = {
  id: string;
  surveyId: string;
} & PaginationPropsType;

export type LotterySettlementHistoryItemType = {
  id: string;
  name: string;
};

export type GetSettlementSurveyHistoryAssigmentByIdSettlementsListResponseType = {
  items: LotterySettlementHistoryItemType[];
} & PaginationResponseType;

export type GetSettlementSurveyHistoryWinnersPropsType = {
  surveyAssignmentHistoryId: string;
  isWinner: boolean;
} & PaginationPropsType;

export type SettlementSurveyHistoryWinnerType = {
  id: string;
  employeeId: string;
  settlementId: string;
  randomNumber: number;
  timestamp: string;
  lotteryDate: string;
  isWinner: boolean;
  surveyAssignmentHistoryId: string;
};

export type GetSettlementSurveyHistoryWinnersResponseType = {
  items: SettlementSurveyHistoryWinnerType[];
} & PaginationResponseType;

export type SendSettlementSurveyNotificationsPropsType = {
  surveyId: string;
  id: string;
  body: {
    allowFeedback: boolean;
    sendEmail: boolean;
    sendApp: boolean;
    sendPush: boolean;
    title: string;
    body: string;
  };
};

export type SendSettlementSurveyNotificationsResponseType = void;

export type GetNotificationsPropsType = {
  packageId: string;
} & PaginationPropsType;

export type GetNotificationsResponseType = {};
