import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './MultiSelectSubtitleCopy.module.scss';

interface Props {
  option:
    | {
        value: string;
        label: string;
      }
    | undefined;
  statementVariablesIdCodeMap: Record<string, string>;
}

const MultiSelectSubtitleCopy = (props: Props) => {
  const { option, statementVariablesIdCodeMap } = props;
  if (!option) return null;
  const { value: statementVariableId } = option;
  const statementVariableCode = statementVariablesIdCodeMap[statementVariableId];

  const handleCopy = () => navigator.clipboard.writeText(statementVariableCode);

  return (
    <button
      title={t('common_copy_data')}
      className={s.copy}
      onClick={handleCopy}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCopy();
        }
      }}>
      <Typography.Text type="secondary">
        {t('common_variable')}:{' '}
        <Typography.Text weight="500" type="primary">
          {statementVariableCode}
        </Typography.Text>
      </Typography.Text>

      <Icon name="copy" />
    </button>
  );
};

export default MultiSelectSubtitleCopy;
