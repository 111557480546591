import { t } from 'tools/i18n';

import { Avatar, Flex, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

interface Props {
  employee?: EmployeeType;
  title?: string;
  showTitle?: boolean;
  showEmployer?: boolean;
  showAvatar?: boolean;
}

const Employee = (props: Props) => {
  const { employee, title, showTitle = true, showEmployer = true, showAvatar = false } = props;

  const { person, position, employer } = employee ?? {};

  const currentTitle = title || t('common_employee');

  return (
    <Flex vertical gap="8">
      {showTitle && <Typography.Title level={5}>{currentTitle}</Typography.Title>}
      <Flex align="center" gap="8">
        {showAvatar && <Avatar size="40" {...person!} />}
        <Flex vertical>
          {showEmployer && (
            <Typography.Text size="10" type="secondary">
              {employer?.name}
            </Typography.Text>
          )}
          <Typography.Text size="14" weight="500">
            {`${person?.lastName} ${person?.firstName} ${person?.patronymic}`}
          </Typography.Text>
          <Typography.Text size="12" weight="500" type="secondary">
            {position?.name}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Employee;
