import { t } from 'tools/i18n';
import { normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from '../notificationNewsletterForm.utils';
import s from './NotificationNewsletterFormName.module.scss';

interface Props {
  disabled?: boolean;
}

const NotificationNewsletterFormName = (props: Props) => {
  const { disabled } = props;

  return (
    <div>
      <Typography.Text strong size="16">
        {t('notification_newsletter_name')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME}
        rules={[{ max: 120 }]}>
        <TextArea
          disabled={disabled}
          placeholder={t('common_enter_text')}
          maxLength={120}
          showCount
          className={s.title}
        />
      </Form.Item>
      <Typography.Text type="secondary" size="12">
        {t('common_showed_only_for_admin')}
      </Typography.Text>
    </div>
  );
};

export default NotificationNewsletterFormName;
