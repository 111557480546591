import { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { useDebounce } from 'utils/useDebounce.hook';

import {
  useApproveDecisionsByCommissionMutation,
  useGetSuggestionDecisionPerformersQuery,
} from 'services/suggestion/suggestionApiService';

import {
  Button,
  DatePicker,
  Flex,
  Form,
  ModalDefault,
  Select,
  TextArea,
  handleRequest,
} from 'gazprom-ui-lib';

import s from './ModalApprove.module.scss';
import { FORM_ENUM, TFormResponse, prepareOptions } from './modalApprove.utils';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  suggestionId?: string;
}

const ModalApprove = (props: Props) => {
  const { isOpen, onCancel, suggestionId } = props;

  const params = useParams<{ id: string }>();

  const [search, setSearch] = useState<string>('');

  const [form] = Form.useForm<TFormResponse>();
  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { data: performers, isLoading: isPerformersLoading } =
    useGetSuggestionDecisionPerformersQuery(
      {
        id: suggestionId!,
        page: 1,
        size: 500,
        text: debouncedSearch,
      },
      { skip: !suggestionId },
    );
  const [approveDecision, { isLoading }] = useApproveDecisionsByCommissionMutation();

  const handleApproveDecision = (formResponse: TFormResponse) => {
    if (params?.id) {
      approveDecision({ id: params.id, ...formResponse }).then(
        handleRequest({
          onSuccess: onCancel,
        }),
      );
    }
  };
  const disabledDate = (current: Dayjs | null) => {
    return current ? current.isBefore(dayjs(), 'day') : false;
  };
  return (
    <ModalDefault open={isOpen} onCancel={onCancel} closable>
      <ModalDefault.Icon name="ok" type="primary" />
      <ModalDefault.Title>{t('suggestions_decision_approve_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestions_decision_approve_description')}
      </ModalDefault.Description>

      <Form form={form} className={s.form} onFinish={handleApproveDecision}>
        <Form.Item
          label={t('common_responsible_for_implementation')}
          name={FORM_ENUM.PERFORMER}
          rules={[formRules.required]}>
          <Select
            className={s.select}
            loading={isPerformersLoading}
            placeholder={t('common_select_second')}
            options={prepareOptions(performers?.items)}
            showSearch
            filterOption={() => true}
            searchValue={search}
            onSearch={setSearch}
          />
        </Form.Item>
        <Form.Item
          label={t('common_implementation_period')}
          name={FORM_ENUM.DEADLINE}
          rules={[formRules.required]}>
          <DatePicker disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          label={t('common_comment')}
          name={FORM_ENUM.COMMENT}
          rules={[formRules.required]}>
          <TextArea placeholder={t('common_enter_text')} maxLength={240} showCount />
        </Form.Item>

        <ModalDefault.Actions>
          <Flex gap="16">
            <Button fullWidth leftIcon="ok" htmlType="submit" loading={isLoading}>
              {t('common_approve')}
            </Button>
            <Button danger ghost fullWidth onClick={onCancel}>
              {t('common_close')}
            </Button>
          </Flex>
        </ModalDefault.Actions>
      </Form>
    </ModalDefault>
  );
};

export default ModalApprove;
