import { ReactNode, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Button, Flex, Select } from 'gazprom-ui-lib';

import s from './MultiSelect.module.scss';

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  classNameWrapper?: string;
  buttonText?: string;
  label?: ReactNode;
  placeholder?: string;
  initialValues?: Option[];
  deleteHandler?: (id: string) => void;
  selectHandler?: (id: string) => void;
  renderSubtitle?: (option: Option | undefined) => ReactNode;
}

const MultiSelect = ({
  label,
  options,
  buttonText = t('common_add_field'),
  classNameWrapper,
  placeholder,
  initialValues = [],
  deleteHandler,
  selectHandler,
  renderSubtitle,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    if (initialValues.length > 0 && selectedValues.length === 0) {
      setSelectedValues(initialValues.map((item) => item.value));
    }
  }, [initialValues, selectedValues]);

  const addSelect = () => {
    setSelectedValues((prev) => [...prev, '']);
  };

  const handleChange = (props: { index: number; newValue: string }) => {
    const { index, newValue } = props;

    const updatedValues = [...selectedValues];
    updatedValues[index] = newValue;
    setSelectedValues(updatedValues);

    if (selectHandler) {
      selectHandler(newValue);
    }
  };

  const handleDelete = (index: number) => {
    const deletedId = selectedValues[index];

    if (deleteHandler && deletedId) {
      deleteHandler(deletedId);
    }

    setSelectedValues((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Flex className={cn(s.wrapper, classNameWrapper)} fullWidth vertical gap="16">
      {label}
      {selectedValues.map((value, index) => (
        <Flex key={index} vertical gap="8">
          <Flex gap="12" align="center">
            <Select
              className={s.fullWidth}
              options={options}
              placeholder={placeholder}
              value={value || undefined}
              onChange={(newValue) => handleChange({ index, newValue })}
            />

            <Button
              className={s.deleteBtn}
              leftIcon="bin"
              danger
              type="link"
              onClick={() => handleDelete(index)}>
              {t('common_delete')}
            </Button>
          </Flex>
          {value && renderSubtitle?.(options.find((option) => option.value === value))}
        </Flex>
      ))}
      <Button type="link" leftIcon="plus" className={s.buttonCustom} onClick={addSelect}>
        {buttonText}
      </Button>
    </Flex>
  );
};

export default MultiSelect;
