import { useMatch, useParams } from 'react-router-dom';
import {
  SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE,
  SETTINGS_DECLARATIONS_KEDO_ROUTE,
} from 'routes/settings/list';

import { Form } from 'gazprom-ui-lib';

import DocumentDeclarationFormCodes from 'containers/documents-declarations-form/document-declaration-form-codes';
import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormExecutor from 'containers/documents-declarations-form/documents-declarations-form-executor';
import DocumentsDeclarationsFormLink from 'containers/documents-declarations-form/documents-declarations-form-link';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import DocumentsDeclarationsFormOrganization from 'containers/documents-declarations-form/documents-declarations-form-organization';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';
import useDeclarationRoleModel from 'pages/documents/documents-declarations/useDeclarationRoleModel';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './TabEdit.module.scss';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from './tabEdit.utils';
import useCommonData from './useCommonData';

const TabEdit = () => {
  const [form] = Form.useForm();

  const isKedoCreation = !!useMatch(SETTINGS_DECLARATIONS_KEDO_ROUTE);
  const isKedoEditing = !!useMatch(SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE);
  const isKedo = isKedoCreation || isKedoEditing;

  const { id } = useParams<{ id: string }>();
  const { isFetching, statementData, createRoute } = useCommonData({ form });

  const statementType = id ? statementData?.type : KEDO_TYPE_ENUM.BPMN;
  const { isLoading, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: createRoute,
    type: statementType,
  });
  const { commonDisabled } = useDeclarationRoleModel({});

  return (
    <WithLoader isLoading={isFetching}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME}>
        <div className={s.container}>
          <DocumentsDeclarationsFormLink
            defaultStatementId={statementData?.defaultStatementId}
            type={statementData?.type}
          />
          <DocumentsDeclarationsFormOrganization
            required
            disabled={true}
            defaultValue={statementData?.ownerEmployerId}
          />
          <DocumentsDeclarationsFormName disabled={commonDisabled} />
          <DocumentsDeclarationsFormCategory type={statementData?.type} disabled={commonDisabled} />
          <DocumentsDeclarationsFormDescription disabled={commonDisabled} />
          <DocumentsDeclarationsFormExecutor disabled={commonDisabled} />
          <DocumentDeclarationFormCodes
            hideStatementCode={isKedo}
            required={!isKedo}
            disabled={commonDisabled}
          />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
          <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID} noStyle />
        </div>
        <DocumentsDeclarationsButtons isLoading={isLoading} />
      </Form>
    </WithLoader>
  );
};

export default TabEdit;
