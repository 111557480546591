import { Table, TableProps } from 'gazprom-ui-lib';

import { SynclogType } from 'types/synclog.types';

import s from './Settings1cInstanceSyncHistory.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: SynclogType[];
  loading: boolean;
  paginationConfig?: TableProps<SynclogType>['pagination'];
  onChange: TableProps<SynclogType>['onChange'];
}

const Settings1cInstanceSyncHistory = (props: Props) => {
  const { onChange, dataSource, loading, paginationConfig } = props;

  const columns = useColumns();

  return (
    <Table
      onChange={onChange}
      className={s.table}
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      pagination={paginationConfig}
      loading={loading}
    />
  );
};

export default Settings1cInstanceSyncHistory;
