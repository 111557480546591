import { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  useGetEmployerSurveysQuery,
  useGetSurveysEmployeeGroupsQuery,
  useGetSurveysListQuery,
  useUpdateSurveyMutation,
} from 'services/surveys/surveysApiService';

import { FormInstance, useHandleRequestWithModal } from 'gazprom-ui-lib';

import { DEFAULT_PAGE_DATA, getReceivers, validateFields } from 'pages/surveys/surveys.utils';

import { NewSurveyType } from 'types/surveys.types';

import { SURVEYS_EVENT_MODAL_ENUM } from './surveyFormEvent.utils';

interface Props {
  form: FormInstance;
}

const useStartSurvey = (props: Props) => {
  const { form } = props;

  const { id } = useParams<{ id: string }>();

  const [modalOpen, setModalOpen] = useState<SURVEYS_EVENT_MODAL_ENUM>(
    SURVEYS_EVENT_MODAL_ENUM.EMPTY,
  );

  const handleOk = useHandleRequestWithModal({});

  const { data: employeeGroupsResult } = useGetSurveysEmployeeGroupsQuery(DEFAULT_PAGE_DATA);
  const { data: organizationOptions } = useGetEmployerSurveysQuery(DEFAULT_PAGE_DATA);
  const { data: surveysList } = useGetSurveysListQuery();
  const [updateSurvey, { isLoading }] = useUpdateSurveyMutation();

  const cancelModalHandler = () => setModalOpen(SURVEYS_EVENT_MODAL_ENUM.EMPTY);

  const startSurvey = (isActive: boolean) => {
    if (!id) {
      return;
    }

    const formData = form.getFieldsValue();
    const { name, receivers, organization, ...rest } = formData;

    const result = {
      ...surveysList?.surveysInfo[name],
      ...rest,
      receivers: getReceivers(receivers, employeeGroupsResult!, organization, organizationOptions!),
      active: isActive,
    } as NewSurveyType;

    updateSurvey({ id, body: result }).then(handleOk).then(cancelModalHandler);
  };

  const acceptStartButtonHandler = () => startSurvey(true);

  const acceptStopButtonHandler = () => startSurvey(false);

  const startButtonHandler = async () => {
    const isFieldsValid = await validateFields(form);

    if (!isFieldsValid) {
      return;
    }

    setModalOpen(SURVEYS_EVENT_MODAL_ENUM.START);
  };

  const stopButtonHandler = async () => setModalOpen(SURVEYS_EVENT_MODAL_ENUM.STOP);

  return {
    isLoading,
    modalOpen,
    cancelModalHandler,
    acceptStartButtonHandler,
    acceptStopButtonHandler,
    stopButtonHandler,
    startButtonHandler,
  };
};

export default useStartSurvey;
