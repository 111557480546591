import { useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_NAME } from 'containers/community-meet-form/communityMeetForm.utils';
import ContentHeader from 'containers/content-header';

interface Props {
  isLoading: boolean;
}

const Header = (props: Props) => {
  const { isLoading } = props;
  const { id } = useParams() as { id: string };

  return (
    <ContentHeader showBorder align="flex-start">
      <ContentHeader.Title goBackLink={getCommunityManagementViewByIdRoute(id)}>
        {t('events_add')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button
          type="primary"
          leftIcon="ok"
          htmlType="submit"
          loading={isLoading}
          form={COMMUNITY_MEETS_FORM_NAME}>
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
