import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

interface Props {
  name?: string;
  anketologId?: string;
  revisionId?: number;
}

const AnketaInfo = (props: Props) => {
  const { name, anketologId, revisionId } = props;

  return (
    <Flex vertical gap="4">
      <Typography.Text size="16" weight="500">
        {name}
      </Typography.Text>
      <Typography.Text type="secondary">
        <Flex gap="16">
          <Flex gap="8" align="center">
            <Icon name="solution" />
            {t('surveys_table_anketa_id', { id: anketologId })}
          </Flex>
          <Flex gap="8" align="center">
            <Icon name="tags" />
            {t('surveys_table_revision_id', { id: revisionId })}
          </Flex>
        </Flex>
      </Typography.Text>
    </Flex>
  );
};

export default AnketaInfo;
