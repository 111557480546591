import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import {
  useGetSuggestionCategoriesQuery,
  useGetSuggestionDecisionsStatusesQuery,
} from 'services/suggestion/suggestionApiService';

import { Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';
import { FiltersResponseType } from 'containers/filters/filters.utils';

const Filters = () => {
  const form = Form.useFormInstance();

  const { data: suggestionCategories } = useGetSuggestionCategoriesQuery();
  const { data: suggestionStatuses } = useGetSuggestionDecisionsStatusesQuery();

  const filters = Form.useWatch('filters', form);

  const categoryOptions =
    suggestionCategories?.map((category) => ({
      value: category.id,
      label: category.name,
    })) ?? [];

  const statusesOptions =
    suggestionStatuses?.map((status) => ({
      value: status.code,
      label: status.name,
    })) ?? [];

  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
      </Flex>
      <FiltersContainer
        showClearAll
        initialValues={filters as FiltersResponseType | null}
        overlay={
          <>
            <FilterCustomWrapper>
              <FilterHeader valueKey="statuses">{t('common_review_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="statuses"
                options={statusesOptions}
              />
            </FilterCustomWrapper>
            <FilterCustomWrapper>
              <FilterHeader valueKey="realizationStates">{t('common_realization')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="realizationStates"
                options={REALIZATION_STATES}
              />
            </FilterCustomWrapper>
            <FilterCollapse
              items={[
                {
                  key: '0',
                  label: (
                    <FilterHeader valueKey="category">{t('suggestion_category')}</FilterHeader>
                  ),
                  children: (
                    <FilterCheckboxGroup usePadding valueKey="category" options={categoryOptions} />
                  ),
                },
              ]}
            />
          </>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </FiltersV2.TableFilters>
  );
};

const REALIZATION_STATES = [
  { label: t('common_finished'), value: 'FINISH' },
  { label: t('common_refuse'), value: 'REFUSE' },
  { label: t('common_in_process'), value: 'IN_PROCESS' },
];

export default Filters;
