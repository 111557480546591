import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_USER_AGREEMENTS_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useLazyGetPolicyAgreementByIdQuery } from 'services/policy-agreement/policyAgreementApiService';

import { Button, Tabs } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './UserAgreementsDetails.module.scss';
import { USER_AGREEMENT_FORM_NAME } from './containers/form/userAgreementForm.utils';
import useTabItemsHook from './useTabItems.hook';
import { POLICY_AGREEMENT_SETTINGS_TAB } from './userAgreementsDetails.utils';

const UserAgreementsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState<string>(POLICY_AGREEMENT_SETTINGS_TAB);

  const [getPolicyAgreement, { data: policyAgreement, isFetching: handbookIsFetching, isLoading }] =
    useLazyGetPolicyAgreementByIdQuery();

  useEffect(() => {
    if (params.id) {
      getPolicyAgreement(params.id);
    }
  }, [getPolicyAgreement, params.id]);

  const handleTabChange = (e: string) => {
    setActiveTab(e);
    navigate(location.pathname, { replace: true });
  };

  const tabs = useTabItemsHook({ policyAgreement });

  return (
    <WithOverflow className={s.wrapper}>
      <ContentHeader align="flex-start">
        <ContentHeader.Title goBackLink={SETTINGS_USER_AGREEMENTS_ROUTE}>
          {t('user_agreement_edit')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {activeTab === POLICY_AGREEMENT_SETTINGS_TAB && (
            <Button
              leftIcon="ok"
              htmlType="submit"
              loading={handbookIsFetching}
              form={USER_AGREEMENT_FORM_NAME}>
              {t('common_save')}
            </Button>
          )}
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <Tabs items={tabs} type="card" onChange={handleTabChange} activeKey={activeTab} />
      </WithLoader>
    </WithOverflow>
  );
};

export default UserAgreementsDetails;
