import { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, TableProps, Typography } from 'gazprom-ui-lib';

import { SettlementSurveyHistoryAssigment } from 'types/surveys.types';

import { SURVEY_SETTLEMENT_TABS_KEYS } from '../../useGetTabs.hook';
import s from './EventsLog.module.scss';

interface Props {
  handleChangeTab: (tab: SURVEY_SETTLEMENT_TABS_KEYS) => void;
}

const useColumns = (props: Props) => {
  const { handleChangeTab } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const goToDateHandler = useCallback(
    (from: string, to: string | null) => () => {
      handleChangeTab(SURVEY_SETTLEMENT_TABS_KEYS.STATS);
      navigate(location.pathname, {
        state: { settlementSurveyInitialDate: { from, to } },
      });
    },
    [handleChangeTab, location.pathname, navigate],
  );

  const periodRenderer = (_v: unknown, record: SettlementSurveyHistoryAssigment) => {
    const { publishDate, unpublishDate } = record;
    const from = dayjs(publishDate).format('DD.MM.YYYY');
    const to = unpublishDate
      ? dayjs(publishDate).format('DD.MM.YYYY')
      : t('common_now_time_lowercase');

    return (
      <Typography.Text size="14" weight="500">
        {`${from} – ${to}`}
      </Typography.Text>
    );
  };

  const settlementsRenderer = (value: SettlementSurveyHistoryAssigment['settlementCount']) => {
    return (
      <Typography.Text size="14" weight="500">
        {value}
      </Typography.Text>
    );
  };

  const statsRenderer = useCallback(
    (_v: unknown, record: SettlementSurveyHistoryAssigment) => {
      const { publishDate, unpublishDate } = record;

      return (
        <Button
          size="small"
          type="secondary"
          leftIcon="graph"
          className={s.button}
          onClick={goToDateHandler(publishDate, unpublishDate)}
        />
      );
    },
    [goToDateHandler],
  );

  const raffleRenderer = useCallback(
    (
      value: SettlementSurveyHistoryAssigment['isLotteried'],
      record: SettlementSurveyHistoryAssigment,
    ) => {
      if (!value) {
        return null;
      }

      const handleClick = () => navigate(`${location.pathname}/gift/${record.id}`);

      return (
        <Button
          size="small"
          type="secondary"
          leftIcon="gift"
          className={s.button}
          onClick={handleClick}
        />
      );
    },
    [location.pathname, navigate],
  );

  const columns: TableProps<SettlementSurveyHistoryAssigment>['columns'] = useMemo(
    () => [
      {
        title: t('common_periods'),
        render: periodRenderer,
      },
      {
        title: t('common_number_of_settlements'),
        dataIndex: 'settlementCount',
        render: settlementsRenderer,
      },
      {
        title: t('surveys_stats'),
        render: statsRenderer,
        align: 'center',
      },
      {
        title: t('common_raffle'),
        dataIndex: 'isLotteried',
        render: raffleRenderer,
        align: 'center',
      },
    ],
    [raffleRenderer, statsRenderer],
  );

  return columns;
};

export default useColumns;
