import { t } from 'i18next';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const filterItems = useFilterItems();

  const preparedFilters = [
    { label: t('common_published'), value: 'true' },
    { label: t('common_not_published'), value: 'false' },
  ];

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer
        showClearAll
        overlay={
          <>
            <FilterCollapse items={filterItems} defaultActiveKey={['settlementIds']} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup valueKey="isPublish" options={preparedFilters} />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
