import { useParams } from 'react-router-dom';

import { useCreateAttributesMutation } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { CreateAttributesPropsType } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { useHandleRequestWithModal } from 'gazprom-ui-lib';

import { CreateAttributeType } from 'types/documents.types';

import { DISABLED_HIDDEN_FIELD } from './documentsDeclarationsAdditional.utils';

const useAdditionalFormSubmitHandler = () => {
  const { id } = useParams();
  const [createAttributes, { isLoading }] = useCreateAttributesMutation();

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  return {
    isLoading: isLoading,
    handleFormSubmit: (formValues: Record<string, string | boolean | number>) => {
      const attributes = getRequestBody(formValues);
      const requestBody: CreateAttributesPropsType = {
        id: id!,
        attributes: {
          attributes,
        },
      };

      attributes.length && createAttributes(requestBody).then(handleCreate);
    },
  };
};

const getRequestBody = (formValues: Record<string, string | boolean | number>) => {
  const attributes: Record<string, CreateAttributeType> = {};

  Object.keys(formValues).forEach((key) => {
    if (key === DISABLED_HIDDEN_FIELD) {
      return;
    }

    const [keyName, ind] = key.split('.');
    const sectionValue = formValues[key];
    const prev = attributes[ind];

    const setObject = prev
      ? { ...prev, [keyName]: sectionValue }
      : ({ [keyName]: sectionValue } as CreateAttributeType);

    attributes[ind] = setObject;
  });

  return Object.values(attributes);
};

export default useAdditionalFormSubmitHandler;
