import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getEventsWithIdRoute } from 'routes/events/list';

import { Form, TableProps } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { EventType } from 'types/event.types';

import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './events.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const Events = () => {
  const navigate = useNavigate();
  const [selectedEvents, setSelectedEvents] = useState<Array<EventType['meeting']['id']>>([]);

  const [form] = Form.useForm<FormResponseType>();

  const onRow = (event: EventType) => ({
    onClick: () => navigate(getEventsWithIdRoute(event.meeting.id)),
  });

  const getRowKey = (row: EventType) => row.meeting.id;

  const rowSelection: TableProps<EventType>['rowSelection'] = {
    selectedRowKeys: selectedEvents,
    onChange: (keys) => {
      setSelectedEvents(keys as string[]);
    },
  };

  const { isLoading, isFetching, data } = useRequest({ form });
  const columns = useColumns();

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} />

        <FiltersV2.Table<EventType, FormResponseType>
          isInitialLoading={isLoading}
          rowKey={getRowKey}
          dataSource={data?.items}
          total={data?.totalCount}
          loading={isFetching}
          rowSelection={rowSelection}
          onRow={onRow}
          columns={columns}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default Events;
