import { t } from 'tools/i18n';

import FileField from 'containers/file-field';

import { USER_AGREEMENT_ENUM } from '../userAgreementForm.utils';
import s from './FormFile.module.scss';

const FormFile = () => {
  return (
    <FileField
      maxSize={10}
      availableTypes={AVAILABLE_TYPES}
      formItemConfig={{
        label: t('common_file'),
        name: USER_AGREEMENT_ENUM.FILE,
      }}
      previewConfig={{
        iconName: 'docTypePdf',
        className: s.preview,
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_a_pdf_restrictions'),
      }}
    />
  );
};

const AVAILABLE_TYPES = ['application/pdf'];

export default FormFile;
