import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { sortDocumentsFiles } from 'utils/files.utils';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileInfo from 'pages/documents/containers/file-info';
import FileDownloadButton from 'pages/documents/documents-view/documents-view-files/containers';
import { getFileKey, renderSign } from 'pages/documents/documents.utils';

import { KedoDocumentType } from 'types/documents.types';
import { FileType } from 'types/file.types';

import FileDownload from '../../../../containers/file-download';
import s from './Files.module.scss';
import { getArchiveUrl, getFileUrl } from './files.utils';

interface Props {
  data?: KedoDocumentType;
}

const Files = (props: Props) => {
  const { data } = props;
  const { id } = useParams();

  if (!data?.files.length) {
    return null;
  }

  const renderFile = (file: FileType) => {
    const { fileKey, fileKeyWithStamp, fileName, signs, dateDoc, numberDocPrint, type } = file;

    const isOrderType = type === 'ORDER';
    const isSigned = Boolean(signs?.length);
    const shouldDisableButton = file.type !== null && !isSigned;

    const renderSigns = () => signs?.map(renderSign);

    const fileKeyFinal = getFileKey({ fileKey, fileKeyWithStamp });

    return (
      <>
        <Flex
          key={fileKey}
          justify="space-between"
          align="center"
          gap="12"
          className={cn(s.download)}>
          <Flex align="center" gap="12">
            <Icon name="image" className={s.icon} />
            <Flex vertical gap="4" className={s.fileDescription}>
              <Typography.Text weight="500" size="14" type="primary">
                {fileName}
              </Typography.Text>
              <FileInfo
                dateDoc={dateDoc}
                numberDocPrint={numberDocPrint}
                isOrderType={isOrderType}
              />
              {renderSigns()}
            </Flex>
          </Flex>
          <FileDownload fileName={fileName} fileDownloadUrl={getFileUrl(id!, fileKeyFinal)} />
        </Flex>

        <FileDownloadButton
          archiveUrl={getArchiveUrl(id!, fileKey)}
          shouldDisableButton={shouldDisableButton}
          addSpace={false}
        />
      </>
    );
  };

  return (
    <div className={s.fileWrapper}>
      {[...(data?.files ?? [])].sort(sortDocumentsFiles).map(renderFile)}
    </div>
  );
};

export default Files;
