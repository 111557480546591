import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { employeeGroupsStateSelector } from 'selectors/employeeGroupsSlice.selector';
import {
  useGetEmployeeGroupByIdQuery,
  useLazyPreviewDraftGroupQuery,
  useLazyPreviewGroupByIdQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';
import { useAppSelector } from 'store';

import { InputProps } from 'gazprom-ui-lib';

import { RequestBodyPreviewType } from 'types/employeeGroups.types';

import { getResultNode } from '../../employeeGroupsCreate.utils';
import Table from '../table';
import { DEFAULT_REQUEST_BODY } from './preview.utils';

const Preview = () => {
  const { id } = useParams();
  const { data: employeeGroupById } = useGetEmployeeGroupByIdQuery(id ?? skipToken);
  const [previewDraftGroup, previewData] = useLazyPreviewDraftGroupQuery();
  const [previewGroupById, previewByIdData] = useLazyPreviewGroupByIdQuery();
  const [requestBody, setRequestBody] = useState<RequestBodyPreviewType>(DEFAULT_REQUEST_BODY);
  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);
  const {
    checkedNodes,
    partialChecked,
    employees,
    isChanged,
    shiftWorker,
    linkSettlement,
    isSsoRegistered,
  } = useAppSelector(employeeGroupsStateSelector);

  const isPreview = id && !isChanged;
  useEffect(() => {
    const mappedChecked =
      id && !isChanged
        ? employeeGroupById?.employersData.checked.map(getResultNode)
        : checkedNodes.map(getResultNode);
    const mappedPartial =
      id && !isChanged
        ? employeeGroupById?.employersData.partialChecked.map(getResultNode)
        : partialChecked.map(getResultNode);
    const employeesData = id && !isChanged ? employeeGroupById?.employeesData : employees;

    const initialRequestBody: RequestBodyPreviewType = {
      body: {
        title: debouncedRequestBody.text,
        employersData: {
          partialChecked: mappedPartial ?? [],
          checked: mappedChecked ?? [],
        },
        employeesData: employeesData ?? [],
        shiftWorker: shiftWorker ? shiftWorker : undefined,
        linkSettlement: linkSettlement ? linkSettlement : undefined,
        isSsoRegistered: isSsoRegistered ? isSsoRegistered : undefined,
      },
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
    };

    if (debouncedRequestBody.text) {
      initialRequestBody.text = debouncedRequestBody.text;
    }

    if (isPreview) {
      previewGroupById({
        id,
        text: debouncedRequestBody.text,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      });
    } else {
      previewDraftGroup(initialRequestBody);
    }
  }, [
    checkedNodes,
    debouncedRequestBody,
    employeeGroupById,
    employees,
    id,
    isChanged,
    isPreview,
    isSsoRegistered,
    linkSettlement,
    partialChecked,
    previewDraftGroup,
    previewGroupById,
    shiftWorker,
  ]);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({ ...prevState, text: e.target.value }));
  };

  const pagination = {
    defaultCurrent: requestBody.page,
    defaultPageSize: requestBody.size,
    total: isPreview ? previewByIdData.data?.totalCount ?? 0 : previewData?.data?.totalCount ?? 0,
  };
  const tablePreviewByIdData = previewByIdData.data?.employees ?? [];
  const tablePreviewData = previewData?.data?.employees ?? [];
  const tableData = isPreview ? tablePreviewByIdData : tablePreviewData;

  return (
    <Table
      searchValue={requestBody.text ?? ''}
      tableData={tableData}
      handleSearchChange={handleSearchChange}
      loading={previewData.isFetching || previewByIdData.isFetching || isDebouncing}
      pagination={pagination}
      setRequestBody={setRequestBody}
    />
  );
};

export default Preview;
