import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { SURVEYS_SETTLEMENT_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import {
  useGetSettlementSurveyHistoryAssigmentByIdQuery,
  useGetSurveySettlementByIdQuery,
} from 'services/surveys/surveysApiService';

import { Button, Divider, Flex, Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import AnketaInfo from 'pages/surveys/containers/anketa-info';

import { SURVEY_SETTLEMENT_GIFT_FORM_NAME } from '../notifications/notifications.utils';
import s from './Header.module.scss';

interface Props {
  isLoading: boolean;
}

const Header = (props: Props) => {
  const { isLoading } = props;
  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const { data: survey } = useGetSurveySettlementByIdQuery(
    {
      surveyId: surveyId!,
    },
    { skip: !surveyId },
  );

  const { data: history } = useGetSettlementSurveyHistoryAssigmentByIdQuery(
    {
      id: giftId!,
      surveyId: surveyId!,
    },
    { skip: !giftId && !surveyId },
  );

  const { publishDate, unpublishDate, notificationPackageId } = history || {};

  return (
    <Flex vertical gap="16" className={s.container}>
      <Flex vertical gap="8" className={s.wrapper}>
        <ContentHeader align="start">
          <ContentHeader.Title goBackLink={SURVEYS_SETTLEMENT_ROUTE}>
            {t('common_prize_draw')}
          </ContentHeader.Title>
          <ContentHeader.Actions>
            {!notificationPackageId && (
              <Button
                form={SURVEY_SETTLEMENT_GIFT_FORM_NAME}
                htmlType="submit"
                leftIcon="send"
                loading={isLoading}>
                {t('common_send_notifications')}
              </Button>
            )}
          </ContentHeader.Actions>
        </ContentHeader>

        {survey && (
          <Flex gap="8" align="center">
            <Typography.Text type="secondary" size="14" weight="400">
              {t('surveys_play_gift_period')}:
            </Typography.Text>
            <Typography.Text type="secondary" size="14" weight="400">
              <Flex gap="8">
                <Icon name="calendar" />
                {dayjs(publishDate).format('DD.MM.YYYY – ')}
                {dayjs(unpublishDate).format('DD.MM.YYYY')}
              </Flex>
            </Typography.Text>
          </Flex>
        )}
      </Flex>

      <Divider />
      <AnketaInfo
        name={survey?.name}
        anketologId={survey?.anketologId}
        revisionId={survey?.revisionId}
      />
    </Flex>
  );
};

export default Header;
