import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { PolicyAgreementType } from 'types/policyAgreement.types';

const useColumns = (): TableProps<PolicyAgreementType>['columns'] => {
  return [
    {
      title: t('common_name'),
      dataIndex: 'name',
      render: (id: string) => (
        <Typography.Text size="14" weight="500">
          {id}
        </Typography.Text>
      ),
    },
    {
      title: t('common_created'),
      dataIndex: 'createAt',
      align: 'center',
      render: (date: string) => <TableDate date={date} />,
      width: 150,
    },
    {
      title: t('common_updated'),
      dataIndex: 'editedAt',
      align: 'center',
      defaultSortOrder: 'descend',
      render: (date: string) => <TableDate date={date} />,
      width: 150,
    },
  ];
};

export default useColumns;
