export type FormResponseType = {
  page: number;
  size: number;
  search: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
};
