import { t } from 'tools/i18n';

import FeedbackTab from './containers/feedback-tab';
import GiftSettingsTab from './containers/gift-settings-tab';

export const SURVEYS_GIFT_TABS = [
  {
    key: '1',
    label: t('common_feedback'),
    children: <FeedbackTab />,
  },
  {
    key: '2',
    label: t('common_notification_settings'),
    children: <GiftSettingsTab />,
  },
];
