import cInitialApiService from 'services/1c/cApiService';
import {
  GetPayslipByYearPropsType,
  GetPayslipByYearResponseType,
} from 'services/payslip/payslipApiService.types';
import { createPayslipUrl } from 'services/payslip/utils';

const payslipApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPayslipByYear: builder.query<GetPayslipByYearResponseType, GetPayslipByYearPropsType>({
      query: ({ year, instanceId }) => ({
        url: createPayslipUrl(`/instance1c/${instanceId}/payslip-by-year/${year}`),
      }),
    }),
  }),
});

export const { useGetPayslipByYearQuery } = payslipApiService;
