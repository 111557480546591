import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, Icon, Input, Typography } from 'gazprom-ui-lib';

import { USER_AGREEMENT_ENUM } from '../userAgreementForm.utils';
import s from './FormName.module.scss';

const FormName = () => {
  return (
    <>
      <Typography.Text strong size="16">
        {t('common_document_name')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={USER_AGREEMENT_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}
        className={s.item}>
        <Input placeholder={t('common_enter_text')} maxLength={80} showCount />
      </Form.Item>
      <Flex gap="4" align="start" className={s.info}>
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">{t('user_agreement_visible_admin_only')}</Typography.Text>
      </Flex>
    </>
  );
};

export default FormName;
