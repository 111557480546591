import { memo } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';

import { useDownloadSocialTaskReportMutation } from 'services/1c/report/reportApiService';
import { DownloadSocialTaskReportPropsType } from 'services/1c/report/reportApiService.types';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { FormFiltersType, FormResponseType } from '../../socialProgramRequests.utils';
import { FILTER_STATUSES } from './filters.utils';
import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const form = Form.useFormInstance<FormResponseType>();

  const [downloadSocialTaskReport, { isLoading }] = useDownloadSocialTaskReportMutation();

  const handleDownload = () => {
    const filters = form.getFieldValue('filters') ?? {};

    const requestBody: DownloadSocialTaskReportPropsType = {
      filename: `Список заявок на социальные программы ${dayjs().format()}`,
      timeZone: getTimezone(),
      socialTasksFilterParam: {
        type: 'SOCIAL',
        search: form.getFieldValue('search'),
        statuses: filters.status ?? [],
        categoryIds: filters.categories ?? [],
      },
    };
    downloadSocialTaskReport(requestBody);
  };

  const filters = Form.useWatch('filters', form);

  const filterItems = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
        <Button
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isLoading}>
          {t('common_download_report')}
        </Button>
      </Flex>
      <FiltersContainer<FormFiltersType>
        showClearAll
        initialValues={filters}
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup usePadding={false} valueKey="status" options={FILTER_STATUSES} />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default memo(Filters);
