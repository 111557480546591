import { SORT_ENUM } from 'types/sort.types';

export type TableFormResponseType = {
  search?: string;
  page: number;
  size: number;
  order: SORT_ENUM;
  property: string;
};

export const DEFAULT_TABLE_FORM_STATE: TableFormResponseType = {
  search: '',
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'uploadedAt',
};
