import cn from 'classnames';
import { t } from 'i18next';

import { Avatar, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { NotificationTypeWithPerson } from 'types/notification.types';
import { CPersonType } from 'types/person.types';

import s from './FeedbackTab.module.scss';

export const columns: TableProps<NotificationTypeWithPerson>['columns'] = [
  {
    title: t('common_receiver'),
    dataIndex: 'person',
    width: 300,
    render: (person: CPersonType) => {
      return (
        <div className={s.receiver}>
          <Avatar
            firstName={person.firstName ?? ''}
            lastName={person.lastName ?? ''}
            alt="аватар пользователя"
            size="40"
          />
          <Typography.Text weight="500" size="14">{`${person.lastName ?? ''} ${
            person.firstName ?? ''
          }`}</Typography.Text>
        </div>
      );
    },
  },
  {
    title: t('common_read'),
    dataIndex: 'viewed',
    align: 'center',
    render: (viewed: boolean) => (
      <div className={cn(s.viewedWrapper, viewed ? s.active : s.inactive)}>
        <Icon name="okFilled" />
      </div>
    ),
  },
  {
    title: t('common_feedback'),
    dataIndex: 'body',
    render: (text: string) => (
      <Typography.Text size="12" className={s.message} title={text}>
        {text}
      </Typography.Text>
    ),
  },
  {
    title: t('notification_sent'),
    align: 'center',
    dataIndex: 'createdAt',
    width: 120,
    render: (createdAt: number) => <TableDate date={createdAt} />,
  },
];
