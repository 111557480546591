import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

const Filters = () => {
  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search placeholder={t('common_title_fio_search_placeholder')} />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default Filters;
