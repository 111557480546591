import { PaginationPropsType } from 'gazprom-common-lib';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetSettlementSurveyHistoryWinnersQuery } from 'services/surveys/surveysApiService';
import { SettlementSurveyHistoryWinnerType } from 'services/surveys/surveysApiService.types';

import { Flex, Form, TableProps, Typography } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

export type FormResponseType = PaginationPropsType;

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
};

const Winners = () => {
  const { giftId } = useParams<{ id: string; giftId: string }>();

  const [form] = Form.useForm();

  const formData: FormResponseType = Form.useWatch([], form);

  const { data, isLoading } = useGetSettlementSurveyHistoryWinnersQuery(
    {
      surveyAssignmentHistoryId: giftId!,
      isWinner: true,
      page: formData?.page ?? DEFAULT_FORM_STATE.page,
      size: formData?.size ?? DEFAULT_FORM_STATE.size,
    },
    { skip: !giftId },
  );

  const columns: TableProps<SettlementSurveyHistoryWinnerType>['columns'] = [
    {
      title: t('guide_handbook_name'),
      dataIndex: 'randomNumber',
      render: (value: string) => <Typography.Text weight="500">{value}</Typography.Text>,
    },
    {
      title: t('common_employee_id'),
      dataIndex: 'id',
      render: (value: string) => <Typography.Text type="secondary">{value}</Typography.Text>,
    },
  ];

  return (
    <Flex vertical gap="8">
      <Flex gap="4">
        <Typography.Text size="16" weight="500">
          {t('surveys_play_gift_settlement_winners')}
        </Typography.Text>
        <Typography.Text size="12" weight="500" type="primary">
          {data?.totalCount}
        </Typography.Text>
      </Flex>

      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.Table
          rowKey="id"
          dataSource={data?.items}
          columns={columns}
          total={data?.totalCount}
          showSorterTooltip={false}
          loading={isLoading}
          showSizeChanger={false}
        />
      </FiltersV2>
    </Flex>
  );
};

export default Winners;
