import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useLazyGetEmployer1cByIdQuery,
  useUpdateEmployer1cByIdMutation,
} from 'services/1c/cApiService';
import { UpdateEmployer1cByIdPropsType } from 'services/1c/cApiService.types';
import { useUpdateEmployerSettingsMutation } from 'services/employer-settings/employerSettingsApiService';
import { UpdateEmployerSettingsPropsType } from 'services/employer-settings/employerSettingsApiService.types';
import {
  useLazyGetEmployer1cShiftInfoByIdQuery,
  useUpdateEmployer1cShiftInfoByIdMutation,
} from 'services/private/privateApiService';
import { UpdateEmployer1cShiftInfoByIdPropsType } from 'services/private/privateApiService.types';

import { Button, Divider, Flex, Icon, Tabs, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import {
  SETTINGS_EMPLOYER_FORM_ENUM,
  SETTINGS_EMPLOYER_GROUP,
  SettingsEmployerFormResponse,
} from 'containers/settings-employer-form/settingsEmployerForm.utils';
import {
  SETTINGS_EMPLOYER_SHIFT_FORM,
  SETTINGS_EMPLOYER_SHIFT_FORM_ENUM,
  SettingsEmployerShiftFormResponse,
} from 'containers/settings-employer-shift-form/settingsEmployerShiftForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { Employer1cShiftInfoHotContactType } from 'types/employer.types';

import s from './EmployerDetails.module.scss';
import InfoTab from './containers/info-tab';
import ShiftTab from './containers/shift-tab';
import {
  SETTINGS_EMPLOYEE_GROUP_INFO_TAB,
  SETTINGS_EMPLOYEE_GROUP_SHIFT_TAB,
} from './employerDetails.utils';

const EmployerDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [getCEmployer, { data: employerData, isFetching: isEmployerDataLoading }] =
    useLazyGetEmployer1cByIdQuery();
  const [
    getCEmployerShiftData,
    { data: employerShiftData, isFetching: isEmployerShiftDataLoading },
  ] = useLazyGetEmployer1cShiftInfoByIdQuery();
  const [updateCEmployerShiftData, { isLoading: isCEmployerShiftDataLoading }] =
    useUpdateEmployer1cShiftInfoByIdMutation();
  const [updateCEmployer, { isLoading: isCEmployerLoading }] = useUpdateEmployer1cByIdMutation();
  const [updateEmployerSettings, { isLoading: isUpdateEmployerSettingsLoading }] =
    useUpdateEmployerSettingsMutation();

  useEffect(() => {
    if (id) {
      getCEmployer({ employerId: id });
      getCEmployerShiftData({ employerId: id });
    }
  }, [getCEmployer, getCEmployerShiftData, id]);

  const [activeTab, setActiveTab] = useState(SETTINGS_EMPLOYEE_GROUP_INFO_TAB);

  const isShiftTab = activeTab === SETTINGS_EMPLOYEE_GROUP_SHIFT_TAB;

  const handleTabChange = (e: string) => {
    setActiveTab(e);
  };

  const [employerShiftHotContacts, setEmployerShiftHotContacts] = useState<
    Employer1cShiftInfoHotContactType[]
  >(() => employerShiftData?.hotContacts ?? []);

  useEffect(() => {
    if (employerShiftData) {
      setEmployerShiftHotContacts(employerShiftData.hotContacts);
    }
  }, [employerShiftData]);

  const handleEditEmployerShift = (form: SettingsEmployerShiftFormResponse) => {
    if (id) {
      const requestBody: UpdateEmployer1cShiftInfoByIdPropsType = {
        telegramBot: form[SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.TELEGRAM_BOT],
        articleId: form[SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.ARTICLE],
        email: form[SETTINGS_EMPLOYER_SHIFT_FORM_ENUM.EMAIL],
        hotContacts: employerShiftHotContacts,
        id,
      };

      updateCEmployerShiftData(requestBody);
    }
  };

  const handleEditEmployer = (form: SettingsEmployerFormResponse) => {
    if (id && employerData) {
      const requestBody: UpdateEmployer1cByIdPropsType = {
        signEnabled: form[SETTINGS_EMPLOYER_FORM_ENUM.SIGN_ENABLED],
        canIssueCert: form[SETTINGS_EMPLOYER_FORM_ENUM.CAN_ISSUE_CERT],
        timezone: form[SETTINGS_EMPLOYER_FORM_ENUM.TIMEZONE],
        id,
      };
      updateCEmployer(requestBody);

      if (employerData.actualNameCode !== form[SETTINGS_EMPLOYER_FORM_ENUM.ACTUAL_NAME_CODE]) {
        const updateSettingsRequestBody: UpdateEmployerSettingsPropsType = {
          employerNameCode: form[SETTINGS_EMPLOYER_FORM_ENUM.ACTUAL_NAME_CODE],
          id,
        };
        updateEmployerSettings(updateSettingsRequestBody);
      }
    }
  };

  const tabs = [
    {
      label: t('common_about_organization'),
      key: SETTINGS_EMPLOYEE_GROUP_INFO_TAB,
      children: <InfoTab employerData={employerData} handleEditEmployer={handleEditEmployer} />,
    },
    {
      label: t('common_shift_change_settings'),
      key: SETTINGS_EMPLOYEE_GROUP_SHIFT_TAB,
      children: (
        <ShiftTab
          employerShiftData={employerShiftData}
          onSubmit={handleEditEmployerShift}
          employerShiftHotContacts={employerShiftHotContacts}
          setEmployerShiftHotContacts={setEmployerShiftHotContacts}
        />
      ),
    },
  ];

  const description = employerData ? (
    <Flex gap="16" align="center">
      {employerData?.deletemark ? (
        <Flex gap="8" align="center">
          <Flex align="center" justify="center" className={s.deletedIcon}>
            <Icon name="ok" />
          </Flex>
          <Typography.Text type="danger" size="14">
            {t('1с_instance_deleted_single')}
          </Typography.Text>
        </Flex>
      ) : (
        <Flex gap="4">
          {`${t('common_created')}: `}
          <Flex gap="4" align="center">
            <Icon name="calendar" />
            <Typography.Text size="14" type="secondary">
              {dayjs.utc(employerData?.createDate).local().format('DD MMMM YYYY, HH:mm')}
            </Typography.Text>
          </Flex>
        </Flex>
      )}
      <Flex gap="4">
        {`${t('common_last_change')}: `}
        <Flex gap="4" align="center">
          <Icon name="calendar" />
          <Typography.Text size="14" type="secondary">
            {dayjs.utc(employerData?.updateDate).local().format('DD MMMM YYYY, HH:mm')}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  ) : null;

  return (
    <WithOverflow>
      <ContentHeader align="flex-start">
        <ContentHeader.Title goBackLink={-1} description={description}>
          {employerData?.name}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            leftIcon="ok"
            htmlType="submit"
            form={isShiftTab ? SETTINGS_EMPLOYER_SHIFT_FORM : SETTINGS_EMPLOYER_GROUP}
            loading={isCEmployerLoading || isUpdateEmployerSettingsLoading}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isEmployerDataLoading || isEmployerShiftDataLoading}>
        <Flex vertical className={s.content}>
          <Tabs activeKey={activeTab} items={tabs} type="card" onChange={handleTabChange} />
          <Divider />
          <Flex className={s.actions}>
            <Button
              loading={
                isCEmployerLoading || isUpdateEmployerSettingsLoading || isCEmployerShiftDataLoading
              }
              className={s.saveButton}
              type="primary"
              leftIcon="ok"
              htmlType="submit"
              form={isShiftTab ? SETTINGS_EMPLOYER_SHIFT_FORM : SETTINGS_EMPLOYER_GROUP}>
              {t('common_save')}
            </Button>
          </Flex>
        </Flex>
      </WithLoader>
    </WithOverflow>
  );
};

export default EmployerDetails;
