import { useLocation } from 'react-router-dom';
import { FAQ_ROUTE } from 'routes/faq/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import { FAQ_QUESTION_FORM } from 'containers/faq-question-form/faqQuestionForm.utils';

interface Props {
  isLoading: boolean;
}

const Header = (props: Props) => {
  const { isLoading } = props;

  const location = useLocation();

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title goBackLink={FAQ_ROUTE} goBackState={{ ...location.state }}>
        {t('common_edit_question')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button form={FAQ_QUESTION_FORM} htmlType="submit" leftIcon="ok" loading={isLoading}>
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
