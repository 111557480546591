import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { formRules } from 'utils/formRules';
import useUploadFile from 'utils/useUploadFile.hook';

import { Form, FormInstance, Icon, RcFile, Typography, Upload } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import WithLoader from 'containers/wrappers/with-loader';

import { FORM_IMAGE_ENUM } from '../imageModal.utils';
import s from './ImageModalUpload.module.scss';
import ImageModalUploadLoader from './editor-image-modal-upload-loader';

interface EditorImageModalUploadProps {
  form: FormInstance;
  description?: string;
  title?: string;
  availableTypes?: string[];
  maxLimitSizeMB?: number;
  kedoReviewTaskId?: string | null;
  isPersonalFile?: boolean;
  customUploadUrl?: string;
  customMetaUrl?: string;
  handleCreateTaskId?: () => void;
  convert?: boolean;
  onImgUpload?: (url: string) => void;
  noStyle?: boolean;
}

const ImageModalUpload: FC<EditorImageModalUploadProps> = (props) => {
  const {
    isPersonalFile,
    kedoReviewTaskId,
    form,
    title = t('common_upload_an_image'),
    description = t('validation_upload_an_image_restrictions'),
    availableTypes = AVAILABLE_TYPES,
    maxLimitSizeMB = 5,
    customUploadUrl,
    customMetaUrl,
    handleCreateTaskId,
    convert = false,
    onImgUpload,
    noStyle = false,
  } = props;
  const normFile = (e: File[] | { fileList: File[] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    if ('fileList' in e) {
      return e?.fileList;
    }
  };

  const [isError, setIsError] = useState<boolean>(false);

  const resetHandler = () => {
    if (form) {
      form.resetFields([FORM_IMAGE_ENUM.URL]);
    }
  };

  const setErrors = (errors: string[]) => {
    setIsError(true);
    form.setFields([
      {
        name: FORM_IMAGE_ENUM.URL,
        errors,
      },
    ]);
  };

  const beforeUpload = (file: RcFile) => {
    setIsError(false);

    if (handleCreateTaskId) {
      handleCreateTaskId();
    }

    const isCorrectFormat = availableTypes.includes(file.type);
    const moreThanLimit = file.size / 1024 / 1024 > maxLimitSizeMB;

    if (!isCorrectFormat) {
      setErrors([t('validation_file_incorrect_extension')]);
      return false;
    } else if (moreThanLimit) {
      setErrors([t('validation_file_max_size', { size: `${maxLimitSizeMB} MB` })]);
      return false;
    } else {
      setErrors([]);
      return file;
    }
  };

  const { isLoading, fileData, customRequest } = useUploadFile({
    setErrors,
    isPersonalFile,
    kedoReviewTaskId,
    customUploadUrl,
    customMetaUrl,
    convertA1A: convert,
  });

  const showPreview = Form.useWatch(FORM_IMAGE_ENUM.SHOW_PREVIEW, form);

  const resetImage = useCallback(() => {
    form.setFieldValue(FORM_IMAGE_ENUM.URL, null);
    form.setFieldValue(FORM_IMAGE_ENUM.FULL_INFO, null);
    form.setFieldValue(FORM_IMAGE_ENUM.SHOW_PREVIEW, false);
  }, [form]);

  useEffect(() => {
    if (fileData.url) {
      if (onImgUpload) {
        onImgUpload(fileData.url);
      }

      form.setFieldValue(FORM_IMAGE_ENUM.URL, fileData.url);
      form.setFieldValue(FORM_IMAGE_ENUM.FULL_INFO, fileData);
      form.setFieldValue(FORM_IMAGE_ENUM.SHOW_PREVIEW, true);
    }
  }, [onImgUpload, fileData, form]);

  const content = showPreview ? (
    <ImgPreview type="image" url={createMediaDownloadUrl(fileData?.url!)} resetImage={resetImage} />
  ) : (
    <Form.Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
      <Upload.Dragger
        className={cn({ [s.errorStatus]: isError })}
        disabled={isLoading}
        beforeUpload={beforeUpload}
        accept={availableTypes.join(', ')}
        showUploadList={false}
        multiple={false}
        customRequest={customRequest}>
        <WithLoader isLoading={isLoading} loader={<ImageModalUploadLoader />}>
          <div className={cn(s.container, { [s.error]: isError })}>
            <div className={s.dragger}>
              <Icon name={isError ? 'info' : 'upload'} />
              <div>
                <Typography.Text className={s.title} size="14" type="primary">
                  {title ? title : isError ? t('common_error') : t('common_upload_a_file')}
                </Typography.Text>
                <Typography.Text weight="500" size="12" type="secondary">
                  {description}
                </Typography.Text>
              </div>
            </div>
            {isError && (
              <button className={s.resetButton} onClick={resetHandler} type="button">
                <Icon name="refresh" />
              </button>
            )}
          </div>
        </WithLoader>
      </Upload.Dragger>
    </Form.Item>
  );

  return (
    <Form.Item
      name={FORM_IMAGE_ENUM.URL}
      rules={[formRules.required]}
      className={noStyle ? s.noStyle : s.wrapper}>
      {content}
    </Form.Item>
  );
};

const AVAILABLE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export default ImageModalUpload;
