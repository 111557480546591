import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import s from './EditorToolbar.module.scss';
import './editorToolbar.utils';

interface Props {
  disabled?: boolean;
}

const EditorToolbar = (props: Props) => {
  const { disabled } = props;

  return (
    <div id="toolbar" className={cn(s.wrapper, { [s.disabled]: disabled })}>
      <span className="ql-formats">
        <button className="ql-bold" disabled={disabled} />
        <button className="ql-italic" disabled={disabled} />
        <button className="ql-strike" disabled={disabled} />
        <button className="ql-link" disabled={disabled} />
        <button className="ql-header" value="2" disabled={disabled} />
        <button className="ql-list" value="ordered" disabled={disabled} />
        <button className="ql-list" value="bullet" disabled={disabled} />
        <button className="ql-insertImg" disabled={disabled}>
          <Icon name="image" color="var(--color-character-secondary-45)" />
        </button>
        <button className="ql-undo" disabled={disabled}>
          <Icon name="arrowLeft" color="var(--color-character-secondary-45)" size={20} />
        </button>
        <button className="ql-redo" disabled={disabled}>
          <Icon name="arrowRight" color="var(--color-character-secondary-45)" size={20} />
        </button>
      </span>
    </div>
  );
};
export default EditorToolbar;
