import { useGetPolicyAgreementsQuery } from 'services/policy-agreement/policyAgreementApiService';

const useGetData = () => {
  const { data, isLoading, isFetching, isUninitialized } = useGetPolicyAgreementsQuery();

  return {
    data,
    isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
