import { t } from 'tools/i18n';

import { EMPLOYEE_GROUPS_FILTER_ENUM } from 'services/employee-groups-new/employeeGroupsNewApiService.types';

import Employees from './containers/employees';
import Preview from './containers/preview';
import Structure from './containers/structure';

export const getResultNode = (node: string) => {
  const [id, type] = node.split('.');
  return { id, type };
};
export const TABS = [
  {
    key: '1',
    label: t('employee_groups_organizational_structure'),
    children: <Structure />,
  },
  {
    key: '2',
    label: t('employee_groups_add_employees'),
    children: <Employees />,
  },
  {
    key: '3',
    label: t('employee_groups_preview_group'),
    children: <Preview />,
  },
];

export enum EMPLOYEE_GROUPS_FORM_ENUM {
  NAME = 'name',
  SHIFT_WORKER = 'shiftWorker',
  LINK_SETTLEMENT = 'linkSettlement',
  IS_SSO_REGISTERED = 'isSsoRegistered',
}

export type EmployeeGroupsFormResponse = {
  [EMPLOYEE_GROUPS_FORM_ENUM.NAME]: string;
  [EMPLOYEE_GROUPS_FORM_ENUM.SHIFT_WORKER]: EMPLOYEE_GROUPS_FILTER_ENUM | undefined;
  [EMPLOYEE_GROUPS_FORM_ENUM.LINK_SETTLEMENT]: EMPLOYEE_GROUPS_FILTER_ENUM | undefined;
  [EMPLOYEE_GROUPS_FORM_ENUM.IS_SSO_REGISTERED]: EMPLOYEE_GROUPS_FILTER_ENUM | undefined;
};
