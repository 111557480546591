import { useGetShiftMedexamRequestStatusesQuery } from 'services/1c/shift/shiftApiService';

export const useFilterOptions = () => {
  const { data: medexamRequestStatuses = [], isFetching } =
    useGetShiftMedexamRequestStatusesQuery();

  const filterOptions = medexamRequestStatuses.map(({ code, title }) => ({
    value: code,
    label: title,
  }));

  return { filterOptions, isLoadingStatuses: isFetching };
};
