import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useSendSettlementSurveyNotificationsMutation } from 'services/surveys/surveysApiService';

import { useHandleRequestWithModal } from 'gazprom-ui-lib';

import { FormResponse } from './containers/notifications/notifications.utils';

const useSendNotification = () => {
  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const [sendNotifications, { isLoading }] = useSendSettlementSurveyNotificationsMutation();

  const requestHandler = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_successfully_sent'),
      },
      description: {
        children: t('surveys_play_gift_notifications_success_modal_description'),
      },
    },
  });

  const onFinishHandler = (formData: FormResponse) => {
    if (surveyId && giftId) {
      sendNotifications({
        surveyId,
        id: giftId,
        body: formData,
      }).then(requestHandler);
    }
  };

  return {
    isLoading,
    onFinishHandler,
  };
};

export default useSendNotification;
