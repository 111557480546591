import { PaginationPropsType } from 'gazprom-common-lib';
import { useParams } from 'react-router-dom';

import { useGetSettlementSurveyHistoryAssigmentQuery } from 'services/surveys/surveysApiService';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';

import { SURVEY_SETTLEMENT_TABS_KEYS } from '../../useGetTabs.hook';
import useColumns from './useColumns.hook';

export type FormResponseType = PaginationPropsType;

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
};

interface Props {
  handleChangeTab: (tab: SURVEY_SETTLEMENT_TABS_KEYS) => void;
}

const EventsLog = (props: Props) => {
  const { handleChangeTab } = props;
  const { id: surveyId } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const { data, isLoading, isFetching } = useGetSettlementSurveyHistoryAssigmentQuery(
    { page: 1, size: 20, surveyId: surveyId! },
    { skip: !surveyId },
  );

  const columns = useColumns({ handleChangeTab });

  return (
    <WithLoader isLoading={isLoading}>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.Table
          isInitialLoading={isLoading}
          rowKey="id"
          dataSource={data?.items}
          columns={columns}
          total={data?.totalCount}
          showSorterTooltip={false}
          loading={isFetching}
        />
      </FiltersV2>
    </WithLoader>
  );
};

export default EventsLog;
