import { Flex, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

export const prepareOptions = (performers?: EmployeeType[]) => {
  if (!performers) {
    return [];
  }

  return performers.map((performer) => {
    const { firstName, lastName } = performer?.person ?? {};

    return {
      value: performer.id,
      label: (
        <Flex gap="8">
          <Typography.Text size="14">{`${firstName} ${lastName}`}</Typography.Text>
          <Typography.Text size="14" type="secondary">
            {performer.position.name}
          </Typography.Text>
        </Flex>
      ),
    };
  });
};
export enum FORM_ENUM {
  PERFORMER = 'performerId',
  DEADLINE = 'deadlineAt',
  COMMENT = 'comment',
}

export type TFormResponse = {
  [FORM_ENUM.PERFORMER]: string;
  [FORM_ENUM.DEADLINE]: string;
  [FORM_ENUM.COMMENT]: string;
};
