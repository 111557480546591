import { useNavigate } from 'react-router-dom';
import { getKedoReviewTaskWithIdRoute } from 'routes/documents/list';

import { Form } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { KedoReviewTaskType } from 'types/review-task.types';

import s from './DocumentsKedoReviewTasks.module.scss';
import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './documentsKedoReviewTasks.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsKedoReviewTasks = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();
  const columns = useColumns();

  const onRow = (row: KedoReviewTaskType) => ({
    onClick: () => navigate(getKedoReviewTaskWithIdRoute(row.id)),
  });

  const locale = {
    emptyText: () => <Empty title="kedo_review_tasks_not_found" />,
  };

  const { data, isFetching, isLoading } = useGetData({ form });

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<KedoReviewTaskType, FormResponseType>
          isInitialLoading={isLoading}
          className={s.table}
          onRow={onRow}
          dataSource={data?.items}
          total={data?.totalCount}
          columns={columns}
          rowKey="id"
          loading={isFetching}
          locale={locale}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsKedoReviewTasks;
