export const SUGGESTIONS_ROUTE = '/suggestions';

export const SUGGESTIONS_LIST_ROUTE = `${SUGGESTIONS_ROUTE}/list`;
export const SUGGESTION_LIST_DETAILS_ROUTE = `${SUGGESTIONS_LIST_ROUTE}/:id`;

export const getSuggestionsListDetailsRoute = (id: string): string =>
  `${SUGGESTIONS_LIST_ROUTE}/${id}`;

export const SUGGESTIONS_COMMISSION_ROUTE = `${SUGGESTIONS_ROUTE}/commission`;
export const SUGGESTIONS_COMMISSION_CREATE_ROUTE = `${SUGGESTIONS_COMMISSION_ROUTE}/create`;
export const SUGGESTION_COMMISSION_DETAILS_ROUTE = `${SUGGESTIONS_COMMISSION_ROUTE}/:id`;

export const getSuggestionCommissionDetailsRoute = (id: string) =>
  `${SUGGESTIONS_COMMISSION_ROUTE}/${id}`;

export const SUGGESTIONS_DECISIONS_ROUTE = `${SUGGESTIONS_ROUTE}/decisions`;
export const SUGGESTIONS_DECISIONS_DETAILS_ROUTE = `${SUGGESTIONS_DECISIONS_ROUTE}/:id`;

export const getSuggestionDecisionDetailsRoute = (id: string): string =>
  `${SUGGESTIONS_DECISIONS_ROUTE}/${id}`;
