import { useNavigate } from 'react-router-dom';
import { getNewsWithIdRoute } from 'routes/news/list';

import { Table, TableProps } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';

import { ArticleType } from 'types/article.types';

import s from './NewsTable.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: ArticleType[];
  onChange: TableProps<ArticleType>['onChange'];
  paginationConfig: TableProps<ArticleType>['pagination'];
  loading: boolean;
  canPinNews: boolean;
  selectedRowKeys: Array<ArticleType['code']>;
  onSelectedRowKeysChange: (value: Array<ArticleType['code']>) => void;
}

const NewsTable = (props: Props) => {
  const {
    dataSource,
    paginationConfig,
    onChange,
    loading,
    canPinNews,
    selectedRowKeys,
    onSelectedRowKeysChange,
  } = props;

  const navigate = useNavigate();
  const columns = useColumns({ canPinNews });
  const onRow = ({ code }: { code: string }) => ({
    onClick: () => navigate(getNewsWithIdRoute(code)),
  });
  const locale = {
    emptyText: () => <Empty title="news_not_found" description="news_not_yet_received" />,
  };

  return (
    <Table<ArticleType>
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      locale={locale}
      rowKey="id"
      showSorterTooltip={false}
      pagination={paginationConfig}
      className={s.table}
      rowSelection={{
        selectedRowKeys,
        onChange: (keys) => onSelectedRowKeysChange(keys as string[]),
      }}
      onChange={onChange}
      onRow={onRow}
    />
  );
};

export default NewsTable;
