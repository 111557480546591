import { PropsWithChildren } from 'react';

import { Flex } from 'gazprom-ui-lib';

import s from './FilterCustomWrapper.module.scss';

interface Props extends PropsWithChildren {}

const FilterCustomWrapper = (props: Props) => {
  const { children } = props;

  return (
    <Flex gap="12" vertical className={s.wrapper}>
      {children}
    </Flex>
  );
};

export default FilterCustomWrapper;
