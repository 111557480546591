import { memo } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useVerdictAssignmentMutation } from 'services/1c/cApiService';

import {
  Button,
  Form,
  Icon,
  Modal,
  TextArea,
  Typography,
  handleRequest,
  useHandleRequestWithModal,
} from 'gazprom-ui-lib';

import s from './DocumentsSignsViewRejectModal.module.scss';

interface Props {
  open: boolean;
  onCancel: () => void;
  taskId?: string;
  employeeId?: string;
  type?: string;
}

const DocumentsSignsViewRejectModal = (props: Props) => {
  const { open, onCancel, taskId, employeeId, type } = props;

  const [verdict, { isLoading }] = useVerdictAssignmentMutation();
  const params = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const handleReject = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('documents_sign_reject_success_title'),
      },
      description: { children: t('documents_sign_reject_success_description') },
    },
  });

  const handleRevision = useHandleRequestWithModal({
    onErrorModalConfig: {
      title: {
        children: t('documents_sign_revision_success_title'),
      },
      description: { children: t('documents_sign_revision_success_description') },
    },
  });

  const comment = Form.useWatch('comment', form);

  const handleVerdict = async (status: string) => {
    if (params.id && employeeId) {
      verdict({
        id: taskId!,
        myEmployeeId: employeeId,
        comment,
        status,
      }).then(
        handleRequest({
          onSuccess: (r) => {
            onSuccess();
            if (status === 'REJECT') {
              handleReject(r);
            } else {
              handleRevision(r);
            }
          },
        }),
      );
    }
  };

  const showRevisionButton = type !== 'EMPLOYMENT_SIGN_HEAD';

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.danger)}>
          <Icon name="delete" />
        </div>
        <Typography.Title level={3}>{t('common_reject_sign')}</Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('documents_sign_reject_reason')}
        </Typography.Title>
        <Form.Item
          name="comment"
          rules={[formRules.required]}
          normalize={(e: string) => e.trimStart()}>
          <TextArea placeholder={t('common_enter_text')} />
        </Form.Item>
        <div className={s.actions}>
          {showRevisionButton && (
            <Button
              leftIcon="return"
              fullWidth
              loading={isLoading}
              onClick={() => handleVerdict('REVISION')}
              disabled={!comment}>
              {t('common_send_to_revision')}
            </Button>
          )}
          <Button
            danger
            loading={isLoading}
            type="secondary"
            fullWidth
            onClick={() => handleVerdict('REJECTED')}
            disabled={!comment}>
            {t('common_reject')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(DocumentsSignsViewRejectModal);
