import { useEffect } from 'react';

import cn from 'classnames';

import { Form } from 'gazprom-ui-lib';

import { ArticleType } from 'types/article.types';

import s from './ImprovementsForm.module.scss';
import FormCover from './form-cover';
import FormSettlements from './form-settlements';
import FormText from './form-text';
import FormTitle from './form-title';
import { FORM_NAME, IMPROVEMENTS_FORM_ENUM, ImprovementsFormResponse } from './newsForm.utils';

interface Props {
  handleSubmit: (form: ImprovementsFormResponse) => void;
  handleReset: () => void;
  className?: string;
  currentArticle?: ArticleType;
}

const ImprovementsForm = (props: Props) => {
  const { handleSubmit, handleReset, className, currentArticle } = props;

  const [form] = Form.useForm<ImprovementsFormResponse>();

  useEffect(() => {
    if (currentArticle && !form.isFieldsTouched()) {
      form.setFieldsValue({
        [IMPROVEMENTS_FORM_ENUM.TITLE]: currentArticle?.title,
        [IMPROVEMENTS_FORM_ENUM.TEXT]: currentArticle?.text,
        [IMPROVEMENTS_FORM_ENUM.COVER]: currentArticle?.cover,
      });
    }
  }, [form, currentArticle]);

  return (
    <Form
      form={form}
      className={cn(s.wrapper, className)}
      onFinish={handleSubmit}
      onReset={handleReset}
      id={FORM_NAME}>
      <FormSettlements initialValues={currentArticle?.settlements} />
      <FormTitle />
      <FormCover />
      <FormText />
    </Form>
  );
};

export default ImprovementsForm;
