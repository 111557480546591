import { PaginationPropsType } from 'gazprom-common-lib';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetSettlementSurveyHistoryAssigmentByIdSettlementsListQuery } from 'services/surveys/surveysApiService';
import { LotterySettlementHistoryItemType } from 'services/surveys/surveysApiService.types';

import { Flex, Form, TableProps, Typography } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

export type FormResponseType = PaginationPropsType;

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
};

const Settlements = () => {
  const { id: surveyId, giftId } = useParams<{ id: string; giftId: string }>();

  const [form] = Form.useForm();

  const formData: FormResponseType = Form.useWatch([], form);

  const { data: settlementsList, isLoading: isSettlementsListLoading } =
    useGetSettlementSurveyHistoryAssigmentByIdSettlementsListQuery(
      {
        id: giftId!,
        surveyId: surveyId!,
        page: formData?.page ?? DEFAULT_FORM_STATE.page,
        size: formData?.size ?? DEFAULT_FORM_STATE.size,
      },
      { skip: !giftId && !surveyId },
    );

  const columns: TableProps<LotterySettlementHistoryItemType>['columns'] = [
    {
      title: t('guide_handbook_name'),
      dataIndex: 'name',
      render: (value: string) => <Typography.Text weight="500">{value}</Typography.Text>,
    },
  ];

  return (
    <Flex vertical gap="8">
      <Flex gap="4">
        <Typography.Text size="16" weight="500">
          {t('surveys_play_gift_settlements')}
        </Typography.Text>
        <Typography.Text size="12" weight="500" type="primary">
          {settlementsList?.totalCount}
        </Typography.Text>
      </Flex>

      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.Table
          rowKey="id"
          dataSource={settlementsList?.items}
          columns={columns}
          total={settlementsList?.totalCount}
          showSorterTooltip={false}
          loading={isSettlementsListLoading}
          showSizeChanger={false}
        />
      </FiltersV2>
    </Flex>
  );
};

export default Settlements;
