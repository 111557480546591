export const methodOptions = [
  {
    label: 'Автоматическая генерация в ПРО ГСП',
    value: 'APP',
  },
  {
    label: 'Автоматическая генерация в 1С',
    value: 'AUTO_1C',
  },
  {
    label: 'Постановка задачи в 1С',
    value: 'MANUAL_1C',
  },
];

export const signerOptions = [
  {
    label: 'Главный бухгалтер',
    value: 'ACCOUNTANT',
  },
  {
    label: 'Начальник кадрового администрирования',
    value: 'HR',
  },
];
