import { t } from 'tools/i18n';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import FileUpload from 'components/file/file-upload';
import ImgPreview from 'components/img-preview';

import { SURVEYS_ANKETA_IMPORT_FORM_ENUM } from './uploadSurveyAnketaForm.utils';
import { useSubmit } from './useSubmit.hook';

interface Props {
  handleClose: () => void;
  form: FormInstance;
  openUploadModal: () => void;
}

export const UploadSurveyAnketaForm = (props: Props) => {
  const { handleClose, form, openUploadModal } = props;

  const file = Form.useWatch(SURVEYS_ANKETA_IMPORT_FORM_ENUM.FILE, form);

  const { handleSubmit, isLoading } = useSubmit({ handleClose, openUploadModal });

  const resetFile = () => form.setFieldValue(SURVEYS_ANKETA_IMPORT_FORM_ENUM.FILE, undefined);
  const beforeUpload = (file: File) =>
    form.setFieldValue(SURVEYS_ANKETA_IMPORT_FORM_ENUM.FILE, file);

  const showPreview = file && !isLoading;

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item name={SURVEYS_ANKETA_IMPORT_FORM_ENUM.FILE}>
        {showPreview ? (
          <ImgPreview
            iconName="docTypeEmpty"
            url=""
            resetImage={resetFile}
            title={file.name}
            description={`${((file.size ?? 0) / 1024 / 1024).toFixed(2)} МБ`}
          />
        ) : (
          <FileUpload
            availableFileTypes={[
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            isLoading={isLoading}
            isError={false}
            beforeUpload={beforeUpload}
            resetHandler={resetFile}
            icon="upload"
            title={t('common_upload_a_file')}
          />
        )}
      </Form.Item>

      <Flex gap="8">
        <Button fullWidth type="secondary" onClick={handleClose}>
          {t('common_close')}
        </Button>
        <Button disabled={!file || isLoading} fullWidth htmlType="submit" type="primary">
          {t('common_upload')}
        </Button>
      </Flex>
    </Form>
  );
};
