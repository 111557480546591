import { ReactNode } from 'react';

import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';

import ContentHeader from 'containers/content-header';

import { CommunityMeetType } from 'types/communityMeet.types';

import { CommunityEntityKind } from '../../../../../tabs.utils';
import SuggestedHeaderInfo from '../../../../suggested-header-info';
import { CommunitySuggestedEntityType } from '../../../../suggested/suggestedTab.utils';

interface Props {
  communityMeet: CommunityMeetType;
  communityId: string;
  communityEntityKind: CommunityEntityKind;
  headerActions: ReactNode;
  tabsAreHidden: boolean;
}

const Header = (props: Props) => {
  const { communityMeet, communityId, communityEntityKind, headerActions, tabsAreHidden } = props;
  const { title } = communityMeet;

  const description = (
    <SuggestedHeaderInfo
      entity={communityMeet as CommunitySuggestedEntityType}
      communityEntityKind={communityEntityKind}
    />
  );

  return (
    <ContentHeader showBorder={tabsAreHidden} align="flex-start">
      <ContentHeader.Title
        description={description}
        goBackLink={getCommunityManagementViewByIdRoute(communityId)}>
        {title}
      </ContentHeader.Title>
      <ContentHeader.Actions>{headerActions}</ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
