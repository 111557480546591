import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetReplacementsQuery } from 'services/private/replacement/replacementApiService';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsReplacement.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const { search, range, filters } = debouncedFormData ?? {};

  const from = range?.[0] ? dayjs.utc(range[0]).format() : undefined;
  const to = range?.[1] ? dayjs.utc(range[1]).format() : undefined;

  const { data, isFetching, isLoading, isUninitialized } = useGetReplacementsQuery(
    debouncedFormData?.size
      ? {
          search,
          page: debouncedFormData.page,
          size: debouncedFormData.size,
          from,
          to,
          ...filters,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isFetching || isDebouncing,
  };
};

export default useGetData;
