import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployeeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form } from 'gazprom-ui-lib';

import MultiTagsSelect from 'components/form/multi-tags-select';

import { EmployeeType } from 'types/employee.types';
import { ExpertCommissionType } from 'types/expertCommission.types';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';

interface Props {
  coordinators?: ExpertCommissionType['members'];
}

const Members = (props: Props) => {
  const { coordinators } = props;

  const { id } = useParams<{ id: string }>();

  const [search, setSearch] = useState<string>('');

  const form = Form.useFormInstance();
  const organizationId = Form.useWatch(EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION);
  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { data: employeeData } = useGetEmployeeQuery({
    page: 1,
    size: 500,
    search: debouncedSearch || undefined,
  });

  useEffect(() => {
    if (!id && organizationId) {
      form.setFieldValue(EXPERT_COMMISSION_FORM_ENUM.COORDINATORS, null);
    }
  }, [form, id, organizationId]);

  const data = useMemo(() => {
    if (coordinators?.length && !debouncedSearch) {
      return employeeData?.employees?.concat(coordinators as EmployeeType[]);
    }

    return employeeData?.employees;
  }, [debouncedSearch, employeeData, coordinators]);

  const options = data?.map((employee) => ({
    label: `${employee.person.firstName} ${employee.person.lastName} ${employee.position.name}`,
    value: employee.id,
  }));
  return (
    <MultiTagsSelect
      name={EXPERT_COMMISSION_FORM_ENUM.COORDINATORS}
      title={t('suggestions_commission_coordinators')}
      placeholder={t('suggestions_commission_coordinators_placeholder')}
      options={options ?? []}
      isRequired={true}
      disabled={!organizationId}
      searchValue={search}
      onSearch={setSearch}
    />
  );
};

export default Members;
