import { ReactNode, useMemo } from 'react';

import { t } from 'tools/i18n';

import SurveySettlementForm from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form';

import EventsLog from './containers/events-log';
import { SurveySettlementFormResponse } from './containers/survey-settlement-form/surveySettlementForm.utils';
import SurveySettlementStats from './containers/survey-settlement-stats';

interface Props {
  handleChangeTab: (tab: SURVEY_SETTLEMENT_TABS_KEYS) => void;
  handleFinish: (formResponse: SurveySettlementFormResponse) => void;
  formActions: ReactNode;
}

export const useGetTabs = (props: Props) => {
  const { handleChangeTab, handleFinish, formActions } = props;

  const tabs = useMemo(() => {
    return [
      {
        key: SURVEY_SETTLEMENT_TABS_KEYS.STATS,
        label: t('surveys_stats'),
        children: <SurveySettlementStats />,
      },
      {
        key: SURVEY_SETTLEMENT_TABS_KEYS.FORM,
        label: t('surveys_settings'),
        children: <SurveySettlementForm handleFinish={handleFinish} formActions={formActions} />,
      },
      {
        key: SURVEY_SETTLEMENT_TABS_KEYS.EVENT_LOG,
        label: t('surveys_events_log'),
        children: <EventsLog handleChangeTab={handleChangeTab} />,
      },
    ];
  }, [formActions, handleChangeTab, handleFinish]);

  return tabs;
};

export enum SURVEY_SETTLEMENT_TABS_KEYS {
  STATS = 'STATS',
  FORM = 'FORM',
  EVENT_LOG = 'EVENT_LOG',
}
