import { MouseEventHandler, useState } from 'react';

import { Button, Typography } from 'gazprom-ui-lib';

import s from './Receivers.module.scss';

interface Props {
  receivers: { id: string; name: string }[];
}

const Receivers = (props: Props) => {
  const { receivers } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const visibleReceivers = expanded ? receivers : receivers.slice(0, 4);
  const buttonText = expanded ? 'Свернуть' : `Ещё +${receivers.length - 4}`;

  const toggleExpanded: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <div className={s.container}>
      {visibleReceivers.map(({ id, name }) => (
        <Typography.Text className={s.tag} key={id}>
          {name}
        </Typography.Text>
      ))}
      {receivers.length > 4 && (
        <Button
          rightIcon={expanded ? 'up' : 'down'}
          onClick={toggleExpanded}
          size="small"
          className={s.button}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Receivers;
