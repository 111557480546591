import { useNavigate } from 'react-router-dom';
import { getImprovementsByIdRoute } from 'routes/guide/list';

import { Form } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ArticleType } from 'types/article.types';

import Filters from '../filters';
import Header from '../header';
import { DEFAULT_FORM_STATE, FormResponseType } from './table.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const Table = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormResponseType>();

  const onRow = (article: ArticleType) => ({
    onClick: () => navigate(getImprovementsByIdRoute(article.code)),
  });

  const { isLoading, isFetching, data } = useRequest({ form });
  const columns = useColumns();

  const emptyText = (
    <Empty
      title="guide_not_found_pulish"
      description="common_empty_list_with_filters_description"
    />
  );

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />

        <FiltersV2.Table
          isInitialLoading={isLoading}
          rowKey="id"
          dataSource={data?.items}
          total={data?.totalCount}
          loading={isFetching}
          onRow={onRow}
          columns={columns}
          locale={{ emptyText }}
        />
        <Form.Item name="type" noStyle />
      </FiltersV2>
    </WithOverflow>
  );
};

export default Table;
