import { FC, memo } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Form, FormItemProps, Icon, Input } from 'gazprom-ui-lib';

import s from './Search.module.scss';

interface SearchProps extends FormItemProps {
  resetPaginationOnChange?: boolean;
  placeholder?: string;
}

const Search: FC<SearchProps> = (props) => {
  const {
    name = 'search',
    resetPaginationOnChange = true,
    placeholder,
    className,
    normalize,
    ...otherProps
  } = props;
  const form = Form.useFormInstance();

  const handleNormalize: FormItemProps['normalize'] = (value: string, ...otherParams) => {
    const modifiedValue = value.trimStart();

    if (resetPaginationOnChange) {
      form.setFieldValue('page', 1);
    }

    if (normalize) {
      return normalize(modifiedValue, ...otherParams);
    }

    return modifiedValue;
  };

  return (
    <Form.Item
      name={name}
      normalize={handleNormalize}
      className={cn(s.wrapper, className)}
      {...otherProps}>
      <Input
        prefix={<Icon name="search" />}
        size="small"
        placeholder={placeholder ?? t('common_search')}
        allowClear
      />
    </Form.Item>
  );
};

export default memo(Search);
