import {
  useLinkStatementVariableMutation,
  useUnlinkStatementVariableMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

interface Props {
  statementId: string;
}

export const useLinkUnlinkStatementVariable = (props: Props) => {
  const { statementId } = props;

  const [linkStatementVariable] = useLinkStatementVariableMutation();
  const handleLinkStatementVariable = (variableId: string) => {
    linkStatementVariable({ variableId, statementId });
  };

  const [unlinkStatementVariable] = useUnlinkStatementVariableMutation();
  const handleUnlinkStatementVariable = (variableId: string) => {
    unlinkStatementVariable({ variableId, statementId });
  };

  return { handleLinkStatementVariable, handleUnlinkStatementVariable };
};
