import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import RejectApproveModal from './containers/reject-approve-modal';
import Table from './containers/table';
import ViewModal from './containers/view-modal';
import {
  DEFAULT_TABLE_FORM_STATE,
  TableFormResponseType,
} from './shiftChangeMedexamRequests.utils';
import { useModalsConfig } from './useModalsConfig.hook';
import { useRequest } from './useRequest.hook';

const ShiftChangeMedexamRequests = () => {
  const [form] = Form.useForm<TableFormResponseType>();
  const requestBody: TableFormResponseType = Form.useWatch([], form);
  const requestData = useRequest({ requestBody });

  const {
    onCloseRejectApproveModal,
    onCloseViewModal,
    onOpenRejectApproveModal,
    onOpenViewModal,
    rejectApproveModalType,
    viewModalOpen,
    selectedMedexamRequest,
  } = useModalsConfig();

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_TABLE_FORM_STATE}>
        <Filters />
        <Table onOpenViewModal={onOpenViewModal} {...requestData} />
      </FiltersV2>
      {selectedMedexamRequest && (
        <>
          <ViewModal
            open={viewModalOpen}
            onCancel={onCloseViewModal}
            selectedMedexamRequest={selectedMedexamRequest}
            onOpenRejectApproveModal={onOpenRejectApproveModal}
          />
          {rejectApproveModalType && (
            <RejectApproveModal
              onCancel={onCloseRejectApproveModal}
              selectedMedexamRequestId={selectedMedexamRequest.id}
              type={rejectApproveModalType}
            />
          )}
        </>
      )}
    </WithOverflow>
  );
};

export default ShiftChangeMedexamRequests;
