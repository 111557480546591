import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, Icon, Modal, Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import useCInstanceConnection from './cInstanceSynchronization.hook';
import s from './cInstanceSynchronizationModal.module.scss';
import { SEGMENT_OPTIONS } from './cInstanceSynchronizationModal.utils';
import CommonSync from './containers/common-sync';
import PayslipSync from './containers/payslip-sync';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  syncTypes: string[];
  instanceId: string;
}

const CInstanceSynchronizationModal = (props: Props) => {
  const { isOpen, onCancel, instanceId, syncTypes } = props;

  const [selectedSegment, setSelectedSegment] = useState(SEGMENT_OPTIONS[0].value);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [selectedPayslipMonth, setSelectedPayslipMonth] = useState<string | null>(null);

  const handleChangeSelectedYear = (year: number) => {
    setSelectedPayslipMonth(null);
    setSelectedYear(year);
  };

  const handleSelectPayslipMonth = (payslipMonth: string) => {
    if (selectedPayslipMonth === payslipMonth) {
      setSelectedPayslipMonth(null);
    } else {
      setSelectedPayslipMonth(payslipMonth);
    }
  };

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) => {
    setSelectedPayslipMonth(null);
    setSelectedSegment(segment.toString());
  };

  const isPayslipSync = selectedSegment === SEGMENT_OPTIONS[1].value;

  const payslipSyncAvailable = isPayslipSync && !!selectedPayslipMonth;

  const getButtonDisabled = () => {
    if (isPayslipSync) {
      return !payslipSyncAvailable;
    } else {
      return !selectedConnectionTypes.length;
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedSegment(SEGMENT_OPTIONS[0].value);
      setSelectedPayslipMonth(null);
    }
  }, [isOpen]);

  const {
    syncTypeOptions,
    selectedConnectionTypes,
    handleConnectionTypeSelection,
    handleSubmit,
    isLoading,
  } = useCInstanceConnection({
    instanceId,
    syncTypes,
    onCancel,
    isPayslipSync,
    selectedPayslipMonth,
    selectedYear,
  });

  return (
    <Modal open={isOpen} onCancel={onCancel} width={520} closable>
      <div className={s.container}>
        <div className={s.icon}>
          <Icon name="importStart" size={40} color="var(--color-neutral-1)" />
        </div>
        <div className={s.text}>
          <Typography.Title level={3}>{t('1c_instance_start_sync')}</Typography.Title>
          <Typography.Text size="16" weight="400" type="secondary">
            {t('1c_instance_select_sync_type')}
          </Typography.Text>
        </div>

        <Segmented
          className={s.tabs}
          options={SEGMENT_OPTIONS}
          value={selectedSegment}
          onChange={handleSegmentChange}
          fullWidth
        />

        <Flex fullWidth className={s.content}>
          {selectedSegment === 'common' ? (
            <CommonSync
              syncTypeOptions={syncTypeOptions}
              handleConnectionTypeSelection={handleConnectionTypeSelection}
              selectedConnectionTypes={selectedConnectionTypes}
            />
          ) : (
            <PayslipSync
              instanceId={instanceId}
              selectedYear={selectedYear}
              handleChangeSelectedYear={handleChangeSelectedYear}
              selectedPayslipMonth={selectedPayslipMonth}
              handleSelectPayslipMonth={handleSelectPayslipMonth}
            />
          )}
        </Flex>

        <Flex fullWidth className={s.actions} gap="8">
          <Button
            disabled={getButtonDisabled()}
            leftIcon="web"
            fullWidth
            type="primary"
            loading={isLoading}
            onClick={handleSubmit}>
            {t('common_start')}
          </Button>
          <Button fullWidth type="primary" ghost loading={isLoading} onClick={onCancel}>
            {t('common_close')}
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default CInstanceSynchronizationModal;
