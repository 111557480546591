import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { SURVEYS_EVENT_ROUTE, SURVEYS_ONCE_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import {
  useGetSurveyByIdQuery,
  useGetSurveySettingsByIdQuery,
} from 'services/surveys/surveysApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';

import Status from 'pages/surveys/containers/status';
import useMatchOnceRoute from 'pages/surveys/containers/useMatchOnceRoute';

import { SURVEY_ACTIVE_ENUM } from 'types/surveys.types';

import s from './Header.module.scss';

const Header = () => {
  const { id, anketologId } = useParams<{ id: string; anketologId: string }>();

  const { data: survey, isLoading: surveyIsLoading } = useGetSurveyByIdQuery(
    anketologId ? { survey_id: anketologId! } : skipToken,
  );
  const { data: surveySettings, isLoading: isSurveySettings } = useGetSurveySettingsByIdQuery(
    id ?? skipToken,
  );

  const isOnceSurveysRoute = useMatchOnceRoute();

  const onceStatus = surveySettings?.status;
  const eventStatus = surveySettings?.active
    ? SURVEY_ACTIVE_ENUM.ACTIVE
    : SURVEY_ACTIVE_ENUM.NOT_ACTIVE;
  const currentSurveyStatus = isOnceSurveysRoute ? onceStatus : eventStatus;
  const isLoading = surveyIsLoading || isSurveySettings;

  const description = !!survey && (
    <>
      <Icon name="calendar" />
      <Typography.Text size="14" type="secondary">
        {`${t('common_created')}: ${dayjs(surveySettings?.createdAt)
          .local()
          .format('DD.MM.YYYY, HH:mm')}`}
      </Typography.Text>
    </>
  );

  return (
    <WithLoader isLoading={isLoading}>
      <ContentHeader>
        <ContentHeader.Title
          description={description}
          goBackLink={isOnceSurveysRoute ? SURVEYS_ONCE_ROUTE : SURVEYS_EVENT_ROUTE}>
          {surveySettings?.info.name}
        </ContentHeader.Title>
        <ContentHeader.Actions className={s.status}>
          {currentSurveyStatus && <Status status={currentSurveyStatus} />}
        </ContentHeader.Actions>
      </ContentHeader>
    </WithLoader>
  );
};

export default Header;
