import { useCallback, useEffect } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { treeNodeUtils } from 'utils/treeNode.utils';

import {
  useGetEmployeeGroupByIdQuery,
  useGetEmployerRootQuery,
  useLazyGetEmployeeGroupTreeQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';
import {
  setCheckedNodes,
  setDefaultData,
  setEmployerTree,
  updateEmployerTree,
} from 'slices/employeeGroupsSlice';
import { useAppSelector } from 'store';

import { DataNode, Flex, Tree } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import {
  FirstArgumentOnCheckType,
  OnLoadArguments,
  SecondArgumentOnCheckType,
} from 'types/employeeGroups.types';

import { EMPLOYEE_GROUPS_FORM_ENUM } from '../../employeeGroupsCreate.utils';
import s from './Structure.module.scss';
import StructureFilter from './containers/structureFilter';
import {
  LINK_SETTLEMENT_OPTIONS,
  SSO_REGISTERED_OPTIONS,
  WORK_METHOD_OPTIONS,
} from './structure.utils';

const Structure = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data: employeeGroupById, isLoading: isEmployeeGroupByIdLoading } =
    useGetEmployeeGroupByIdQuery(id ?? skipToken);
  const { data: employerRootData, isLoading: isEmployerRootDataLoading } = useGetEmployerRootQuery(
    id ? skipToken : undefined,
  );
  const [getTree] = useLazyGetEmployeeGroupTreeQuery();

  const { employerTree } = useAppSelector((store) => store.employeeGroupsSlice);

  useEffect(() => {
    if (employeeGroupById) {
      const tree = employerTree?.length ? employerTree : employeeGroupById.employersData.treeData;

      dispatch(
        setDefaultData({
          tree: tree,
          employees: employeeGroupById.employeesData,
          checkedNodes: employeeGroupById.employersData.checked,
          partialChecked: employeeGroupById.employersData.partialChecked.map((id) =>
            treeNodeUtils(employeeGroupById.employersData.treeData as DataNode[], id),
          ),
          isSsoRegistered: employeeGroupById.isSsoRegistered,
          linkSettlement: employeeGroupById.linkSettlement,
          shiftWorker: employeeGroupById.shiftWorker,
        }),
      );
      return;
    }
  }, [dispatch, employeeGroupById, employerTree]);

  useEffect(() => {
    if (employerRootData) {
      dispatch(setEmployerTree(employerRootData));
    }
  }, [dispatch, employerRootData]);

  const onLoadData = useCallback(
    (treeNode: OnLoadArguments) =>
      new Promise<void>((resolve) => {
        const { key, children, type } = treeNode as OnLoadArguments & { type: string };
        if (children) {
          resolve();
          return;
        }

        getTree({
          parentId: (key as string).split('.')[0],
          type: type,
        })
          .unwrap()
          .then((res) => dispatch(updateEmployerTree({ key, children: res })))
          .then(() => resolve());
      }),
    [dispatch, getTree],
  );

  const onCheck = useCallback(
    (_: FirstArgumentOnCheckType, info: SecondArgumentOnCheckType) => {
      const checkedNodes = info.checkedNodes.map((el) => el.key) as string[];
      const halfCheckedKeys = info.halfCheckedKeys as string[];
      const partialChecked = halfCheckedKeys.map((id) => treeNodeUtils(employerTree, id));

      dispatch(setCheckedNodes({ checkedNodes, partialChecked }));
    },
    [dispatch, employerTree],
  );

  return (
    <WithLoader
      isLoading={
        isEmployeeGroupByIdLoading || isEmployerRootDataLoading || employerTree?.length < 1
      }>
      <Flex justify="space-between" gap="32" className={s.wrapper}>
        <StructureFilter
          title={t('employee_group_work_method')}
          fieldName={EMPLOYEE_GROUPS_FORM_ENUM.SHIFT_WORKER}
          options={WORK_METHOD_OPTIONS}
        />

        <StructureFilter
          title={t('employee_group_settlement')}
          fieldName={EMPLOYEE_GROUPS_FORM_ENUM.LINK_SETTLEMENT}
          options={LINK_SETTLEMENT_OPTIONS}
        />

        <StructureFilter
          title={t('employee_group_mobile_registration')}
          fieldName={EMPLOYEE_GROUPS_FORM_ENUM.IS_SSO_REGISTERED}
          options={SSO_REGISTERED_OPTIONS}
        />
      </Flex>

      <Tree
        checkable
        treeData={employerTree}
        loadData={onLoadData}
        onCheck={onCheck}
        key="key"
        defaultCheckedKeys={employeeGroupById?.employersData.checked}
        selectable={false}
        className={s.tree}
      />
    </WithLoader>
  );
};

export default Structure;
