import { FC, useEffect } from 'react';

import { formRules } from 'utils/formRules';

import { Form, Input } from 'gazprom-ui-lib';

interface CommonInputProps {
  title: string;
  name: string;
  placeholder: string;
  maxWidth: number;
  initialValue?: string;
}

const CommonInput: FC<CommonInputProps> = (props) => {
  const { title, name, placeholder, maxWidth, initialValue } = props;
  const form = Form.useFormInstance();

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <Form.Item name={name} rules={[formRules.required]} label={title}>
      <Input placeholder={placeholder} max={maxWidth} />
    </Form.Item>
  );
};

export default CommonInput;
