import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import SettingsEmployerForm from 'containers/settings-employer-form';
import { SettingsEmployerFormResponse } from 'containers/settings-employer-form/settingsEmployerForm.utils';

import { Employer1cResType } from 'types/employer.types';

import s from './InfoTab.module.scss';

interface Props {
  employerData?: Employer1cResType;
  handleEditEmployer: (form: SettingsEmployerFormResponse) => void;
}

const InfoTab = (props: Props) => {
  const { employerData, handleEditEmployer } = props;

  const {
    firstName: orgHeadFirstName,
    lastName: orgHeadLastName,
    patronymic: orgHeadPatronymic,
  } = employerData?.orgHead1c?.person ?? {};
  const {
    firstName: mainHrFirstName,
    lastName: mainHrLastName,
    patronymic: mainHrPatronymic,
  } = employerData?.mainHr1c?.person ?? {};
  const {
    firstName: mainAccountantFirstName,
    lastName: mainAccountantLastName,
    patronymic: mainAccountantPatronymic,
  } = employerData?.mainAccountant1c?.person ?? {};

  const orgHeadName = orgHeadFirstName
    ? `${orgHeadLastName} ${orgHeadFirstName} ${orgHeadPatronymic}`
    : '-';
  const mainHrName = mainHrFirstName
    ? `${mainHrLastName} ${mainHrFirstName} ${mainHrPatronymic}`
    : '-';
  const mainAccountantName = mainAccountantFirstName
    ? `${mainAccountantLastName} ${mainAccountantFirstName} ${mainAccountantPatronymic}`
    : '-';

  return (
    <Flex vertical>
      <SettingsEmployerForm employerData={employerData} onSubmit={handleEditEmployer} />
      <Flex vertical gap="24" className={s.wrapper}>
        <Flex vertical gap="16" className={s.infoContainer}>
          <Typography.Text size="16" weight="500">
            {t('common_details')}
          </Typography.Text>
          <Flex className={s.infoContainerRow}>
            <Flex vertical>
              <Typography.Text size="16" weight="500">
                {t('common_inn')}
              </Typography.Text>
              <Typography.Text>{employerData?.inn ?? '-'}</Typography.Text>
            </Flex>
            <Flex vertical>
              <Typography.Text size="16" weight="500">
                {t('common_kpp')}
              </Typography.Text>
              <Typography.Text>{employerData?.kpp ?? '-'}</Typography.Text>
            </Flex>
          </Flex>
          <Flex className={s.infoContainerRow}>
            <Flex vertical>
              <Typography.Text size="16" weight="500">
                {t('common_ogrn')}
              </Typography.Text>
              <Typography.Text>{employerData?.ogrn ?? '-'}</Typography.Text>
            </Flex>
            <Flex vertical>
              <Typography.Text size="16" weight="500">
                {t('common_okved')}
              </Typography.Text>
              <Typography.Text>{employerData?.okved ?? '-'}</Typography.Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap="16" className={s.infoContainer}>
          <Typography.Text size="16" weight="500">
            {t('common_other')}
          </Typography.Text>
          <Flex vertical>
            <Typography.Text size="16" weight="500">
              {t('1c_base')}
            </Typography.Text>
            <Typography.Text>{employerData?.instance1cId ?? '-'}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text size="16" weight="500">
              {t('employee_groups_organizational_structure_type')}
            </Typography.Text>
            <Typography.Text>{employerData?.structureType ?? '-'}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text size="16" weight="500">
              {t('common_ceo')}
            </Typography.Text>
            <Typography.Text>{orgHeadName}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text size="16" weight="500">
              {t('1c_main_accountant')}
            </Typography.Text>
            <Typography.Text>{mainAccountantName}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text size="16" weight="500">
              {t('1c_head_hr')}
            </Typography.Text>
            <Typography.Text>{mainHrName}</Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InfoTab;
