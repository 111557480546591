import { t } from 'tools/i18n';

import { useGetEmployer1cQuery } from 'services/1c/cApiService';
import { CEmployerType } from 'services/1c/cApiService.types';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableIsPublish from 'components/table/table-is-publish';

const useColumns = (): TableProps<CEmployerType>['columns'] => {
  const { data } = useGetEmployer1cQuery({ page: 1, size: 500 });

  return [
    {
      title: t('common_name'),
      dataIndex: 'name',
      render: (id: string) => (
        <Typography.Text size="14" weight="500">
          {id}
        </Typography.Text>
      ),
    },
    {
      title: t('employee_group_leader'),
      dataIndex: 'parentId',
      align: 'left',
      render: (parentId: string) => {
        if (!parentId) return null;

        return (
          <Typography.Text size="14" weight="500">
            {data?.employers?.find((employer) => employer.id === parentId)?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: t('1с_instance_kedo_enabled'),
      dataIndex: 'signEnabled',
      align: 'center',
      render: (value: boolean) => <TableIsPublish isPublish={value} />,
    },
    {
      title: t('1с_instance_certificate_issue'),
      dataIndex: 'canIssueCert',
      align: 'center',
      render: (value: boolean) => <TableIsPublish isPublish={value} />,
    },
    {
      title: t('1с_instance_deleted'),
      dataIndex: 'deletemark',
      align: 'center',
      render: (value: boolean) => <TableIsPublish isPublish={value} danger />,
    },
  ];
};

export default useColumns;
