import { t } from 'tools/i18n';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import UserAgreementsTable from './containers/table';
import useGetData from './useGetData.hook';

const SettingsUserAgreements = () => {
  const { data, isFetching, isLoading } = useGetData();

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('common_user_agreements')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2>
        <UserAgreementsTable loading={isFetching} isInitialLoading={isLoading} dataSource={data} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SettingsUserAgreements;
