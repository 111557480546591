import cInitialApiService from 'services/1c/cApiService';
import { createSynchronizationUrl } from 'services/synchronization/utils';

import { SyncPayslipPropsType, SyncPayslipResponseType } from './synchronizationApiService.types';

const synchronizationApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    syncPayslip: builder.mutation<SyncPayslipResponseType, SyncPayslipPropsType>({
      query: (body) => ({
        url: createSynchronizationUrl('/payslip'),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSyncPayslipMutation } = synchronizationApiService;
