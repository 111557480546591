import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useGetCommunitySurveyByIdQuery,
  usePublishCommunitySurveyMutation,
  useUnpublishCommunitySurveyMutation,
} from 'services/communities/communitiesApiService';

import { Button, Flex, Icon, Typography, useHandleRequestWithModal } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';

const Header = () => {
  const { id, surveyId } = useParams();

  const [unpublish, { isLoading: isUnpublishLoading }] = useUnpublishCommunitySurveyMutation();
  const [publish, { isLoading: isPublishLoading }] = usePublishCommunitySurveyMutation();
  const { data: survey, isLoading: surveyIsLoading } = useGetCommunitySurveyByIdQuery(
    { communityId: id!, surveyId: surveyId! },
    { skip: !id || !surveyId },
  );

  const { name, createdAt, isPublish } = survey || {};

  const handleAfterPublish = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_published'),
      },
    },
  });

  const handleAfterUnpublish = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_unpublished'),
      },
    },
  });

  const handlePublish = (isPublish: boolean) => () => {
    if (!id || !surveyId) return;

    if (isPublish) {
      publish({ communityId: id, surveyId: surveyId! }).then(handleAfterPublish);
      return;
    }

    unpublish({ communityId: id, surveyId }).then(handleAfterUnpublish);
  };

  const description = (
    <Flex gap="8" align="flex-end">
      <Icon name="calendar" />
      <Typography.Text type="secondary">{`${t('common_created')}: ${dayjs(createdAt)
        .local()
        .format('DD.MM.YYYY, HH:mm')}`}</Typography.Text>
    </Flex>
  );

  const buttonProps = isPublish
    ? ({
        danger: true,
        type: 'secondary',
        children: t('common_unpublish'),
        onClick: handlePublish(false),
      } as const)
    : ({
        type: 'primary',
        children: t('common_publish'),
        onClick: handlePublish(true),
      } as const);

  return (
    <WithLoader isLoading={surveyIsLoading}>
      <ContentHeader>
        <ContentHeader.Title description={description} goBackLink={-1}>
          {name}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            leftIcon="publish"
            loading={isPublishLoading || isUnpublishLoading}
            {...buttonProps}
          />
        </ContentHeader.Actions>
      </ContentHeader>
    </WithLoader>
  );
};

export default Header;
