import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetHandbooksQuery } from 'services/settlement/settlementApiService';
import { GetHandbooksResponseType } from 'services/settlement/settlementApiService.types';

import MultiTagsSelect from 'components/form/multi-tags-select';

import { IMPROVEMENTS_FORM_ENUM } from '../newsForm.utils';

const DEFAULT_PAGINATION = { page: 1, size: 500 };

interface Props {
  initialValues?:
    | {
        id: string;
        name: string;
      }[]
    | null;
}

const FormSettlements = (props: Props) => {
  const { initialValues } = props;

  const [search, setSearch] = useState('');
  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { options } = useGetHandbooksQuery(
    { ...DEFAULT_PAGINATION, name: debouncedSearch },
    { selectFromResult },
  );

  const { initialIds } = useMemo(() => {
    const initialIds = initialValues?.map((el) => el.id) ?? [];

    return { initialIds };
  }, [initialValues]);

  return (
    <MultiTagsSelect
      name={IMPROVEMENTS_FORM_ENUM.SETTLEMENT_IDS}
      title={t('common_settlement')}
      placeholder={t('common_select_placeholder')}
      options={options}
      onSearch={setSearch}
      searchValue={search}
      initialValues={initialIds}
      isRequired={true}
    />
  );
};

export default FormSettlements;

const selectFromResult = ({
  data,
  ...props
}: {
  data?: GetHandbooksResponseType;
  isFetching: boolean;
}) => {
  const options = data?.items.map((el) => ({ label: el.name, value: el.id }));

  return {
    options: options ?? [],
    ...props,
  };
};
