import { useNavigate, useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import s from './CommunityManagementForm.module.scss';
import CommunityManagementFormCategories from './community-management-form-categories';
import CommunityManagementFormDescription from './community-management-form-description';
import CommunityManagementFormIsPublish from './community-management-form-is-publish';
import CommunityManagementFormLinks from './community-management-form-links';
import CommunityManagementFormMedia from './community-management-form-media';
import CommunityManagementFormModerators from './community-management-form-moderators';
import CommunityManagementFormName from './community-management-form-name';
import CommunityManagementFormType from './community-management-form-type';
import {
  COMMUNITY_MANAGEMENT_FORM_ENUM,
  COMMUNITY_MANAGEMENT_FORM_NAME,
  CommunityManagementFormResponse,
} from './communityManagementForm.utils';

type CommunityManagementFormProps = {
  handleSubmit: (form: CommunityManagementFormResponse) => void;
  form: FormInstance<CommunityManagementFormResponse>;
  isCreatingOrUpdating: boolean;
};

const CommunityManagementForm = (props: CommunityManagementFormProps) => {
  const { handleSubmit, form, isCreatingOrUpdating } = props;
  const navigate = useNavigate();
  const { id: communityId } = useParams<{ id: string }>();
  const closeFormLink = communityId
    ? `${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}`
    : COMMUNITY_MANAGEMENT_ROUTE;

  const handleClose = () => navigate(closeFormLink);

  return (
    <div className={s.container}>
      <Form
        form={form}
        className={s.wrapper}
        onFinish={handleSubmit}
        id={COMMUNITY_MANAGEMENT_FORM_NAME}>
        <CommunityManagementFormName />
        <CommunityManagementFormDescription />
        <CommunityManagementFormMedia
          fieldName={COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR}
          label={t('communities_avatar')}
        />
        <CommunityManagementFormMedia
          fieldName={COMMUNITY_MANAGEMENT_FORM_ENUM.COVER}
          label={t('communities_cover')}
        />
        <CommunityManagementFormType />
        <CommunityManagementFormCategories />
        <CommunityManagementFormLinks />
        <CommunityManagementFormModerators />
        <CommunityManagementFormIsPublish />

        <Flex gap="8">
          <Button
            onClick={handleClose}
            htmlType="button"
            type="secondary"
            fullWidth
            form={COMMUNITY_MANAGEMENT_FORM_NAME}>
            {t('common_close')}
          </Button>
          <Button
            leftIcon="ok"
            fullWidth
            type="primary"
            htmlType="submit"
            form={COMMUNITY_MANAGEMENT_FORM_NAME}
            loading={isCreatingOrUpdating}>
            {t('common_save')}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default CommunityManagementForm;
