import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementSurveysByIdRoute } from 'routes/communities/list';

import { useGetCommunitySurveysListQuery } from 'services/communities/communitiesApiService';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityAnketaType } from 'types/community.types';

import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType, columns } from './surveys.utils';

const Surveys = () => {
  const navigate = useNavigate();
  const { id: communityId } = useParams<{ id: string }>();

  const [form] = Form.useForm<FormResponseType>();
  const requestBody: FormResponseType = Form.useWatch([], form);

  const { data: surveysList, isLoading } = useGetCommunitySurveysListQuery({
    communityId: communityId!,
    ...requestBody,
  });

  const onRow = (entity: CommunityAnketaType) => {
    const { id: anketaLinkId, anketologId, revisionId } = entity;
    const path = getCommunityManagementSurveysByIdRoute(
      communityId!,
      anketaLinkId,
      anketologId,
      revisionId.toString(),
    );
    return {
      onClick: () => navigate(path),
    };
  };

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters />
      <FiltersV2.Table<CommunityAnketaType, FormResponseType>
        dataSource={surveysList?.items}
        loading={isLoading}
        total={surveysList?.totalCount}
        onRow={onRow}
        columns={columns}
      />
    </FiltersV2>
  );
};

export default Surveys;
