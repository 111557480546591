import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { SUGGESTIONS_LIST_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { useGetExpertCommissionByIdQuery } from 'services/expert-commissions/expertCommissionsApiService';
import { useGetSuggestionByIdQuery } from 'services/suggestion/suggestionApiService';

import { Button, Flex, StepsProps, Tabs, TabsProps } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SuggestionsListDetails.module.scss';
import Commission from './containers/commission';
import Description from './containers/description';
import Header from './containers/header';
import Performer from './containers/performer';
import TextField from './containers/text-field';

const SuggestionsListDetails = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { data: suggestion, isFetching: suggestionIsFetching } = useGetSuggestionByIdQuery(
    { id: params.id! },
    { skip: !params.id },
  );
  const { data: commission, isFetching: commissionIsFetching } = useGetExpertCommissionByIdQuery(
    suggestion?.commissionId ? { id: suggestion?.commissionId } : skipToken,
  );

  const { employee, coordinator, category, name, curStatus, statusHistory, createdAt } =
    suggestion ?? {};

  const items = useMemo<TabsProps['items']>(() => {
    const tabs = [];

    const { id: suggestionId, curStatus, releaseReportFile, performer } = suggestion ?? {};

    if (suggestion) {
      tabs.push({
        label: t('common_description'),
        key: 'description',
        children: <Description {...suggestion} />,
      });
    }

    if (commission) {
      tabs.push({
        label: t('common_commission'),
        key: 'commission',
        children: <Commission {...commission} />,
      });
    }

    const showPerformerTab = performer && suggestionId;
    if (showPerformerTab) {
      tabs.push({
        label: t('common_realization'),
        key: 'performer',
        children: (
          <Performer
            releaseReportFile={releaseReportFile}
            performer={performer}
            curStatus={curStatus}
            suggestionId={suggestionId}
          />
        ),
      });
    }

    return tabs;
  }, [commission, suggestion]);

  const handleGoBack = () => {
    navigate(SUGGESTIONS_LIST_ROUTE);
  };

  const isFetching = commissionIsFetching || suggestionIsFetching;

  return (
    <WithOverflow>
      <Header status={curStatus} createdDate={createdAt} />
      <WithLoader isLoading={isFetching}>
        <DocumentView>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              <DocumentView.Employee employee={employee} showAvatar />
              <TextField title={t('suggestion_category')} value={category?.name} />
              <TextField title={t('suggestion_name')} value={name} />
              {coordinator && (
                <DocumentView.Employee
                  employee={coordinator}
                  showAvatar
                  title={t('suggestions_coordinator')}
                />
              )}
              <Tabs items={items} type="card" className={s.tabs} />
            </Flex>
            <DocumentView.History historyItems={statusHistory as StepsProps['items']} />
          </DocumentView.Content>
          <Button type="secondary" className={s.closeButton} onClick={handleGoBack}>
            {t('common_close')}
          </Button>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default SuggestionsListDetails;
