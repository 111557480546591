import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  search?: string;
  property?: string;
  order: SORT_ENUM;
};
export const DEFAULT_FORM_RESPONSE: FormResponseType = {
  page: 1,
  size: 500,
  property: 'createdAt',
  order: SORT_ENUM.DESC,
};
