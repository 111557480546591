import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

export const getTagConfig = (state: string) => {
  let iconName: TIconsLiteral = 'time';
  let tagType: TagType = 'warning';

  if (state === 'done') {
    iconName = 'ok';
    tagType = 'success';
  }

  if (state === 'error') {
    iconName = 'deleteFilled';
    tagType = 'danger';
  }

  return { iconName, tagType };
};
